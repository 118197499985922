const deriveModulesWithNewAmountOfTestItems = (contents, prevModules, currentModuleId) => {
  const amountOfQuestionContentsInCurrentModule = contents.filter((content) =>
    ['yes-no', 'true-false', 'multiple-choice', 'multiple-choice-2', 'nps'].includes(content.content_type)
  ).length;

  const currentModuleIndex = prevModules.findIndex((li) => li.id === currentModuleId);
  const currentModule = prevModules[currentModuleIndex];

  const newModules = [
    ...prevModules.slice(0, currentModuleIndex),
    {
      ...currentModule,
      amount_of_question_contents: amountOfQuestionContentsInCurrentModule
    },
    ...prevModules.slice(currentModuleIndex + 1)
  ];

  return newModules;
};

export default deriveModulesWithNewAmountOfTestItems;
