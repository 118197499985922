import React from 'react';
import PropTypes from 'prop-types';

import Utils from '~/services/Utils';
import StudentApi from '~/api/StudentApi';
import TestService from '../../services/TestService';

const ITEM_MAX_WIDTH = 70;

class SurveyMultipleChoice extends React.Component {
  static propTypes = {
    content: PropTypes.object.isRequired,
    user_role: PropTypes.string.isRequired,
    view_as: PropTypes.bool.isRequired,
    if_current_module_is_completed: PropTypes.bool.isRequired,
    uiUpdateContent: PropTypes.func.isRequired,
    isNps: PropTypes.bool,
  };

  uiUpdateAnswer = (answerid, updatedIndex) => {
    const { content } = this.props;
    const selectedAnswer = content.answers[updatedIndex];
    const updatedContent = {
      ...content,
      student_correct: selectedAnswer.is_correct_answer,
      answers: [
        ...content.answers.slice(0, updatedIndex),
        {
          ...selectedAnswer,
          selected: true,
        },
        ...content.answers.slice(updatedIndex + 1),
      ],
    };
    updatedContent.answers.forEach(answer => {
      if (answer.id !== selectedAnswer.id) {
        answer.selected = false;
      }
    });
    this.props.uiUpdateContent(updatedContent);

    StudentApi.answerTest(false, {
      content_id: content.id,
      answer_id: answerid,
    }).then(() => {
      TestService.countItems();
      TestService.validateQuestions();
    }).catch(() => {
      this.props.uiUpdateContent(content);
    });
  }

  render = () => {
    const {
      content, user_role, view_as, if_current_module_is_completed, isNps
    } = this.props;
    const answersWithoutNotAppl = content.answers.filter(answer => answer.payload !== 'Not Applicable');
    const notApplicable = content.answers.find(answer => answer.payload === 'Not Applicable');
    const selectedAnswer = answersWithoutNotAppl.find(answer => answer.selected);
    return (
      <div className="answer-container">
        <div className="survey-multiple-choice" style={{maxWidth: ITEM_MAX_WIDTH * answersWithoutNotAppl.length}}>
          <div className="first-row">
            <span>{ isNps ? "Not at all Likely" : Utils.stripHtml(answersWithoutNotAppl[0].payload)}</span>
            <span>{ isNps ? "Extremely Likely" : Utils.stripHtml(answersWithoutNotAppl[answersWithoutNotAppl.length - 1].payload)}</span>
          </div>
          {
            answersWithoutNotAppl.map((answer, index) => {
              const hlsColor = Utils.hslColorByPercent(index/answersWithoutNotAppl.length*100, 0, 120);
              
              return <label key={answer.id} className="answer-radio-box" style={{width: `${100/answersWithoutNotAppl.length}%`}}>
                <input
                  type="radio"
                  className="question-answer"
                  name={`multiple-choice-radio${content.id}`}
                  value={answer.id}
                  checked={(user_role === 'creator' && !view_as && (answer.is_correct_answer || answer.selected)) || (user_role === 'student' && answer.selected)}
                  disabled={if_current_module_is_completed}
                  onChange={() => this.uiUpdateAnswer(answer.id, index)}
                />
                  <span className="box-label" style={{backgroundColor: answer.selected ? hlsColor : '' }}>{ isNps ? index : index + 1}</span>
                  <div className="top-bar" style={{backgroundColor: hlsColor}}></div>
              </label>;
            })
          }
          {selectedAnswer && <p className="selected-text">{Utils.stripHtml(selectedAnswer.payload)}</p>}
          {notApplicable && <label key={notApplicable.id} className="not-applicable-radio-box">
              <input
                type="radio"
                name={`multiple-choice-radio${content.id}`}
                value={notApplicable.id}
                checked={(user_role === 'creator' && !view_as && (notApplicable.is_correct_answer || notApplicable.selected)) || (user_role === 'student' && notApplicable.selected)}
                disabled={if_current_module_is_completed}
                onChange={() => this.uiUpdateAnswer(notApplicable.id, answersWithoutNotAppl.length)}
              />
              Not Applicable
            </label>}
        </div>
      </div>
    );
  }
}

export default SurveyMultipleChoice;

