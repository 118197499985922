import React from 'react';
import PropTypes from 'prop-types';

import TogglerAndModal from '~/_partials/TogglerAndModal';
import TimeInput from '../../TimeInput';

import css from './index.scss';

class AddCustomTimeTogglerAndModal extends React.Component {
  constructor(props) {
    super(props);
    this.modalWrapperRef = React.createRef();
  }

  static propTypes = {
    toggler: PropTypes.element.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  state = {
    formState: {
      custom_hour: '',
      custom_minute: '',
    }
  }

  clearState = () => {
    this.setState({
      formState: {
        custom_hour: '',
        custom_minute: '',
      },
    });
  }

  handleSaveClick = (closeModal) => {
    const { formState } = this.state;
    this.clearState();
    closeModal();
    this.props.onSave({
      hour: formState.custom_hour,
      minute: formState.custom_minute,
    });
  }

  onClickOnModalWrapper = (event, closeModal) => {
    const elClickedOn = event.target;
    const modalWrapper = this.modalWrapperRef.current;

    if (elClickedOn === modalWrapper) {
      closeModal();
    }
  }

  renderFooter = (closeModal) => {
    const { formState } = this.state;
    const isSaveDisabled = formState.custom_hour.length === 0 && formState.custom_minute.length === 0;

    return (<div className="standard-modal-footer -with-buttons">
      <button
        className="standard-button -sliding -red"
        type="reset"
        onClick={closeModal}
      >Cancel</button>
      <button
        className="standard-button -sliding -blue"
        type="submit"
        disabled={isSaveDisabled}
        onClick={() => this.handleSaveClick(closeModal)}
      >Save</button>
    </div>);
  }

  render = () =>
    <TogglerAndModal
      toggler={this.props.toggler}
      afterModalCloses={this.clearState}
    >{(closeModal, renderCloseButton) =>
      <div
        className={`standard-modal-wrapper ${css.modal}`}
        onClick={e => this.onClickOnModalWrapper(e, closeModal)} ref={this.modalWrapperRef}
      >
        <div className="standard-modal -with-white-header">
          {renderCloseButton()}

          <div className="standard-modal-header">Add Custom Time</div>
          <div className="standard-modal-main">
            <p>Allow extra time for students requiring special accomodations.</p>
            <TimeInput
              className="extra-time-input"
              label="Extra Time:"
              hourName="custom_hour"
              minuteName="custom_minute"
              formState={this.state.formState}
              updateFormState={formState => this.setState({ formState })}
            />
          </div>

          {this.renderFooter(closeModal)}
        </div>
      </div>
    }</TogglerAndModal>
}

export default AddCustomTimeTogglerAndModal;
