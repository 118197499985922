import React from 'react';
import PropTypes from 'prop-types';

import ProgramDescriptionTogglerAndModal from '~/_appPartials/ProgramDescriptionTogglerAndModal';

import css from './index.scss';

class ProgramTitle extends React.Component {
  static propTypes = {
    program: PropTypes.object.isRequired
  }

  render = () =>
    <section className={css.section}>
      <h1 className="program-name">{this.props.program.name}</h1>

      <ProgramDescriptionTogglerAndModal
        programId={this.props.program.id}
        initialProgramDescription={this.props.program.description}
      />
    </section>
}

export default ProgramTitle;
