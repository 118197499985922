import React from 'react';
import PropTypes from 'prop-types';

import SlidyTextInput from '~/_partials/SlidyTextInput';

class SectionUploadFromUrl extends React.Component {
  static propTypes = {
    videoUrl: PropTypes.string.isRequired,
    updateVideoUrl: PropTypes.func.isRequired
  }

  render = () =>
    <section className="upload-section -fromUrl">
      <SlidyTextInput
        className="slidy-input"
        value={this.props.videoUrl}
        onChange={(event) => this.props.updateVideoUrl(event.target.value)}
        placeholder="Enter video URL"
        autoComplete="off"
      />
    </section>
}

export default SectionUploadFromUrl;
