import React from 'react';
import PropTypes from 'prop-types';

import css from './index.scss';

class SwitchInput extends React.Component {
  static propTypes = {
    value: PropTypes.any.isRequired,
    updateValue: PropTypes.func.isRequired,

    // array of 2 values, e.g. ['monthly', 'yearly']
    possibleValues: PropTypes.array.isRequired,
    labelLeft: PropTypes.string,
    labelRight: PropTypes.string,
    className: PropTypes.string
  }

  static defaultProps = {
    possibleValues: [false, true],
    labelLeft: '',
    labelRight: '',
    className: ''
  }

  toggle = () => {
    if (this.props.value === this.props.possibleValues[0]) {
      this.props.updateValue(this.props.possibleValues[1]);
    } else {
      this.props.updateValue(this.props.possibleValues[0]);
    }
  }

  render = () =>
    <div className={`${css.div} ${this.props.value === this.props.possibleValues[0] ? '-left' : '-right'} ${this.props.className}`} onClick={this.toggle}>
      <label className="left-label">{this.props.labelLeft}</label>
      <button type="button" className="rectangle">
        <div className="circle"/>
      </button>
      <label className="right-label">{this.props.labelRight}</label>
    </div>
}

export default SwitchInput;
