import React from 'react';
import PropTypes from 'prop-types';

import ContentApi from '~/api/ContentApi';
import disableOnSpeRequest from '~/services/disableOnSpeRequest';

class PageBreakContentLi extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      id: PropTypes.number.isRequired,
      content_type: PropTypes.oneOf(['page-break']).isRequired,
    }).isRequired,
    liProps: PropTypes.object.isRequired,
    uiDeleteContent: PropTypes.func.isRequired
  }

  state = {
    speDelete: {}
  }

  apiDelete = () =>
    ContentApi.delete(
      (spe) => this.setState({ speDelete: spe }),
      this.props.content.id
    )
      .then(() => {
        this.props.uiDeleteContent(this.props.content.id);
      })

  render = () =>
    <li {...this.props.liProps} style={{ ...this.props.liProps.style, ...disableOnSpeRequest(this.state.speDelete) }}>
      <hr/>

      <button className="delete-content-button" type="button" onClick={this.apiDelete}>
        <i className="fa fa-remove"/>
      </button>
    </li>
}

export default PageBreakContentLi;
