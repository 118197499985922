import React from 'react';
import PropTypes from 'prop-types';
import StudentApi from '~/api/StudentApi';
import SelectDropdown from '~/_partials/SelectDropdown';
import TestService from '../../services/TestService';

class DropdownContent extends React.Component {
  static propTypes = {
    content: PropTypes.object.isRequired,
    if_current_module_is_completed: PropTypes.bool.isRequired,
  }

  state = {
    selectedOption: '',
    ifActionsDropdownIsOpen: false
  }

  uiOpenDropdown = () => {
    const { if_current_module_is_completed } = this.props;
    const { ifActionsDropdownIsOpen } = this.state;

    if (!if_current_module_is_completed) {
      this.setState({
        ifActionsDropdownIsOpen: !ifActionsDropdownIsOpen
      });
    }
  }

  uiUpdateDropdownOption = (itemValue) => {
    const { content, if_current_module_is_completed } = this.props;
    this.setState({
      selectedOption: itemValue,
      ifActionsDropdownIsOpen: false,
    });

    if (!if_current_module_is_completed) {
      StudentApi.answerFreeResponse(false, {
        content_id: content.id,
        freeresponse: itemValue,
      }).then(() => {
        TestService.countItems();
      });
    }
  }

  render = () => {
    const { content, if_current_module_is_completed } = this.props;
    const { selectedOption, ifActionsDropdownIsOpen } = this.state;
    return (
      <div className="test-dropdown dropdown test-answers">
        <button
          className="dropdown-toggle"
          onClick={this.uiOpenDropdown}
          disabled={if_current_module_is_completed}
        >
          <input
            className="dropdown-input"
            value={(if_current_module_is_completed && content.answers.length > 0) ? content.answers[0].payload : selectedOption}
            placeholder="Drop-down response options"
            disabled={if_current_module_is_completed}
            readOnly
          />
          <span className="caret"/>
        </button>
        <SelectDropdown
          open={ifActionsDropdownIsOpen}
          onCloseDropdown={() => this.setState({ ifActionsDropdownIsOpen: false })}
        >
          {content.options.split(',').map((option, index) =>
            <li
              key={index}
              onClick={() => this.uiUpdateDropdownOption(option)}
            >
              <a>{option}</a>
            </li>
          )}
        </SelectDropdown>
      </div>
    );
  }
}

export default DropdownContent;
