import React from 'react';
import PropTypes from 'prop-types';

import Utils from '~/services/Utils';

class FileContent extends React.Component {
  static propTypes = {
    content: PropTypes.object.isRequired,
  };

  render = () => {
    const { content } = this.props;
    return (
      <div>
        <i className="fa fa-file-text-o" style={{ fontSize: 50 }}/>
        <a id="content-file-upload-link" href={Utils.stripHtml(content.payload)}>
          <span id="content-file-upload-title">{content.title}</span>
        </a>
        <br/>
        <span id="content-file-upload-desc">{content.description}</span>
      </div>
    );
  }
}

export default FileContent;

