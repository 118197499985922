import React from 'react';
import PropTypes from 'prop-types';
import { Rnd } from 'react-rnd';

import Constants from '~/services/Constants';
import Utils from '~/services/Utils';
import ContentApi from '~/api/ContentApi';
import ParagraphTogglerAndModal from '../../_contentModals/ParagraphTogglerAndModal';

class ParagraphContentLi extends React.Component {
  static propTypes = {
    itemType: PropTypes.string.isRequired,
    content: PropTypes.shape({
      id: PropTypes.number.isRequired,
      content_type: PropTypes.oneOf(['paragraph', 'cert-text', 'field']).isRequired,
      // html string
      payload: PropTypes.string.isRequired,
      options: PropTypes.string,
      explanation: PropTypes.string,
    }).isRequired,
    courseName: PropTypes.string.isRequired,
    organizationName: PropTypes.string.isRequired,
    liProps: PropTypes.object.isRequired,
    uiUpdateContent: PropTypes.func.isRequired,
    uiDeleteContent: PropTypes.func.isRequired,
    uiUpdateCertificateElementLayout: PropTypes.func,
  }

  state = {
    fieldPayload: '',
    ifEnableToggler: true,
  }

  componentDidMount() {
    const { content } = this.props;

    if (content.content_type === 'field') {
      const fieldType = Utils.parseHtmlProps(content.payload, 'title');
      const fieldId = parseInt(fieldType, 10);

      if (fieldId > 0) {
        this.apiGetFieldContent(fieldId)
          .then(data =>
            this.setState({ fieldPayload: Utils.stripHtml(data.payload) })
          );
      }
    }
  }

  apiGetFieldContent = (fieldContentId) =>
    ContentApi.get(false, fieldContentId)

  apiDelete = () =>
    ContentApi.delete(
      (spe) => this.setState({ speDelete: spe }),
      this.props.content.id
    )
      .then(() => {
        this.props.uiDeleteContent(this.props.content.id);
      })

  getContentPayload = () => {
    const { content } = this.props;

    if (content.content_type === 'field') {
      const fieldType = Utils.parseHtmlProps(content.payload, 'title');
      const fieldId = parseInt(fieldType, 10);

      if (fieldType === Constants.FIELD_TYPE.program) {
        return this.props.courseName;
      } else if (fieldType === Constants.FIELD_TYPE.organization) {
        return this.props.organizationName;
      } else if (fieldType === Constants.FIELD_TYPE.completion_date) {
        return 'September 12, 2019';
      } else if (fieldType === Constants.FIELD_TYPE.student_name) {
        return 'Student Name';
      } else if (fieldType === Constants.FIELD_TYPE.certificate_id) {
        return 'Certificate ID: 100';
      } else if (fieldId > 0) {
        return this.state.fieldPayload !== '' ? this.state.fieldPayload : content.payload;
      } else {
        return content.payload;
      }
    } else {
      return content.payload;
    }
  }

  uiUpdateContentElementLayout = (ifUpdateFinished) => {
    if (ifUpdateFinished) {
      if (!this.state.ifEnableToggler) {
        const contentElement = this.resizer ? this.resizer.getSelfElement() : null;

        if (contentElement) {
          this.props.uiUpdateCertificateElementLayout(contentElement);
        }

        setTimeout(() => this.setState({ ifEnableToggler: true }));
      }
    } else if (this.state.ifEnableToggler) {
      this.setState({ ifEnableToggler: false });
    }
  }

  uiDeleteContent = () => {
    this.apiDelete();
  }

  renderCertToggler = (content) =>
    <Rnd
      className={`certificate-resizer ${content.content_type === 'field' ? '-field' : ''}`}
      ref={rndRef => this.resizer = rndRef}
      default={{
        x: content.options ? parseInt(content.options.split(', ')[0], 10) : 0,
        y: content.options ? parseInt(content.options.split(', ')[1], 10) : 0,
        width: Utils.parseHtmlTagCSS(content.payload, 'width'),
        height: Utils.parseHtmlTagCSS(content.payload, 'height'),
      }}
      bounds="parent"
      onResize={() => this.uiUpdateContentElementLayout(false)}
      onResizeStop={() => this.uiUpdateContentElementLayout(true)}
      onDrag={() => this.uiUpdateContentElementLayout(false)}
      onDragStop={() => this.uiUpdateContentElementLayout(true)}
    >
      <div className="standard-article" dangerouslySetInnerHTML={{ __html: this.getContentPayload() }}/>
      {content.content_type === 'field' &&
        <button className="button-remove-cert" onClick={this.uiDeleteContent}>
          <i className="fa fa-times"/>
        </button>
      }
    </Rnd>

  renderLi = (content) =>
    <li {...this.props.liProps}>
      <div className="standard-article" dangerouslySetInnerHTML={{ __html: content.payload }}/>
    </li>

  render = () =>
    <ParagraphTogglerAndModal
      toggler={this.props.content.content_type === 'paragraph' ? this.renderLi(this.props.content) : this.renderCertToggler(this.props.content)}
      enableToggler={this.props.content.content_type === 'field' ? false : this.state.ifEnableToggler}
      itemType={this.props.itemType}
      content={this.props.content}
      afterContentIsUpdated={this.props.uiUpdateContent}
      afterContentIsDeleted={this.props.uiDeleteContent}
    />
}

export default ParagraphContentLi;
