import React from 'react';
import PropTypes from 'prop-types';
import orFalse from '~/services/orFalse';

import TogglerAndModal from '~/_partials/TogglerAndModal';
import PROGRAM_COMPLEGE_JPG from '~/../static/frontend/img/program-complete.jpg';

import css from './index.scss';

class TestCompleteModal extends React.Component {
  static propTypes = {
    toggler: PropTypes.element.isRequired,
    programTitle: PropTypes.string.isRequired,
    image: orFalse(PropTypes.string).isRequired,
  };

  ifHasNextModule
  handleContinueClick = (closeModal) => {
    closeModal();
    window.location = '/dashboard-programs/';
  };

  render() {
    return (
      <TogglerAndModal
        toggler={this.props.toggler}
      >{(closeModal) =>
        <div className={`standard-modal-wrapper ${css.modal}`}>
          <div className="standard-modal">
            <div className="standard-modal-header">Program Completed</div>
            <div className="standard-modal-main">
              <img className="complete-image" src={this.props.image ? this.props.image : PROGRAM_COMPLEGE_JPG}/>
              <p>Thank you for completing the <strong>{this.props.programTitle}</strong> program!</p>
              <p className="complete-info">
                Your program has been submitted successfully and your score will be emailed to you once your free response questions have been graded.
              </p>
            </div>
            <div className="standard-modal-footer -with-buttons -vertical-center">
              <button
                className="standard-button -border"
                type="button"
                onClick={() => this.handleContinueClick(closeModal)}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      }</TogglerAndModal>
    );
  }
}

export default TestCompleteModal;
