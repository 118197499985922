import React from 'react';
import PropTypes from 'prop-types';

import GetQuestionDetails from '../services/GetQuestionDetails';

class SimpleAnswers extends React.Component {
  static propTypes = {
    itemType: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    questionDetails: PropTypes.array.isRequired,
    updateQuestionDetails: PropTypes.func.isRequired
  }

  uiUpdateQuestionDetailIsCorrect = (index) =>
    this.props.updateQuestionDetails(
      GetQuestionDetails.withCorrectAnswerAtIndex(this.props.questionDetails, index)
    )

  renderQuestionDetail = (questionDetail, index) =>
    <li
      key={index}
      className="answer"
      onClick={() => this.uiUpdateQuestionDetailIsCorrect(index)}
    >
      <div className="if-correct-answer-radio-input">
        <input
          type="radio"
          checked={questionDetail.isCorrectAnswer}
          readOnly
        />
      </div>
      <div className="answer-payload">{questionDetail.payload}</div>
    </li>

  render = () =>
    <div className={`answers ${this.props.className} -simple`}>
      {this.props.itemType === 'test' &&
        <div className="correct-thead">Correct</div>
      }

      <ul>
        {this.props.questionDetails.map(this.renderQuestionDetail)}
      </ul>
    </div>;
}

export default SimpleAnswers;
