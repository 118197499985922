import React from 'react';
import PropTypes from 'prop-types';

import disableOnSpeRequest from '~/services/disableOnSpeRequest';
import TransloaditApi from '~/api/TransloaditApi';
import ContentApi from '~/api/ContentApi';

import VideoTogglerAndModal from '../../_contentModals/VideoTogglerAndModal';
import LinearProgress from '@material-ui/core/LinearProgress';

import videoIsProcessingPlaceholderPngSrc from './videoIsProcessingPlaceholder.png';

class VideoContentLi extends React.Component {
  static propTypes = {
    content: PropTypes.object.isRequired,
    liProps: PropTypes.object.isRequired,
    uiUpdateContent: PropTypes.func.isRequired,
    uiDeleteContent: PropTypes.func.isRequired
  }

  state = {
    // 'request', 'success', 'failure'
    videoStatus: this.props.content.transloadit_assembly_url ? 'request' : 'success',
    videoStatusMessage: '',
    speDelete: {}
  }

  // As soon as component mounted - start fetching transloadit to determine its status.
  componentDidMount = () => {
    // ___When is this condition true?
    //    1. When we have just added the content, and it's TUSing (yeah yeah, a couple of requests will be useless, who cares).
    //    2. When we have reloaded the page, and transloadit_assembly_url is present.
    if (this.props.content.transloadit_assembly_url) {
      this.startCheckingVideoStatus();
    }
  }

  componentWillUnmount = () => {
    this.stopCheckingVideoStatus();
  }

  stopCheckingVideoStatus = () =>
    this.intervalId && clearInterval(this.intervalId)

  startCheckingVideoStatus = () => {
    this.apiCheckVideoStatus();
    this.intervalId = setInterval(
      this.apiCheckVideoStatus,
      10 * 1000
    );
  }

  apiCheckVideoStatus = () => {
    const assemblyUrl = this.props.content.transloadit_assembly_url;
    TransloaditApi.getAssembly(false, assemblyUrl)
      .then((response) => {
        console.log(response);
        if (response.ok) {
          switch (response.ok) {
            // 1. Success?
            case 'ASSEMBLY_COMPLETED': {
              this.stopCheckingAndSetStatusToSuccess();
              this.apiUpdateVideoStatusToSuccess();
              break;
            }
            // 2. Still processing?
            case 'ASSEMBLY_UPLOADING':
            case 'ASSEMBLY_EXECUTING': {
              // this.state.videoStatus just stays 'request'
              break;
            }
            // 3. Or failed?
            case 'REQUEST_ABORTED':
            case 'ASSEMBLY_CANCELED':
              this.stopCheckingAndSetStatusToFailure(response.message);
              break;
            default:
              throw new Error(`Transloadit ok status shouldn't be ${response.ok}.`);
          }
        // 3. Or failed?
        } else if (response.error) {
          this.stopCheckingAndSetStatusToFailure(response.message);
        } else {
          throw new Error('Transloadit should either return .ok, or .error in response.');
        }
      })
        // 3. Or failed?
        .catch((error) => {
          this.stopCheckingAndSetStatusToFailure(error.toString());
        });
  }

  // ___Why do we need to remove .transloadit_assembly_url from content?
  //    This way next time we render this page, we don't have to query /transloadit for readiness.
  apiUpdateVideoStatusToSuccess = () =>
    ContentApi.removeTransloaditAssemblyUrlFromVideo(false, this.props.content.id)
      .then(this.props.uiUpdateContent)

  apiDelete = () =>
    ContentApi.delete(
      (spe) => this.setState({ speDelete: spe }),
      this.props.content.id
    )
      .then(() => {
        this.props.uiDeleteContent(this.props.content.id);
      })

  stopCheckingAndSetStatusToFailure = (errorString) => {
    this.stopCheckingVideoStatus();
    this.setState({ videoStatus: 'failure', videoStatusMessage: errorString });
  }

  stopCheckingAndSetStatusToSuccess = () => {
    this.stopCheckingVideoStatus();
    this.setState({ videoStatus: 'success', videoStatusMessage: '' });
  }

  renderDeleteButton = () =>
    <button className="delete-content-button" type="button" onClick={this.apiDelete}>
      <i className="fa fa-remove"/>
    </button>

  renderSuccess = (liProps) =>
    <VideoTogglerAndModal
      toggler={
        <li {...liProps}>
          <video controls>
            <source src={this.props.content.payload}/>
          </video>

          <label className="if-must-watch-entire-video">
            <input
              type="checkbox"
              checked={this.props.content.must_watch}
              readOnly
            />
            Must watch
          </label>
        </li>
      }
      content={this.props.content}
      afterContentIsUpdated={this.props.uiUpdateContent}
      afterContentIsDeleted={this.props.uiDeleteContent}
    />

  renderRequest = (liProps, type) =>
    <li {...liProps}>
      <a href="http://help.certcentral.com/video-uploads/video-processing-time/" target="_blank" rel="noopener noreferrer">
        <img className="placeholder" src={videoIsProcessingPlaceholderPngSrc}/>
        {
          type === 'waitingForTus' ?
            // 'waitingForTus'
            <LinearProgress variant="determinate" value={this.props.content._videoIsUploadingViaTus.payload.percentage}/> :
            // 'waitingForTransloadit'
            <LinearProgress variant="indeterminate"/>
        }
      </a>

      {this.renderDeleteButton()}
    </li>

  renderFailure = (liProps, errorString) =>
    <li {...liProps}>
      <div>
        Your video couldn't be uploaded. Please delete this one, and try again!
        ({errorString})
      </div>

      {this.renderDeleteButton()}
    </li>

  render = () => {
    const liProps = {
      ...this.props.liProps,
      ...disableOnSpeRequest(this.state.speDelete),
      className: `${this.props.liProps.className} ${this.state.videoStatus ? `-status-${this.state.videoStatus}` : ''}`
    };

    // 1. Is our video uploading from tus at the moment?
    if (this.props.content._videoIsUploadingViaTus) {
      switch (this.props.content._videoIsUploadingViaTus.status) {
        case 'request':
          return this.renderRequest(liProps, 'waitingForTus');
        case 'failure':
          return this.renderFailure(liProps, this.props.content._videoIsUploadingViaTus.payload.error);
        default:
          throw new Error(`There can be content._videoIsUploadingViaTus.status can't be '${this.props.content._videoIsUploadingViaTus.status}'.`);
      }
    // 2. Or did it already upload, and we are just fetching its status?
    } else {
      switch (this.state.videoStatus) {
        case 'request': return this.renderRequest(liProps, 'waitingForTransloadit');
        case 'success': return this.renderSuccess(liProps);
        case 'failure': return this.renderFailure(liProps, this.state.videoStatusMessage);
        // keep in mind <li/> with props needs to be returned in any case, because react-beautiful-dnd expects it.
        default: return <li {...liProps}/>;
      }
    }
  }
}

export default VideoContentLi;
