import React from 'react';
import PropTypes from 'prop-types';
import { StarBorder as StarOutlinedIcon } from '@material-ui/icons';

import RatingScaleTogglerAndModal from '../../_contentModals/RatingScaleTogglerAndModal';

const RATING_MAX_POINT = 5;

class RatingContentLi extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      content_type: PropTypes.oneOf(['rating-scale']).isRequired,
      payload: PropTypes.string.isRequired
    }).isRequired,
    liProps: PropTypes.object.isRequired,
    uiUpdateContent: PropTypes.func.isRequired,
    uiDeleteContent: PropTypes.func.isRequired,
  }

  renderRatingStars = () => {
    const ratingStarContents = [];

    for (let i = 0; i < RATING_MAX_POINT; i += 1) {
      ratingStarContents.push(<StarOutlinedIcon key={i}/>);
    }

    return ratingStarContents;
  }

  renderLi = () => {
    const { content } = this.props;
    return (
      <li {...this.props.liProps}>
        <label className="standard-label">{content.payload}</label>
        <div className="ratings">
          {this.renderRatingStars()}
        </div>
      </li>
    );
  }

  render = () =>
    <RatingScaleTogglerAndModal
      toggler={this.renderLi()}
      content={this.props.content}
      afterContentIsUpdated={this.props.uiUpdateContent}
      afterContentIsDeleted={this.props.uiDeleteContent}
    />
}

export default RatingContentLi;
