import ourFetch from './services/ourFetch';
import oldApiFetch from './services/oldApiFetch';
// import hashToQueryString from './services/hashToQueryString';

// old api method that uses formData, will change it later
const create = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST', '/api/section/add/',
    params
  );

// old api method reused
const reorderAll = (dispatch, arrayOfModuleIds) =>
  ourFetch(
    dispatch,
    'POST', '/api/modules/reorder/',
    {
      modules: arrayOfModuleIds.map((id, index) => ({
        id,
        position: index
      }))
    }
  );

const updateScoring = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/program-scoring/module/save/',
    params
  );

const duplicate = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/module/duplicate/',
    params
  );

const destory = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST', '/api/module/delete/',
    params
  );

export default {
  reorderAll,
  create,
  updateScoring,
  duplicate,
  destory,
};
