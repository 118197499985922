import hashToQueryString from '~/api/services/hashToQueryString';

const getPreviewCertificateUrl = (programId, formState) => {
  const baseUrl = `/certificate-preview/${programId}/`;
  const queryString = hashToQueryString({
    company_name: formState.company_name,
    company_photo: formState.company_photo,
    custom_certificate_text: formState.custom_certificate_text ? formState.custom_certificate_text : "",
    custom_field_1: formState.custom_field_1 ? formState.custom_field_1 + ': <' + formState.custom_field_1 + '>' : "",
    custom_field_2: formState.custom_field_2 ? formState.custom_field_2 + ': <' + formState.custom_field_2 + '>' : "",
  });
  return baseUrl + '?' + queryString;
};

export default getPreviewCertificateUrl;
