import React from 'react';
import PropTypes from 'prop-types';

import StandardTooltip from '~/_partials/StandardTooltip';

class SubscriptionPlanLi extends React.Component {
  static propTypes = {
    subscriptionPlan: PropTypes.object.isRequired,
    // updateSelectedSubscriptionPlan: PropTypes.func.isRequired
    continueUrl: PropTypes.string.isRequired
  }

  getMonthlyPrice = (subscriptionPlan) => {
    switch (subscriptionPlan.interval) {
      case 'Monthly': return subscriptionPlan.price;
      case 'Yearly': return Math.floor(subscriptionPlan.price / 12);
    }
  }

  renderActiveUsersTooltip = (title) =>
    <div>
      <b>{title}</b><br/>
      An <b>active user</b> is someone who has begun taking a training program within the 30-day billing period. Account logins are not counted toward user activity.
    </div>

  renderFeatures = (subscriptionPlan) =>
    <ul className="features">
      {subscriptionPlan.features.map((feature) =>
        <li key={feature.plan_feature}>{feature.plan_feature}</li>
      )}
    </ul>

  renderSubscriptionPlan = (subscriptionPlan) =>
    <li className="subscription-plan -standard">
      {/* STARTER */}
      <div className="name">{subscriptionPlan.name.toUpperCase()}</div>
      {/* $29/mo */}
      <div className="price-height-container">
        <div className="price">
          <span className="currency">{subscriptionPlan.currency}</span>
          <span className="number">{this.getMonthlyPrice(subscriptionPlan)}</span>
          <span className="interval">/mo</span>
        </div>
        { // $348 billed annually
          subscriptionPlan.interval === 'Yearly' &&
          <div className="annual-price">
            {`${subscriptionPlan.currency}${subscriptionPlan.price} billed annually`}
          </div>
        }
      </div>
      {/* 25 active users per month */}
      <StandardTooltip
        className="n-active-users-per-month"
        tooltipEl={this.renderActiveUsersTooltip(`Active users (${subscriptionPlan.maximum_executions})`)}
      >
        <React.Fragment>
          <mark>{subscriptionPlan.maximum_executions}</mark>
          active users <br/> per month
        </React.Fragment>
      </StandardTooltip>

      {this.renderFeatures(subscriptionPlan)}

      {
        // <button type="button" className="standard-button -blue" onClick={() => this.props.updateSelectedSubscriptionPlan(this.props.subscriptionPlan)}>
        //   Buy Now
        // </button>
      }

      <a className="standard-button -blue" href={`/checkout/${this.props.subscriptionPlan.stripe_plan_id}/?redirect_url=${encodeURIComponent(this.props.continueUrl)}`} target="_blank" rel="noopener noreferrer">
        Buy Now
      </a>
    </li>

  renderEnterprisePlan = (subscriptionPlan) =>
    <li className="subscription-plan -enterprise">
      <div className="name">ENTERPRISE</div>
      <div className="price-height-container">
        <div className="price">CUSTOM</div>
      </div>
      <StandardTooltip
        className="n-active-users-per-month"
        tooltipEl={this.renderActiveUsersTooltip('Active users (Custom)')}
      >
        <React.Fragment>
          Tailored to <br/> your business
        </React.Fragment>
      </StandardTooltip>
      {this.renderFeatures(subscriptionPlan)}
      <a className="standard-button" href="/sales/enterprise" target="_blank" rel="noopener noreferrer">
        Free Demo
      </a>
    </li>

  render = () => (
    this.props.subscriptionPlan.name === 'Enterprise' ?
      this.renderEnterprisePlan(this.props.subscriptionPlan) :
      this.renderSubscriptionPlan(this.props.subscriptionPlan)
  )
}

export default SubscriptionPlanLi;
