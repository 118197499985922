import React from 'react';
import PropTypes from 'prop-types';
import orFalse from '~/services/orFalse';

import FileApi from '~/api/FileApi';

import Dropzone from 'react-dropzone';
import Loading from '~/_partials/Loading';

import css from './index.scss';

class FileUploadDropzone extends React.Component {
  static propTypes = {
    url: orFalse(PropTypes.string).isRequired,
    updateUrl: PropTypes.func.isRequired,

    afterFileWasDropped: PropTypes.func,
    className: PropTypes.string
  }

  static defaultProps = {
    afterFileWasDropped: () => {},
    className: ''
  }

  state = {
    speUploadFile: {}
  }

  onDrop = (files) => {
    if (files.length === 0) return;

    this.props.updateUrl(false);
    const file = files[0];
    this.props.afterFileWasDropped(file);
    FileApi.upload(
      (spe) => this.setState({ speUploadFile: spe }),
      file
    )
      .then((response) =>
        this.props.updateUrl(response.url)
      );
  }

  removeFile = (event) => {
    event.stopPropagation();
    this.props.updateUrl(false);
  }

  render = () =>
    <Dropzone
      // accept="image/*"
      onDrop={this.onDrop}
      multiple={false}
    >{({ getRootProps, getInputProps }) =>
      <section
        {...getRootProps()}
        className={`standard-dropzone ${this.props.className} ${css.section} ${this.props.url ? '-file-is-uploaded' : '-file-is-not-uploaded'}`}
      >
        <input {...getInputProps()}/>
        {
          this.props.url ?
            <React.Fragment>
              <i className="fa fa-file-text-o"/>
              <button type="button" className="standard-button -red remove-file-button" onClick={this.removeFile}>
                REMOVE
              </button>
            </React.Fragment> :
            <React.Fragment>
              {
                this.state.speUploadFile.status !== 'request' &&
                <label>Drop File Here</label>
              }
              <Loading spe={this.state.speUploadFile}/>
            </React.Fragment>
        }
      </section>
    }</Dropzone>
}

export default FileUploadDropzone;
