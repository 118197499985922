import React from 'react';
import PropTypes from 'prop-types';
import { Textarea } from '~/_partials/standardForm';

import TextTogglerAndModal from '../../_contentModals/TextTogglerAndModal';

class MlineTextContentLi extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      content_type: PropTypes.oneOf(['multi-line-text']).isRequired,
      payload: PropTypes.string.isRequired,
    }).isRequired,
    liProps: PropTypes.object.isRequired,
    uiUpdateContent: PropTypes.func.isRequired,
    uiDeleteContent: PropTypes.func.isRequired,
  }

  state = {
    textState: {
      mtext_val: '',
    }
  }

  renderLi = () => {
    const { content } = this.props;
    const { textState } = this.state;
    return (
      <li {...this.props.liProps}>
        <Textarea
          name="mtext_val"
          label={content.payload}
          placeholder="Multiple line response"
          formState={textState}
          updateFormState={() => {}}
        />
      </li>
    );
  }

  render = () =>
    <TextTogglerAndModal
      toggler={this.renderLi()}
      content={this.props.content}
      afterContentIsUpdated={this.props.uiUpdateContent}
      afterContentIsDeleted={this.props.uiDeleteContent}
    />
}

export default MlineTextContentLi;
