import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Checkbox } from '~/_partials/standardForm';
import Urls from '~/services/Urls';
import ProgramApi from '~/api/ProgramApi';
import ModuleApi from '~/api/ModuleApi';
import css from './index.scss';

class StepSetting extends React.Component {
  static propTypes = {
    modules_count: PropTypes.number.isRequired,
    score_setup: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    course_id: PropTypes.number.isRequired,
    course_latest_id: PropTypes.number.isRequired,
    students_invited: PropTypes.bool.isRequired,
    certificate_id: PropTypes.number.isRequired,
    uiUpdateCourseStatus: PropTypes.func.isRequired,
    uiUpdateShowAddSectionAlert: PropTypes.func.isRequired,
  }

  static defaultProps = {
    score_setup: false,
    active: false,
    students_invited: false,
  }

  state = {
    todoStatus: {
      scoring: false,
      certificate: false,
      skip_certificate: false,
      billing: false,
      activate: this.props.active,
      students_invited: this.props.students_invited,
    },
  }

  componentDidMount() {
    this.apiGetTodoStatus()
      .then(response =>
        this.setState({
          todoStatus: response.todo_status
        })
      );
  }

  componentWillReceiveProps(props) {
    const { todoStatus } = this.state;
    if ((props.active !== todoStatus.activate) || (props.students_invited !== todoStatus.students_invited)) {
      this.setState({
        todoStatus: {
          ...todoStatus,
          activate: props.active,
          students_invited: props.students_invited,
        }
      });
    }
  }

  apiGetTodoStatus = () =>
    ProgramApi.getTodo(
      false, {
        course_id: this.props.course_id,
      }
    )

  apiUpdateTodoStatus = (option) =>
    ProgramApi.checkTodo(
      false, {
        course_id: this.props.course_id,
        option,
      }
    )

  apiCreateCertificateModule = () =>
    ModuleApi.create(
      false, {
        program_id: this.props.course_id,
        section_name: 'Certificate',
        section_type: 'Certificate',
        position: this.props.modules_count,
      }
    )

  getCheckedPercent = () => {
    const { todoStatus } = this.state;
    const allStepsCount = 4;
    let checkedStepsCount = 0;

    if (todoStatus.scoring) {
      checkedStepsCount += 1;
    }

    if (todoStatus.activate) {
      checkedStepsCount += 1;
    }

    if (this.props.certificate_id > 0 || todoStatus.skip_certificate) {
      checkedStepsCount += 1;
    }

    if (todoStatus.students_invited) {
      checkedStepsCount += 1;
    }

    return checkedStepsCount / allStepsCount * 100;
  }

  uiUpdateCourseStatus = (todoStatus) => {
    if (this.props.modules_count > 0) {
      this.setState({ todoStatus });
      this.props.uiUpdateCourseStatus();
    } else {
      this.props.uiUpdateShowAddSectionAlert();
    }
  }

  uiUpdateCertificate = () => {
    const { certificate_id } = this.props;

    if (certificate_id) {
      window.location = Urls.getCourseEditorUrl({
        courseId: this.props.course_latest_id,
        moduleId: certificate_id,
        itemType: 'certificate',
      });
    } else {
      this.apiCreateCertificateModule()
        .then(data => {
          window.location = Urls.getCourseEditorUrl({
            courseId: this.props.course_latest_id,
            moduleId: data.id,
            itemType: 'certificate',
          });
        });
    }
  }

  uiUpdateSetupSkip = (e) => {
    e.stopPropagation();
    this.apiUpdateTodoStatus('skip_certificate')
      .then(() => {
        this.setState({
          todoStatus: {
            ...this.state.todoStatus,
            skip_certificate: true,
          }
        });
      });
  }

  render = () => {
    const { todoStatus } = this.state;
    const noCheckActionProps = {
      formState: {
        ...todoStatus,
        scoring: this.props.score_setup,
      },
      updateFormState: () => {},
    };
    const certificateProps = {
      formState: {
        ...todoStatus,
        certificate: this.props.certificate_id > 0 || todoStatus.skip_certificate,
      },
      updateFormState: this.uiUpdateCertificate
    };
    const courseProps = {
      formState: todoStatus,
      updateFormState: this.uiUpdateCourseStatus,
    };
    const parentPath = Urls.getCourseUrl(this.props.course_id);
    const checkedPercent = this.getCheckedPercent();
    return (
      <div
        className={css.settingContainer}
        ref={divRef => this.settingContainer = divRef}
      >
        <div className="steps-wrapper">
          <h4 className="title">To Do</h4>
          <div>
            <Link to={`${parentPath}/settings`}>
              <Checkbox
                rightLabel="Set passing score"
                name="scoring"
                outline
                {...noCheckActionProps}
              />
            </Link>
          </div>
          <div className={classNames('skip-step-wrapper', { '-skipped': !todoStatus.certificate && todoStatus.skip_certificate })}>
            <Checkbox
              rightLabel="Design certificate"
              name="certificate"
              outline
              {...certificateProps}
            />
            {!todoStatus.certificate && !todoStatus.skip_certificate &&
              <button
                className="standard-button -white btn-skip-item"
                onClick={this.uiUpdateSetupSkip}
              >
                Skip item
              </button>
            }
          </div>
          <div className="more-steps-container">
            <Checkbox
              label=""
              rightLabel="Activate program"
              name="activate"
              outline
              {...courseProps}
            />
            <div>
              <Link to={`${parentPath}/students`}>
                <Checkbox
                  label=""
                  rightLabel="Invite students"
                  name="students_invited"
                  outline
                  {...noCheckActionProps}
                />
              </Link>
            </div>
          </div>
          <div className={classNames('step-progress', { '-completed': checkedPercent === 100 })}>
            <div className="checked-progress" style={{ width: `${checkedPercent}%` }}/>
          </div>
          <button
            className="standard-more-button btn-more"
            ref={btnRef => this.moreButton = btnRef}
          >
            See More
            <i className="fa fa-angle-double-down"/>
          </button>
        </div>
      </div>
    );
  }
}

export default StepSetting;
