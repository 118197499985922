import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Utils from '~/services/Utils';
import StudentApi from '~/api/StudentApi';
import AnswerContent from '../_partials/AnswerContent';
import SurveyMultipleChoice from '../_partials/SurveyMultipleChoice';
import LikertContent from '../_partials/LikertContent';
import DropdownContent from '../_partials/DropdownContent';
import RatingScaleContent from '../_partials/RatingScaleContent';
import TestService from '../../services/TestService';

class QuestionContentContainer extends React.Component {
  static propTypes = {
    content: PropTypes.object.isRequired,
    current_module: PropTypes.object.isRequired,
    user_role: PropTypes.string.isRequired,
    if_can_show_correct_answers: PropTypes.bool.isRequired,
    if_current_module_is_completed: PropTypes.bool.isRequired,
  };

  apiAnswerFreeResponse = (response) =>
    StudentApi.answerFreeResponse(false, {
      content_id: this.props.content.id,
      freeresponse: response,
    })

  uiSubmitFreeResponse = (response) => {
    if (response.length > 0 && !this.props.if_current_module_is_completed) {
      this.apiAnswerFreeResponse(response)
        .then(() => TestService.countItems());
    }
  }

  renderAnswerContent = () => {
    const { content, current_module, if_current_module_is_completed } = this.props;
    let answerContent = null;

    switch (content.content_type) {
      case 'multiple-choice':
      case 'yes-no':
      case 'true-false':
        answerContent = <AnswerContent if_current_module_is_test={current_module.program_type === 'Test'} {...this.props}/>;
        break;
      case 'multiple-choice-2':
        answerContent = <SurveyMultipleChoice {...this.props}/>;
        break;
      case 'nps':
        answerContent = <SurveyMultipleChoice {...this.props} isNps/>;
        break;
      case 'likert':
        answerContent =
          <LikertContent
            content={content}
            if_current_module_is_completed={if_current_module_is_completed}
          />;
        break;
      case 'dropdown':
        answerContent =
          <DropdownContent
            content={content}
            if_current_module_is_completed={if_current_module_is_completed}
          />;
        break;
      case 'rating-scale':
        answerContent =
          <RatingScaleContent
            content={content}
            if_current_module_is_completed={if_current_module_is_completed}
          />;
        break;
      case 'single-line-text':
        answerContent =
          <div className="single-line test-answers">
            <input
              className="text-input"
              placeholder="Single Line Response"
              defaultValue={content.answers.length > 0 ? content.answers[0].payload : ''}
              disabled={if_current_module_is_completed}
              onBlur={e => this.uiSubmitFreeResponse(e.target.value)}
            />
          </div>;
        break;
      case 'multi-line-text':
        answerContent =
          <div className="multi-line-text test-answers">
            <textarea
              className="text-area"
              placeholder="Multiple Line Reponse"
              defaultValue={content.answers.length > 0 ? content.answers[0].payload : ''}
              disabled={if_current_module_is_completed}
              onBlur={e => this.uiSubmitFreeResponse(e.target.value)}
            />
          </div>;
        break;
    }
    return answerContent;
  }

  render = () => {
    const { content, current_module, user_role, if_can_show_correct_answers } = this.props;
    const ifCurrentModuleIsTest = (current_module.program_type === 'Test');
    return (
      <div className="question-content-container">
        {user_role === 'student' && !content.student_correct && if_can_show_correct_answers && ifCurrentModuleIsTest &&
          <div className="incorrect-label">INCORRECT</div>
        }
        <span className="question-num">
          Question {content.test_num}:
        </span><br/>
        <div
          className={classNames('question-answer-container', {
            '-choice': content.content_type === 'multiple-choice' || content.content_type === 'multiple-choice-2' || content.content_type === 'nps' || content.content_type === 'yes-no' || content.content_type === 'true-false',
            '-multiline': content.content_type === 'multi-line-text',
          })}
        >
          {content.content_type !== 'likert' &&
            <div className="question-title">
              <p className="title" dangerouslySetInnerHTML={{__html: content.payload}}></p>
              {(content.content_type === 'multiple-choice' || content.content_type === 'multiple-choice-2' || content.content_type === 'nps' || content.content_type === 'yes-no' || content.content_type === 'true-false') &&
                <span className="question-point-val">
                    {current_module.scoring_type === 'point' ? `( Value: ${content.point_value} points)` : ''}
                </span>
              }
            </div>
          }
          {this.renderAnswerContent()}
        </div>
      </div>
    );
  }
}

export default QuestionContentContainer;
