import React from 'react';
import PropTypes from 'prop-types';
import LikertTable from './LikertTable';

import LikertTogglerAndModal from '../../_contentModals/LikertTogglerAndModal';

class LikertContentLi extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      content_type: PropTypes.oneOf(['likert']).isRequired,
      question_details: PropTypes.array.isRequired,
      options: PropTypes.string.isRequired,
    }).isRequired,
    liProps: PropTypes.object.isRequired,
    uiUpdateContent: PropTypes.func.isRequired,
    uiDeleteContent: PropTypes.func.isRequired,
  }

  renderLi = (content) => {
    const options = content.options;
    const ifOldFormatOptions = !options.includes('"value":') && !options.includes('"label":');
    const formState = {
      question_details: content.question_details,
      options: !ifOldFormatOptions ?
        JSON.parse(options) :
        options.split(',').map(option => {
          const newOption = {
            value: option,
            label: '',
          };
          return newOption;
        }),
    };
    return (
      <li {...this.props.liProps}>
        <LikertTable
          className="likert-content"
          formState={formState}
          updateFormState={() => {}}
        />
      </li>
    );
  }

  render = () =>
    <LikertTogglerAndModal
      toggler={this.renderLi(this.props.content)}
      content={this.props.content}
      afterContentIsUpdated={this.props.uiUpdateContent}
      afterContentIsDeleted={this.props.uiDeleteContent}
    />
}

export default LikertContentLi;
