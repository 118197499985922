import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, } from 'recharts';
import _ from 'lodash/core';

import css from './index.scss';

const STUDENT_DATA = {
  enrolled_but_not_started: { name: 'Registered', value: 0, color: '#d1478c' },
  in_progress: { name: 'In Progress', value: 0, color: '#d1478c' },
  completed: { name: 'Completed', value: 0, color: '#d1478c' },
};

const SCORING_DATA = {
  number_of_scores_passed: { name: 'Passed', value: 0, className: 'passed', color: '#FECA7A' },
  number_of_scores_failed: { name: 'Failed', value: 0, className: 'failed', color: '#D84774' },
};

class ChartView extends React.Component {
  static propTypes = {
    course_status: PropTypes.object.isRequired,
    className: PropTypes.string,
    diameter: PropTypes.number,
    innerRadius: PropTypes.number,
    outerRadius: PropTypes.number,
    fillColor: PropTypes.string,
  }

  static defaultProps = {
    course_status: {
      amount_of_students: STUDENT_DATA,
      scoring: SCORING_DATA,
    },
    className: '',
    diameter: 102,
    innerRadius: 40,
    outerRadius: 50,
    fillColor: '#f1f4f7',
  }

  getOtherChartDataWith = (chartItem) => {
    const otherChartItem = {
      name: 'Other',
      value: 0,
      color: this.props.fillColor,
    };
    let valueSum = 0;
    _.values(STUDENT_DATA).map(data => valueSum += data.value);

    if (valueSum === 0) {
      valueSum = 100;
    }

    otherChartItem.value = valueSum - chartItem.value;
    return otherChartItem;
  }

  renderChartLabels = () =>
    <div className="chart-labels">
      {
        _.values(STUDENT_DATA).map((dataItem, index) =>
          <div key={index} className="chart-label">
            <span className="label-rect" style={{ background: dataItem.color }}/>
            <span className="label-name">{dataItem.name}</span>
          </div>
        )
      }
    </div>;

  renderScoring = () => {
    const { course_status } = this.props;
    SCORING_DATA.number_of_scores_passed.value = course_status.scoring.number_of_scores_passed;
    SCORING_DATA.number_of_scores_failed.value = course_status.scoring.number_of_scores_failed;

    return (
      <div className="status-wrapper">
        {
          _.values(SCORING_DATA).map((statusItem, index) =>
            <div key={index} className="status-group">
              <span className="status-name">{statusItem.name}</span>
              <div className="status-progress">
                <div className={`progress-rect ${statusItem.className}`} style={{ width: `${statusItem.value}%` }}/>
              </div>
            </div>
          )
        }
      </div>
    );
  }

  render = () => {
    const { course_status, className, diameter, innerRadius, outerRadius } = this.props;
    STUDENT_DATA.in_progress.value = course_status.amount_of_students.in_progress;
    STUDENT_DATA.completed.value = course_status.amount_of_students.completed;
    STUDENT_DATA.enrolled_but_not_started.value = course_status.amount_of_students.enrolled_but_not_started > 0 ? course_status.amount_of_students.enrolled_but_not_started : STUDENT_DATA.enrolled_but_not_started.value;

    return (
      <div className={`${css.chartviewContainer} ${className}`}>
        {_.values(STUDENT_DATA).map(data => {
          const chartData = [data, this.getOtherChartDataWith(data)];
          return (
            <div className="pie-chart" key={data.name}>
              <PieChart width={diameter} height={diameter}>
                <Pie
                  data={chartData}
                  startAngle={180}
                  endAngle={0}
                  innerRadius={innerRadius}
                  outerRadius={outerRadius}
                  labelLine={true}
                  fill="transparent"
                  dataKey="value"
                >
                  {
                    chartData.map((entry, index) => <Cell key={`cell-${index}`} stroke="transparent" fill={entry.color}/>)
                  }
                </Pie>
              </PieChart>
              <div className="chart-labels">
                <span className="chart-value">{data.value}</span>
                <span className="chart-name">{data.name}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default ChartView;
