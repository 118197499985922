import React from 'react';
import PropTypes from 'prop-types';

import UserTocApi from '~/api/UserTocApi';
import ModuleApi from '~/api/ModuleApi';
import Urls from '~/services/Urls';
import Constants from '~/services/Constants';

import { Draggable } from 'react-beautiful-dnd';
import SlidyTextInput from '~/_partials/SlidyTextInput';

class ModuleLi extends React.Component {
  static propTypes = {
    module: PropTypes.object.isRequired,
    modules: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateModules: PropTypes.func.isRequired,

    currentModuleId: PropTypes.number.isRequired,
    currentCourseId: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired
  }

  state = {
    ifEditing: this.props.module.id ? false : true,
    moduleName: this.props.module.name
  }

  uiStartEditing = () =>
    this.setState({
      ifEditing: true,
    })

  uiCancelEditing = () => {
    this.setState({
      ifEditing: false,
      moduleName: this.props.module.name
    });
    const { module, modules } = this.props;

    if (!module.id) {
      this.props.updateModules(modules.slice(0, modules.length - 1));
    }
  }

  apiCreateModule = () =>
    ModuleApi.create(
      false, {
        program_id: this.props.currentCourseId,
        section_name: this.state.moduleName,
        section_type: this.props.module.program_type,
        position: this.props.modules.length - 1,
      }
    );

  apiUpdateModule = () =>
    UserTocApi.update(
      false,
      this.props.module.id,
      { name: this.state.moduleName }
    );

  uiCreateModule = (e) => {
    e.preventDefault();
    this.apiCreateModule()
      .then(response => {
        const prevModules = this.props.modules;
        const newModules = [
          ...prevModules.slice(0, prevModules.length - 1),
          response.payload,
        ];
        this.props.updateModules(newModules);
        this.setState({ ifEditing: false });
      });
  }

  uiUpdateModule = (e) => {
    e.preventDefault();
    this.apiUpdateModule()
    .then(() =>
      this.uiUpdateModuleName()
    );
  }

  uiUpdateModuleName = () => {
    const prevModules = this.props.modules;
    const updatedModuleIndex = prevModules.findIndex((li) => li.id === this.props.module.id);
    const newModules = [
      ...prevModules.slice(0, updatedModuleIndex),
      {
        ...this.props.module,
        name: this.state.moduleName
      },
      ...prevModules.slice(updatedModuleIndex + 1)
    ];
    this.props.updateModules(newModules);
    this.setState({ ifEditing: false });
  }

  renderModule = (module, dragProvided) =>
    <div className="module-not-edited">
      <i className={`fa ${module.program_type !== 'Certificate' ? 'fa-bars' : 'fa-lock'}`} {...dragProvided.dragHandleProps}/>

      <a href={Urls.getCourseEditorUrl({ courseId: this.props.currentCourseId, moduleId: module.id, itemType: Constants.MODULE_TYPE[module.program_type.toLowerCase()].type.toLowerCase() })}>
        <div className="type-box">
          {Constants.MODULE_TYPE[module.program_type.toLowerCase()].icon({ color: '#555555' })}
          <p>{Constants.MODULE_TYPE[module.program_type.toLowerCase()].name}</p>
        </div>
        <div className="name">
          {module.name}
        </div>
      </a>

      <i className="fa fa-pencil" onClick={this.uiStartEditing}/>
    </div>

  renderForm = () =>
    <form className="module-edited" onSubmit={(e) => this.props.module.id ? this.uiUpdateModule(e) : this.uiCreateModule(e)}>
      <i className="fa fa-times-circle" onClick={this.uiCancelEditing}/>

      <SlidyTextInput
        className="slidy-input"
        type="text"
        value={this.state.moduleName}
        autoFocus={true}
        maxLength={Constants.MAX_TITLE_LENGTH}
        onChange={(e) => this.setState({ moduleName: e.target.value.trim().length > 0 ? e.target.value : '' })}
      />

      <button
        type="submit"
        className={this.state.moduleName.trim() === '' ? '-disabled' : ''}
      >
        {this.props.module.id ? 'SAVE' : 'ADD'}
      </button>
    </form>

  render = () =>
    <Draggable draggableId={this.props.index + 1} index={this.props.index}>{(dragProvided, dragSnapshot) =>
      <li
        className={`
          module
          ${this.props.currentModuleId === this.props.module.id ? '-active' : '-not-active'}
          ${this.state.ifEditing ? '-editing' : '-not-editing'}
          ${dragSnapshot.isDragging ? '-is-dragging' : ''}
        `}
        ref={dragProvided.innerRef}
        {...dragProvided.draggableProps}
        {...(!this.props.module.id ? dragProvided.dragHandleProps : {})}
      >
        { !this.state.ifEditing ?
          this.renderModule(this.props.module, dragProvided) :
          this.renderForm()
        }
      </li>
    }</Draggable>
}

export default ModuleLi;
