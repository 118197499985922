import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CourseCard from '~/_partials/CourseCard';
import SubHeader      from './_partials/SubHeader';
import AddCourseTogglerAndModal from './_partials/AddCourseTogglerAndModal';

import css from './index.scss';


class DashboardCourses extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    programs: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  state = {
    searchText: '',
    bodyClass: document.body.className,
    all_programs: this.props.programs,
  }

  componentDidMount() {
    document.body.addEventListener('bannerChange', this.uiUpdateBodyClass);
  }

  handleOnSearchChange = (searchText) => {
    this.setState({ searchText });
  }

  uiUpdateBodyClass = () => {
    this.setState({ bodyClass: document.body.className });
  }

  uiUpdatePrograms = (newPrograms) => {
    this.setState({ all_programs: newPrograms });
  }

  renderToggler = () =>
    <button className="add-course" onClick={this.handleAddCourseClick}>+ Add Program</button>

  renderContents = () => {
    const { all_programs, searchText } = this.state;
    const filteredCourses = all_programs.filter(program => program.name.toLowerCase().includes(searchText.toLowerCase()));
    return (
      <div className="contents">
        {
          filteredCourses.map(program =>
            <CourseCard
              key={program.id}
              courseIndex={all_programs.findIndex(courseProgram => courseProgram.id === program.id)}
              course_id={program.id}
              courses={all_programs}
              label={program.name}
              image={program.image}
              active={program.active}
              href={`/dashboard-program/${program.id}/`}
              className="search-item"
              uiUpdateCourses={this.uiUpdatePrograms}
            />
          )
        }
        <AddCourseTogglerAndModal toggler={this.renderToggler()}/>
        <div className="search-item"/>
      </div>
    );
  }

  render = () =>
    <main
      className={classNames(css.main,
        `${this.props.className ? this.props.className : ''}`,
        'standard-dashboard-content -auto-height', {
        '-with-banner': this.state.bodyClass.includes('-with-banner'),
        '-full-banner': this.state.bodyClass.includes('-full-banner'),
      })}
    >
      <SubHeader
        onChangeSearch={this.handleOnSearchChange}
      />
      {this.renderContents()}
    </main>;
}

export default DashboardCourses;
