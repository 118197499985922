import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Urls from '~/services/Urls';
import Contents from './Contents';
import StudentApi from '~/api/StudentApi';
import TestService from './services/TestService';
import ConfirmTogglerAndModal from '~/pages/Modals/ConfirmTogglerAndModal';
import TestResultModal from './Modals/TestResultModal';
import TestCompleteModal from './Modals/TestCompleteModal';
import css from './index.scss';

const UNANSWERED_QUESTIONS_MESSAGE = 'You have unanswered questions. Please go back and check your survey responses.';
const UNSTARTED_MODULES_MESSAGE = 'You have unstarted modules. Please go back and check your survey responses.';
const INCOMPLETE_MODULES_MESSAGE = 'You have incomplete modules. Please go back and check your survey responses.';
const MUST_WATCHVIDEO_MESSAGE = 'Please finish watching all videos before continuing.';

class ProgramTest extends React.Component {
  static propTypes = {
    module_item_content: PropTypes.array.isRequired,
    modules: PropTypes.array.isRequired,
    current_course: PropTypes.object.isRequired,
    current_module: PropTypes.object.isRequired,
    current_course_id: PropTypes.number.isRequired,
    next_module_id: PropTypes.number,
    prev_module_id: PropTypes.number,
    show_welcome: PropTypes.bool,
    view_as: PropTypes.bool.isRequired,
    take_sequentially: PropTypes.bool.isRequired,
    user_role: PropTypes.string.isRequired,
    if_contains_free_response: PropTypes.bool.isRequired,
    if_current_course_is_completed: PropTypes.bool.isRequired,
    if_current_module_is_completed: PropTypes.bool.isRequired,
    if_current_module_is_last: PropTypes.bool.isRequired,
    amount_of_test_contents_of_current_module: PropTypes.number.isRequired,
    pagenum: PropTypes.number.isRequired,
    num_pages: PropTypes.number.isRequired,
  }

  state = {
    module_item_content: this.props.module_item_content,
    if_current_module_is_completed: this.props.if_current_module_is_completed,
    incompleteMessage: '',
    ifBlankModule: true,
    ifRetakeModule: false,
    ifSubmitAction: false,
    nextModule: '',
    ifPassed: false,
    dataScore: '0',
    ifFinalPage: false,
  }

  componentDidMount() {
    TestService.initTimer();
  }

  apiVerifyQuestionAnswered = (params) =>
    StudentApi.verifyQuestionAnswered(false, params)

  apiContinueTest = (params) =>
    StudentApi.continueTest(false, params)

  apiCompleteTest = (params) =>
    StudentApi.completeTest(false, params)

  getProgramTestUrlProps = (module) => {
    if (this.props.view_as > 0) {
      return {
        href: `${Urls.getProgramTestUrl(this.props.current_course_id, module.id)}${this.props.view_as}/`
      };
    } else {
      if (this.props.take_sequentially) {
        return {
          href: Urls.getProgramTestUrl(this.props.current_course_id, module.id)
        };
      } else {
        return {};
      }
    }
  }

  getModuleTestItemsLabel = (module) => {
    if (module.program_type === 'Exam' && module.test_items > 0) {
      return `${module.test_items} Test Items`;
    } else {
      return '';
    }
  }

  uiUpdateContent = (updatedContent) => {
    const { module_item_content } = this.state;
    const updatedIndex = module_item_content.findIndex(content => content.id === updatedContent.id);

    if (updatedIndex >= 0) {
      this.setState({
        module_item_content: [
          ...module_item_content.slice(0, updatedIndex),
          updatedContent,
          ...module_item_content.slice(updatedIndex + 1),
        ]
      });
    }
  }

  uiBackClick = () => {
    const { current_module, current_course_id, pagenum } = this.props;
    const previousPage = pagenum -1;
    window.location = '/' + current_course_id + '/survey/' + current_module.id + '/'+ previousPage +'/';
  }

  uiBeginSurvey = () => {
    const { current_module, current_course_id } = this.props;
    window.location = '/' + current_course_id + '/survey/' + current_module.id + '/1/';
  }

  uiSubmitNext = () => {
    const {
      current_course_id,
      current_module,
      next_module_id,
      if_contains_free_response,
      if_current_module_is_last,
    } = this.props;
    const { ifSubmitAction } = this.state;
    this.apiContinueTest({
      program_id: current_course_id,
      module_id: current_module.id,
    }).then(data => {
      if (data.message === 'failure') {
        this.setState({
          incompleteMessage: UNANSWERED_QUESTIONS_MESSAGE
        }, () =>
          this.submitIncompleteModalToggler.click()
        );
        return;
      }

      this.setState({ if_current_module_is_completed: true });

      if (if_current_module_is_last || data.next_module === 'retake') {
        if (!ifSubmitAction) {
          this.setState({ ifSubmitAction: true });
          this.apiCompleteTest({
            program_id: current_course_id,
            module_id: current_module.id,
          }).then((responseData) => {
            TestService.stopTimer();
            TestService.resetFlag(window.navigator_id);

            if (if_contains_free_response) {
              // this.testCompleteToggler.click();
              this.setState({
                ifFinalPage: true,
              });
            } else {
              this.setState({
                nextModule: false,
                ifBlankModule: false,
                ifRetakeModule: false,
                ifPassed: responseData.passed,
                dataScore: responseData.score,
              }, () =>
                this.testResultToggler.click()
              );
            }
          }).catch(error => {
            const message = error.payload.message;
            let modalMessage = 'Something went wrong!';

            if (message === 'unstarted_sections') {
              modalMessage = UNSTARTED_MODULES_MESSAGE;
            } else if (message === 'incomplete_sections') {
              modalMessage = INCOMPLETE_MODULES_MESSAGE;
            } else {
              modalMessage = 'Something went wrong!';
            }

            this.setState({
              incompleteMessage: modalMessage
            }, () =>
              this.submitIncompleteModalToggler.click()
            );
          });
        } else {
          if (if_contains_free_response) {
            // this.testCompleteToggler.click();
            this.setState({
              ifFinalPage: true,
            });
          } else {
            this.setState({
              nextModule: data.next_module,
              ifBlankModule: data.next_module ? true : false,
              ifRetakeModule: (data.next_module === 'retake'),
              ifPassed: data.passed,
              dataScore: data.score,
            }, () =>
              this.testResultToggler.click()
            );
          }
        }
      } else {
        if (if_current_module_is_last) { // last module first take
          this.setState({
            incompleteMessage: UNANSWERED_QUESTIONS_MESSAGE
          }, () =>
            this.submitIncompleteModalToggler.click()
          );
        } else {
          window.location = '/' + current_course_id + '/survey/' + next_module_id + '/1/';
        }
      }
    }).catch(error => {
      console.log(error.toString());
    });
  }

  uiSubmitTest = (e) => {
    const {
      user_role,
      current_module,
      current_course_id,
      next_module_id,
      if_current_course_is_completed,
      if_current_module_is_last,
      view_as,
      num_pages,
      pagenum,
    } = this.props;
    const { if_current_module_is_completed } = this.state;
    const ifSubmitAvailable = !if_current_course_is_completed || (if_current_course_is_completed && !if_current_module_is_completed);

    if (ifSubmitAvailable) {
      const statusFlag = TestService.canContinue();
      TestService.updateTime();

      if (e.preventDefault) {
        e.preventDefault();
      }

      if (!if_current_course_is_completed) {
        // Submit Next
        const newLocationUrl = '/' + current_course_id + '/survey/' + next_module_id + '/1/' + view_as + '/';

        if (view_as > 0) {
          window.location = newLocationUrl;
        }

        if (statusFlag === 0) {
          if (pagenum < num_pages) {
            
            //Validate that required questions are answered
            
            if (window.questions_answered_on_page == false) {
              this.setState({
                incompleteMessage: UNANSWERED_QUESTIONS_MESSAGE
              }, () =>
                this.submitIncompleteModalToggler.click()
              );
            
            } else { //All questions answered
              window.location = `/${current_course_id}/survey/${current_module.id}/${pagenum + 1}`;
            }
            
          } else {
            if (if_current_module_is_last) {
              const params = {
                program_id: window.currentCourseId,
                module_id: window.currentModuleId,
              };
              this.apiVerifyQuestionAnswered(params)
                .then(data => {
                  if (data.all_questions_answered) {
                    if (!if_current_course_is_completed && !next_module_id) {
                       this.submitTestModalToggler.click();
                    } else if (next_module_id > 0) {
                        window.location = `/${current_course_id}/survey/${next_module_id}/1/${view_as > 0 ? `${view_as}/` : ''}`;
                    } else {
                        console.log('WTF');
                        window.location = '/dashboard-programs/';
                    }
                  } else {
                    this.setState({
                      incompleteMessage: UNANSWERED_QUESTIONS_MESSAGE
                    }, () =>
                      this.submitIncompleteModalToggler.click()
                    );
                  }
                });
            } else {
              if (!if_current_module_is_completed && user_role === 'student') {
                if (current_module.program_type === 'Exam') {
                  this.submitTestModalToggler.click();
                } else {
                  this.uiSubmitNext(true);
                }
              } else if (window.next_module_id > 0) {
                window.location = `/${current_course_id}/survey/${next_module_id}/1/${view_as > 0 ? `${view_as}/` : ''}`;
              } else {
                window.location = '/dashboard-programs/';
              }
            }
          }
        } else if (statusFlag === 1) {
          this.setState({
            incompleteMessage: `Please spend the minimum time (${current_module.minimum_time} minutes).`
          }, () =>
            this.submitIncompleteModalToggler.click()
          );
        } else if (statusFlag === 2) {
          this.setState({
            incompleteMessage: MUST_WATCHVIDEO_MESSAGE
          }, () =>
            this.submitIncompleteModalToggler.click()
          );
        }
      } else {
        // Submit Complete
        if (if_current_module_is_completed && if_current_course_is_completed) {
          window.location = '/dashboard-programs/';
        } else {
          if (statusFlag === 0 || statusFlag === 1) {
            this.submitTestModalToggler.click();
          } else if (statusFlag === 2) {
            this.setState({
              incompleteMessage: MUST_WATCHVIDEO_MESSAGE
            }, () =>
              this.submitIncompleteModalToggler.click()
            );
          }
        }
      }
    }
  }

  renderContinueToggler = () => {
    const {
      user_role,
      if_current_course_is_completed,
      if_current_module_is_last,
      num_pages,
      pagenum
    } = this.props;
    const { if_current_module_is_completed } = this.state;
    const ifContinueAvailable = !if_current_course_is_completed || (if_current_course_is_completed && !if_current_module_is_completed);
    const ifSubmitted = user_role === 'student' && if_current_module_is_last && if_current_course_is_completed && if_current_module_is_completed;
    const last_page_of_survey = num_pages == pagenum;
    return (
      <button
        className={classNames('gradient-btn sliding-middle-out', {
          'continue-btn pull-right': ifContinueAvailable,
          'next-btn': !if_current_course_is_completed,
          'completed-btn': if_current_course_is_completed && !if_current_module_is_completed,
          'continue-btn-disabled': ifSubmitted,
        })}
        onClick={this.uiSubmitTest}
      >
        {ifContinueAvailable ? ( last_page_of_survey ? 'Submit Survey' : 'Next') : 'Submitted'}
      </button>
    );
  }

  renderSubmitTestToggler = () =>
    <button ref={buttonRef => this.submitTestModalToggler = buttonRef}/>

  renderSubmitIncompleteToggler = () =>
    <button ref={buttonRef => this.submitIncompleteModalToggler = buttonRef}/>

  renderTestResultToggler = () =>
    <button ref={buttonRef => this.testResultToggler = buttonRef}/>

  renderTestCompleteToggler = () =>
    <button ref={buttonRef => this.testCompleteToggler = buttonRef}/>

  render = () => {
    const {
      modules,
      user_role,
      prev_module_id,
      current_course,
      current_module,
      pagenum,
      current_course_id,
      if_current_course_is_completed,
      if_current_module_is_last,
      amount_of_test_contents_of_current_module,
      show_welcome,
    } = this.props;
    const {
      module_item_content,
      if_current_module_is_completed,
      incompleteMessage,
      ifBlankModule, ifRetakeModule,
      nextModule, ifPassed, dataScore,
      ifFinalPage,
    } = this.state;
    const contentProps = {
      ...this.props,
      module_item_content,
      if_current_module_is_completed,
      uiUpdateContent: this.uiUpdateContent,
    };

    const ifContinueAvailable = !if_current_course_is_completed || (if_current_course_is_completed && !if_current_module_is_completed);
    const ifSubmitted = user_role === 'student' && if_current_module_is_last && if_current_course_is_completed && if_current_module_is_completed;
    return (
      <main className={css.main}>
        <div id="droppable-area" className="test-container">
          <div className="row">
            {/* Nav Sidebar */}
            <div className="left-module-nav">
              <ul className="sidebar-nav-list">
                {modules.map((module, index) =>
                  <li key={index} className={`sidebar-item ${current_module.id === module.id ? 'selected-module' : ''}`}>
                    <a {...this.getProgramTestUrlProps(module)}>
                      <h4 className="module-nav-name">{`Section ${index + 1}: ${module.name}`}</h4>
                      <span className="num-module-items">
                        {module.score ?
                          <span className={module.passed ? 'pass-label' : 'fail-label'}>{module.passed ? 'PASSED' : 'FAILED'}</span>
                          : this.getModuleTestItemsLabel(module)
                        }
                        {module.score && `/ ${module.score}`}
                      </span>
                    </a>
                  </li>
                )}
              </ul>
            </div>
            {/* Test Content */}
            {!show_welcome && !ifFinalPage && <div className="main-content-area">
              {module_item_content.length > 0 ?
                <Contents {...contentProps}/> :
                <div className="empty-content-container">
                  <div className="module-item">
                    <h2>This test doesn't have any content.</h2>
                  </div>
                </div>
              }
            </div>}
            {/* Survey Welcome Page */}
            {show_welcome && <div className="main-content-area welcome-survey">
              <h1>Welcome to the {current_module.name}</h1>
              <p className="desc-3">Now in its 12<sup>th</sup> year, the Resident Satisfaction Survey focuses on measuring Residents’ service experiences on board and maintaining a high level of Resident satisfaction. The survey can take as little as 10 minutes to complete. This year, a new question from the Board of Directors has been added as the Board would like to gauge your opinion on the current restriction on the number of partnerships. The RSS is designed for ease of use, optimized and formatted for mobile devices, and can be accessed two ways – via an email link, bypassing a Navigator login, or from an orange link on your Navigator home page. All questions have fields for optional comments and a progress bar at the top of your screen indicating the number of questions remaining.</p>
              <p className="desc-3">The results will be tabulated by an independent third-party company. The findings will establish a Resident Service Satisfaction Indicator (RSSI) score as the benchmark for future rating targets and for the development of annual targeted improvement plans. The valuable data collected will be used by Management to increase engagement and continue to enhance Resident satisfaction of your one-of-a-kind lifestyle. Comments will be reviewed by Senior Leadership so that they can take focused actions to improve programs and services and/or add new ones if indicated.</p>
              <p className="desc-3">Residents are encouraged to participate individually. After clicking SUBMIT SURVEY, the following message will appear on your screen, "Thank you for completing the 2022 Resident Satisfaction Survey. We are eager to learn how we might serve you better to improve your experience."</p>
              <p className="desc-3">We are eager to learn about ways we might serve you better and encourage 100% participation. Thank you in advance for your valuable time.</p>
              <button
                className="begin-survey"
                onClick={this.uiBeginSurvey}
              >
                Begin Survey
              </button>
            </div>}
            {/* Survey Thank you Page */}
            {ifFinalPage && <div className="main-content-area welcome-survey">
              <h1>Thank you for completing the 2022 Resident Satisfaction Survey.</h1>
              <p className="desc-1">We are eager to learn how we might serve you better to improve your experience.</p>
            </div>}
          </div>
          {/* Program Bar */}
          {!show_welcome && !ifFinalPage && <div className="program-bar">
            {<button className={classNames('gradient-btn sliding-middle-out back-btn pull-left', {'disabled': show_welcome || pagenum == 1})}
                onClick={this.uiBackClick}
              >
                Back
              </button>
            }
            {user_role === 'student' &&
              <div className={`prog-meta  ${if_current_course_is_completed ? '-completed' : ''}`}>
                <div className={`time-container ${ifSubmitted ? '-submitted' : ''}`}>
                  <span className="prog-time-label meta-label">
                    <i className="fa fa-clock-o"/>
                  </span>
                  <span className="prog-time prog-value timer">
                    <span className="hour">00</span>:
                    <span className="minute">00</span>:
                    <span className="second">00</span>
                  </span>
                </div>
                <span className="prog-progress-label meta-label">Survey Progress:</span>
                <span className="prog-progress prog-value">
                  <span className="test-items-completed">0</span>/{amount_of_test_contents_of_current_module} Questions
                </span>
              </div>
            }
            {(ifContinueAvailable || ifSubmitted) && this.renderContinueToggler()}
          </div>}
          {/* Test Submit Modal */}
          <ConfirmTogglerAndModal
            toggler={this.renderSubmitTestToggler()}
            title="Are you sure you want to submit your survey responses?"
            onClose={() => this.uiSubmitNext(false)}
          />
          {/* Submit Incomplete Modal */}
          <ConfirmTogglerAndModal
            toggler={this.renderSubmitIncompleteToggler()}
            title={incompleteMessage}
            okOnly={true}
            onClose={() => this.setState({ ifSubmitAction: false })}
          />
          {/* Test Result Modal */}
          <TestResultModal
            title={current_module.name}
            toggler={this.renderTestResultToggler()}
            courseId={current_course_id}
            nextModule={nextModule ? nextModule.toString() : false}
            passed={ifPassed}
            dataScore={dataScore}
            ifBlankModule={ifBlankModule}
            ifRetakeModule={ifRetakeModule}
            onClose={() => {}}
          />
          {/* Test Complete Modal */}
          <TestCompleteModal
            toggler={this.renderTestCompleteToggler()}
            programTitle={current_course.name}
            image={current_course.image}
            onClose={() => {}}
          />
        </div>
      </main>
    );
  }
}

export default ProgramTest;
