import React from 'react';
import PropTypes from 'prop-types';

import FileTogglerAndModal from '../../_contentModals/FileTogglerAndModal';

class FileContentLi extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      content_type: PropTypes.oneOf(['file']).isRequired,
      // https://s3.amazonaws.com/module-uploads/uploads/files/de2e331.doc
      payload: PropTypes.string.isRequired,
      // Article on Stigma
      title: PropTypes.string.isRequired,
      // Please read this article as a part of this course.
      description: PropTypes.string.isRequired
    }).isRequired,
    liProps: PropTypes.object.isRequired,
    uiUpdateContent: PropTypes.func.isRequired,
    uiDeleteContent: PropTypes.func.isRequired
  }

  renderLi = (content) =>
    <li {...this.props.liProps}>
      <div className="file_and_title">
        <i className="fa fa-file-text-o"/>
        <a
          className="title"
          href={content.payload}
          onClick={(e) => e.stopPropagation()}
          target="_blank"
          rel="noreferrer noopener"
        >{content.title}</a>
      </div>
      <p className="description">{content.description}</p>
    </li>

  render = () =>
    <FileTogglerAndModal
      toggler={this.renderLi(this.props.content)}
      content={this.props.content}
      afterContentIsUpdated={this.props.uiUpdateContent}
      afterContentIsDeleted={this.props.uiDeleteContent}
    />
}

export default FileContentLi;
