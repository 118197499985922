import React from 'react';
import PropTypes from 'prop-types';
import orFalse from '~/services/orFalse';

import ContentTogglerAndModalWrapper from '../ContentTogglerAndModalWrapper';
import LikertTable from '../../Contents/_partials/LikertTable';

import css from './index.scss';

const initialState = {
  question_details: [
    { payload: '' }
  ],
  options: [
    { value: 1, label: 'Strongly Disagree' },
    { value: 2, label: 'Disagree' },
    { value: 3, label: 'Neutral' },
    { value: 4, label: 'Agree' },
    { value: 5, label: 'Strongly Agree' },
  ],
  explanation: '',
  point_value: 0,
};

const fromApiToState = (api) => ({
  question_details: api.question_details,
  options: api.options.includes('"value":') && api.options.includes('"label":') ?
    JSON.parse(api.options) :
    api.options.split(',').map(option => {
      const newOption = {
        value: option,
        label: '',
      };
      return newOption;
    }),
  explanation: api.explanation,
  point_value: api.point_value,
});

const fromStateToApi = (formState) => ({
  question_details: formState.question_details,
  options: JSON.stringify(formState.options),
  explanation: formState.explanation,
  point_value: formState.point_value,
});

class LikertTogglerAndModal extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    content: orFalse(PropTypes.shape({
      content_type: PropTypes.oneOf(['likert']).isRequired,
      question_details: PropTypes.array.isRequired,
      options: PropTypes.string.isRequired,
    })).isRequired
  }

  uiCreateLikertQuestion = (formState, updateFormState) => {
    const question_details = formState.question_details;
    updateFormState({
      ...formState,
      question_details: [
        ...question_details,
        { payload: '' }
      ]
    });
  }

  uiCreateLikertOption = (formState, updateFormState) => {
    const options = formState.options;
    const newOption = {
      value: parseInt(options[options.length - 1].value, 10) + 1,
      label: '',
    };
    updateFormState({
      ...formState,
      options: [
        ...options,
        newOption,
      ]
    });
  }

  render = () =>
    <ContentTogglerAndModalWrapper
      className={`${css.modal} -with-green-header`}
      title="Likert Scale"
      initialState={initialState}
      fromApiToState={fromApiToState}
      fromStateToApi={fromStateToApi}
      {...this.props}
    >{(formState, updateFormState) =>
      <div className="standard-modal-main likert-modal-main">
        <div className="likert-modal-body">
          <div className="table-content">
            <div className="table-wrapper">
              <LikertTable
                formState={formState}
                updateFormState={updateFormState}
              />
            </div>
            <button className="btn-add-question" onClick={() => this.uiCreateLikertQuestion(formState, updateFormState)}>Add Question</button>
          </div>
          <div className="btn-control-group">
            <button className="btn-add-option" onClick={() => this.uiCreateLikertOption(formState, updateFormState)}>Add Option</button>
          </div>
        </div>
      </div>
    }</ContentTogglerAndModalWrapper>
}

export default LikertTogglerAndModal;
