import React from 'react';
import PropTypes from 'prop-types';
import orFalse from '~/services/orFalse';

import SlidyTextInput from '~/_partials/SlidyTextInput';
import ContentTogglerAndModalWrapper from '../ContentTogglerAndModalWrapper';

import css from './index.scss';

const initialState = {
  payload: ''
};

const fromApiToState = (api) => ({
  payload: api.payload
});

const fromStateToApi = (formState) => ({
  payload: formState.payload
});

class HeadingTogglerAndModal extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    content: orFalse(PropTypes.shape({
      content_type: PropTypes.oneOf(['heading']).isRequired,
      // 'Home61 Final Exam'
      payload: PropTypes.string.isRequired
    })).isRequired
  }

  render = () =>
    <ContentTogglerAndModalWrapper
      className={css.modal}
      title="Heading"
      initialState={initialState}
      fromApiToState={fromApiToState}
      fromStateToApi={fromStateToApi}
      {...this.props}
    >{(formState, updateFormState) =>
      <div className="standard-modal-main">
        <SlidyTextInput
          className="slidy-input-wrapper"
          type="text"
          value={formState.payload}
          onChange={(e) => updateFormState({ ...formState, payload: e.target.value })}
          placeholder="Enter Heading Here"
        />
      </div>
    }</ContentTogglerAndModalWrapper>
}

export default HeadingTogglerAndModal;
