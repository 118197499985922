import ourFetch from './services/ourFetch';
// import hashToQueryString from './services/hashToQueryString';

const index = (dispatch) =>
  ourFetch(
    dispatch,
    'GET', '/api/subscription-plans/'
  );

export default {
  index
};
