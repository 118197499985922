import fetchWrapper from './fetchWrapper';
import handleErrors from './handleErrors';
import getCookie from './getCookie';

// ___how to use it?
//
// _in api/UserApi.js:
// const create = (dispatch, values) =>
//   ourFetch(dispatch,
//     'POST', '/api/users',
//     { userDTO: values }
//   );
//
// _and then in our component:
// UserApi.create(
//   (spe) => this.setState({ speCreateUser: spe }), // so that our UserApi knows how to update out component's state
//   'GET', '/api/users',
//   { username: 'Mary' }
// )
//
// it explicitly returns a promise, so that we can do something on success/failure:
// UserApi.create()
//   .then((createdUser) => console.log(createdUser))
//   .catch((error) => this.setState({ error }))
//
// if it's a GET request - just omit the body argument.
const ourFetch = (dispatch, method, url, body = undefined) =>
  fetchWrapper(
    dispatch,
    fetch(url, {
      method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken')
      },
      credentials: 'same-origin',
      body: JSON.stringify(body)
    })
      .then(handleErrors)
  );

export default ourFetch;
