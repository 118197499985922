const withCorrectAnswerAtIndex = (questionDetails, index) => [
  ...questionDetails.slice(0, index).map((questionDetail) => ({
    ...questionDetail, isCorrectAnswer: false
  })),
  {
    ...questionDetails[index],
    isCorrectAnswer: true
  },
  ...questionDetails.slice(index + 1).map((questionDetail) => ({
    ...questionDetail, isCorrectAnswer: false
  }))
];

const withDeletedAtIndex = (questionDetails, indexToDelete) => {
  let newQuestionDetails;

  // if answer we want to delete is_correct answer
  if (questionDetails[indexToDelete].isCorrectAnswer) {
    // if it's the lowest answer
    if (indexToDelete === questionDetails.length - 1) {
      // - assign before index as correct!
      newQuestionDetails = withCorrectAnswerAtIndex(questionDetails, indexToDelete - 1);
    } else {
      // - assign next in index as correct!
      newQuestionDetails = withCorrectAnswerAtIndex(questionDetails, indexToDelete + 1);
    }
  } else {
    newQuestionDetails = questionDetails;
  }

  // delete the answer
  newQuestionDetails = newQuestionDetails.filter((_, index) => indexToDelete !== index);

  return newQuestionDetails;
};

const withNewOne = (questionDetails) => [
  ...questionDetails,
  {
    payload: '',
    isCorrectAnswer: false
  }
];

export default {
  withCorrectAnswerAtIndex,
  withDeletedAtIndex,
  withNewOne
};
