import React from 'react';
import PropTypes from 'prop-types';
import { Gateway } from 'react-gateway';

import ErrorBoundary from '~/_partials/ErrorBoundary';

// works with 'Function As Component Child' (example: https://gist.github.com/choonkending/7da9fd006df752680ad51f58440bbc40)
// ___How to use it?
// <TogglerAndModal toggler={<a>Add User</a>}>{(closeModal) =>
//   <SomeComponent
//     title="New User"
//     closeModal={closeModal}
//  />
// }</TogglerAndModal>
// ___Gotchas?
//    You have to have <GatewayProvider/> somewhere as parent, refer to 'react-gateway' docs.
//    This is done in order to keep it a direct child of <main/>.
class TogglerAndModal extends React.Component {
  static propTypes = {
    // notice that toggler must be an element that can have children (not a void one)
    // (because we are keeping the modal in it in order to avoid creating exsessive wrapping elements)
    toggler: PropTypes.element.isRequired,
    enableToggler: PropTypes.bool,
    children: PropTypes.any.isRequired,
    afterModalOpens: PropTypes.func,
    afterModalCloses: PropTypes.func,
    modalWrapperClassName: PropTypes.string
  }

  static defaultProps = {
    enableToggler: true,
    afterModalOpens: () => {},
    afterModalCloses: () => {},
    modalWrapperClassName: ''
  }

  state = {
    ifModalIsOpen: false,
    // ___Why do we need to keep the key of <ErrorBoundary/>?
    //    Once error is caught - every time modal is opened, this error will be displayed.
    //    We want to give users the chance to not stumble upon that error again!
    //    (https://www.slightedgecoder.com/2018/09/29/resetting-error-boundary-error-state/)
    errorBoundaryKey: 0
  }

  constructor(props) {
    super(props);
    this.modalWrapperRef = React.createRef();
  }

  openModal = () => {
    this.setState({ ifModalIsOpen: true }, () => {
      this.props.afterModalOpens(this.closeModal);
    });
  }
  closeModal = () => {
    this.setState({
      ifModalIsOpen: false,
      // Reset the state.error to null in <ErrorBoundary/>
      errorBoundaryKey: this.state.errorBoundaryKey + 1
    }, () => {
      this.props.afterModalCloses();
    });
  }

  onClickOnModalWrapper = (event) => {
    const elClickedOn = event.target;
    const modalWrapper = this.modalWrapperRef.current;
    if (elClickedOn === modalWrapper) {
      this.closeModal();
    }
  }

  renderCloseButton = () =>
    <button type="button" className="close-button" onClick={this.closeModal}>
      <i className="fa fa-remove"/>
    </button>

  renderErrorBoundaryEl = () =>
    <div className="standard-modal">
      {this.renderCloseButton()}
      <div className="standard-modal-header">
        Error
      </div>
      <div className="standard-modal-main" style={{ textAlign: 'center' }}>
        <h3 style={{ marginTop: 20 }}>Something went wrong :c</h3>
        <h4 style={{ marginTop: 15, marginBottom: 20 }}>Please try again later.</h4>
      </div>
    </div>

  renderModal = () =>
    <Gateway key="key-for-array" into="main">
      <div
        style={{ display: this.state.ifModalIsOpen ? 'block' : 'none' }}
        className={`standard-modal-wrapper ${this.props.modalWrapperClassName}`}
        onClick={this.onClickOnModalWrapper}
        ref={this.modalWrapperRef}
      >
        <ErrorBoundary key={this.state.errorBoundaryKey} errorEl={this.renderErrorBoundaryEl()}>{
          typeof this.props.children === 'function' ?
            this.props.children(this.closeModal, this.renderCloseButton) :
            this.props.children
        }</ErrorBoundary>
      </div>
    </Gateway>

  renderToggler = () =>
    React.cloneElement(
      this.props.toggler,
      {
        onClick: () => this.props.enableToggler ? this.openModal() : {},
        style: { ...this.props.toggler.props.style, cursor: 'pointer' }
      }
    )

  render = () =>
    <React.Fragment>
      {this.renderToggler()}
      {this.renderModal()}
    </React.Fragment>
}

export default TogglerAndModal;
