import React from 'react';
import PropTypes from 'prop-types';
import orFalse from '~/services/orFalse';

import FormState from '../services/FormState';

class PointValueInputDiv extends React.Component {
  static propTypes = {
    content: orFalse(PropTypes.object).isRequired,
    totalAmountOfPoints: PropTypes.number.isRequired,

    formState: PropTypes.shape({
      pointValue: PropTypes.string.isRequired
    }).isRequired,
    updateFormState: PropTypes.func.isRequired
  }

  deriveTotalPoints = () => {
    // formPointValueString is either '' or a proper 'integer' at this point!
    const formPointValueInt = FormState.fromPointValueStringToInt(this.props.formState.pointValue);
    if (this.props.content) {
      return this.props.totalAmountOfPoints - this.props.content.point_value + formPointValueInt;
    } else {
      return this.props.totalAmountOfPoints + formPointValueInt;
    }
  }

  // Dumb chrome will fire onChange with '' if we input '-' or some 'e-e-e' bullshit >:-c
  onChange = (event) => {
    const newPointValue = event.target.value;
    const ifProperInteger = !isNaN(parseInt(newPointValue, 10));
    // Only update things if user inputs:
    //   1. either nothing (to allow user to delete all input to write something new)
    //   2. or a proper integer
    if (newPointValue === '' || ifProperInteger) {
      this.props.updateFormState({
        ...this.props.formState,
        pointValue: newPointValue
      });
    }
  }

  onBlur = () =>
    this.props.updateFormState({
      ...this.props.formState,
      pointValue: FormState.fromPointValueStringToInt(this.props.formState.pointValue).toString()
    })

  render = () =>
    <div className="point-value">
      <input
        type="number"
        className="standard-input -TextInput"
        value={this.props.formState.pointValue}
        onChange={this.onChange}
        onBlur={this.onBlur}
      />
      <div className="out-of-total-amount-of-points">
        points out of {this.deriveTotalPoints()}
      </div>
    </div>
}

export default PointValueInputDiv;
