const deriveTotalAmountOfPoints = (contents) =>
  contents.reduce((totalAmountOfPoints, content) => {
    if (['yes-no', 'true-false', 'multiple-choice', 'multiple-choice-2', 'nps'].includes(content.content_type)) {
      return totalAmountOfPoints + content.point_value;
    } else {
      return totalAmountOfPoints + 0;
    }
  }, 0);

export default deriveTotalAmountOfPoints;
