import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import  LabelAndInput from '../LabelAndInput';

import css from './index.scss';

// why is there value={this.props.formState[name] || ''} in all inputs?
// to avoid this issue: https://github.com/twisty/formsy-react-components/issues/66
// when the initial this.props.formState is {}
class Checkbox extends React.Component {
  static propTypes = {
    label: PropTypes.string, // not required when naked
    rightLabel: PropTypes.string, // not required when naked
    name: PropTypes.string.isRequired,
    updateFormState: PropTypes.func.isRequired,
    formState: PropTypes.object.isRequired,
    required: PropTypes.bool,
    naked: PropTypes.bool,
    readOnly: PropTypes.bool,
    outline: PropTypes.bool,
    checkmark: PropTypes.bool,
    round: PropTypes.bool,
  }

  static defaultProps = {
    label: '',
    rightLabel: '',
    required: false,
    naked: false,
    readOnly: false,
    outline: false,
    checkmark: true,
  }

  updateFormState = (event) => {
    if (this.props.outline || !this.props.checkmark || this.props.round) {
      event.stopPropagation();
    }
    this.props.updateFormState({
      ...this.props.formState,
      [this.props.name]: event.target.checked
    });
  }

  handleCheckboxClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.updateFormState({
      ...this.props.formState,
      [this.props.name]: !this.props.formState[this.props.name]
    });
  }

  renderInput = () =>
    <input
      className={`standard-input -Checkbox ${this.props.readOnly ? '-read-only' : ''}`}
      type="checkbox"
      checked={this.props.formState[this.props.name] || false}
      onChange={this.updateFormState}
      disabled={this.props.readOnly}
      autoComplete="off"
    />

  renderOutlineCheckbox = () =>
    <label
      className={classNames(css.checkboxContainer, {
        '-no-mark': !this.props.checkmark,
        '-round': this.props.round,
      })}
    >
      {this.renderInput()}
      <span className="checkmark"/>
    </label>

  render = () => {
    const ifCustomCheckbox = (this.props.outline || !this.props.checkmark || this.props.round);
    return (
      this.props.naked ?
      this.renderInput() :
      <LabelAndInput
        className={'-' + this.props.name + ' ' + (this.props.formState[this.props.name] ? '-checked' : '')}
        label={this.props.label}
        rightLabel={this.props.rightLabel}
        required={this.props.required}
        inputEl={ifCustomCheckbox ? this.renderOutlineCheckbox() : this.renderInput()}
        onClick={e => (ifCustomCheckbox ? this.handleCheckboxClick(e) : {})}
      />
    );
  }
}

export default Checkbox;
