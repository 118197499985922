import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';

import Constants from '~/services/Constants';
import SpeCreator from '~/services/SpeCreator';
import Loading from '~/_partials/Loading';
import StandardAlert from '~/_partials/StandardAlert';
import ProgramApi from '~/api/ProgramApi';
import Urls from '~/services/Urls';
import { Checkbox, TextInput, Textarea } from '~/_partials/standardForm';

import css from './index.scss';

const PROGRAM_TYPES = {
  cp: 'Course',
  exam: 'Exam',
  module: 'Module',
};

class SaleTabContent extends React.Component {
  static propTypes = {
    program_id: PropTypes.number.isRequired,
    program_type: PropTypes.string.isRequired,
    if_charge_then_program_title: PropTypes.string.isRequired,
    if_charge_then_program_description: PropTypes.string.isRequired,
    if_charge_then_program_price: PropTypes.string.isRequired,
    if_charge_students_for_program: PropTypes.bool.isRequired,
    if_can_sell_courses: PropTypes.bool.isRequired,
    oauth_stripe_connect_user_id: PropTypes.string,
  }

  state = {
    formState: {
      course_title: this.props.if_charge_then_program_title,
      course_price: this.props.if_charge_then_program_price,
      course_description: this.props.if_charge_then_program_description,
      if_charge_students: this.props.if_charge_students_for_program,
    },
    speUpdate: SpeCreator.empty(),
    ifSettingsSaved: false,
  }

  apiUpdateSales = () =>
    ProgramApi.updateSales(
      (spe) => this.setState({ speUpdate: spe }),
      this.props.program_id, {
        if_charge_then_program_title: this.state.formState.course_title,
        if_charge_then_program_price: this.state.formState.course_price,
        if_charge_then_program_description: this.state.formState.course_description,
        if_charge_students_for_program: this.state.formState.if_charge_students,
      });

  updateFormState = (formState) => {
    if (formState.if_charge_students) {
      if (formState.course_price.length === 0 || parseInt(formState.course_price, 10) < 1) {
        formState.course_price = '1';
      } else if (formState.course_price.length > Constants.MAX_COURSE_PRICE_INPUTLENGTH) {
        formState.course_price = formState.course_price.substring(0, Constants.MAX_COURSE_PRICE_INPUTLENGTH);
      }
    } else {
      formState.course_price = '0';
    }

    this.setState({ formState });
  }

  handleClickSave = () => {
    this.apiUpdateSales()
      .then(() => {
        this.setState({ ifSettingsSaved: true },
          () => {
            setTimeout(() => {
              window.location.reload();
            });
          });
      });
  }

  getProgramType = () =>
    PROGRAM_TYPES[this.props.program_type.toLowerCase()]

  render = () => {
    const formProps = {
      formState: this.state.formState,
      updateFormState: this.updateFormState
    };
    const { ifSettingsSaved } = this.state;
    const { if_can_sell_courses, oauth_stripe_connect_user_id } = this.props;
    const maxPrice = parseInt(Array(Constants.MAX_COURSE_PRICE_INPUTLENGTH + 1).join('9'), 10);
    return (
      <div className={css.salesContainer}>
        {if_can_sell_courses && !oauth_stripe_connect_user_id &&
          <div>
            <div className="step-info-container">
              <WarningOutlinedIcon style={{ color: '#EB5757' }}/>
              <div className="step-info">
                <span>Please connect your account to a Stripe payment account.</span>
                <a href={Urls.getSalesPaymentSettingsUrl(this.props.program_id)}>Set Up Payment Settings.</a>
              </div>
            </div>
            <div className="step-info-container" style={{ display: 'none' }}>
              <WarningOutlinedIcon style={{ color: '#EB5757' }}/>
              <div className="step-info">
                <span>This information will be displayed on student receipts.</span>
                <a href={Urls.getCompleteCompanyProfileUrl(this.props.program_id)}>Complete Company Profile.</a>
              </div>
            </div>
            <div className="step-info-container step-three" style={{ display: 'none' }}>
              <WarningOutlinedIcon style={{ color: '#EB5757' }}/>
              <div className="step-info">
                <span>Configure Course Sales settings below.</span>
              </div>
            </div>
          </div>
        }
        <form
          className={classNames('sale-form', {
            '-charge': formProps.formState.if_charge_students,
          })}
          onSubmit={(e) => e.preventDefault()}
        >
          <Checkbox
            label="Charge students for program"
            name="if_charge_students"
            outline
            {...formProps}
          />
          <div className="setting-panel">
            <TextInput
              label="Program Title"
              name="course_title"
              placeholder="Type Title Here"
              maxLength={200}
              {...formProps}
            />
            <div className="price-control-group">
              <label className="control-label">Program Price</label>
              <TextInput
                label="$"
                name="course_price"
                type="number"
                maxValue={maxPrice}
                {...formProps}
              />
            </div>
            <Textarea
              name="course_description"
              label="Program Description"
              placeholder="Type Description Here"
              {...formProps}
            />
          </div>
          <button className="standard-button -plum" onClick={this.handleClickSave}>Save</button>

          <Loading className="loading-spe" spe={this.state.speUpdate}/>
        </form>

        <StandardAlert
          open={ifSettingsSaved}
          type={Constants.ALERT_TYPE.success}
          message="Sale Settings Saved!"
          onClose={() => this.setState({ ifSettingsSaved: false })}
        />
      </div>
    );
  }
}

export default SaleTabContent;
