import React from 'react';
import PropTypes from 'prop-types';
import orFalse from '~/services/orFalse';

import Constants from '~/services/Constants';
import preloadImage from '~/services/preloadImage';
import FileApi from '~/api/FileApi';
import SpeCreator from '~/services/SpeCreator';

import Dropzone from 'react-dropzone';
import Loading from '~/_partials/Loading';
import StandardAlert from '~/_partials/StandardAlert';

import CLOUDICON from '~/../static/frontend/img/gray-cloud-icon.svg';

import css from './index.scss';

class ImageUploadDropzone extends React.Component {
  static propTypes = {
    src: orFalse(PropTypes.string).isRequired,
    updateSrc: PropTypes.func.isRequired,

    className: PropTypes.string,
    placeholder: PropTypes.string,
    hasDragdropIcon: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    className: '',
    placeholder: 'Drop Image Here',
    hasDragdropIcon: false,
  }

  state = {
    speUploadImage: SpeCreator.success(),
    ifShowImageErrorAlert: false,
  }

  onDrop = (files) => {
    if (files.length === 0) return;

    const file = files[0];

    if (file.size > Constants.IMAGE_MAX_SIZE) {
      this.setState({ ifShowImageErrorAlert: true });
    } else {
      this.props.updateSrc(false);
      this.setState({ speUploadImage: SpeCreator.request() });
      FileApi.upload(false, files[0])
        .then((response) => {
          preloadImage(response.url, () => {
            this.props.updateSrc(response.url);
            this.setState({ speUploadImage: SpeCreator.success() });
          });
        })
        .catch((error) =>
          this.setState({ speUploadImage: SpeCreator.failure(error) })
        );
    }
  }

  uiClickRemoveButton = (event) => {
    this.props.updateSrc(false);
    // prevent onClick that opens our file system
    event.preventDefault();
  }

  renderRemoveButton = () =>
    <button
      className="standard-button -red remove-button"
      onClick={this.uiClickRemoveButton}
      type="button"
    >REMOVE</button>

  renderLoading = () =>
    <Loading enabledStatuses={['failure', 'request']} spe={this.state.speUploadImage}/>

  renderDragDropContent = () =>
    <div className="dragdrop-info-container">
      {
        !this.props.hasDragdropIcon &&
        <label>{this.props.placeholder}</label>
      }
      {
        this.props.hasDragdropIcon &&
        <div className="dragdrop-help-panel">
          <img src={CLOUDICON}/>
          <p className="drag-and-drop">Drag & Drop</p>
          <p className="browse-files">your files anywhere, <span>browse</span> or <span>add from web</span></p>
        </div>
      }
    </div>

  render = () =>
    <Dropzone
      accept="image/*"
      onDrop={this.onDrop}
      multiple={false}
    >{({ getRootProps, getInputProps }) =>
      <section
        {...getRootProps()}
        style={this.props.src ? { backgroundImage: `url('${this.props.src}')` } : {}}
        className={`standard-dropzone ${this.props.className} ${css.section}`}
      >
        <input {...getInputProps()}/>

        {
          this.props.src &&
          this.renderRemoveButton()
        }

        {
          !this.props.src &&
          this.state.speUploadImage.status !== 'request' &&
          this.renderDragDropContent()
        }

        {this.renderLoading()}

        <StandardAlert
          open={this.state.ifShowImageErrorAlert}
          type={Constants.ALERT_TYPE.warning}
          message="Image size should be less than 2MB."
          onClose={() => this.setState({ ifShowImageErrorAlert: false })}
        />
      </section>
    }</Dropzone>
}

export default ImageUploadDropzone;
