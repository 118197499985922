import React from 'react';
import PropTypes from 'prop-types';

import fakeFillOnCtrlP from './services/fakeFillOnCtrlP';
import LabelAndInput from './_partials/LabelAndInput';

class Textarea extends React.Component {
  static propTypes = {
    label: PropTypes.string, // not required when naked
    name: PropTypes.string.isRequired,
    formState: PropTypes.object.isRequired,
    updateFormState: PropTypes.func.isRequired,

    placeholder: PropTypes.string,
    required: PropTypes.bool,
    naked: PropTypes.bool,
    readOnly: PropTypes.bool,
    textareaProps: PropTypes.object,
    ifComplexPlaceholder: PropTypes.bool
  }

  static defaultProps = {
    label: '',
    placeholder: '',
    required: false,
    naked: false,
    readOnly: false,
    ifComplexPlaceholder: false
  }

  constructor(props) {
    super(props);
    this.refTextarea = React.createRef();
  }

  updateFormState = (event) =>
    this.props.updateFormState({
      ...this.props.formState,
      [this.props.name]: event.target.value
    })

  ifTextareaIsFocused = () =>
    this.refTextarea.current === document.activeElement

  ifShowComplexPlaceholder = () =>
    this.props.ifComplexPlaceholder &&
    this.props.formState[this.props.name] === '' &&
    !this.ifTextareaIsFocused()

  getValue = () => (
    this.ifShowComplexPlaceholder() ?
      this.props.placeholder :
      this.props.formState[this.props.name]
  )

  renderInput = () =>
    <textarea
      ref={this.refTextarea}
      className={`
        standard-input -Textarea
        ${this.props.readOnly ? '-read-only' : ''}
        ${this.ifShowComplexPlaceholder() ? '-showing-complex-placeholder' : ''}
      `}
      value={this.getValue()}
      onChange={this.updateFormState}
      readOnly={this.props.readOnly}
      {...this.props.textareaProps}

      onBlur={() => this.props.ifComplexPlaceholder && this.forceUpdate()}
      onFocus={() => this.props.ifComplexPlaceholder && this.forceUpdate()}
      placeholder={this.props.ifComplexPlaceholder ? '' : this.props.placeholder}
    />

  render = () => (
    this.props.naked ?
      this.renderInput() :
      <LabelAndInput
        className={'-' + this.props.name}
        label={this.props.label}
        required={this.props.required}
        inputEl={this.renderInput()}
      />
  )
}

fakeFillOnCtrlP(Textarea, function () {
  const randomString = (length) =>
    Math.random().toString(36).substring(2, length + 2);

  const value = randomString(100);
  this.updateFormState({ target: { value } });
});

export default Textarea;
