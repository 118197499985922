import React from 'react';
import PropTypes from 'prop-types';

class NpsChart extends React.Component {
  render = () => {
    const { value } = this.props;
    const seekPercent = !isNaN(value) ? (value + 100) / 200 * 100 : 0;
    const seekBoxStyle = {
      left: `calc(${seekPercent}% - 15px)`
    };

    let seekTextColor = '';

    if (seekPercent < 50) {
      seekTextColor = '#e2766a';
    } else if (seekPercent >= 50 && seekPercent < 65) {
      seekTextColor = '#f0b957';
    } else if (seekPercent >= 65 && seekPercent < 85) {
      seekTextColor = '#66bf7b';
    } else {
      seekTextColor = '#52a761';
    }
    return (
      <div className="nps-chart">
        <div className="min-max">-100</div>
        <div className="main-block">
          <div className="color-blocks">
            <div className="bad"></div>
            <div className="good">0</div>
            <div className="great">30</div>
            <div className="excellent">70</div>
          </div>
          <div className="borders-block">
            <div className="bad"></div>
            <div className="good"></div>
            <div className="great"></div>
            <div className="excellent"></div>
          </div>
          <div className="text-blocks">
            <div className="bad"><span className="label">NEEDS IMPROVEMENT</span><br/>(-100 - 0)</div>
            <div className="good"><span className="label">GOOD</span><br/>(0 - 30)</div>
            <div className="great"><span className="label">GREAT</span><br/>(30 - 70)</div>
            <div className="excellent"><span className="label">EXCELLENT</span><br/>(70 - 100)</div>
          </div>
          <div className="seek-box" style={seekBoxStyle}>
            <h3 style={{color: seekTextColor}}>{value}</h3>
            <div className="seek-bar"></div>
          </div>
        </div>
        <div className="min-max">100</div>
      </div>
    );
  }
}

NpsChart.propTypes = {
  value: PropTypes.number.isRequired,
};

export default NpsChart;
