import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import WatchLaterOutlinedIcon from '@material-ui/icons/WatchLaterOutlined';

import css from './index.scss';

class DatetimeInput extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    className: ''
  }

  ifValidDate = (currentDate) =>
    currentDate.isAfter(Datetime.moment().subtract(1, 'day'))

  render() {
    const { className, label, value } = this.props;
    return (
      <div className={`${css.div} ${className}`}>
        <label>{label}</label>
        <Datetime
          value={value}
          isValidDate={this.ifValidDate}
          timeFormat="h:mm A"
          inputProps={{ placeholder: 'Always' }}
          onChange={this.props.onChange}
          closeOnSelect
        />
        <WatchLaterOutlinedIcon className="icon-watch"/>
      </div>
    );
  }
}

export default DatetimeInput;
