import ourFetch from './services/ourFetch';
import hashToQueryString from './services/hashToQueryString';

const getDirectVideoUrl = (dispatch, possiblyIndirectUrl) =>
  ourFetch(
    dispatch,
    'GET', `/api/get-direct-video-url/?${hashToQueryString({ url: possiblyIndirectUrl })}`,
  );

export default {
  getDirectVideoUrl
};
