import fileFetch from './services/fileFetch';

// File#fileName gets read from the js's file.name (https://developer.mozilla.org/en-US/docs/Web/API/File/File)
const upload = (dispatch, file) => {
  const formData = new FormData();
  formData.append('file', file);
  return fileFetch(dispatch,
    'POST', '/api/files/upload/',
    formData
  );
};

export default { upload };
