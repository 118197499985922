import React from 'react';
import PropTypes from 'prop-types';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import AddCourseTogglerAndModal from '../AddCourseTogglerAndModal';
import { TextInput } from '~/_partials/standardForm';

import css from './index.scss';

class SubHeader extends React.Component {
  static propTypes = {
    addCourse: PropTypes.func,
    onChangeSearch: PropTypes.func,
  };

  state = {
    searchState: {
      search_text: '',
    }
  }

  handleChangeSearchState = (searchState) => {
    this.setState({ searchState });
    this.props.onChangeSearch(searchState.search_text);
  }

  renderToggler = () =>
    <button className="standard-button -plum" onClick={this.props.addCourse}>+ Add Program</button>

  render = () =>
    <section className={`subheader ${css.section}`}>
      <div className="title-and-add-course">
        <h3 className="subheader-title">Program</h3>
        <AddCourseTogglerAndModal toggler={this.renderToggler()}/>
      </div>
      <div className="standard-search-box">
        <SearchOutlinedIcon className="search-icon"/>
        <TextInput
          name="search_text"
          formState={this.state.searchState}
          updateFormState={this.handleChangeSearchState}
          placeholder="Search by program name"
        />
      </div>
    </section>
}

export default SubHeader;
