import React from 'react';
import PropTypes from 'prop-types';

import fakeFillOnCtrlP from './services/fakeFillOnCtrlP';

import ImageUploadDropzone from '~/_partials/ImageUploadDropzone';
import LabelAndInput from './_partials/LabelAndInput';

class ImageInput extends React.Component {
  static propTypes = {
    label: PropTypes.string, // not required when naked
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    formState: PropTypes.object.isRequired,
    updateFormState: PropTypes.func.isRequired,
    required: PropTypes.bool,
    naked: PropTypes.bool,
    readOnly: PropTypes.bool
  }

  static defaultProps = {
    label: '',
    required: false,
    naked: false,
    readOnly: false
  }

  updateFormState = (src) =>
    this.props.updateFormState({
      ...this.props.formState,
      [this.props.name]: src
    })

  renderInput = () =>
    <ImageUploadDropzone
      className={`standard-input -ImageInput ${this.props.readOnly ? '-read-only' : ''}`}
      src={this.props.formState[this.props.name]}
      updateSrc={this.updateFormState}
      readOnly={this.props.readOnly}
      placeholder={this.props.placeholder}
    />

  render = () => (
    this.props.naked ?
      this.renderInput() :
      <LabelAndInput
        className={'-' + this.props.name}
        label={this.props.label}
        required={this.props.required}
        inputEl={this.renderInput()}
      />
  )
}

fakeFillOnCtrlP(ImageInput, function () {
  this.updateFormState('https://loremflickr.com/320/240/nature');
});

export default ImageInput;
