import ourFetch from './services/ourFetch';
// import hashToQueryString from './services/hashToQueryString';

const bulkInviteStudents = (dispatch, arrayOfInvitations, { programId = null }) =>
  ourFetch(
    dispatch,
    'POST', '/api/invitations/bulk-invite-students/',
    {
      array_of_invitations: arrayOfInvitations,
      program_id: programId
    }
  );

export default {
  bulkInviteStudents
};
