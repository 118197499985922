import React from 'react';
import PropTypes from 'prop-types';

import SwitchInput from '~/_partials/SwitchInput';
import StandardEditor from '~/_partials/StandardEditor';

class QuestionAndExplanation extends React.Component {
  static propTypes = {
    itemType: PropTypes.string.isRequired,
    formState: PropTypes.object.isRequired,
    // When we click SAVE - update the content in the UI right away.
    updateFormState: PropTypes.func.isRequired
  }

  state = {
    ifShowExplanation: false
  }

  updateFormState = (hash) =>
    this.props.updateFormState({
      ...this.props.formState,
      ...hash
    })

  render = () =>
    <div className="question_and_explanation">
      <div className="question">
        <StandardEditor
          value={this.props.formState['payload']}
          updateValue={(html) => this.updateFormState({ payload: html })}
          tinymceToolbar={'styleselect | bold | bullist numlist | link image | forecolor | codesample'}
        />
      </div>

      {this.props.itemType === 'test' &&
        <div className="explanation">
          <SwitchInput
            className="switch-input"
            value={this.state.ifShowExplanation}
            updateValue={(value) => this.setState({ ifShowExplanation: value })}
            possibleValues={[false, true]}
            labelRight={this.state.ifShowExplanation ? 'Hide Answer Explanation' : 'Show Answer Explanation'}
          />
          {
            // ___If we'd like a slidedown animation?
            //    https://stackoverflow.com/a/40891107/3192470
            this.state.ifShowExplanation &&
            <textarea
              value={this.props.formState['explanation']}
              onChange={(event) => this.updateFormState({ explanation: event.target.value })}
              maxLength={1000}
              placeholder="Explanation (up to 1,000 characters)"
            />
          }
        </div>
      }
    </div>
}

export default QuestionAndExplanation;
