import React from 'react';
import PropTypes from 'prop-types';

import SpeCreator from '~/services/SpeCreator';
import ProgramApi from '~/api/ProgramApi';
import Urls from '~/services/Urls';

import Loading from '~/_partials/Loading';

import css from './index.scss';

class NewVersionNeededModal extends React.Component {
  static propTypes = {
    editorForCourseOrExam: PropTypes.oneOf(['course', 'exam']).isRequired,
    programId: PropTypes.number.isRequired,
    ifAlreadyAdministed: PropTypes.bool.isRequired
  }

  state = {
    speSave: SpeCreator.empty()
  }

  apiCreateNewVersion = () =>
    ProgramApi.createNewVersion(
      (spe) => this.setState({ speSave: spe }),
      this.props.programId
    )
      .then(({ prog_id, first_module }) => {
        window.location = this.props.editorForCourseOrExam === 'course' ?
          Urls.getCourseEditorUrl({ courseId: prog_id, moduleId: first_module, itemType: 'module' }) :
          Urls.getExamEditorUrl({ examId: prog_id });
      })

  uiReturnToPreviousPage = () => {
    history.back();
  }

  render = () => (
    this.props.ifAlreadyAdministed &&
    <div className={`standard-modal-wrapper ${css.modalWrapper}`}>
      <div className="standard-modal">
        <div className="standard-modal-header"/>

        <div className="standard-modal-main">
          <h2>
            This program has already been administered.
          </h2>
          <h2>
            Are you sure you want to edit it?
          </h2>

          <p className="please-note">
            *Please note:* New edits will only be visible to students who have not already started your program.
            If you are making major changes to your program,
            consider creating a duplicate copy to ensure the integrity of your data.<br/>
            Visit our Help Center for more information
          </p>
        </div>

        <Loading className="standard-modal-spe" spe={this.state.speSave}/>

        <div className="standard-modal-footer -with-buttons">
          <button
            className="standard-button -sliding -red"
            type="button"
            onClick={this.uiReturnToPreviousPage}
          >Cancel</button>

          <button
            className="standard-button -sliding -blue"
            type="submit"
            onClick={this.apiCreateNewVersion}
          >Continue</button>
        </div>
      </div>
    </div>
  )
}

export default NewVersionNeededModal;
