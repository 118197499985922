import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Rnd } from 'react-rnd';

import ContentApi from '~/api/ContentApi';
import disableOnSpeRequest from '~/services/disableOnSpeRequest';

const HORIZONTAL_RESIZE_ENABLE_OPTION = {
  left: true,
  right: true,
  bottom: false,
  bottomLeft: false,
  bottomRight: false,
  top: false,
  topLeft: false,
  topRight: false,
};

const VERTICAL_RESIZE_ENABLE_OPTION = {
  bottom: true,
  top: true,
  bottomLeft: false,
  bottomRight: false,
  left: false,
  right: false,
  topLeft: false,
  topRight: false,
};

class LineContent extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      id: PropTypes.number.isRequired,
      content_type: PropTypes.oneOf(['horizontal-line', 'vertical-line']).isRequired,
      payload: PropTypes.string.isRequired,
      options: PropTypes.string.isRequired,
    }).isRequired,
    uiDeleteContent: PropTypes.func.isRequired,
    uiUpdateCertificateElementLayout: PropTypes.func,
  }

  state = {
    speDelete: {}
  }

  apiDelete = () =>
    ContentApi.delete(
      (spe) => this.setState({ speDelete: spe }),
      this.props.content.id
    )
      .then(() => {
        this.props.uiDeleteContent(this.props.content.id);
      })

  uiUpdateContentElementLayout = () => {
    const contentElement = this.resizer ? this.resizer.getSelfElement() : null;

    if (contentElement) {
      this.props.uiUpdateCertificateElementLayout(contentElement);
    }
  }

  uiDeleteContent = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.apiDelete();
  }

  render = () => {
    const ifHorizontalLine = (this.props.content.content_type === 'horizontal-line');
    const ifVerticalLine = (this.props.content.content_type === 'vertical-line');
    return (
      <Rnd
        className={classNames('certificate-resizer -line', {
          '-horizontal': ifHorizontalLine,
          '-vertical': ifVerticalLine,
        })}
        ref={rndRef => this.resizer = rndRef}
        default={{
          x: parseInt(this.props.content.options.split(', ')[0], 10),
          y: parseInt(this.props.content.options.split(', ')[1], 10),
          width: ifHorizontalLine ? parseInt(this.props.content.payload, 10) : undefined,
          height: ifVerticalLine ? parseInt(this.props.content.payload, 10) : undefined,
        }}
        enableResizing={ifHorizontalLine ? HORIZONTAL_RESIZE_ENABLE_OPTION : VERTICAL_RESIZE_ENABLE_OPTION}
        bounds="parent"
        style={{ ...disableOnSpeRequest(this.state.speDelete) }}
        onResizeStop={this.uiUpdateContentElementLayout}
        onDragStop={this.uiUpdateContentElementLayout}
      >
        <div className="cert-line-container">
          <div className="cert-line"/>
          <button className="button-remove-cert" onClick={this.uiDeleteContent}>
            <i className="fa fa-times"/>
          </button>
        </div>
      </Rnd>
    );
  }
}

export default LineContent;
