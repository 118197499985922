import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/SearchOutlined';

import checkReordableFromOneToNewIndex from '~/services/checkReordableFromOneToNewIndex';
import injectFromOldToNewIndex from '~/services/injectFromOldToNewIndex';
import ModuleApi from '~/api/ModuleApi';
import { TextInput } from '~/_partials/standardForm';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import AddModuleTogglerAndModal from '~/pages/DashboardCourseDetail/_partials/Modals/AddModuleTogglerAndModal';
import ModuleLi from './_partials/ModuleLi';

import css from './index.scss';

class CourseModulesAside extends React.Component {
  static propTypes = {
    currentModuleId: PropTypes.number.isRequired,
    currentCourseId: PropTypes.number.isRequired,

    modules: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateModules: PropTypes.func.isRequired
  }

  state = {
    searchState: {
      search_text: ''
    },
    ifCreatingModule: false,
  }

  updateSearchState = (searchState) => {
    this.setState({ searchState });
  }

  uiCreateModule = (type) => {
    const prevModules = this.props.modules;
    const newModules = [
      ...prevModules,
      {
        name: '',
        program_type: type,
      }
    ];
    this.props.updateModules(newModules);
    this.setState({ ifCreatingModule: true });
  }

  onDragEnd = (result) => {
    const ifDroppedInsideList = result.destination;

    if (!ifDroppedInsideList) return;

    const { searchState } = this.state;
    const fromIndex = result.source.index;
    const toIndex = result.destination.index;
    const modules = this.props.modules.filter(module =>
      module.program_type !== 'Alert' && module.name.toLowerCase().includes(searchState.search_text.toLowerCase()));
    const ifCanReorder = checkReordableFromOneToNewIndex(modules, fromIndex, toIndex);

    if (ifCanReorder) {
      this.uiReorderModules({ fromIndex, toIndex });
    }
  }

  uiReorderModules = ({ fromIndex, toIndex }) => {
    const { modules } = this.props;
    const filteredModules = modules.filter(module =>
      module.program_type !== 'Alert' &&
      module.name.toLowerCase().includes(this.state.searchState.search_text.toLowerCase()));

    if (filteredModules[fromIndex] && filteredModules[toIndex]) {
      const realFromIndex = modules.findIndex(module => module.id === filteredModules[fromIndex].id);
      const realToIndex = modules.findIndex(module => module.id === filteredModules[toIndex].id);
      const reorderedModules = injectFromOldToNewIndex(modules, realFromIndex, realToIndex);
      this.props.updateModules(reorderedModules);
      this.apiReorderModules(reorderedModules);
    }
  }

  uiUpdateModules = (newModules) => {
    const oldModules = this.props.modules;
    const oldLastModule = oldModules[oldModules.length - 1];
    const newLastModule = newModules[newModules.length - 1];

    if (newModules.length !== oldModules.length || (oldLastModule.name.length === 0 && newLastModule.name.length > 0)) {
      this.setState({ ifCreatingModule: false });
    }

    this.props.updateModules(newModules);
  }

  apiReorderModules = (reorderedModules) =>
    ModuleApi.reorderAll(
      false,
      reorderedModules.map((module) => module.id)
    )

  renderToggler = () =>
    <button
      className="standard-button -green"
      disabled={this.state.ifCreatingModule}
    >
      + Add Section
    </button>

  render = () => {
    const { searchState } = this.state;
    return (
      <aside className={`course-modules-aside ${css.aside}`}>
        <div className="title-bar">
          <h1 className="section-title">Sections</h1>
          <div className="standard-search-box section-search-box">
            <TextInput
              name="search_text"
              formState={searchState}
              updateFormState={this.updateSearchState}
              placeholder="Search by Name"
            />
            <SearchIcon className="search-icon"/>
          </div>
        </div>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="contents">{(dropProvided, dropSnapshot) =>
            <ul
              className={`modules ${dropSnapshot.isDraggingOver ? '-is-dragging-over' : ''}`}
              ref={dropProvided.innerRef}
              {...dropProvided.droppableProps}
            >
              {/* TODO Hide & Alert modules */}
              {this.props.modules.filter(module =>
                module.program_type !== 'Alert' && module.name.toLowerCase().includes(searchState.search_text.toLowerCase()))
                .map((module, index) =>
                  <ModuleLi
                    key={index}
                    index={index}

                    module={module}
                    modules={this.props.modules}
                    updateModules={this.uiUpdateModules}

                    currentModuleId={this.props.currentModuleId}
                    currentCourseId={this.props.currentCourseId}
                  />
              )}
              {dropProvided.placeholder}
            </ul>
          }</Droppable>
        </DragDropContext>

        <div className="add-module-div">
          <AddModuleTogglerAndModal toggler={this.renderToggler()} modules={this.props.modules} onClose={this.uiCreateModule}/>
        </div>
      </aside>
    );
  }
}

export default CourseModulesAside;
