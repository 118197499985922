import React from 'react';
import PropTypes from 'prop-types';

import disableOnSpeRequest from '~/services/disableOnSpeRequest';
import ModuleOrExamApi from '~/api/ModuleOrExamApi';

import { RadioInput } from '~/_partials/standardForm';
import StandardTooltip from '~/_partials/StandardTooltip';
import Loading from '~/_partials/Loading';

class ProgramScoringSection extends React.Component {
  static propTypes = {
    moduleOrExamId: PropTypes.number.isRequired,
    editorForCourseOrExam: PropTypes.oneOf(['course', 'exam']).isRequired,
    subscriptionPlanName: PropTypes.string.isRequired,

    scoringType: PropTypes.oneOf(['percentage', 'point']).isRequired,
    updateScoringType: PropTypes.func.isRequired,
    totalAmountOfPoints: PropTypes.number.isRequired
  }

  state = {
    speUpdateScoringType: {}
  }

  apiUpdateScoringType = (event) => {
    const oldScoringType = this.props.scoringType;
    const newScoringType = event.target.value;

    // ___Why not just wait for the api response?
    //    Because we expect radio button to work instanteneously.
    this.props.updateScoringType(newScoringType);
    ModuleOrExamApi.updateScoringType(
      (spe) => this.setState({ speUpdateScoringType: spe }),
      this.props.moduleOrExamId,
      newScoringType
    )
      .catch(() => {
        this.props.updateScoringType(oldScoringType);
      });
  }

  renderRadioButton = (value, labelText, tooltipText, { ifDisabled = false } = {}) =>
    <label className={ifDisabled ? '-disabled' : ''}>
      <RadioInput
        label={labelText}
        value={value}
        checked={this.props.scoringType === value}
        updateChecked={ifDisabled ? () => {} : this.apiUpdateScoringType}
      />

      <StandardTooltip tooltipEl={tooltipText}/>
    </label>

  render = () =>
    <section className="exam-scoring-section _joyride-course-test-3 _joyride-exam-3">
      <h2 className="title">{
        this.props.editorForCourseOrExam === 'course' ?
        'Test Scoring' : 'Course Scoring'
      }</h2>

      <div className="percentage-or-points" style={disableOnSpeRequest(this.state.speUpdateScoringType)}>
        {this.renderRadioButton('percentage', 'Percentage', 'Score will be calculated by taking the average of all items.')}
        {
          this.props.subscriptionPlanName === 'Starter' ?
            this.renderRadioButton('point', 'Point', 'Upgrade to Professional Plan to access this feature.', { ifDisabled: true }) :
            this.renderRadioButton('point', 'Point', 'Assign specific point values based on the item type. Use this method to make some items worth more than others.')
        }
      </div>

      {
        this.props.scoringType === 'point' &&
        <div className="total-amount-of-points">Total points: {this.props.totalAmountOfPoints}</div>
      }

      <Loading spe={this.state.speUpdateScoringType} enabledStatuses={['failure']}/>
    </section>
}

export default ProgramScoringSection;
