import React from 'react';
import PropTypes from 'prop-types';

import Utils from '~/services/Utils';
import StudentApi from '~/api/StudentApi';
import TestService from '../../services/TestService';

class AnswerContent extends React.Component {
  static propTypes = {
    content: PropTypes.object.isRequired,
    user_role: PropTypes.string.isRequired,
    view_as: PropTypes.bool.isRequired,
    if_current_module_is_test: PropTypes.bool.isRequired,
    if_can_show_correct_answers: PropTypes.bool.isRequired,
    if_current_module_is_completed: PropTypes.bool.isRequired,
    if_can_show_answer_explanations: PropTypes.bool.isRequired,
    if_current_course_is_completed: PropTypes.bool.isRequired,
    uiUpdateContent: PropTypes.func.isRequired,
  };

  uiUpdateAnswer = (answerid, updatedIndex) => {
    const { content } = this.props;
    const selectedAnswer = content.answers[updatedIndex];
    const updatedContent = {
      ...content,
      student_correct: selectedAnswer.is_correct_answer,
      answers: [
        ...content.answers.slice(0, updatedIndex),
        {
          ...selectedAnswer,
          selected: true,
        },
        ...content.answers.slice(updatedIndex + 1),
      ],
    };
    updatedContent.answers.forEach(answer => {
      if (answer.id !== selectedAnswer.id) {
        answer.selected = false;
      }
    });
    this.props.uiUpdateContent(updatedContent);

    StudentApi.answerTest(false, {
      content_id: content.id,
      answer_id: answerid,
    }).then(() => {
      TestService.countItems();
      TestService.validateQuestions();
    }).catch(() => {
      this.props.uiUpdateContent(content);
    });
  }

  render = () => {
    const {
      content, user_role, view_as, if_current_module_is_test,
      if_can_show_correct_answers, if_current_module_is_completed,
      if_can_show_answer_explanations, if_current_course_is_completed,
    } = this.props;
    return (
      <div className="answer-container">
        <div className="test-answers">
          {content.answers.map((answer, index) =>
            <label key={answer.id} className="answer-radio-box">
              <input
                type="radio"
                className="question-answer"
                name={`multiple-choice-radio${content.id}`}
                value={answer.id}
                checked={(user_role === 'creator' && !view_as && (answer.is_correct_answer || answer.selected)) || (user_role === 'student' && answer.selected)}
                disabled={if_current_module_is_completed}
                onChange={() => this.uiUpdateAnswer(answer.id, index)}
              />
              <span className="question-text">{Utils.stripHtml(answer.payload)}</span>
              {/* if we are student, this is a correct answer, but we didn't choose it */}
              {user_role === 'student' && answer.is_correct_answer && if_can_show_correct_answers && !answer.selected && if_current_module_is_test &&
                <span className="correct-answer">CORRECT</span>
              }
            </label>
          )}
          {!content.student_correct && content.explanation !== '' && if_can_show_answer_explanations && if_current_course_is_completed &&
            <div className="content-explanation">
              <b>Explanation:</b> {content.explanation}
            </div>
          }
        </div>
      </div>
    );
  }
}

export default AnswerContent;

