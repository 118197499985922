import React from 'react';
import PropTypes from 'prop-types';
import orFalse from '~/services/orFalse';

import SpeCreator from '~/services/SpeCreator';
import disableOnSpeRequest from '~/services/disableOnSpeRequest';
import ContentApi from '~/api/ContentApi';

import Loading from '~/_partials/Loading';
import TogglerAndModal from '~/_partials/TogglerAndModal';

class ContentTogglerAndModalWrapper extends React.Component {
  static propTypes = {
    // 1. These are the props passed through from e.g. <QuestionTogglerAndModal/>!
    toggler: PropTypes.element.isRequired,
    enableToggler: PropTypes.bool,
    itemType: PropTypes.string,
    // Either or must be passed:
    // (content must always be passed though, even if false, for the sale of 'here is the intent')
    content: orFalse(PropTypes.object).isRequired,
    createWith: orFalse(PropTypes.shape({
      moduleOrExamId: PropTypes.number.isRequired,
      itemType: PropTypes.string.isRequired,
      contentType: PropTypes.oneOf([
        'yes-no', 'multiple-choice', 'multiple-choice-2', 'nps', 'true-false',
        'heading', 'paragraph', 'page-break', 'image', 'file', 'dropdown', 'likert',
        'video', 'rating-scale', 'single-line-text', 'multi-line-text',
        'cert-image', 'cert-text', 'vertical-line', 'horizontal-line', 'field', 'background-image',
      ]).isRequired,
      position: PropTypes.number.isRequired
    })),

    afterContentIsCreated: PropTypes.func,
    afterContentIsUpdated: PropTypes.func,
    afterContentIsDeleted: PropTypes.func,
    afterModalCloses: PropTypes.func,

    // 2. And these are the props that e.g. <QuestionTogglerAndModal/> should pass!
    title: PropTypes.string.isRequired,
    // formState
    initialState: PropTypes.object.isRequired,
    fromApiToState: PropTypes.func.isRequired,
    fromStateToApi: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
    // A component in the middle of the modal, that handles formState display and changes!
    // It's a function, accepts (formState, updateFormState)
    children: PropTypes.func.isRequired
  }

  static defaultProps = {
    createWith: false,
    afterContentIsCreated: () => {},
    afterContentIsUpdated: () => {},
    afterContentIsDeleted: () => {},
    afterModalCloses: () => {}
  }

  state = {
    formState: this.props.initialState,
    speSave: SpeCreator.empty()
  }

  componentWillReceiveProps(props) {
    if (this.props.createWith && props.initialState.options && props.initialState.options !== this.state.formState.options) {
      this.setState({ formState: props.initialState });
    }
  }

  afterModalOpens = () => {
    if (this.props.content) {
      this.setState({
        formState: this.props.fromApiToState(this.props.content),
        speSave: SpeCreator.empty()
      });
    }
  }

  afterModalCloses = () => {
    this.setState({
      formState: this.props.initialState,
      speSave: SpeCreator.empty()
    });
    this.props.afterModalCloses();
  }

  apiCreate = (closeModal) =>
    ContentApi.create(
      (spe) => this.setState({ speSave: spe }),
      {
        ...this.props.fromStateToApi(this.state.formState),
        item_type: this.props.createWith.itemType,
        module_or_exam_id: this.props.createWith.moduleOrExamId,
        content_type: this.props.createWith.contentType,
        position: this.props.createWith.position
      }
    )
      .then((createdContent) => {
        this.props.afterContentIsCreated(createdContent);
        closeModal();
      })

  apiUpdate = (closeModal) => {
    ContentApi.update(
      (spe) => this.setState({ speSave: spe }),
      this.props.content.id, {
        ...this.props.fromStateToApi(this.state.formState),
        item_type: this.props.itemType,
      }
    )
      .then((updatedContent) => {
        this.props.afterContentIsUpdated(updatedContent);
        closeModal();
      });
    }

  apiSave = (closeModal) => (
    this.props.content ?
      this.apiUpdate(closeModal) :
      this.apiCreate(closeModal)
  )

  apiDelete = (closeModal) =>
    ContentApi.delete(
      (spe) => this.setState({ speSave: spe }),
      this.props.content.id
    )
      .then(() => {
        closeModal();
        this.props.afterContentIsDeleted(this.props.content.id);
      })

  renderFooter = (closeModal) =>
    <div className="standard-modal-footer -with-buttons">
      {
        this.props.content &&
        <button
          className="standard-button -sliding -red"
          type="button"
          onClick={() => this.apiDelete(closeModal)}
          style={disableOnSpeRequest(this.state.speSave)}
        >Delete</button>
      }
      <button
        className="standard-button -sliding -red"
        type="reset"
        onClick={closeModal}
        style={disableOnSpeRequest(this.state.speSave)}
      >Cancel</button>
      <button
        className="standard-button -sliding -blue"
        type="submit"
        onClick={() => this.apiSave(closeModal)}
        style={disableOnSpeRequest(this.state.speSave)}
      >Save</button>
    </div>

  render = () =>
    <TogglerAndModal
      toggler={this.props.toggler}
      enableToggler={this.props.enableToggler}
      afterModalOpens={this.afterModalOpens}
      afterModalCloses={this.afterModalCloses}
    >{(closeModal, renderCloseButton) =>
      <div className={`standard-modal ${this.props.className}`}>
        {renderCloseButton()}

        <div className="standard-modal-header">
          {this.props.title}
        </div>

        {this.props.children(
          this.state.formState,
          (formState) => this.setState({ formState })
        )}

        <Loading className="standard-modal-spe" spe={this.state.speSave}/>

        {this.renderFooter(closeModal)}
      </div>
    }</TogglerAndModal>
}

export default ContentTogglerAndModalWrapper;
