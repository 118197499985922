import fetchWrapper from './fetchWrapper';
import handleErrors from './handleErrors';
import getCookie from './getCookie';

const fileFetch = (dispatch, method, url, formData) =>
  fetchWrapper(
    dispatch,
    fetch(url, {
      method,
      headers: {
        'X-CSRFToken': getCookie('csrftoken')
      },
      credentials: 'same-origin',
      body: formData
    })
      .then(handleErrors)
  );

export default fileFetch;
