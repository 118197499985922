import oldApiFetch from './services/oldApiFetch';
import ourFetch from './services/ourFetch';

const updateGroup = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/groups/edit/',
    params,
  );

const duplicateGroup = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/groups/duplicate/',
    params,
  );

const deleteGroup = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/groups/delete/',
    params,
  );

const getStudents = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/groups/get-students/',
    params,
  );

const addStudents = (dispatch, params) =>
  ourFetch(dispatch,
    'POST',
    '/api/groups/add-students/',
    params,
  );

export default {
  updateGroup,
  duplicateGroup,
  deleteGroup,
  getStudents,
  addStudents,
};
