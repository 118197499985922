// ___Where is this taken from?
//    https://stackoverflow.com/a/34166265/3192470
const prettifyBytes = (bytes) => {
  const kb = 1024;
  const ndx = Math.floor(Math.log(bytes) / Math.log(kb));
  const fileSizeTypes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const size = +(bytes / kb / kb).toFixed(2);
  const type = fileSizeTypes[ndx];
  return size + ' ' + type;
};

export default prettifyBytes;
