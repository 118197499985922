import React from 'react';
import PropTypes from 'prop-types';
import StandardTooltip from '~/_partials/StandardTooltip';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import {
  CheckBoxOutlined as CheckboxIcon,
  LayersOutlined as MultipleChoiceIcon,
  ArrowDropDownCircleOutlined as DropdownIcon,
  ViewQuiltOutlined as LikertIcon,
  IsoOutlined as TrueFalseIcon,
  TextFieldsOutlined as HeadingIcon,
  NotesOutlined as MultilineIcon,
  PhotoSizeSelectActualOutlined as ImageIcon,
  Title as TextIcon,
  PhotoAlbumOutlined as BackgroundIcon,
  Remove as HorizontalLineIcon,
  OndemandVideoOutlined as VideoIcon,
} from '@material-ui/icons';

import VideoTogglerAndModal     from '../../../../_contentModals/VideoTogglerAndModal';
import FileTogglerAndModal      from '../../../../_contentModals/FileTogglerAndModal';
import QuestionTogglerAndModal  from '../../../../_contentModals/QuestionTogglerAndModal';
import ImageTogglerAndModal     from '../../../../_contentModals/ImageTogglerAndModal';
import ParagraphTogglerAndModal from '../../../../_contentModals/ParagraphTogglerAndModal';
import HeadingTogglerAndModal   from '../../../../_contentModals/HeadingTogglerAndModal';
import DropdownTogglerAndModal  from '../../../../_contentModals/DropdownTogglerAndModal';
import LikertTogglerAndModal    from '../../../../_contentModals/LikertTogglerAndModal';
import TextTogglerAndModal    from '../../../../_contentModals/TextTogglerAndModal';
import RatingScaleTogglerAndModal from '../../../../_contentModals/RatingScaleTogglerAndModal';

const TOGGLER_ICON = {
  checkbox: { icon: () => <CheckboxIcon/> },
  multiselect: { icon: () => <MultipleChoiceIcon/> },
  dropdown: { icon: () => <DropdownIcon/> },
  likert: { icon: () => <LikertIcon/> },
  truefalse: { icon: () => <TrueFalseIcon/> },
  heading: { icon: () => <HeadingIcon/> },
  image: { icon: () => <ImageIcon/> },
  text: { icon: () => <TextIcon/> },
  multiline: { icon: () => <MultilineIcon/> },
  background: { icon: () => <BackgroundIcon/> },
  vert: { icon: () => <HorizontalLineIcon style={{ transform: 'rotate(90deg)' }}/> },
  horiz: { icon: () => <HorizontalLineIcon/> },
  video: { icon: () => <VideoIcon/> },
};

const PROGRAM_TOOL = {
  question: {
    modal: QuestionTogglerAndModal,
    type: 'yes-no',
    title: 'Yes/No',
    faClass: '',
    icon: TOGGLER_ICON.checkbox,
  },
  multichoice: {
    modal: QuestionTogglerAndModal,
    type: 'multiple-choice',
    title: 'Multiple Choice',
    faClass: '',
    icon: TOGGLER_ICON.multiselect,
  },
  multichoice2: {
    modal: QuestionTogglerAndModal,
    type: 'multiple-choice-2',
    title: 'Multiple Choice 2',
    faClass: '',
    icon: TOGGLER_ICON.multiselect,
  },
  nps: {
    modal: QuestionTogglerAndModal,
    type: 'nps',
    title: 'NPS',
    faClass: '',
    icon: TOGGLER_ICON.multiselect,
  },
  logic_question: {
    modal: QuestionTogglerAndModal,
    type: 'true-false',
    title: 'True/False',
    faClass: '',
    icon: TOGGLER_ICON.truefalse,
  },
  dropdown: {
    modal: DropdownTogglerAndModal,
    type: 'dropdown',
    title: 'Drop-Down',
    faClass: '',
    icon: TOGGLER_ICON.dropdown,
  },
  ratings: {
    modal: RatingScaleTogglerAndModal,
    type: 'rating-scale',
    title: 'Rating Scale',
    faClass: 'fa fa-star-half-o',
    icon: null,
  },
  likert: {
    modal: LikertTogglerAndModal,
    type: 'likert',
    title: 'Likert',
    faClass: '',
    icon: TOGGLER_ICON.likert,
  },
  heading: {
    modal: HeadingTogglerAndModal,
    type: 'heading',
    title: 'Heading',
    faClass: '',
    icon: TOGGLER_ICON.heading,
  },
  paragraph: {
    modal: ParagraphTogglerAndModal,
    type: 'paragraph',
    title: 'Paragraph',
    faClass: 'fa fa-paragraph',
    icon: null,
  },
  text: {
    modal: ParagraphTogglerAndModal,
    type: 'cert-text',
    title: 'Text',
    faClass: '',
    icon: TOGGLER_ICON.text,
  },
  singleline: {
    modal: TextTogglerAndModal,
    type: 'single-line-text',
    title: 'Single-Line Field',
    faClass: '',
    icon: TOGGLER_ICON.text,
  },
  multiline: {
    modal: TextTogglerAndModal,
    type: 'multi-line-text',
    title: 'Multi-Line Field',
    faClass: '',
    icon: TOGGLER_ICON.multiline,
  },
  background: {
    modal: null,
    type: 'background',
    title: 'Background Image',
    faClass: '',
    icon: TOGGLER_ICON.background,
  },
  vertical_line: {
    modal: null,
    type: 'vertical-line',
    title: 'Vertical Line',
    faClass: '',
    icon: TOGGLER_ICON.vert,
  },
  horizontal_line: {
    modal: null,
    type: 'horizontal-line',
    title: 'Horizontal Line',
    faClass: '',
    icon: TOGGLER_ICON.horiz,
  },
  image: {
    modal: ImageTogglerAndModal,
    type: 'image',
    title: 'Image',
    faClass: '',
    icon: TOGGLER_ICON.image,
  },
  video: {
    modal: VideoTogglerAndModal,
    type: 'video',
    title: 'Video',
    faClass: '',
    icon: TOGGLER_ICON.video,
  },
  pagebreak: {
    modal: null,
    type: 'page-break',
    title: 'Page Break',
    faClass: 'fa fa-unlink',
    icon: null,
  },
  file: {
    modal: FileTogglerAndModal,
    type: 'file',
    title: 'Attach File',
    faClass: 'fa fa-paperclip',
    icon: null,
  },
  sub_header: {
    modal: null,
    type: 'sub-header',
    title: 'Formating',
    faClass: '',
    icon: null,
  }
};

const ITEM_TOOLS = {
  general: [
    PROGRAM_TOOL.heading,
    PROGRAM_TOOL.paragraph,
    PROGRAM_TOOL.image,
    PROGRAM_TOOL.video,
    PROGRAM_TOOL.pagebreak,
    PROGRAM_TOOL.file,
  ],
  test: [
    PROGRAM_TOOL.question,
    PROGRAM_TOOL.multichoice,
    PROGRAM_TOOL.logic_question,
    PROGRAM_TOOL.multiline,
    PROGRAM_TOOL.heading,
    PROGRAM_TOOL.paragraph,
    PROGRAM_TOOL.image,
    PROGRAM_TOOL.video,
    PROGRAM_TOOL.pagebreak,
  ],
  certificate: [
    {
      ...PROGRAM_TOOL.image,
      type: 'cert-image',
    },
    PROGRAM_TOOL.text,
    PROGRAM_TOOL.background,
    PROGRAM_TOOL.vertical_line,
    PROGRAM_TOOL.horizontal_line,
  ],
  survey: [
    PROGRAM_TOOL.multichoice,
    PROGRAM_TOOL.multichoice2,
    PROGRAM_TOOL.nps,
    PROGRAM_TOOL.dropdown,
    PROGRAM_TOOL.ratings,
    PROGRAM_TOOL.likert,
    PROGRAM_TOOL.singleline,
    PROGRAM_TOOL.multiline,
    PROGRAM_TOOL.sub_header,
    PROGRAM_TOOL.paragraph,
    PROGRAM_TOOL.pagebreak,
  ],
  data: [
    PROGRAM_TOOL.multichoice,
    PROGRAM_TOOL.dropdown,
    PROGRAM_TOOL.ratings,
    PROGRAM_TOOL.singleline,
    PROGRAM_TOOL.multiline,
    PROGRAM_TOOL.sub_header,
    PROGRAM_TOOL.paragraph,
  ]
};

class Tools extends React.Component {
  static propTypes = {
    moduleOrExamId: PropTypes.number.isRequired,
    editorForCourseOrExam: PropTypes.oneOf(['course', 'exam']),
    itemType: PropTypes.string.isRequired,
    x_coordinate: PropTypes.string.isRequired,
    y_coordinate: PropTypes.string.isRequired,
    uiInitCoordinates: PropTypes.func.isRequired,

    scoringType: PropTypes.oneOf(['percentage', 'point']).isRequired,
    totalAmountOfPoints: PropTypes.number.isRequired,

    positionToCreateCurrentContentAt: PropTypes.number.isRequired,
    resetPositionToCreateCurrentContentAt: PropTypes.func.isRequired,

    uiCreateContent: PropTypes.func.isRequired,
    apiProceedWithUploadingFileViaTus: PropTypes.func.isRequired,
    uiOpenBackgrounds: PropTypes.func.isRequired,
  }

  ifCanHaveText = () =>
    this.props.itemType === 'certificate' || this.props.itemType === 'survey';

  ifCanHavePageBreak = () => {
    switch (this.props.editorForCourseOrExam) {
      case 'course':
        switch (this.props.itemType) {
          case 'module': return true;
          case 'test': return false;
        }
      case 'exam': return true;
    }
  }

  getToolsByItemType = () => {
    let tools = [];

    switch (this.props.itemType) {
      case 'test':
        tools = ITEM_TOOLS.test;
        break;
      case 'certificate':
        tools = ITEM_TOOLS.certificate;
        break;
      case 'survey':
        tools = ITEM_TOOLS.survey;
        break;
      case 'data':
        tools = ITEM_TOOLS.data;
        break;
      default:
        tools = ITEM_TOOLS.general;
        break;
    }

    return tools;
  }

  renderToggler = (type, text, faClass, dragProvided, dndIndex, joyrideClass, matIcon = null) =>
    <li
      // ___Why do we need .-dnd-index-N class?
      //    When we drop tool into the contentarea that's how we determine which modal to open
      className={`-dnd-index-${dndIndex} ${type} ${joyrideClass}`}
      // Props for react-dnd.
      ref={dragProvided.innerRef}
      {...dragProvided.draggableProps}
      {...dragProvided.dragHandleProps}
    >
      {(type === 'horizontal-line' || type === 'vertical-line' || type === 'page-break') &&
        <StandardTooltip
          className="standard-info-tooltip"
          tooltipEl={
            type === 'page-break' ?
              'Please drag the Page Break to split your section into multiple pages.'
              : 'Please drag the line to where it should appear in your Certificate'
          }
        />
      }
      {!matIcon ? <i className={faClass}/> : matIcon.icon()}
      <span>{text}</span>
    </li>

  renderTool = (
    dndIndex, SomeContentTypeTogglerAndModal, contentType,
    title, faClass,
    { additionalProps = {}, joyrideClass = '' } = {}
  ) =>
    <Draggable key={dndIndex} draggableId={dndIndex} index={dndIndex}>{(dragProvided) =>
      (contentType !== 'horizontal-line' && contentType !== 'vertical-line' && contentType !== 'page-break') ?
        React.createElement(SomeContentTypeTogglerAndModal, {
          toggler: this.renderToggler(contentType, title, faClass, dragProvided, dndIndex, joyrideClass, additionalProps.matIcon),
          content: false,
          createWith: {
            contentType,
            moduleOrExamId: this.props.moduleOrExamId,
            itemType: this.props.itemType,
            position: this.props.positionToCreateCurrentContentAt,
            x_coordinate: this.props.x_coordinate,
            y_coordinate: this.props.y_coordinate,
            uiInitCoordinates: this.props.uiInitCoordinates,
          },
          afterContentIsCreated: this.props.uiCreateContent,
          afterModalCloses: this.props.resetPositionToCreateCurrentContentAt,

          // only for QuestionTogglerAndModal
          scoringType: this.props.scoringType,
          totalAmountOfPoints: this.props.totalAmountOfPoints,

          ...additionalProps
        })
        : this.renderToggler(contentType, title, faClass, dragProvided, dndIndex, joyrideClass, additionalProps.matIcon)
    }</Draggable>

  render = () => {
    const tools = this.getToolsByItemType();
    return (
      <Droppable droppableId="tools" isDropDisabled={true}>{(dropProvided) =>
        <ul className="tools _joyride-course-module-3" ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
          {tools.map((tool, index) => {
            if (tool.type === 'background') {
              return (
                <li key={`-dnd-index-${index + 1}`} onClick={this.props.uiOpenBackgrounds}>
                  {tool.icon.icon()}
                  <span>{tool.title}</span>
                </li>
              );
            } else if (tool.type === 'sub-header') {
              return (
                <li key={`-dnd-index-${index + 1}`} className="group-header">
                  <span>Formating</span>
                </li>
              );
            } else {
              const otherToolOption = {
                additionalProps: {
                  matIcon: tool.icon,
                },
              };

              if (tool.type === 'true-false') {
                otherToolOption.joyrideClass = '_joyride-course-test-1 _joyride-exam-1';
              } else if (tool.type === 'video') {
                otherToolOption.additionalProps.apiProceedWithUploadingFileViaTus = this.props.apiProceedWithUploadingFileViaTus;
                otherToolOption.joyrideClass = '_joyride-course-test-2 _joyride-exam-2';
              }

              return this.renderTool(index + 1, tool.modal, tool.type, tool.title, tool.faClass, otherToolOption);
            }
          })}
        </ul>
      }</Droppable>
    );
  }
}

export default Tools;
