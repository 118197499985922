import React from 'react';
import PropTypes from 'prop-types';

import small_cc_icon_png from './small_cc_icon.png';

import css from './index.scss';

class LeftNav extends React.Component {
  static propTypes = {
    program: PropTypes.object.isRequired
  }

  getPrettyProgramType = () => (
    this.props.program.program_type === 'CP' ?
     'Course' : 'Exam'
  )

  renderTitle = (text) =>
    <h1>{text}</h1>

  renderLi = (text, href, classNameOrImg) =>
    <li className={text === 'Certificates' ? '-active' : ''}>
      <a href={href}>
        <span className="icon">
          {
            typeof classNameOrImg === 'string' ?
              <i className={classNameOrImg}/> :
              classNameOrImg // <img/>
          }
        </span>
        <span className="text">{text}</span>
      </a>
    </li>

  renderDevelopmentSection = (id) =>
    <section className="development-section">
      {this.renderTitle('DEVELOPMENT')}
      <ul>
        {
          this.props.program.program_type === 'CP' &&
          this.renderLi('Modules', `/dashboard-modules/${id}/`, 'fa fa-th-large')
        }
        {this.renderLi(`${this.getPrettyProgramType()} Scoring`, `/dashboard-scoring/${id}/`, 'fa fa-flask')}
        {this.renderLi('Certificates', `/dashboard-certificates/${id}/`, <img src={small_cc_icon_png}/>)}
        {this.renderLi('Sign-Up Questions', `/${id}/signup-questions/`, 'fa fa-question-circle')}
        {this.renderLi(`${this.getPrettyProgramType()} Sales`, `/teacher-dashboard/programs/${id}/sales/`, 'fa fa-dollar')}
        {this.renderLi('Notification Settings', `/dashboard-notifications/${id}/`, 'fa fa-flag')}
      </ul>
    </section>

  renderAdministrationSection = (id) =>
    <section className="administration-section">
      {this.renderTitle('ADMINISTRATION')}
      <ul>
        {this.renderLi(`Activate ${this.getPrettyProgramType()}`, `/dashboard-status/${id}/`,   'fa fa-check')}
        {this.renderLi('Invite Students', `/dashboard-students/${id}/`, 'fa fa-user')}
      </ul>
    </section>

  renderResultsSection = (id) =>
    <section className="results-section">
      {this.renderTitle('RESULTS')}
      <ul>
        {this.renderLi('Demographics', `/dashboard-answers/${id}/`, 'fa fa-pie-chart')}
        {this.renderLi('Results',      `/dashboard-results/${id}/`, 'fa fa-bar-chart-o')}
        {this.renderLi('Export Data',  `/dashboard-export/${id}/`,  'fa fa-file-excel-o')}
      </ul>
    </section>

  render = () =>
    <nav className={`left-nav ${css.nav}`}>
      {this.renderDevelopmentSection(this.props.program.id)}
      {this.renderAdministrationSection(this.props.program.id)}
      {this.renderResultsSection(this.props.program.id)}
    </nav>
}

export default LeftNav;
