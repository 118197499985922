import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import orFalse from '~/services/orFalse';
import css from './index.scss';
import ProgramApi from '~/api/ProgramApi';
import NOIMAGE_PNG from '~/../static/frontend/img/no-image.png';
import StandardTooltip from '~/_partials/StandardTooltip';
import SelectDropdown from '~/_partials/SelectDropdown';
import ConfirmTogglerAndModal from '~/pages/Modals/ConfirmTogglerAndModal';
import NameEditTogglerAndModal from '~/pages/Modals/NameEditTogglerAndModal';

class CourseCard extends React.Component {
  static propTypes = {
    courseIndex: PropTypes.number.isRequired,
    course_id: PropTypes.number.isRequired,
    courses: PropTypes.array.isRequired,
    label: orFalse(PropTypes.string).isRequired,
    href: PropTypes.string,
    className: PropTypes.string,
    image: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    uiUpdateCourses: PropTypes.func.isRequired,
  }

  static defaultProps = {
    label: false,
    className: ''
  }

  state = {
    ifActionsDropdownIsOpen: false,
  }

  apiUpdateCourse = (courseName) =>
    ProgramApi.updateCourse(
      false, {
        program: this.props.course_id,
        name: courseName,
      }
    );

  apiDuplicateCourse = () =>
    ProgramApi.duplicateCourse(
      false, {
        program_id: this.props.course_id,
      }
    );

  apiDeleteCourse = () =>
    ProgramApi.deleteCourse(
      false, {
        program_id: this.props.course_id,
      }
    );

  uiUpdateCourseName = (newCourseName) => {
    if (newCourseName !== this.props.label) {
      const { courseIndex, courses, label } = this.props;

      if (newCourseName.length > 0 && newCourseName !== label) {
        this.apiUpdateCourse(newCourseName)
          .then(() => {
            this.props.uiUpdateCourses([
              ...courses.slice(0, courseIndex),
              {
                ...courses[courseIndex],
                name: newCourseName,
              },
              ...courses.slice(courseIndex + 1),
            ]);
          });
      } else {
        this.setState({ courseName: this.props.label });
      }
    }
  }

  uiDeleteCourse = () => {
    const { courseIndex, courses } = this.props;
    courses.splice(courseIndex, 1);
    this.apiDeleteCourse()
    .then(() => {
      this.props.uiUpdateCourses(courses);
    });
  }

  uiDuplicateCourse = () => {
    const { courseIndex, courses, label } = this.props;
    this.apiDuplicateCourse()
      .then(result => {
        this.props.uiUpdateCourses([
          ...courses,
          {
            ...courses[courseIndex],
            id: result.id,
            name: `${label} Copy`,
            image: result.image,
          },
        ]);
      });
  }

  renderEditToggler = () =>
    <li><a>Edit Program Name</a></li>

  renderDeleteToggler = () =>
    <li><a>Delete</a></li>


  render() {
    const { label, image, href, active } = this.props;
    const { ifActionsDropdownIsOpen } = this.state;
    return <div className={`${this.props.className} ${css.div}`}>
      <a href={href}>
        <img src={image.length > 0 ? image : NOIMAGE_PNG}/>
      </a>
      <div className="text-div">
        <label>
          {label}
          <StandardTooltip
            className="standard-info-tooltip"
            tooltipEl={label}
          />
        </label>
        <div className="course-status">
          <span className={classNames('status', { '-active': active })}>
            {active ? 'ACTIVE' : 'INACTIVE'}

            {
              !active &&
              <StandardTooltip tooltipEl="This program is not yet active for students to use. Feel free to build and edit to your heart’s content. When you’re ready for students to take the program, mark it as Active."/>
            }
          </span>
          <button type="button" className="course-action-toggle" onClick={() => this.setState({ ifActionsDropdownIsOpen: !ifActionsDropdownIsOpen })}>
            <i className="glyphicon glyphicon-cog"/>
          </button>
          <SelectDropdown open={ifActionsDropdownIsOpen} onCloseDropdown={() => this.setState({ ifActionsDropdownIsOpen: false })}>
            <li><a href={href}>Program Dashboard</a></li>
            <NameEditTogglerAndModal
              toggler={this.renderEditToggler()}
              name={label}
              title="Edit Program Name"
              placeholder="Program Name"
              onSave={this.uiUpdateCourseName}
            />
            <ConfirmTogglerAndModal
              title="Are you sure you want to delete this program?"
              toggler={this.renderDeleteToggler()}
              onClose={this.uiDeleteCourse}
            />
            <li onClick={this.uiDuplicateCourse}><a>Duplicate</a></li>
          </SelectDropdown>
        </div>
      </div>
    </div>;
  }
}

export default CourseCard;
