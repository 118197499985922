import Highcharts from 'highcharts';

export const stripHtml = (html) => {
  const temporalDivElement = document.createElement('div');
  temporalDivElement.innerHTML = html;
  return temporalDivElement.textContent || temporalDivElement.innerText || '';
};

export const isAvailableTypeInResult = (type) => {
  return ['multiple-choice', 'multiple-choice-2', 'nps', 'multi-line-text'].includes(type);
}

export const getPercentOfAnswer = (answer_id, answers) => {
  if (!answers.length) return 0;
  return Math.round(answers.filter(answer => answer_id === answer.answer_id).length / answers.length * 100);
}

export const getNumberOfAnswer = (answer_id, answers) => {
  if (!answers.length) return 0;
  return answers.filter(answer => answer_id === answer.answer_id).length;
}

export const getAnswerPayloadById = (answer_id, answers) => {
  const ans = answers.find(answer => answer.answer_id === answer_id);
  return (ans || {}).payload;
}

export const cleanUpText = (text) => {

  //remove numbers
  text=text.replace(/\d/g, '');

  //remove consecutive whitespaces
  text=text.replace(/\s{2,}/g, ' ');

  // make all text lowercase
  text = text.toLowerCase();

  let words = ['of', 'the', 'in', 'on', 'at', 'to', 'a', 'as', 'is', 'and', 'but', 'are', 'be', 'too', 'so', 'not', 'for', 'we', 'that', 'this', 'there', 'have', 'with', 'or', 'were', 'i', 'us', 'can', 'will', 'from', 'by', 'being', 'you', 'am', 'my', 'it', 'an', 'me', 'do', 'if', 'than', 'has' ];
  let re = new RegExp('\\b(' + words.join('|') + ')\\b', 'g');
  return (text || '').replace(re, '').replace(/[ ]{2,}/, ' ');

}

export const getWordCloudChartOption = (content) => {
  let answerText = '';

  content.student_answers.forEach(function(item){
    answerText=answerText + ' ' + item.free_response;
  });
  let filteredComments = cleanUpText( answerText);
  let lines = filteredComments.split(/[,\. ]+/g);
  const wordArray = Highcharts.reduce(lines, function (arr, word) {
      let obj = Highcharts.find(arr, function (obj) {
          return obj.name === word;
      });
      if (obj) {
          obj.weight += 1;
      } else {
          obj = {
              name: word,
              weight: 1
          };
          arr.push(obj);
      }
      return arr;
  }, []);

    //Filter out infrequent words
    let data = wordArray.filter(function (word) {
      return word.weight > 1;
    });

    let makeScale = function (domain, range) {
      let minDomain = domain[0];
      let maxDomain = domain[1];
      let rangeStart = range[0];
      let rangeEnd = range[1];

      return (value) => {
          return rangeStart + (rangeEnd - rangeStart) * ((value - minDomain) / (maxDomain - minDomain));
      }
  };
  /**
   * Find min and max weight using reduce on data array
   */
  let minWeight = data.length > 0 ? data.reduce((min, word) =>
      (word.weight < min ? word.weight : min),
      data[0].weight
  ) : 0;
  let maxWeight = data.length > 0 ? data.reduce((max, word) =>
      (word.weight > max ? word.weight : max),
      data[0].weight
  ) : 0;
  let scale = makeScale([minWeight, maxWeight], [0.3, 1]);
  /**
   * creating a new, scaled data array
   */
  let scaledData = data.map(word =>
      ({ name: word.name, weight: word.weight, color: `rgba(60,170,200,${scale(word.weight)})` })
  );
  return {
    series: [{
      type: 'wordcloud',
      data: scaledData,
      rotation: {
          from: 0,
          to: 0,
      },
      minFontSize: 8,
      style: {
          fontFamily: 'Arial',
      },
      name: 'Occurrences'
  }],
    title: {
        text: ''
    }
  };
}


export const getSurveyChartOption = (content) => {
  const CHART_BAR_COLORS = ['#00bf6f', null, '#f9be00', '#6bc8cd', '#ff8b4e', '#7c5e90', '#d15f90', '#c6b879', '#db4d5b'];
  const seriesData = content.answers.map((answer, index) => {
    return {
      name: answer.payload,
      y: getPercentOfAnswer(answer.answer_id, content.student_answers),
      color: CHART_BAR_COLORS[index],
      numberOfAnswer: getNumberOfAnswer(answer.answer_id, content.student_answers),
    }
  })

  return {
    chart: {
      type: 'bar'
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: seriesData.map(dataItem => dataItem.name),
      title: {
        text: null
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
        align: 'high'
      },
      labels: {
        overflow: 'justify',
        format: '{value}%'
      },
      max: 100,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true
        }
      },
      series: {
        pointWidth: 30,
        dataLabels: {
          enabled: true,
          format: '{point.y}% ({point.numberOfAnswer})'
        }
      }
    },
    tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}%</b> of total<br/>'
    },
    credits: {
        enabled: false
    },
    series: [{
      name: 'Answers',
      data: seriesData
    }]
  }
}

export const getAverageScore = (content) => {
  const sum = content.student_answers.reduce((total, student_answer) => {
    return total + content.answers.map(answer => answer.answer_id).indexOf(student_answer.answer_id) + 1;
  }, 0);
  return (sum / content.student_answers.length).toFixed(2);
}

export const getMedianScore = (content) => {
  const scores = content.student_answers.map(student_answer => content.answers.map(answer => answer.answer_id).indexOf(student_answer.answer_id) + 1);
  scores.sort((a, b) => a - b);
  return scores.length % 2 ? scores[Math.round(scores.length / 2) - 1] : Math.round((scores[scores.length / 2 - 1] + scores[scores.length / 2]) / 2);
}

export const calcNPSScore = (content) => {
  const scores = content.student_answers.map(student_answer => content.answers.map(answer => answer.answer_id).indexOf(student_answer.answer_id));
  let promoters = 0;
  let detractors = 0;
  scores.forEach(score => {
    if (score > 8) {
      promoters++;
    } else if (score <= 6) {
      detractors++;
    }
  })
  return Math.round((promoters - detractors) / scores.length * 100);
}
