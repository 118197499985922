import $ from 'jquery';

const desc = (a, b, orderBy) => {
  if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
    return -1;
  }
  if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
    return 1;
  }
  return 0;
};

const getSorting = (order, orderBy) => {
  if (order === 'desc') {
    return (a, b) => desc(a, b, orderBy);
  } else {
    return (a, b) => -desc(a, b, orderBy);
  }
};

const convertTime = (hrs, mins) => {
  const hours = isNaN(parseInt(hrs, 10)) ? 0 : parseInt(hrs, 10);
  const minutes = isNaN(parseInt(mins, 10)) ? 0 : parseInt(mins, 10);
  return hours * 60 + minutes;
};

const stripHtml = (html) => {
  const temporalDivElement = document.createElement('div');
  temporalDivElement.innerHTML = html;
  return temporalDivElement.textContent || temporalDivElement.innerText || '';
};

const parseHtmlProps = (html, property) =>
  $($.parseHTML(html)).prop(property);

const parseHtmlTagCSS = (html, property) => {
  const propertyStr = $($.parseHTML(html)).css(property);

  if (propertyStr && propertyStr !== '0px') {
    return parseInt(propertyStr, 10);
  } else {
    return 'auto';
  }
};

const hslColorByPercent = (percent, start, end) => {
  var a = percent / 100,
      b = (end - start) * a,
      c = b + start;

  // Return a CSS HSL string
  return 'hsl('+c+', 100%, 50%)';
}

export default {
  desc,
  getSorting,
  convertTime,
  parseHtmlProps,
  parseHtmlTagCSS,
  hslColorByPercent,
  stripHtml,
};
