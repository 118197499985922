import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BrowserRouter, Switch, Route, NavLink as Link, Redirect } from 'react-router-dom';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import InsertChartOutlinedOutlinedIcon from '@material-ui/icons/InsertChartOutlinedOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import _ from 'lodash/core';

import Loading from '~/_partials/Loading';
import StandardAlert from '~/_partials/StandardAlert';
import SpeCreator from '~/services/SpeCreator';
import Constants from '~/services/Constants';
import Urls from '~/services/Urls';
import ProgramApi from '~/api/ProgramApi';
import ModuleApi from '~/api/ModuleApi';
import SwitchInput from '~/_partials/SwitchInput';
import SubHeader from './_partials/SubHeader';
import ModuleTabContent from './_partials/Contents/ModuleTabContent';
import StudentTabContent from './_partials/Contents/StudentTabContent';
import ResultTabContent from './_partials/Contents/ResultTabContent';
import SaleTabContent from './_partials/Contents/SaleTabContent';
import SettingsTabContent from './_partials/Contents/SettingsTabContent';
import ExportTabContent from './_partials/Contents/ExportTabContent';

import css from './index.scss';

class DashboardCourseDetail extends React.Component {
  static propTypes = {
    course_id: PropTypes.number.isRequired,
    course_latest_id: PropTypes.number.isRequired,
    open_invite_hash: PropTypes.string,
    course_status: PropTypes.object.isRequired,
    modules: PropTypes.array.isRequired,
    if_can_sell_courses: PropTypes.bool.isRequired,
    if_new_course: PropTypes.bool.isRequired,
    oauth_stripe_connect_user_id: PropTypes.string,
    send_on_completion: PropTypes.bool.isRequired,
    send_on_pass: PropTypes.bool.isRequired,
  };

  state = {
    spePage: SpeCreator.empty(),
    bodyClass: document.body.className,
    ifShowAlert: false,
  }

  TABS = {
    content: {
      id: 'content',
      name: 'Content',
      icon: <AssignmentOutlinedIcon/>
    },
    settings: {
      id: 'settings',
      name: 'Settings',
      icon: <SettingsOutlinedIcon/>
    },
    certificate: {
      id: 'certificate',
      name: 'Certificate',
      icon: Constants.MODULE_TYPE['certificate'].icon(),
    },
    sales: {
      id: 'sales',
      name: 'Sales',
      icon: <ShoppingCartOutlinedIcon/>
    },
    students: {
      id: 'students',
      name: 'Students',
      icon: <AssignmentIndOutlinedIcon/>
    },
    results: {
      id: 'results',
      name: 'Results',
      icon: <InsertChartOutlinedOutlinedIcon/>
    },
    export: {
      id: 'export',
      name: 'Export',
      icon: <InsertChartOutlinedOutlinedIcon/>
    },
  }

  componentDidMount() {
    this.apiGetPage()
      .then(() => {
        this.uiUpdateModules(this.props.modules);
        this.uiUpdateCourseDetail({
          send_on_completion: this.props.send_on_completion,
          send_on_pass: this.props.send_on_pass,
        });
      });
    document.body.addEventListener('bannerChange', this.uiUpdateBodyClass);
  }

  getNewSpePage = (keyName, payloadUnderKey) => ({
    ...this.state.spePage,
    payload: {
      ...this.state.spePage.payload,
      [keyName]: payloadUnderKey
    }
  })

  apiGetPage = () =>
    ProgramApi.get(
      (spe) => this.setState({ spePage: spe }),
      this.props.course_id,
    )

  apiActivateCourse = () =>
    ProgramApi.activateCourse(
      false,
      {
        program_id: this.props.course_id
      },
    );

  apiCreateCertificateModule = (position) =>
    ModuleApi.create(
      false, {
        program_id: this.props.course_id,
        section_name: 'Certificate',
        section_type: 'Certificate',
        position,
      }
    )

  uiUpdateBodyClass = () => {
    this.setState({ bodyClass: document.body.className });
  }

  updateCourseImage = (imageUrl) => {
    this.setState({
      spePage: this.getNewSpePage('image', imageUrl)
    });
  }

  updateDescription = (description) => {
    this.setState({
      spePage: this.getNewSpePage('description', description)
    });
  }

  uiUpdateModules = (modules) => {
    this.setState({
      spePage: this.getNewSpePage('modules', modules)
    });
  }

  uiUpdateCourseDetail = (courseDetail) =>
    this.setState({
      spePage: {
        ...this.state.spePage,
        payload: {
          ...this.state.spePage.payload,
          ...courseDetail,
        }
      }
    });

  uiUpdateCourseStatus = () => {
    const modules = this.state.spePage.payload.modules ? this.state.spePage.payload.modules : this.props.modules;

    if (modules.length > 0) {
      this.apiActivateCourse().then(() =>
        this.setState({
          spePage: this.getNewSpePage('active', !this.state.spePage.payload.active)
        }));
    } else {
      this.uiUpdateShowAddSectionAlert();
    }
  }

  uiUpdateStudentsInvited = (students_invited) => {
    this.setState({
      spePage: this.getNewSpePage('students_invited', students_invited)
    });
  }

  uiUpdateShowAddSectionAlert = (ifShowAlert = true) => {
    this.setState({ ifShowAlert });
  }

  uiGoToCertificateEditor = () => {
    const { modules } = this.state.spePage.payload;
    const courseModules = modules ? modules : this.props.modules;
    const certificateModule = courseModules.find(module => module.program_type === 'Certificate');

    if (certificateModule) {
      window.location = Urls.getCourseEditorUrl({
        courseId: this.props.course_latest_id,
        moduleId: certificateModule.id,
        itemType: 'certificate',
      });
    } else {
      this.apiCreateCertificateModule(courseModules.length)
        .then(data => {
          window.location = Urls.getCourseEditorUrl({
            courseId: this.props.course_latest_id,
            moduleId: data.id,
            itemType: 'certificate',
          });
        });
    }
  }

  renderTabLink = (tab) => {
    const path = Urls.getCourseUrl(this.props.course_id);

    if (tab.id !== 'certificate') {
      return (
        <Link
          key={tab.id}
          to={`${path}/${tab.id}`}
          className={classNames('standard-tab tab-link', {
            '-result': tab.id === 'results',
            '-export': tab.id === 'export',
          })}
          activeClassName="standard-tab-selected"
        >
          {tab.icon}
          <span className="link-name">{tab.name}</span>
        </Link>
      );
    } else {
      return (
        <a key={tab.id} className="standard-tab tab-link" onClick={this.uiGoToCertificateEditor}>
          {tab.icon}
          <span className="link-name">{tab.name}</span>
        </a>
      );
    }
  }

  renderTabContent = (tab, path) => {
    const {
      if_can_sell_courses, oauth_stripe_connect_user_id, if_new_course,
    } = this.props;
    const {
      id, scored, modules, program_type, active,
      if_charge_then_program_title, if_charge_then_program_price,
      if_charge_then_program_description, if_charge_students_for_program,
      total_score_to_pass, user_must_pass_all_modules, max_num_retakes,
      randomize_answers, can_review, allow_viewing_corrected_test,
      minimum_time_required, time_limit,
      start_date, end_date, score_release, take_sequentially,
      randomize, if_student_can_view_module_content_on_retake,
      send_on_completion, send_on_pass
    } = this.state.spePage.payload;
    let tabContent = null;
    let courseModules = modules;
    let course_send_on_completion = send_on_completion;
    let course_send_on_pass = send_on_pass;

    if (!courseModules) {
      courseModules = this.props.modules;
    }

    if (course_send_on_completion === undefined) {
      course_send_on_completion = this.props.send_on_completion;
      course_send_on_pass = this.props.send_on_pass;
    }

    switch (tab.id) {
      case this.TABS.content.id: // Module Tab Content
        tabContent = <ModuleTabContent
          course_id={this.props.course_id}
          course_latest_id={this.props.course_latest_id}
          if_new_course={if_new_course}
          modules={courseModules}
          uiUpdateModules={this.uiUpdateModules}
        />;
        break;
      case this.TABS.students.id: // Students Tab Content
        tabContent = <StudentTabContent
          course_id={id}
          course_active={active}
          open_invite_hash={this.props.open_invite_hash}
          uiUpdateStudentsInvited={this.uiUpdateStudentsInvited}
        />;
        break;
      case this.TABS.results.id: // Results Tab Content
        tabContent = <ResultTabContent
          course_id={id}
          course_latest_id={this.props.course_latest_id}
          course_status={this.props.course_status}
          root_path={path}
          modules={courseModules.filter(module => ['Survey', 'Data'].includes(module.program_type))}
        />;
        break;
      case this.TABS.sales.id: // Sales Tab Content
        tabContent = <SaleTabContent
          program_id={id}
          program_type={program_type}
          if_charge_then_program_title={if_charge_then_program_title}
          if_charge_then_program_price={if_charge_then_program_price}
          if_charge_then_program_description={if_charge_then_program_description}
          if_charge_students_for_program={if_charge_students_for_program}
          if_can_sell_courses={if_can_sell_courses}
          oauth_stripe_connect_user_id={oauth_stripe_connect_user_id}
        />;
        break;
      case this.TABS.settings.id: // Settings Tab Content
        tabContent = <SettingsTabContent
          course_id={id}
          scored={scored}
          modules={courseModules}
          total_score_to_pass={total_score_to_pass}
          user_must_pass_all_modules={user_must_pass_all_modules}
          max_num_retakes={max_num_retakes}
          randomize_answers={randomize_answers}
          can_review={can_review}
          allow_viewing_corrected_test={allow_viewing_corrected_test}
          minimum_time_required={minimum_time_required}
          start_date={start_date}
          end_date={end_date}
          score_release={score_release}
          time_limit={time_limit}
          take_sequentially={take_sequentially}
          randomize={randomize}
          if_student_can_view_module_content_on_retake={if_student_can_view_module_content_on_retake}
          send_on_completion={course_send_on_completion}
          send_on_pass={course_send_on_pass}
          uiUpdateModules={this.uiUpdateModules}
          uiUpdateCourseDetail={this.uiUpdateCourseDetail}
        />;
        break;
      case this.TABS.export.id: // Export Tab Content
        tabContent = <ExportTabContent program_id={id}/>;
        break;
    }

    return tabContent;
  }

  render = () => {
    const { course_id, course_latest_id, course_status, modules } = this.props;
    const { spePage } = this.state;
    const courseModules = (spePage.payload && spePage.payload.modules) ? spePage.payload.modules : modules;
    const path = Urls.getCourseUrl(course_id);
    const certificateSection = courseModules.find(module => module.program_type === 'Certificate');
    return (
      <Loading spe={spePage} className="standard-page-loading">{({
        id, name, image, description, score_setup, active
      }) =>
        <main
          className={classNames(css.main, 'standard-dashboard-content', {
            '-with-banner': this.state.bodyClass.includes('-with-banner'),
            '-full-banner': this.state.bodyClass.includes('-full-banner'),
          })}
        >
          <BrowserRouter>
            <SubHeader
              course_id={id}
              modules_count={courseModules.length}
              course_latest_id={course_latest_id}
              modules={courseModules}
              name={name}
              imageUrl={image}
              description={description}
              score_setup={score_setup}
              active={spePage.payload.active}
              course_status={course_status}
              students_invited={spePage.payload.students_invited ? true : false}
              certificate_id={certificateSection ? certificateSection.id : 0}
              updateCourseImage={this.updateCourseImage}
              updateDescription={this.updateDescription}
              uiUpdateCourseStatus={this.uiUpdateCourseStatus}
              uiUpdateShowAddSectionAlert={this.uiUpdateShowAddSectionAlert}
            />
            <div className="contents">
              <div className="tab-group">
                <div className="standard-tab-list tabs">
                  {_.values(this.TABS).map(tab =>
                    this.renderTabLink(tab)
                  )}
                </div>
                <SwitchInput
                  value={active}
                  labelRight={active ? 'Status: Opened' : 'Status: Closed'}
                  className="-green-small switch-input"
                  updateValue={this.uiUpdateCourseStatus}
                />
              </div>
              <Switch>
                {_.keys(this.TABS).map((tabKey) =>
                  <Route
                    key={tabKey}
                    path={`${path}/${tabKey}`}
                    render={() =>
                      <div
                        className={classNames('tab-content', {
                          '-full-height': window.location.pathname.includes('/content'),
                          '-settings': window.location.pathname.includes('/settings'),
                          '-scroll': window.location.pathname.includes('/export') || window.location.pathname.includes('/students'),
                          '-results': window.location.pathname.includes('/results'),
                        })}
                      >
                        {this.renderTabContent(this.TABS[tabKey], `${path}/${tabKey}`)}
                      </div>
                    }
                  />
                )}
                <Redirect to={`${path}/${this.TABS.content.id}`}/>
              </Switch>
            </div>
          </BrowserRouter>

          <StandardAlert
            open={this.state.ifShowAlert}
            type={Constants.ALERT_TYPE.warning}
            message="Please add sections to this program before activating."
            onClose={() => this.uiUpdateShowAddSectionAlert(false)}
          />
        </main>
      }</Loading>
    );
  }
}

export default DashboardCourseDetail;
