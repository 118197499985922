import React from 'react';
import PropTypes from 'prop-types';

import css from './index.scss';

class TimeInput extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    hourName: PropTypes.string.isRequired,
    minuteName: PropTypes.string.isRequired,
    formState: PropTypes.object.isRequired,
    updateFormState: PropTypes.func.isRequired,
  }

  handleUpdateTime = (e, name) => {
    if (e.target.value.length > 2) {
      return;
    }

    const { hourName, formState } = this.props;
    const value = isNaN(parseInt(e.target.value, 10)) ? 0 : parseInt(e.target.value, 10);

    formState[name] = value;
    if (value < 0) {
      formState[name] = 0;
    }

    if (name === hourName) {
      if (value > 23) {
        formState[name] = 23;
      }
    } else {
      formState[name] = value;
      if (value > 59) {
        formState[name] = 59;
      }
    }

    this.props.updateFormState(formState);
  }

  render() {
    const { label, className, hourName, minuteName, formState } = this.props;

    return (
      <div className={`${css.div} ${className}`}>
        <label>{label}</label>
        <div className="time-spinner">
          <input
            className="input-hour"
            type="number"
            name={hourName}
            placeholder="hh"
            min="0"
            max="23"
            value={formState[hourName]}
            onChange={e => this.handleUpdateTime(e, hourName)}
          />
          <input
            className="input-minute"
            type="number"
            name={minuteName}
            placeholder="mm"
            min="0"
            max="59"
            value={formState[minuteName]}
            onChange={e => this.handleUpdateTime(e, minuteName)}
          />
        </div>
      </div>
    );
  }
}

export default TimeInput;
