import React from 'react';
import PropTypes from 'prop-types';

import SpeCreator from '~/services/SpeCreator';
import ourFetch from '~/api/services/ourFetch';

import FormState from './services/FormState';
import getFittedText from './services/getFittedText';
import getPreviewCertificateUrl from './services/getPreviewCertificateUrl';
import getFullPreviewCertificateUrl from './services/getFullPreviewCertificateUrl';

import { Checkbox, TextInput, Textarea, ImageInput, Select } from '~/_partials/standardForm';
import Loading from '~/_partials/Loading';
import StandardTooltip from '~/_partials/StandardTooltip';
import ProgramDashboardWrapper from '~/_appPartials/ProgramDashboardWrapper';

import css from './index.scss';

class dashboardCertificates_id extends React.Component {
  static propTypes = {
    programId: PropTypes.string.isRequired
  }

  state = {
    spePage: SpeCreator.empty(),
    speUpdate: SpeCreator.empty(),
    formState: FormState.initialState,
    ifTooMuchText: false
  }

  componentDidMount = () => {
    this.apiGetPage()
      .then((response) =>
        this.setState({
          formState: FormState.fromApiToState(response)
        })
      );
  }

  apiGetPage = () =>
    ourFetch(
      (spe) => this.setState({ spePage: spe }),
      'GET', `/api/pages/dashboardCertificates_id/${this.props.programId}/get/`
    )

  apiUpdatePage = () =>
    ourFetch(
      (spe) => this.setState({ speUpdate: spe }),
      'PUT', `/api/pages/dashboardCertificates_id/${this.props.programId}/update/`,
      FormState.fromStateToApi(this.state.formState)
    )

  onTextareaChange = (formState) => {
    this.setState({ formState });
    const result = formState.custom_certificate_text;
    //this.setState({ ifTooMuchText: result.ifTooMuchText });
  }

  updateFormState = (formState) => {
    this.setState({
      formState,
      speUpdate: SpeCreator.empty()
    });
  }

  renderPreviewLink = () =>
    <a
      className="standard-button -theme-bg submit-button preview-link"
      target="_blank"
      rel="noreferrer noopener"
      href={getFullPreviewCertificateUrl(this.props.programId, this.state.formState)}
    >View Full Size Preview</a>

  renderPreviewIframe = () =>
    <iframe
      className="preview-iframe"
      src={getPreviewCertificateUrl(this.props.programId, this.state.formState)}
    />

  renderCertificateTextTextarea = (formProps) =>
    <React.Fragment>
      <Textarea
        label="Add custom text to your certificate"
        name="custom_certificate_text"
        {...formProps}
        updateFormState={this.onTextareaChange}
        // ___Why not simple "" instead of {''}?
        //    \n only work within {}.
        placeholder={'Instructor: \nApproval No.: \nNo. of CE Credits: '}
        ifComplexPlaceholder
      />
      {
        this.state.ifTooMuchText &&
        <div className="too-much-text-error">Too much text. It will get cut off. Please try to use fewer new lines or words.</div>
      }
    </React.Fragment>

  renderSubmitButton = () =>
    <button
      className={`standard-button -theme-bg submit-button ${this.state.ifTooMuchText ? '-disabled' : ''}`}
      type="submit"
      onClick={this.apiUpdatePage}
    >SAVE</button>

  render = () => {
    const props = {
      formState: this.state.formState,
      updateFormState: this.updateFormState
    };
    return <ProgramDashboardWrapper programId={parseInt(this.props.programId, 10)}>
      <div className={`standard-program-dashboard-form-wrapper ${css.div}`}>
        <h1 className="form-heading">
          <div className="left-heading">
            <span>Certificates</span>
          </div>
          <div className="right-heading">
            <span>Certificate Preview</span>
            {
              this.state.formState.send_cert &&
              this.renderPreviewLink()
            }
          </div>
          <div className="float-clear"/>

        </h1>
        <div className="form-explanation">
          Customize certificates with your company or institution's logo.
        </div>

        <form className="form standard-form" onSubmit={(e) => e.preventDefault()}>
          <Checkbox label="Send certificate of completion?" name="send_cert" {...props}/>
          {
            this.state.formState.send_cert &&
            <React.Fragment>
              {this.renderCertificateTextTextarea(props)}
              <ImageInput label="Company photo" name="company_photo" {...props}/>
              <TextInput  label="Company name" name="company_name" {...props}/>
              <div className="input-container">
                <Select
                  label="Custom Field 1"
                  name="custom_field_1"
                  {...props}
                />
                <StandardTooltip
                  className="custom-field-tooltip"
                  tooltipEl="To enable custom fields, go to the Sign-Up Questions screen and add a field."
                />
              </div>
              <div className="input-container -custom-two">
                <Select
                  label="Custom Field 2"
                  name="custom_field_2"
                  {...props}
                />
                <StandardTooltip
                  className="custom-field-tooltip"
                  position="bottom"
                  tooltipEl="To enable custom fields, go to the Sign-Up Questions screen and add a field."
                />
              </div>

            </React.Fragment>
          }

          {this.renderSubmitButton()}

          <Loading spe={this.state.speUpdate}>
            <div className="standard-alert -success">Certificates are successfully set up!</div>
          </Loading>
        </form>
        <div className="iframe-container">
          {
            this.renderPreviewIframe()
          }
          <p className="certificate-description">
          Need a custom certificate? Our support team would be happy to assist.<br/>
            <a href="mailto:support@certcentral.com">Click here</a> to contact us and discuss your custom needs.
          </p>
        </div>
        <div className="float-clear"/>
      </div>
    </ProgramDashboardWrapper>;
  }
}

export default dashboardCertificates_id;
