import React from 'react';
import PropTypes from 'prop-types';
// import orFalse from '~/services/orFalse';

import { Editor } from '@tinymce/tinymce-react';

import css from './index.scss';

class StandardEditor extends React.Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    updateValue: PropTypes.func.isRequired,

    tinymceToolbar: PropTypes.string,

    // requires placeholder plugin
    // placeholder: PropTypes.string

    // className: PropTypes.string

    // on readOnly links won't be clickable though.
    // readOnly: PropTypes.boolean
  }

  static defaultProps = {
    tinymceToolbar: 'undo redo | styleselect | bold italic underline | alignleft aligncenter alignright | bullist numlist | outdent indent | link image | forecolor backcolor | codesample'
  }

  render = () =>
    <div className={css.div}>
      <Editor
        value={this.props.value}
        onEditorChange={this.props.updateValue}
        init={{
          plugins: 'link image lists textcolor colorpicker codesample autoresize',
          toolbar: this.props.tinymceToolbar,
          menu: 'none',
          statusbar: false,
          target_list: false,
          default_link_target: '_blank',
        }}
      />
    </div>
}

export default StandardEditor;
