import oldApiFetch from './services/oldApiFetch';
import ourFetch from './services/ourFetch';

const get = (dispatch, programId) =>
  ourFetch(dispatch,
    'GET', `/api/programs/${programId}/`
  );


const createNewVersion = (dispatch, programId) =>
  oldApiFetch(dispatch,
    'POST', '/api/program/create-version/',
    { program_id: programId }
  );


const addCourse = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/cert-progam/create/',
    params
  );


const updateCourse = (dispatch, params) =>
  oldApiFetch(dispatch,
      'POST',
      '/api/program/edit/',
      params,
    );

const duplicateCourse = (dispatch, params) =>
  oldApiFetch(dispatch,
      'POST',
      '/api/program/duplicate/',
      params,
    );

const deleteCourse = (dispatch, params) =>
  oldApiFetch(dispatch,
      'POST',
      '/api/program/delete/',
      params,
    );

const getTodo = (dispatch, params) =>
  oldApiFetch(dispatch,
      'POST',
      '/api/course/todo/get/',
      params,
    );

const checkTodo = (dispatch, params) =>
  oldApiFetch(dispatch,
      'POST',
      '/api/course/todo/check/',
      params,
    );

const updateImage = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/course/image/add/',
    params,
  );

const updateDescriptionOrModuleName = (dispatch, course_id, params) =>
  oldApiFetch(dispatch,
    'PUT',
    `/api/usertocs/${course_id}/update/`,
    params,
  );

const activateCourse = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/program/active/',
    params,
  );

const getResults =  (dispatch, course_id) =>
  ourFetch(dispatch,
    'GET', `/api/program/results/get/${course_id}/`
  );

const getSurveyResults = (dispatch, section_id) =>
  ourFetch(dispatch,
    'GET', `/api/program/results/section/get/${section_id}/`
  );

const updateSales = (dispatch, program_id, params) =>
  ourFetch(dispatch,
    'POST',
    `/api/teacher-dashboard/programs/${program_id}/sales/update/`,
    params,
  );

const updateNotification = (dispatch, course_id, params) =>
  oldApiFetch(dispatch,
    'POST',
    `/api/programs/${course_id}/update-notification/`,
    params,
  );

const updateSettings = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/program-scoring/program/save/',
    params,
  );

const addStudents = (dispatch, params) =>
  ourFetch(dispatch,
    'POST',
    '/api/program/add/students/',
    params,
  );

const getSignupFields = (dispatch, program_id) =>
  ourFetch(dispatch,
      'GET', `/api/program/signupfields/get/${program_id}`,
    );

const getHelpArticle = (dispatch, article_id) =>
  ourFetch(dispatch,
      'GET', `/api/help/get/${article_id}`,
    );

const respondHelpArticle = (dispatch, params) =>
  oldApiFetch(dispatch,
      'POST',
      '/api/help/respond/',
      params,
    );

const getFreeResponseQuestions = (dispatch, params) =>
  oldApiFetch(dispatch,
      'POST',
      '/api/course/free-response/get/',
      params,
    );

const updateFreeResponseScore = (dispatch, params) =>
  oldApiFetch(dispatch,
      'POST',
      '/api/course/free-response/score/',
      params,
    );

export default {
  get,
  addCourse,
  updateCourse,
  duplicateCourse,
  deleteCourse,
  getTodo,
  checkTodo,
  updateImage,
  updateDescriptionOrModuleName,
  activateCourse,
  getResults,
  getSurveyResults,
  updateSales,
  updateNotification,
  updateSettings,
  addStudents,
  getHelpArticle,
  respondHelpArticle,
  getSignupFields,
  getFreeResponseQuestions,
  updateFreeResponseScore,
  createNewVersion,
};
