// spe stands for { Status, Payload, Error }.

// if this.props.children doesn't depend on resulting payload, use as:
// <Loading spe={apiGetProfile}>
//   <section>
//     <h3>yay! loaded!</h3>
//   </section>
// </Loading>

// if this.props.children depends on resulting payload - use:
// <Loading spe={apiGetProfile}>{(payload) => {
//   <h3>{profile.firstName}</h3>
// }</Loading>
import React from 'react';
import PropTypes from 'prop-types';

import css from './index.scss';

class Loading extends React.Component {
  static propTypes = {
    spe: PropTypes.object.isRequired,
    children: PropTypes.any, // can be null, or false, or element

    enabledStatuses: PropTypes.array,
    className: PropTypes.string
  }

  static defaultProps = {
    children: null,
    className: '',
    enabledStatuses: ['request', 'success', 'failure']
  }

  getClassName = (status) =>
    // returning both request and -request (first is deprecated)
    `${this.props.className} ${css.Loading} -${status}`

  renderRequest = () =>
    <div className={this.getClassName('request')}>
      <i className="fa fa-circle-o-notch fa-spin fa-2x fa-fw"/>
    </div>

  renderSuccess = () => (
    typeof this.props.children === 'function' ?
      this.props.children(this.props.spe.payload) :
      this.props.children
  )

  // okay,
  // Failed to execute 'removeChild' on 'Node': parameter 1 is not of type 'Node'.
  // issue with a loading icon & red background is unsolvable, we should deal with it at some other place I'd guess
  renderFailure = () =>
    <div className={this.getClassName('error')}>
      <div className="error-string">{this.props.spe.error.payload}</div>
    </div>

  renderValidation = () =>
    <div className={this.getClassName('validation')}>
      <ul>
        {this.props.spe.error.payload.map((error, index) =>
          <li key={index}>{error}</li>
        )}
      </ul>
    </div>

  render = () => {
    const status = this.props.spe.status;
    if (!this.props.enabledStatuses.includes(status)) return null;

    switch (status) {
      case 'request': return this.renderRequest();
      case 'success': return this.renderSuccess();
      case 'failure': return this.props.spe.error._ifValidation ? this.renderValidation() : this.renderFailure();
      default: throw new Error(`props.spe.status can't be '${status}'`);
    }
  }
}

export default Loading;
