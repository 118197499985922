import React from 'react';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import SpellcheckOutlinedIcon from '@material-ui/icons/SpellcheckOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import CertificateIcon from '@material-ui/icons/BookOutlined';

import MEDIA_PIC1 from '../../static/frontend/img/temp/media-pic.jpeg';
import MEDIA_PIC2 from '../../static/frontend/img/temp/media-pic2.jpeg';
import MEDIA_PIC3 from '../../static/frontend/img/temp/media-pic3.jpeg';
import MEDIA_PIC4 from '../../static/frontend/img/temp/media-pic4.jpeg';
import MEDIA_PIC5 from '../../static/frontend/img/temp/media-pic5.jpeg';
import MEDIA_PIC6 from '../../static/frontend/img/temp/media-pic6.jpeg';
import MEDIA_PIC7 from '../../static/frontend/img/temp/media-pic7.jpeg';
import BACKGROUND_DEFAULT_TEMPLATE  from '~/../static/pdf/CC-Certificate.png';
import BACKGROUND_TEMPLATE_BLUE     from '~/../static/pdf/CC-Certificate-Blue.png';
import BACKGROUND_TEMPLATE_ORANGE   from '~/../static/pdf/CC-Certificate-Orange.png';
import BACKGROUND_TEMPLATE_RED      from '~/../static/pdf/CC-Certificate-Red.png';

const ROOT_PATH = window.location.protocol + '//' + window.location.host;

const MODULE_TYPE = {
  module: {
    id: 'module',
    type: 'Module',
    name: 'Content',
    label: 'Content Module',
    icon: (style) => <SchoolOutlinedIcon style={style}/>,
    visible: true,
  },
  exam: {
    id: 'exam',
    type: 'Test',
    name: 'Test',
    label: 'Test',
    icon: (style) => <SpellcheckOutlinedIcon style={style}/>,
    visible: true,
  },
  data: {
    id: 'data',
    type: 'Data',
    name: 'Data',
    label: 'Collect Data',
    icon: (style) => <DashboardOutlinedIcon style={style}/>,
    visible: true,
  },
  alert: {
    id: 'alert',
    type: 'Alert',
    name: 'Alert',
    label: 'Alert / Message',
    icon: (style) => <NotificationsNoneOutlinedIcon style={style}/>,
    visible: false,
  },
  survey: {
    id: 'survey',
    type: 'Survey',
    name: 'Survey',
    label: 'Survey',
    icon: (style) => <SmsOutlinedIcon style={style}/>,
    visible: true,
  },
  certificate: {
    id: 'certificate',
    type: 'Certificate',
    name: 'Certificate',
    label: 'Certificate of Completion',
    icon: (style) => <CertificateIcon style={style}/>,
    visible: true,
  },
};

const LIBRARY_DATA = {
  medias: [
    {
      id: 'media-1',
      name: 'media1',
      image: MEDIA_PIC1,
      type: 'image',
    }, {
      id: 'media-2',
      name: 'media2',
      image: MEDIA_PIC2,
      type: 'video',
    }, {
      id: 'media-3',
      name: 'media3',
      image: MEDIA_PIC3,
      type: 'course',
    }, {
      id: 'media-4',
      name: 'media4',
      image: MEDIA_PIC4,
      type: 'image',
    }, {
      id: 'media-5',
      name: 'media5',
      image: MEDIA_PIC5,
      type: 'video',
    }, {
      id: 'media-6',
      name: 'media6',
      image: MEDIA_PIC6,
      type: 'course',
    }, {
      id: 'media-7',
      name: 'python',
      image: MEDIA_PIC7,
      type: 'image',
    }
  ],
  contents:
  [
    'Intro to Python', 'Front - end Development', 'Back - end 101',
    'Python Class Libraries', 'Python String Handling', 'Python vs Django'
  ],
};

const ALERT_TYPE = {
  success: 'success',
  warning: 'warning',
};

const CERTIFICATE_DEFAULT_BACKGROUNDS = {
  green: {
    title: 'green',
    image: ROOT_PATH + BACKGROUND_DEFAULT_TEMPLATE,
  },
  blue: {
    title: 'blue',
    image: ROOT_PATH + BACKGROUND_TEMPLATE_BLUE
  },
  orange: {
    title: 'orange',
    image: ROOT_PATH + BACKGROUND_TEMPLATE_ORANGE
  },
  red: {
    title: 'red',
    image: ROOT_PATH + BACKGROUND_TEMPLATE_RED
  },
};

const FIELD_TYPE = {
  program: 'program-name',
  organization: 'organization-name',
  completion_date: 'completion-date',
  student_name: 'student-name',
  certificate_id: 'certificate-id',
};

const DEFAULT_BACKGROUND_TITLE = 'green';
const ALERT_DISPLAY_TIME = 4000; // milliseconds
const IMAGE_MAX_SIZE = 2 * 1024 * 1024; // 2MB
const MAX_TITLE_LENGTH = 120; // maximum length of section title
const MAX_NAME_LENGTH = 60; // maximum length of name input
const MAX_COURSE_PRICE_INPUTLENGTH = 6; // maximum length of input for coruse prise

export default {
  MODULE_TYPE,
  LIBRARY_DATA,
  ALERT_TYPE,
  ALERT_DISPLAY_TIME,
  IMAGE_MAX_SIZE,
  MAX_TITLE_LENGTH,
  MAX_NAME_LENGTH,
  FIELD_TYPE,
  CERTIFICATE_DEFAULT_BACKGROUNDS,
  DEFAULT_BACKGROUND_TITLE,
  MAX_COURSE_PRICE_INPUTLENGTH,
};
