import React from 'react';
import PropTypes from 'prop-types';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import SpellcheckOutlinedIcon from '@material-ui/icons/SpellcheckOutlined';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import _ from 'lodash/core';

import ProgramApi from '~/api/ProgramApi';
import Urls from '~/services/Urls';
import TogglerAndModal from '~/_partials/TogglerAndModal';

import css from './index.scss';

const COURSE_TYPE = {
  course: {
    id: 'course',
    name: 'Course',
    icon: (style) => <SchoolOutlinedIcon style={style}/>
  },
  exam: {
    id: 'exam',
    name: 'Exam',
    icon: (style) => <SpellcheckOutlinedIcon style={style}/>
  },
  survey: {
    id: 'survey',
    name: 'Survey',
    icon: (style) => <SmsOutlinedIcon style={style}/>
  },
};

class AddCourseTogglerAndModal extends React.Component {
  constructor(props) {
    super(props);
    this.modalWrapperRef = React.createRef();
  }

  static propTypes = {
    toggler: PropTypes.element.isRequired,
  };

  state = {
    courseType: COURSE_TYPE.course.name,
    courseName: '',
  }

  clearState = () => {
    this.setState({
      courseType: COURSE_TYPE.course.name,
      courseName: '',
    });
  }

  handleCancelClick = (closeModal) => {
    this.clearState();
    closeModal();
  }

  handleSaveClick = (closeModal) => {
    const { courseName } = this.state;
    ProgramApi.addCourse(
      false, {
        program_name: courseName,
      }
    ).then(data => {
      closeModal();
      window.location.href = Urls.getCourseUrl(data.id);
    });
  }

  handleItemClick = (courseType) =>
    this.setState({ courseType })

  onClickOnModalWrapper = (event, closeModal) => {
    const elClickedOn = event.target;
    const modalWrapper = this.modalWrapperRef.current;

    if (elClickedOn === modalWrapper) {
      this.clearState();
      closeModal();
    }
  }

  renderFooter = (closeModal) => {
    const { courseName } = this.state;
    const isSaveDisabled = courseName.length === 0;

    return (<div className="standard-modal-footer -with-buttons">
      <button
        className="standard-button -sliding -red"
        type="reset"
        onClick={() => this.handleCancelClick(closeModal)}
      >Cancel</button>
      <button
        className="standard-button -sliding -blue"
        type="submit"
        disabled={isSaveDisabled}
        onClick={() => this.handleSaveClick(closeModal)}
      >Add</button>
    </div>);
  }

  render = () =>
    <TogglerAndModal
      toggler={this.props.toggler}
    >{(closeModal, renderCloseButton) =>
      <div
        className={`standard-modal-wrapper ${css.wrapper}`}
        onClick={e => this.onClickOnModalWrapper(e, closeModal)} ref={this.modalWrapperRef}
      >
        <div className="standard-modal -with-white-header">
          {renderCloseButton()}

          <div className="standard-modal-header">Add Program</div>
          <div className="standard-modal-main">
            <input placeholder="Program Name" maxLength={120} onChange={e => this.setState({ courseName: e.target.value })}/>
          </div>

          {this.renderFooter(closeModal)}
        </div>
      </div>
    }</TogglerAndModal>
}

export default AddCourseTogglerAndModal;
