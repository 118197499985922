import React from 'react';
import PropTypes from 'prop-types';

import css from './index.scss';

import CourseEdit from './_partials/CourseEdit';
import StepSetting from './_partials/StepSetting';
import ChartView from '../ChartView';

class SubHeader extends React.Component {
  static propTypes = {
    course_id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    score_setup: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    students_invited: PropTypes.bool.isRequired,
    course_status: PropTypes.object.isRequired,
    updateCourseImage: PropTypes.func.isRequired,
    updateDescription: PropTypes.func.isRequired,
    uiUpdateCourseStatus: PropTypes.func.isRequired,
  }

  render = () => {
    const { course_id, name, imageUrl, description, score_setup, active, students_invited, course_status } = this.props;
    return (
      <section className={`subheader ${css.section}`}>
        <CourseEdit
          course_id={course_id}
          name={name}
          imageUrl={imageUrl}
          description={description}
          updateCourseImage={this.props.updateCourseImage}
          updateDescription={this.props.updateDescription}
        />
        <StepSetting
          course_id={course_id}
          score_setup={score_setup}
          active={active}
          students_invited={students_invited}
          uiUpdateCourseStatus={this.props.uiUpdateCourseStatus}
          {...this.props}
        />
        <ChartView course_status={course_status}/>
      </section>
    );
  }
}

export default SubHeader;
