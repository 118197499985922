import ourFetch from './services/ourFetch';
import hashToQueryString from './services/hashToQueryString';

const getIfUserShouldSee = (dispatch, tutorialName) =>
  ourFetch(
    dispatch,
    'GET', `/api/tutorials/should-user-see/?${hashToQueryString({ tutorial_name: tutorialName })}`
  );

const markAsSeen = (dispatch, tutorialName) =>
  ourFetch(
    dispatch,
    'PUT', '/api/tutorials/mark-as-seen/',
    { tutorial_name: tutorialName }
  );

export default {
  getIfUserShouldSee,
  markAsSeen
};
