import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import LockIcon from '@material-ui/icons/LockOutlined';
import { Draggable } from 'react-beautiful-dnd';

import ModuleApi from '~/api/ModuleApi';
import Urls from '~/services/Urls';
import UserTocApi from '~/api/UserTocApi';
import SelectDropdown from '~/_partials/SelectDropdown';
import Constants from '~/services/Constants';
import ConfirmTogglerAndModal from '~/pages/Modals/ConfirmTogglerAndModal';

import css from './index.scss';

class ModuleItem extends React.Component {
  static propTypes = {
    course_id: PropTypes.number.isRequired,
    className: PropTypes.string,
    module: PropTypes.object.isRequired,
    isNew: PropTypes.bool,
    index: PropTypes.number.isRequired,
    onCancel: PropTypes.func,
    onCopy: PropTypes.func,
    onUpdate: PropTypes.func,
    onAdd: PropTypes.func,
    onDelete: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    module: {},
    isNew: false,
  };

  state = {
    nameText: '',
    ifEditing: false,
    ifNewItem: this.props.isNew,
    ifActionsDropdownIsOpen: false,
  };

  componentDidMount() {
    if (this.state.ifNewItem) {
      this.nameInputRef.focus();
    }
  }

  apiUpdateModuleName = (newName) =>
    UserTocApi.update(
      false,
      this.props.module.id, {
        name: newName
      },
    )

  apiDuplicateModule = () =>
    ModuleApi.duplicate(
      false, {
        module_id: this.props.module.id,
      }
    )

  apiDeleteModule = () =>
    ModuleApi.destory(
      false, {
        program_id: this.props.course_id,
        module_id: this.props.module.id,
      }
    )

  uiUpdateModuleName = (newName) =>
    this.apiUpdateModuleName(newName)
      .then((updatedModule) => {
        this.props.onUpdate(updatedModule);
      });

  uiDuplicateModule = () => {
    this.apiDuplicateModule()
      .then(({ id }) => {
        this.props.onCopy(
          this.props.module,
          {
            ...this.props.module,
            id,
            name: this.props.module.name + ' Copy',
          }
        );
      });
  }

  uiDeleteModule = () =>
    this.apiDeleteModule()
    .then(() => {
      this.props.onDelete(this.props.module.id);
    });

  handleModuleItemClick = (e) => {
    const { course_id, module } = this.props;

    if (module.id > 0 &&
      e.target !== this.actionButton &&
      e.target !== this.caretButton &&
      !this.state.ifActionsDropdownIsOpen &&
      !this.state.ifNewItem && !this.state.ifEditing) {
      window.location.href = Urls.getCourseEditorUrl({
        courseId: course_id,
        moduleId: module.id,
        itemType: Constants.MODULE_TYPE[module.program_type.toLowerCase()].type.toLowerCase(),
      });
    }
  }

  handleNameEditClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      ifEditing: true,
      nameText: this.props.module.name,
      ifActionsDropdownIsOpen: false,
    });
    setTimeout(() => {
      this.nameInputRef.focus();
    });
  }

  handleNameInputBlur = () => {
    const { nameText, ifEditing, ifNewItem } = this.state;

    if (nameText && nameText.trim().length > 0) {
      if (ifNewItem) {
        this.setState({ ifNewItem: false });
        this.props.onAdd({
          ...this.props.module,
          name: nameText,
        });
      } else if (ifEditing) {
        if (nameText !== this.props.module.name) {
          this.uiUpdateModuleName(nameText);
        }

        setTimeout(() => {
          this.setState({ ifEditing: false });
        }, 100);
      }
    } else {
      if (ifNewItem || ifEditing) {
        if (ifEditing) {
          setTimeout(() =>
            this.setState({
              nameText: this.props.module.name,
              ifEditing: false
            }), 100);
        } else {
          setTimeout(() => {
            this.nameInputRef.focus();
          });
        }
      }
    }
  }

  handleKeyDownOnName = (event) => {
    if (event.keyCode === 13) {
      this.handleNameInputBlur();
    }
  }

  handleEditCancelClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.ifNewItem) {
      this.props.onCancel();
    } else {
      this.setState({ nameText: this.props.module.name });
      setTimeout(() => {
        this.setState({
          ifEditing: false
        });
      }, 100);
    }
  }

  uiUpdateDropdownToggle = () => {
    this.setState({ ifActionsDropdownIsOpen: !this.state.ifActionsDropdownIsOpen });
  }

  renderDeleteToggler = () =>
    <li><a>Delete</a></li>

  render = () => {
    const { course_id, className, module, index } = this.props;
    const { nameText, ifEditing, ifNewItem, ifActionsDropdownIsOpen } = this.state;
    const module_type = Constants.MODULE_TYPE;
    const icon = module_type[module.program_type.toLowerCase()].icon({ color: '#555555' });
    const parentPath = Urls.getCourseUrl(course_id);
    return (
      <Draggable draggableId={`module-${module.id}`} index={index}>{(dragProvided) =>
        <div
          className={classNames(css.moduleContainer, className, {
              '-editing': ifEditing || ifNewItem,
          })}
          ref={dragProvided.innerRef}
          {...dragProvided.draggableProps}
        >
          <div
            className={classNames('module-icon', { '-disable-handler': (ifEditing || ifNewItem || module.program_type === 'Certificate') })}
            {...dragProvided.dragHandleProps}
          >
            {module.program_type !== 'Certificate' ?
              <MenuOutlinedIcon style={{ color: '#B3B3B3' }}/>
              : <LockIcon style={{ color: '#B3B3B3' }}/>
            }
          </div>
          <div className="module-info-wrapper" onClick={this.handleModuleItemClick}>
            <div className="name-edit-group">
              <div className="name-group">
                { icon }
                <p className="name">
                  {`${module_type[module.program_type.toLowerCase()].name}: ${module.name}`}
                </p>
                <button className="button-edit">Launch Editor</button>
              </div>
              <div className="edit-group">
                { icon }
                <input
                  className="name-input"
                  value={nameText}
                  placeholder="Section Name"
                  ref={inputRef => this.nameInputRef = inputRef}
                  maxLength={Constants.MAX_TITLE_LENGTH}
                  onKeyDown={this.handleKeyDownOnName}
                  onChange={e => this.setState({ nameText: e.target.value.trim().length > 0 ? e.target.value : '' })}
                  onBlur={this.handleNameInputBlur}
                />
                <button
                  className="button-cancel"
                  onMouseDown={this.handleEditCancelClick}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className={classNames('module-actions', { '-open': ifActionsDropdownIsOpen })}>
              <button
                type="button"
                className="menu-toggle"
                ref={buttonRef => this.actionButton = buttonRef}
                onClick={this.uiUpdateDropdownToggle}
              >
                Actions
                <span
                  className="caret"
                  ref={buttonRef => this.caretButton = buttonRef}
                />
              </button>
              <SelectDropdown open={ifActionsDropdownIsOpen} onCloseDropdown={this.uiUpdateDropdownToggle}>
                <li>
                  <a
                    href={Urls.getCourseEditorUrl({
                      courseId: course_id,
                      moduleId: module.id,
                      itemType: module_type[module.program_type.toLowerCase()].type.toLowerCase(),
                    })}
                  >Edit {module_type[module.program_type.toLowerCase()].name}</a>
                </li>
                <li><Link to={`${parentPath}/settings`}>Settings</Link></li>
                <li><a onClick={this.handleNameEditClick}>Rename Section</a></li>
                <li><a onClick={this.uiDuplicateModule}>Duplicate</a></li>
                <li className="menu-divider"/>
                <ConfirmTogglerAndModal
                  title="Are you sure you want to delete this section?"
                  toggler={this.renderDeleteToggler()}
                  onClose={this.uiDeleteModule}
                />
              </SelectDropdown>
            </div>
          </div>
        </div>
      }</Draggable>
    );
  }
}

export default ModuleItem;
