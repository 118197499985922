import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIcon from '@material-ui/icons/MenuOutlined';

import injectFromOldToNewIndex from '~/services/injectFromOldToNewIndex';
import ContentTogglerAndModalWrapper from '../ContentTogglerAndModalWrapper';

import css from './index.scss';

const initialState = {
  payload: '',
  options: ',,',
};

const fromApiToState = (api) => ({
  payload: api.payload,
  options: api.options,
});

const fromStateToApi = (formState) => ({
  payload: formState.payload,
  options: formState.options
});

class DropdownTogglerAndModal extends React.Component {
  uiCreateOption = (formState, updateFormState) => {
    const options = formState.options;
    updateFormState({
      ...formState,
      options: options !== null ? options.concat(',') : ''
    });
  }

  uiUpdateOption = (updatedOptionIndex, e, formState, updateFormState) => {
    const element = e.target;

    if (updateFormState) {
      const newOptions = [];

      if (formState.options) {
        formState.options.split(',').forEach((option, index) =>
          newOptions.push(index !== updatedOptionIndex ? option : element.value)
        );
      } else {
        newOptions.push(element.value);
      }

      const newOptionsStr = newOptions.join();
      updateFormState({
        ...formState,
        options: newOptionsStr
      });
    }
  }

  uiReorderOptions = ({ fromIndex, toIndex, formState, updateFormState }) => {
    const options = formState.options.split(',');
    const reorderedOptions = injectFromOldToNewIndex(options, fromIndex, toIndex);
    updateFormState({
      ...formState,
      options: reorderedOptions.join()
    });
  }

  onDragEnd = (result, formState, updateFormState) => {
    const ifDroppedInsideList = result.destination;
    if (!ifDroppedInsideList) return;

    const fromIndex = result.source.index;
    const toIndex = result.destination.index;
    const ifChangedPosition = toIndex !== fromIndex;
    if (ifChangedPosition) {
      this.uiReorderOptions({ fromIndex, toIndex, formState, updateFormState });
    }
  }

  renderOptions = (dropProvided, formState, updateFormState) => {
    const options = formState.options !== null ? formState.options.split(',') : [];
    return (
      <ul
        ref={dropProvided.innerRef}
        {...dropProvided.droppableProps}
      >
        {options.map((option, index) =>
          <Draggable draggableId={`${index}`} key={index} index={index}>{(dragProvided) =>
            <li
              className="dropdown-option"
              ref={dragProvided.innerRef}
              {...dragProvided.draggableProps}
            >
              <div className="drag-icon" {...dragProvided.dragHandleProps}>
                <DragIcon/>
              </div>
              <input
                value={option}
                placeholder="Enter Text"
                autoFocus
                onChange={(e) => this.uiUpdateOption(index, e, formState, updateFormState)}
              />
            </li>
          }</Draggable>
        )}
        {dropProvided.placeholder}
      </ul>
    );
  }

  render = () =>
    <ContentTogglerAndModalWrapper
      className={`${css.modal} -with-green-header`}
      title="Dropdown"
      initialState={initialState}
      fromApiToState={fromApiToState}
      fromStateToApi={fromStateToApi}
      {...this.props}
    >{(formState, updateFormState) =>
      <div className="standard-modal-main dropdown-modal-main">
        <textarea
          value={formState.payload}
          placeholder="Type you question here…. (example: What is your role?)"
          onChange={(e) => updateFormState({ ...formState, payload: e.target.value })}
        />
        <div className="dropdown-options">
          <h4 className="standard-modal-title">Options</h4>
          <DragDropContext onDragEnd={result => this.onDragEnd(result, formState, updateFormState)}>
            <Droppable droppableId="options">{(dropProvided) =>
              this.renderOptions(dropProvided, formState, updateFormState)
            }</Droppable>
          </DragDropContext>
          <button className="btn-add-option" onClick={() => this.uiCreateOption(formState, updateFormState)}>+ Add Option</button>
        </div>
      </div>
    }</ContentTogglerAndModalWrapper>
}

export default DropdownTogglerAndModal;
