import React from 'react';
import PropTypes from 'prop-types';

import Constants from '~/services/Constants';
import SpeCreator from '~/services/SpeCreator';
import ProgramApi from '~/api/ProgramApi';
import StudentAlert from '~/_partials/StandardAlert';

import css from './index.scss';

class FreeResponseContent extends React.Component {
  static propTypes = {
    course_id: PropTypes.number.isRequired,
  }

  state = {
    spePage: SpeCreator.empty(),
    questions: [],
    ifScoringSuccess: false,
    scoringMessage: '',
  }

  componentDidMount() {
    this.apiGetPage()
      .then(data => {
        this.setState({
          questions: data.free_response_questions.map(question => {
            question.responses.map(response =>
              response.ifScored = response.score === null ? false : true
            );
            return question;
          })
        });
      });
  }

  apiGetPage = () =>
    ProgramApi.getFreeResponseQuestions(
      false, {
        program_id: this.props.course_id,
      }
    );

  apiUpdateFreeResponseScore = (params) =>
    ProgramApi.updateFreeResponseScore(false, params)

  uiShowAlert = (ifSuccess) => {
    if (ifSuccess) {
      this.setState({
        ifScoringSuccess: true,
        scoringMessage: 'Free Response Answer Scored',
      });
    } else {
      this.setState({
        ifScoringSuccess: true,
        scoringMessage: 'Something Went Wrong',
      });
    }
  }

  uiUpdateAnswerScore = (questionIndex, answerIndex, score, ifScored = true) => {
    const { questions } = this.state;
    const question = questions[questionIndex];
    const answer = question.responses[answerIndex];
    this.setState({
      questions: [
        ...questions.slice(0, questionIndex),
        {
          ...question,
          responses: [
            ...question.responses.slice(0, answerIndex),
            {
              ...answer,
              score,
              ifScored,
            },
            ...question.responses.slice(answerIndex + 1),
          ]
        },
        ...questions.slice(questionIndex + 1),
      ]
    });
  }

  handleUpdatePercentScore = (questionIndex, answerIndex, pass) => {
    const { questions } = this.state;
    const question = questions[questionIndex];
    const answer = question.responses[answerIndex];
    this.uiUpdateAnswerScore(questionIndex, answerIndex, (pass ? 1 : 0));
    this.apiUpdateFreeResponseScore({
      answer_id: answer.id,
      pass,
      score: 0,
    }).then(() => {
      this.uiShowAlert(true);
    }).catch(() => {
      this.setState({ questions });
      this.uiShowAlert(false);
    });
  }

  uiUpdatePointScore = (e, questionIndex, answerIndex) => {
    let score = parseInt(e.target.value, 10);

    if (isNaN(score)) {
      score = 0;
    }

    if (score > 10) {
      score = 10;
    }

    this.uiUpdateAnswerScore(questionIndex, answerIndex, score, false);
  }

  handleSavePointScore = (questionIndex, answerIndex) => {
    const { questions } = this.state;
    const question = questions[questionIndex];
    const answer = question.responses[answerIndex];
    this.uiUpdateAnswerScore(questionIndex, answerIndex, (answer.score ? answer.score : 0));
    this.apiUpdateFreeResponseScore({
      answer_id: answer.id,
      pass: null,
      score: answer.score,
    }).then(() => {
      this.uiShowAlert(true);
    }).catch(() => {
      this.setState({ questions });
      this.uiShowAlert(false);
    });
  }

  handleCloseAlert = () => {
    this.setState({
      scoringMessage: ''
    });
  }

  render = () =>
    <div className={css.content}>
      {this.state.questions.map((question, questionIndex) =>
        <div key={questionIndex} className="question-container">
          <p className="question-title"><strong>Question {questionIndex + 1}: </strong>{question.payload}</p>
          <ul className="answer-list">
            {question.responses.map((response, answerIndex) =>
              <li key={answerIndex} className="answer-item">
                <p className="answer-response"><strong>{response.user_name}: </strong>{response.free_response}</p>
                <div className="scoring-container">
                  <label className="score-label">Score:</label>
                  {!response.ifScored ?
                    <div>
                      {question.scoring_type === 'percentage' ?
                        <div className="scoring-box -percent">
                          <button className="button-score -pass" onClick={() => this.handleUpdatePercentScore(questionIndex, answerIndex, true)}>Pass</button>
                          <button className="button-score -fail" onClick={() => this.handleUpdatePercentScore(questionIndex, answerIndex, false)}>Fail</button>
                        </div> :
                        <div className="scoring-box -point">
                          <input
                            type="number"
                            className="input-point"
                            placeholder="0 Points"
                            value={(response.score === null || response.score === 0) ? '' : response.score}
                            onChange={e => this.uiUpdatePointScore(e, questionIndex, answerIndex)}
                          />
                          <label className="label-point">out of 10 Points</label>
                          <button className="button-save" onClick={() => this.handleSavePointScore(questionIndex, answerIndex)}>Save Score</button>
                        </div>
                      }
                    </div> :
                    <div className="score-change-box">
                      {question.scoring_type === 'percentage' &&
                        <label className={response.score === 1 ? 'passed' : 'failed'}>{response.score === 1 ? 'Passed' : 'Failed'}</label>
                      }
                      {question.scoring_type === 'point' &&
                        <label>{response.score}</label>
                      }
                      <button onClick={() => this.uiUpdateAnswerScore(questionIndex, answerIndex, response.score, false)}>Change Score</button>
                    </div>
                  }
                </div>
              </li>
            )}
          </ul>
        </div>
      )}
      <StudentAlert
        open={this.state.scoringMessage.length > 0}
        type={this.state.ifScoringSuccess ? Constants.ALERT_TYPE.success : Constants.ALERT_TYPE.warning}
        message={this.state.scoringMessage}
        onClose={this.handleCloseAlert}
      />
    </div>
}

export default FreeResponseContent;
