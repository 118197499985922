import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash/core';

import Utils from '~/services/Utils';
import Loading from '~/_partials/Loading';
import SpeCreator from '~/services/SpeCreator';
import ProgramApi from '~/api/ProgramApi';

import css from './index.scss';

class SectionContent extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    contentSelected: PropTypes.bool.isRequired,
    uiSelectSectionContent: PropTypes.func.isRequired,
  }

  state = {
    spePage: SpeCreator.empty(),
    contentResponseViewState: {},
    selectedContent: null,
  }

  componentDidMount() {
    this.apiGetPage(this.props.match.params.section_id);
  }

  componentWillReceiveProps(props) {
    if (props.match.params.section_id !== this.props.match.params.section_id) {
      this.apiGetPage(props.match.params.section_id);
    } else if (props.contentSelected !== this.props.contentSelected && !props.contentSelected) {
      this.setState({ selectedContent: null });
    }
  }

  apiGetPage = (section_id) =>
    ProgramApi.getSurveyResults(
      (spe) => this.setState({ spePage: spe }),
      section_id,
    )

  isContentResponseViewed = (contentId) => {
    const { contentResponseViewState } = this.state;

    if (contentId in contentResponseViewState) {
      return contentResponseViewState[contentId];
    } else {
      return false;
    }
  }

  getMlutipleChoiceStudentAnswers = (answer, content) => {
    const selectedAnswers = content.student_answers.filter(answerItem => answerItem.answer_id === answer.answer_id);
    return selectedAnswers;
  }

  getRatingStudentAnswers = (ratingPoint, content) => {
    const selectedAnswers = content.student_answers.filter(answerItem => ratingPoint.toString() === answerItem.free_response);
    return selectedAnswers;
  }

  getMean = (numbers) => {
    let total = 0;
    let i;

    for (i = 0; i < numbers.length; i += 1) {
        total += numbers[i];
    }

    const retMean = numbers.length > 0 ? total / numbers.length : 0;
    return !Number.isNaN(retMean) ? retMean : 0;
  }

  getMedian = (numbers) => {
    let median = 0;
    const numsLen = numbers.length;
    numbers.sort();

    if (numsLen % 2 === 0) {
        median = (numbers[numsLen / 2 - 1] + numbers[numsLen / 2]) / 2;
    } else {
        median = numbers[(numsLen - 1) / 2];
    }

    return !Number.isNaN(median) ? median : 0;
  }

  getMode = (numbers) => {
    const modes = [];
    const count = [];
    let i;
    let number;
    let maxIndex = 0;

    for (i = 0; i < numbers.length; i += 1) {
      number = numbers[i];
      count[number] = (count[number] || 0) + 1;
      if (count[number] > maxIndex) {
        maxIndex = count[number];
      }
    }

    _.keys(count).forEach(key => {
      if (key in count) {
        if (count[key] === maxIndex) {
          modes.push(Number(key));
        }
      }
    });

    return modes.length > 0 ? modes.join(', ') : 0;
  }

  uiUpdateResponseViewStatus = (contentId) => {
    const { contentResponseViewState } = this.state;
    this.setState({
      contentResponseViewState: {
        ...contentResponseViewState,
        [contentId]: true,
      }
    });
  }

  uiSelectContent = (content) => {
    this.setState({ selectedContent: content });
    this.props.uiSelectSectionContent(Number(this.props.match.params.section_id));
  }

  renderLikertContent = (content) => {
    const { selectedContent } = this.state;

    if (!selectedContent || selectedContent.id !== content.id) {
      const ifOldFormatOptions = !content.options.includes('"value":') && !content.options.includes('"label":');
      const options = !ifOldFormatOptions ? JSON.parse(content.options) :
        content.options.split(',').map(option => {
          const newOption = {
            value: option,
            label: '',
          };
          return newOption;
        });
      const inversedOptions = [];

      for (let i = options.length - 1; i >= 0; i -= 1) {
        inversedOptions.push(options[i]);
      }

      return (
        <div className="result-likert">
          <table>
            <thead>
              <tr>
                <th>Likert Question</th>
                <th className="distribution"><div>Response Distribution</div></th>
                <th>Statistics</th>
              </tr>
            </thead>
            <tbody>
              {content.answers.map((answer, answerIndex) => {
                const answerResponses =
                  content.student_answers.filter(student_answer => student_answer.answer_id === answer.answer_id)
                    .map(answerItem => answerItem.free_response ? parseInt(answerItem.free_response, 10) : 0);
                const meanOfAnswers = this.getMean(answerResponses);
                const medianOfAnswers = this.getMedian(answerResponses);
                const modeOfAnswers = this.getMode(answerResponses);
                return (
                  <tr key={answerIndex}>
                    <td>
                      <div>
                        {Utils.stripHtml(answer.payload)}
                      </div>
                    </td>
                    <td>
                      {inversedOptions.map((option, index) => {
                        const studentAnswers = content.student_answers.filter(student_answer => student_answer.answer_id === answer.answer_id && student_answer.free_response === option.value.toString());
                        const percent = answerResponses.length > 0 ? (studentAnswers.length / answerResponses.length * 100) : 0;
                        return (
                          <div className="likert-info" key={index}>
                            <label className="likert-option-value">{option.value}{index === 0 ? ' Most' : ''}{index === options.length - 1 ? ' Least' : ''}</label>
                            <div className="answer-box">
                              <div className="answer-percent" style={{ width: `${percent}%` }}/>
                            </div>
                            <label className="likert-option-answer">{studentAnswers.length}({percent}%)</label>
                          </div>
                        );
                      })}
                    </td>
                    <td>
                      <div className="point-container">
                        <div className="point-box">
                          <p className="point-name">Mean</p>
                          <p className="point-value">{meanOfAnswers !== 0 ? meanOfAnswers : 'N/A'}</p>
                        </div>
                        <div className="point-box">
                          <p className="point-name">Median</p>
                          <p className="point-value">{medianOfAnswers !== 0 ? meanOfAnswers : 'N/A'}</p>
                        </div>
                        <div className="point-box">
                          <p className="point-name">Mode</p>
                          <p className="point-value">{modeOfAnswers !== 0 ? modeOfAnswers : 'N/A'}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <ul className="result-response">
          {content.student_answers.map((answer, index) =>
            <li key={index}>{answer.free_response}</li>
          )}
        </ul>
      );
    }
  }

  renderRatingScale = (content) => {
    const { selectedContent } = this.state;
    if (!selectedContent || selectedContent.id !== content.id) {
      const studentCount = content.student_answers.length;
      let ratingSum = 0;
      content.student_answers.forEach(answer => ratingSum +=  answer.free_response ? parseInt(answer.free_response, 10) : 0);
      let averageRating = studentCount > 0 ? (ratingSum * 1.0 / studentCount) : 0;
      averageRating = Number.isNaN(averageRating) ? 0 : averageRating;
      const ratingStars = [];
      const ratingValues = content.student_answers.map(answer => answer.free_response ? parseInt(answer.free_response, 10) : 0);
      const medianOfRatings = this.getMedian(ratingValues);
      const modeOfRatings = this.getMode(ratingValues);

      for (let i = 0; i < 5; i += 1) {
        if (i + 1 <= averageRating) {
          ratingStars.push(<span className="rating-star" key={i}><i className="glyphicon glyphicon-star"/></span>);
        } else {
          ratingStars.push(<span className="rating-star" key={i}><i className="glyphicon glyphicon-star-empty"/></span>);
        }
      }

      return (
        <div className="result-rating">
          <div className="rating-detail-container">
            <div className="star-group">
              {ratingStars}
              <span className="rating-score">({averageRating !== 0 ? averageRating : 'N/A'})</span>
            </div>
            <ul className="rating-detail-list">
              {ratingStars.map((star, index) => {
                const answersForRating = this.getRatingStudentAnswers((5 - index), content);
                const ratingPercent = content.student_answers.length > 0 ? answersForRating.length / content.student_answers.length * 100 : 0;
                return (
                  <li className="rating-detail-item" key={index}>
                    <label>{(5 - index)} {index < 4 ? 'Stars' : 'Star'}</label>
                    <div className="answer-box">
                      <div className="answer-percent" style={{ width: `${ratingPercent}%` }}/>
                      <span>{`${answersForRating.length} (${ratingPercent}%)`}</span>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="point-container">
            <div className="point-box">
              <p className="point-name">Mean</p>
              <p className="point-value">{averageRating !== 0 ? averageRating : 'N/A'}</p>
            </div>
            <div className="point-box">
              <p className="point-name">Median</p>
              <p className="point-value">{medianOfRatings !== 0 ? medianOfRatings : 'N/A'}</p>
            </div>
            <div className="point-box">
              <p className="point-name">Mode</p>
              <p className="point-value">{modeOfRatings !== 0 ? modeOfRatings : 'N/A'}</p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <ul className="result-response">
          {content.student_answers.map((answer, index) =>
            <li key={index}>{answer.free_response}</li>
          )}
        </ul>
      );
    }
  }

  renderContent = (content) => {
    const { selectedContent } = this.state;
    if (content.type === 'multiple-choice') {
      return (
        (!selectedContent || selectedContent.id !== content.id) ?
          <ul className="result-multichoice">
            {content.answers.map((answer, index) => {
              const studentAnswersForChoice = this.getMlutipleChoiceStudentAnswers(answer, content);
              const percent = content.student_answers.length > 0 ? (studentAnswersForChoice.length / content.student_answers.length) * 100 : 0;
              return (
                <li className="answer-choice" key={index}>
                  <label>{answer.payload}</label>
                  <div className="answer-box">
                    <div className="answer-percent" style={{ width: `${percent}%` }}/>
                    <span>{`${studentAnswersForChoice.length} (${percent}%)`}</span>
                  </div>
                </li>
              );
            })}
          </ul> :
          <ul className="result-response">
            {content.student_answers.map((studentAnswer, index) => {
              const choiceAnswer = content.answers.find(answer => answer.answer_id === studentAnswer.answer_id);
              return (
                <li key={index}>{choiceAnswer.payload}</li>
              );
            })}
          </ul>
      );
    } else if (content.type === 'multiple-choice-2') {
      return (
        (!selectedContent || selectedContent.id !== content.id) ?
          <ul className="result-multichoice">
            {content.answers.map((answer, index) => {
              const studentAnswersForChoice = this.getMlutipleChoiceStudentAnswers(answer, content);
              const percent = content.student_answers.length > 0 ? (studentAnswersForChoice.length / content.student_answers.length) * 100 : 0;
              return (
                <li className="answer-choice" key={index}>
                  <label>{answer.payload}</label>
                  <div className="answer-box">
                    <div className="answer-percent" style={{ width: `${percent}%` }}/>
                    <span>{`${studentAnswersForChoice.length} (${percent}%)`}</span>
                  </div>
                </li>
              );
            })}
          </ul> :
          <ul className="result-response">
            {content.student_answers.map((studentAnswer, index) => {
              const choiceAnswer = content.answers.find(answer => answer.answer_id === studentAnswer.answer_id);
              return (
                <li key={index}>{choiceAnswer.payload}</li>
              );
            })}
          </ul>
      );
    } else if (content.type === 'nps') {
      return (
        (!selectedContent || selectedContent.id !== content.id) ?
          <ul className="result-multichoice">
            {content.answers.map((answer, index) => {
              const studentAnswersForChoice = this.getMlutipleChoiceStudentAnswers(answer, content);
              const percent = content.student_answers.length > 0 ? (studentAnswersForChoice.length / content.student_answers.length) * 100 : 0;
              return (
                <li className="answer-choice" key={index}>
                  <label>{answer.payload}</label>
                  <div className="answer-box">
                    <div className="answer-percent" style={{ width: `${percent}%` }}/>
                    <span>{`${studentAnswersForChoice.length} (${percent}%)`}</span>
                  </div>
                </li>
              );
            })}
          </ul> :
          <ul className="result-response">
            {content.student_answers.map((studentAnswer, index) => {
              const choiceAnswer = content.answers.find(answer => answer.answer_id === studentAnswer.answer_id);
              return (
                <li key={index}>{choiceAnswer.payload}</li>
              );
            })}
          </ul>
      );
    } else if (content.type === 'dropdown' || content.type === 'single-line-text' || content.type === 'multi-line-text') {
      const ifResponsesViewed = this.isContentResponseViewed(content.id);
      return (
        <ul className={classNames('result-response', { '-view-ready': !ifResponsesViewed })}>
          {(content.student_answers.length > 0 && !ifResponsesViewed) ?
            <button className="btn-view-response" onClick={() => this.uiUpdateResponseViewStatus(content.id)}>{`Click to View ${content.student_answers.length} Responses`}</button> :
            content.student_answers.map((answer, index) =>
              <li key={index}>{answer.free_response}</li>
            )
          }
        </ul>
      );
    } else if (content.type === 'rating-scale') {
      return this.renderRatingScale(content);
    } else if (content.type === 'likert') {
      return this.renderLikertContent(content);
    } else {
      return null;
    }
  }

  render = () =>
    <Loading spe={this.state.spePage} className="standard-page-loading survey-page-loading">{({
      section_contents
    }) => {
      const { selectedContent } = this.state;
      const contents = !selectedContent ? section_contents : [selectedContent];
      const questionIndex = !this.state.selectedContent ? -1 : section_contents.findIndex(content => content.id === selectedContent.id);
      return (
        <div className={css.content}>
          {contents.length > 0 ?
            <ul>
              {contents.filter(content => content.type !== 'paragraph' && content.type !== 'image' && content.type !== 'video')
                .map((content, index) =>
                  <li
                    key={index}
                    className={classNames({
                      '-clickable': content.type === 'dropdown' || content.type === 'single-line-text' || content.type === 'multi-line-text',
                    })}
                  >
                    <h3
                      className={classNames('question-title', {
                        '-empty-answer': (!content.options && content.answers.length === 0 && content.student_answers.length === 0 && content.type !== 'rating-scale') ||
                        (content.type === 'dropdown' && content.student_answers.length === 0)
                      })}
                    >
                      {`Question ${questionIndex >= 0 ? questionIndex + 1 : index + 1}: ${content.type === 'likert' ? '' : Utils.stripHtml(content.payload)}`}
                    </h3>

                    {this.renderContent(content)}

                    {(content.type === 'multiple-choice' || content.type === 'multiple-choice-2' || content.type === 'nps' || content.type === 'rating-scale' || content.type === 'likert') &&
                      <button
                        className={classNames('btn-answer-response', {
                            '-no-clickable': this.state.selectedContent || content.student_answers.length === 0,
                          }
                        )}
                        onClick={() => this.uiSelectContent(content)}
                      >
                        {`${content.student_answers.length} Responses`}
                      </button>
                    }
                  </li>
              )}
            </ul> :
            <div className="empty-content">
              <p>This section does not have any content.</p>
            </div>
          }
        </div>
      );
    }}</Loading>
}

export default SectionContent;
