import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import isRequired from 'react-required-if';
import _ from 'lodash/core';
import orFalse from '~/services/orFalse';

import injectFromOldToNewIndex from '~/services/injectFromOldToNewIndex';
import SpeCreator              from '~/services/SpeCreator';
import ContentApi              from '~/api/ContentApi';
import Constants               from '~/services/Constants';
import apiProceedWithUploadingFileViaTus     from './services/apiProceedWithUploadingFileViaTus';
import deriveTotalAmountOfPoints             from './services/deriveTotalAmountOfPoints';
import deriveModulesWithNewAmountOfTestItems from './services/deriveModulesWithNewAmountOfTestItems';
import dontCloseWindowIfStilUploadingFile    from './services/dontCloseWindowIfStilUploadingFile';

import { DragDropContext } from 'react-beautiful-dnd';

import Loading         from '~/_partials/Loading';
import SubHeader             from './_partials/SubHeader';
import Contents              from './_partials/Contents';
import ToolboxAside          from './_partials/ToolboxAside';
import CourseModulesAside    from './_partials/CourseModulesAside';
import NewVersionNeededModal from './_partials/NewVersionNeededModal';
import WelcomeModal from './_partials/WelcomeModal';

import css from './index.scss';

class ProgramEditor extends React.Component {
  static propTypes = {
    editorForCourseOrExam: PropTypes.oneOf(['course', 'exam']).isRequired,
    organization_name: PropTypes.string.isRequired,
    itemType: PropTypes.string.isRequired,
    first_view: PropTypes.bool.isRequired,

    // - either
    examId: isRequired(orFalse(PropTypes.number), (props) => !props.courseId),
    // - or
    courseId: isRequired(orFalse(PropTypes.number), (props) => !props.examId),
    moduleId: isRequired(orFalse(PropTypes.number), (props) => !props.examId)
  }

  static defaultProps = {
    examId: false,
    courseId: false,
    moduleId: false,
    first_view: false,
  }

  constructor(props) {
    super(props);
    this.toolboxRef = React.createRef();
  }

  state = {
    spePage: SpeCreator.empty(),
    // ___What does it affect?
    //    What .position our next Content will be pasted in on creation.
    // ___How is it determined?
    //    1. When we drag some tool from the <Toolbox/> into the <Contents/> - it's set to the position it was dragged in.
    //    2. When we close the creation modal after having created something - it's set to be the last index + 1 again.
    positionToCreateCurrentContentAt: 0,
    x_coordinate: '50',
    y_coordinate: '50',
  }

  componentDidMount = () => {
    this.apiGetPage()
      .then(({ contents }) =>
        this.setState({ positionToCreateCurrentContentAt: contents.length })
      );

    window.addEventListener('beforeunload', (event) =>
      dontCloseWindowIfStilUploadingFile(event, this.state.spePage)
    );
  }

  createContentAtPosition = ({ toolIndex, contentIndex }) => {
    // at what position should we create the content?
    this.setState({ positionToCreateCurrentContentAt: contentIndex });

    const toolboxEl = ReactDOM.findDOMNode(this.toolboxRef.current);
    // what particular modal should we open?
    const createContentModalTogglerEl = toolboxEl.getElementsByClassName(`-dnd-index-${toolIndex}`)[0];
    const togglerClasses = createContentModalTogglerEl.classList;
    if (!togglerClasses.contains('horizontal-line') && !togglerClasses.contains('vertical-line')) {
      if (togglerClasses.contains('page-break')) {
        // create page break content
        this.apiCreateContent({
          item_type: this.props.itemType,
          module_or_exam_id: this.props.editorForCourseOrExam === 'exam' ? this.props.examId : this.props.moduleId,
          content_type: 'page-break',
          position: contentIndex,
        }).then(createdContent => {
          this.uiCreateContent(createdContent);
        });
      } else {
        // open creating modal
        createContentModalTogglerEl.click();
      }
    } else {
      // create line contents
      this.apiCreateContent({
          item_type: this.props.itemType,
          module_or_exam_id: this.props.editorForCourseOrExam === 'exam' ? this.props.examId : this.props.moduleId,
          content_type: togglerClasses.contains('horizontal-line') ? 'horizontal-line' : 'vertical-line',
          payload: '245',
          x_coordinate: this.state.x_coordinate,
          y_coordinate: this.state.y_coordinate,
      }).then((createdContent) =>
          setTimeout(() => {
            // delay sending request to create content with correct coordinates
            this.uiCreateContent(createdContent);
            this.uiInitCoordinates();
          })
        );
    }
  }

  uiCreateCertificateBackground = (newBackground) => {
    const contents = this.state.spePage.payload.contents;
    const promises = [];
    const oldActiveIndex = contents.findIndex(content => content.content_type === 'background-image' && content.options === 'active');
    const defaultBackgroundIndex = contents.findIndex(content => content.content_type === 'background-image' && content.title !== null);

    if (oldActiveIndex < 0 && newBackground.title === Constants.DEFAULT_BACKGROUND_TITLE) return;

    // remove active option from old active background
    if (oldActiveIndex >= 0) {
      promises.push(
        this.apiUpdateContent({
          ...contents[oldActiveIndex],
          options: '',
        })
      );
    }

    // create or write active option to new background
    if (defaultBackgroundIndex >= 0) {
      promises.push(
        this.apiUpdateContent({
          ...contents[defaultBackgroundIndex],
          title: newBackground.title,
          payload: `<img src=${newBackground.image}>`,
          options: 'active',
        })
      );
    } else {
      promises.push(
        this.apiCreateContent({
          title: newBackground.title,
          item_type: this.props.itemType,
          module_or_exam_id: this.props.editorForCourseOrExam === 'exam' ? this.props.examId : this.props.moduleId,
          content_type: 'background-image',
          payload: `<img src=${newBackground.image}>`,
          options: 'active',
          x_coordinate: '0',
          y_coordinate: '0',
        })
      );
    }

    Promise.all(promises).then(results => {
      let newContents = contents;

      if (oldActiveIndex >= 0) {
        newContents = [
          ...contents.slice(0, oldActiveIndex),
          results[0],
          ...contents.slice(oldActiveIndex + 1),
        ];
      }

      if (defaultBackgroundIndex >= 0) {
        newContents = [
          ...newContents.slice(0, defaultBackgroundIndex),
          results[1],
          ...newContents.slice(defaultBackgroundIndex + 1),
        ];
      } else {
        newContents.push(oldActiveIndex < 0 ? results[0] : results[1]);
      }

      this.setState({
        spePage: this.getNewSpePage('contents', newContents)
      });
    });
  }

  uiUpdateCertificateBackground = (updatedBackground) => {
    const contents = this.state.spePage.payload.contents;
    const oldActiveBGContent = contents.find(content => content.content_type === 'background-image' && content.options === 'active');

    if (!oldActiveBGContent || oldActiveBGContent.id !== updatedBackground.id) {
      const promises = [];
      const newBGContent = contents.find(content => content.id === updatedBackground.id);
      const newActiveIndex = contents.findIndex(content => content.id === updatedBackground.id);
      newBGContent.options = 'active';
      promises.push(
        this.apiUpdateContent({
          ...newBGContent,
          item_type: 'certificate',
        })
      );

      if (oldActiveBGContent) {
        oldActiveBGContent.options = '';
        promises.push(
          this.apiUpdateContent({
            ...oldActiveBGContent,
            item_type: 'certificate',
          }
        ));
      }

      Promise.all(promises).then(() => {
        let newContents = contents;

        if (newActiveIndex >= 0) {
          newContents = [
            ...contents.slice(0, newActiveIndex),
            newBGContent,
            ...contents.slice(newActiveIndex + 1),
          ];
        }

        if (oldActiveBGContent) {
          oldActiveBGContent.options = '';
          const oldActiveIndex = contents.findIndex(content => content.id === oldActiveBGContent.id);
          newContents = [
            ...newContents.slice(0, oldActiveIndex),
            oldActiveBGContent,
            ...newContents.slice(oldActiveIndex + 1),
          ];
        }

        this.setState({
          spePage: this.getNewSpePage('contents', newContents)
        });
      });
    }
  }

  onDragEnd = (result) => {
    const ifDroppedInsideList = result.destination;
    if (!ifDroppedInsideList) return;

    const fromList = result.source.droppableId;
    const toList = result.destination.droppableId;

    // we dropped one of the tools between some contents?
    if (fromList === 'tools' && toList === 'contents') {
      this.createContentAtPosition({ toolIndex: result.source.index, contentIndex: result.destination.index });
    // we dropped one of the contents between some other contents?
    } else if (fromList === 'contents' && toList === 'contents') {
      this.uiReorderContents({ fromIndex: result.source.index, toIndex: result.destination.index });
    // we dropped tools to tools, or contents to tools?
    } else {
      // do nothing!
    }
  }

  getNewSpePage = (keyName, payloadUnderKey) => ({
    ...this.state.spePage,
    payload: {
      ...this.state.spePage.payload,
      [keyName]: payloadUnderKey
    }
  })

  apiGetPage = () =>
    ContentApi.getProgramWithContents(
      (spe) => this.setState({ spePage: spe }),
      {
        module_or_exam_id: this.props.editorForCourseOrExam === 'exam' ? this.props.examId : this.props.moduleId,
        item_type: this.props.itemType,
        first_view: this.props.first_view
      }
    )

  apiSyncReorderedContents = () => {
    this.resetPositionToCreateCurrentContentAt();
    ContentApi.reorderAll(
      false,
      this.state.spePage.payload.contents.map((content) => content.id)
    );
  }

  apiProceedWithUploadingFileViaTus = ({ content, file, tusUrl, assemblyUrl }) =>
    apiProceedWithUploadingFileViaTus({ content, file, tusUrl, assemblyUrl }, this.uiUpdateContent)

  apiCreateContent = (createContent) =>
    ContentApi.create(
      false,
      { ...createContent },
    );

  apiUpdateContent = (updatedContent) =>
    ContentApi.update(
      false,
      updatedContent.id,
      { ...updatedContent },
    );

  getCertificateBackgrounds = () => {
    let defaultBackgrounds = [
      ..._.values(Constants.CERTIFICATE_DEFAULT_BACKGROUNDS)
    ];
    const storedBackgrounds = this.state.spePage.payload.contents.filter(content => content.content_type === 'background-image');
    defaultBackgrounds = defaultBackgrounds.map(background => {
      const storedBackground = storedBackgrounds.find(stored_background => stored_background.title === background.title);
      return storedBackground ? storedBackground : background;
    });
    return [
      ...defaultBackgrounds,
      ...storedBackgrounds.filter(background => background.title === null),
    ];
  }

  uiSyncUpdateTestItemCount = () => {
    if (this.props.editorForCourseOrExam === 'course') {
      this.setState((state) => {
        const newModules = deriveModulesWithNewAmountOfTestItems(state.spePage.payload.contents, state.spePage.payload.modules_of_course, this.props.moduleId);
        return { spePage: this.getNewSpePage('modules_of_course', newModules.filter(module => module)) };
      });
    }
  }

  uiReorderContents = ({ fromIndex, toIndex }) => {
    const ifChangedPosition = toIndex !== fromIndex;

    if (ifChangedPosition) {
      const reorderedContents = injectFromOldToNewIndex(this.state.spePage.payload.contents, fromIndex, toIndex);
      this.setState({
        spePage: this.getNewSpePage('contents', reorderedContents)
      }, this.apiSyncReorderedContents);
    }
  }

  uiInitCoordinates = () => {
    this.setState({
      x_coordinate: '50',
      y_coordinate: '50',
    });
  }

  uiCreateContent = (createdContent) => {
    const positionContentWasCreatedIn = this.state.positionToCreateCurrentContentAt;
    const newContents = [...this.state.spePage.payload.contents, createdContent];
    // insert content exactly in the position we need
    const newOrderedContents = injectFromOldToNewIndex(newContents, newContents.length - 1, positionContentWasCreatedIn);

    this.setState({
      spePage: this.getNewSpePage('contents', newOrderedContents)
    }, this.apiSyncReorderedContents);
    this.uiSyncUpdateTestItemCount();
    this.uiInitCoordinates();
  }

  uiUpdateContent = (updatedContent) => {
    const prevContents = this.state.spePage.payload.contents;
    const updatedContentIndex = prevContents.findIndex((li) => li.id === updatedContent.id);

    if (updatedContentIndex >= 0) {
      const newContents = [
        ...prevContents.slice(0, updatedContentIndex),
        updatedContent,
        ...prevContents.slice(updatedContentIndex + 1)
      ];
      this.setState({
        spePage: this.getNewSpePage('contents', newContents)
      });
    }
  }

  uiDeleteContent = (deletedContentId) => {
    const newContents = this.state.spePage.payload.contents.filter(content =>
      content.id !== deletedContentId
    );
    this.setState({
      spePage: this.getNewSpePage('contents', newContents)
    }, this.apiSyncReorderedContents);
    this.uiSyncUpdateTestItemCount();
  }

  uiUpdateCoordinates = (coord_x, coord_y) => {
    this.setState({
      x_coordinate: coord_x.toString(),
      y_coordinate: coord_y.toString(),
    });
  }

  // make next position last index again
  resetPositionToCreateCurrentContentAt = () =>
    this.setState({
      positionToCreateCurrentContentAt: this.state.spePage.payload.contents.length
    })

  getScoringType = (courseOrExam) => {
    const speName = courseOrExam === 'course' ? 'module' : 'exam';
    return this.state.spePage.payload[speName].scoring_type;
  }

  updateScoringType = (courseOrExam, scoringType) => {
    const speName = courseOrExam === 'course' ? 'module' : 'exam';
    const updatedModuleOrExam = {
      ...this.state.spePage.payload[speName],
      scoring_type: scoringType
    };
    this.setState({
      spePage: this.getNewSpePage(speName, updatedModuleOrExam)
    });
  }

  updateModulesOfCourse = (modulesOfCourse) =>
    this.setState({
      spePage: this.getNewSpePage('modules_of_course', modulesOfCourse)
    })

  render = () =>
    <Loading spe={this.state.spePage} className="standard-page-loading">{({ contents, exam, course, modules_of_course, if_already_administered, subscription_plan }) =>
      <main className={css.main}>
        <SubHeader
          editorForCourseOrExam={this.props.editorForCourseOrExam}
          itemType={this.props.itemType}
          course={course || false}
          exam={exam || false}
          moduleId={this.props.moduleId}
        />

        {
          this.props.editorForCourseOrExam === 'course' &&
          <CourseModulesAside
            currentModuleId={this.props.moduleId}
            currentCourseId={this.props.courseId}
            modules={modules_of_course}
            updateModules={this.updateModulesOfCourse}
          />
        }

        <DragDropContext onDragEnd={this.onDragEnd}>
          <React.Fragment>
            <Contents
              contents={contents}
              uiUpdateContent={this.uiUpdateContent}
              uiDeleteContent={this.uiDeleteContent}
              uiUpdateCoordinates={this.uiUpdateCoordinates}
              editorForCourseOrExam={this.props.editorForCourseOrExam}
              itemType={this.props.itemType}
              courseName={course ? course.name : ''}
              organizationName={this.props.organization_name}

              scoringType={this.getScoringType(this.props.editorForCourseOrExam)}
              totalAmountOfPoints={deriveTotalAmountOfPoints(contents)}
            />
            <ToolboxAside
              ref={this.toolboxRef}
              editorForCourseOrExam={this.props.editorForCourseOrExam}
              itemType={this.props.itemType}
              exam={exam || false}
              course={course || false}
              organization_name={this.props.organization_name}
              moduleId={this.props.moduleId}
              x_coordinate={this.state.x_coordinate}
              y_coordinate={this.state.y_coordinate}
              backgrounds={this.getCertificateBackgrounds()}

              scoringType={this.getScoringType(this.props.editorForCourseOrExam)}
              updateScoringType={(scoringType) => this.updateScoringType(this.props.editorForCourseOrExam, scoringType)}
              totalAmountOfPoints={deriveTotalAmountOfPoints(contents)}

              positionToCreateCurrentContentAt={this.state.positionToCreateCurrentContentAt}
              resetPositionToCreateCurrentContentAt={this.resetPositionToCreateCurrentContentAt}

              uiInitCoordinates={this.uiInitCoordinates}
              uiCreateContent={this.uiCreateContent}
              uiDeleteContent={this.uiDeleteContent}
              uiCreateCertificateBackground={this.uiCreateCertificateBackground}
              uiUpdateCertificateBackground={this.uiUpdateCertificateBackground}
              apiProceedWithUploadingFileViaTus={this.apiProceedWithUploadingFileViaTus}

              subscriptionPlanName={subscription_plan.name}
            />
          </React.Fragment>
        </DragDropContext>

        <NewVersionNeededModal
          editorForCourseOrExam={this.props.editorForCourseOrExam}
          programId={this.props.editorForCourseOrExam === 'course' ? this.props.courseId : this.props.examId}
          ifAlreadyAdministed={if_already_administered}
        />

        <WelcomeModal
          open={this.props.first_view}
        />
      </main>
    }</Loading>
}

export default ProgramEditor;
