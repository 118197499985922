import $ from 'jquery';

// autofill inputs on CTRL+S in dev environment
const fakeFillOnCtrlP = (component, fakeFillFunction) => {
  // if (process.env.NODE_ENV === 'development') {
    component.prototype.componentDidMount = function () {
      $(document).on('keydown', (event) => {
        if (!this.props.readOnly) {
          // CTRL+P (as in Populate)
          if (event.key === 'p' && event.ctrlKey) {
            this.fakeFillInput();
          }
        }
      });
    };

    component.prototype.componentWillUnmount = function () {
      $(document).off('keydown');
    };

    component.prototype.fakeFillInput = fakeFillFunction;
  // }
};

export default fakeFillOnCtrlP;
