import React from 'react';
import PropTypes from 'prop-types';

import MultipleChoiceAnswers from './_partials/MultipleChoiceAnswers';
import SimpleAnswers from './_partials/SimpleAnswers';

import css from './index.scss';

class AnswersDiv extends React.Component {
  static propTypes = {
    itemType: PropTypes.string.isRequired,
    contentType: PropTypes.oneOf(['yes-no', 'true-false', 'multiple-choice', 'multiple-choice-2', 'nps']).isRequired,
    formState: PropTypes.object.isRequired,
    // When we click SAVE - update the content in the UI right away.
    updateFormState: PropTypes.func.isRequired
  }

  updateQuestionDetails = (questionDetails) =>
    this.props.updateFormState({
      ...this.props.formState,
      questionDetails
    })

  render = () => {
    switch (this.props.contentType) {
      case 'multiple-choice':
      case 'multiple-choice-2':
      case 'nps':
        return <MultipleChoiceAnswers
          itemType={this.props.itemType}
          className={css.div}
          questionDetails={this.props.formState.questionDetails}
          updateQuestionDetails={this.updateQuestionDetails}
          ifNps={this.props.contentType === 'nps'}
        />;
      case 'yes-no':
      case 'true-false':
        return <SimpleAnswers
          itemType={this.props.itemType}
          className={css.div}
          questionDetails={this.props.formState.questionDetails}
          updateQuestionDetails={this.updateQuestionDetails}
        />;
    }
  }
}

export default AnswersDiv;
