import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Utils from '~/services/Utils';
import VideoContent from './_partials/VideoContent';
import FileContent from './_partials/FileContent';
import QuestionContentContainer from './container/QuestionContainer';
import css from './index.scss';

class Contents extends React.Component {
  static propTypes = {
    module_item_content: PropTypes.array.isRequired,
    current_module: PropTypes.object.isRequired,
    user_role: PropTypes.string.isRequired,
    if_current_module_is_last: PropTypes.bool.isRequired,
    if_current_course_is_completed: PropTypes.bool.isRequired,
    pagenum: PropTypes.number.isRequired,
    num_pages: PropTypes.number.isRequired,
    uiUpdateContent: PropTypes.func.isRequired,
  }

  renderContent = (content) => {
    let contentComponent = null;
    const contentProps = {
      ...this.props,
      content,
      uiUpdateContent: this.props.uiUpdateContent,
    };
    switch (content.content_type) {
      case 'image':
        contentComponent = <div dangerouslySetInnerHTML={{ __html: content.payload }}/>;
        break;
      case 'video':
        contentComponent = <VideoContent content={content}/>;
        break;
      case 'file':
        contentComponent = <FileContent content={content}/>;
        break;
      case 'multiple-choice':
      case 'multiple-choice-2':
      case 'nps':
      case 'yes-no':
      case 'true-false':
      case 'likert':
      case 'dropdown':
      case 'rating-scale':
      case 'single-line-text':
      case 'multi-line-text':
        contentComponent = this.props.current_module.program_type !== 'Module' ?
          <QuestionContentContainer {...contentProps}/>
          : null;
        break;
      default: contentComponent = <p dangerouslySetInnerHTML={{ __html: content.payload }}/>;
    }
    return contentComponent;
  }

  render = () => {
    const {
      module_item_content,
      current_module,
      user_role,
      if_current_module_is_last,
      if_current_course_is_completed,
      num_pages,
      pagenum,
    } = this.props;
    return (
      <div className={`${css.contents} section-${current_module.program_type}`}>
        {module_item_content.map(content =>
          <div
            key={content.id}
            className={classNames(content.content_type, 'module-item', {
              student_incorrect: !content.student_correct,
              'survey-multi-line-text': content.content_type === 'multi-line-text'
            })}
          >
            {this.renderContent(content)}
          </div>
        )}
        {(user_role === 'student' && if_current_module_is_last && if_current_course_is_completed && pagenum === num_pages) &&
          <span className="continue-btn-disabled mobile-submit-test nonmobile-hidden" href="#">Program Submitted</span>
        }
      </div>
    );
  }
}

export default Contents;
