import React from 'react';
import PropTypes from 'prop-types';

class FromDeviceOrFromUrlNav extends React.Component {
  static propTypes = {
    typeOfUpload: PropTypes.oneOf(['fromDevice', 'fromUrl', false]).isRequired,
    updateTypeOfUpload: PropTypes.func.isRequired
  }

  renderButton = (typeOfUpload, label, iconClassName) =>
    <button
      className={`-${typeOfUpload} ${this.props.typeOfUpload === typeOfUpload ? '-selected' : '-not-selected'}`}
      type="button"
      onClick={() => this.props.updateTypeOfUpload(typeOfUpload)}
    >
      <i className={iconClassName}/>
      <label>{label}</label>
    </button>

  render = () =>
    <nav className="from-device-or-from-url-nav">
      {this.renderButton('fromDevice', 'My Device', 'fa fa-upload')}
      {this.renderButton('fromUrl', 'From Url', 'fa fa-link')}
    </nav>
}

export default FromDeviceOrFromUrlNav;
