import React from 'react';
import PropTypes from 'prop-types';

import HeadingTogglerAndModal from '../../_contentModals/HeadingTogglerAndModal';

class HeadingContentLi extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      content_type: PropTypes.oneOf(['heading']).isRequired,
      payload: PropTypes.string.isRequired
    }).isRequired,
    liProps: PropTypes.object.isRequired,
    uiUpdateContent: PropTypes.func.isRequired,
    uiDeleteContent: PropTypes.func.isRequired
  }

  renderLi = (content) =>
    <li {...this.props.liProps}>
      <h1>{content.payload}</h1>
    </li>

  render = () =>
    <HeadingTogglerAndModal
      toggler={this.renderLi(this.props.content)}
      content={this.props.content}
      afterContentIsUpdated={this.props.uiUpdateContent}
      afterContentIsDeleted={this.props.uiDeleteContent}
    />
}

export default HeadingContentLi;
