import React from 'react';
import PropTypes from 'prop-types';

import fakeFillOnCtrlP from './services/fakeFillOnCtrlP';
import LabelAndInput from './_partials/LabelAndInput';

class TextInput extends React.Component {
  static propTypes = {
    label: PropTypes.string, // not required when naked
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    formState: PropTypes.object.isRequired,
    updateFormState: PropTypes.func.isRequired,
    required: PropTypes.bool,
    naked: PropTypes.bool,
    type: PropTypes.string,
    readOnly: PropTypes.bool,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    maxLength: PropTypes.number,
    autoComplete: PropTypes.string
  }

  static defaultProps = {
    label: '',
    placeholder: '',
    required: false,
    naked: false,
    type: 'text',
    readOnly: false,
    autoComplete: 'off'
  }

  updateFormState = (event) =>
    this.props.updateFormState({
      ...this.props.formState,
      [this.props.name]: event.target.value
    })

  renderInput = () =>
    <input
      className={`standard-input -TextInput ${this.props.readOnly ? '-read-only' : ''}`}
      type={this.props.type}
      value={this.props.formState[this.props.name]}
      onChange={this.updateFormState}
      readOnly={this.props.readOnly}
      autoComplete={this.props.autoComplete}
      placeholder={this.props.placeholder}
      min={this.props.minValue}
      max={this.props.maxValue}
      maxLength={this.props.maxLength}
    />

  render = () => (
    this.props.naked ?
      this.renderInput() :
      <LabelAndInput
        className={'-' + this.props.name}
        label={this.props.label}
        required={this.props.required}
        inputEl={this.renderInput()}
      />
  )
}

fakeFillOnCtrlP(TextInput, function () {
  const randomString = (length) =>
    Math.random().toString(36).substring(2, length + 2);

  let value;
  if (this.props.type === 'email') {
    value = `${randomString(5)}@${randomString(3)}.${randomString(3)}`;
  } else if (this.props.type === 'number') {
    value = Math.floor(Math.random() * 10);
  } else {
    value = randomString(10);
  }

  this.updateFormState({ target: { value } });
});

export default TextInput;
