import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class RadioInput extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    readOnly: PropTypes.bool,
    inputProps: PropTypes.object,
    checked: PropTypes.bool.isRequired,
    updateChecked: PropTypes.func,
  }

  render = () =>
    <label
      className={classNames('standard-radio', {
        '-readonly': this.props.readOnly,
        '-no-label': !this.props.label,
      })}
    >
      {this.props.label ? this.props.label : ''}
      <input
        type="radio"
        name={this.props.name}
        value={this.props.value}
        onChange={this.props.updateChecked ? e => this.props.updateChecked(e) : () => {}}
        checked={this.props.checked}
        readOnly={this.props.readOnly}
        {...this.props.inputProps}
      />
      <span className="radio-checkmark"/>
    </label>
}

export default RadioInput;
