import React from 'react';
import PropTypes from 'prop-types';

import DropdownTogglerAndModal from '../../_contentModals/DropdownTogglerAndModal';

class DropdownContentLi extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      content_type: PropTypes.oneOf(['dropdown']).isRequired,
      payload: PropTypes.string.isRequired,
    }).isRequired,
    liProps: PropTypes.object.isRequired,
    uiUpdateContent: PropTypes.func.isRequired,
    uiDeleteContent: PropTypes.func.isRequired,
  }

  uiUpdateDropdownContent = (currentDropdownContent) =>
    this.setState({ currentDropdownContent });

  renderLi = () => {
    const { content } = this.props;
    return (
      <li {...this.props.liProps}>
        <label className="standard-label">{content.payload}</label>
        <div className="dropdown-wrapper">
          <button type="button">
            <input placeholder="Drop-down response options" readOnly/>
            <i className="fa fa-sort-down"/>
          </button>
        </div>
      </li>
    );
  }

  render = () =>
    <DropdownTogglerAndModal
      toggler={this.renderLi(this.props.content)}
      content={this.props.content}
      afterContentIsUpdated={this.props.uiUpdateContent}
      afterContentIsDeleted={this.props.uiDeleteContent}
    />
}

export default DropdownContentLi;
