import $ from 'jquery';

const amIOverflowingMyContainer = (element) =>
  element.get(0).offsetHeight < element.get(0).scrollHeight;

const setFontSize = (previewEl, fontSize) =>
  previewEl.css({ 'font-size': fontSize + 'px', 'line-height': fontSize + 5 + 'px' });

const maxFontSize = 29;
const minFontSize = 21;

// it can't be display: none, because then browser won't understand if it's overflowing. We could [probably] use virtual element instead, but let's leave it like this for now. -->
const previewWrapperCss = {
  'z-index': -10,
  'visibility': 'hidden'
};

const previewCss = {
  // this is dependent upon pdf.css, and should be the same
  'width': '826px',
  'height': '244px',
  'padding': '10px',
  'white-space': 'pre-wrap',
  'word-break': 'keep-all',
  'font-family': 'Arial',
};

// Every time text changes, we create an element and call getFittedText() on it starting from the largest font-size.
// => { ifTooMuchText: true, preHtml: '<pre style="font-size: 13px; width: 826px;">My Text!</pre>' }
const getFittedText = (textToFit) => {
  const previewWrapperEl = $(`<div><pre>${textToFit}</pre></div>`);
  const previewEl = previewWrapperEl.find('pre');

  previewWrapperEl.css(previewWrapperCss);
  previewEl.css(previewCss);

  setFontSize(previewEl, maxFontSize);

  previewWrapperEl.appendTo('body');

  let currentFontSize = maxFontSize;
  let result;
  while (true) {
    if (amIOverflowingMyContainer(previewEl)) {
      // text doesn't fit, but we can still reduce the font size
      if (currentFontSize > minFontSize) {
        currentFontSize -= 1;
        setFontSize(previewEl, currentFontSize);
        // if we don't check right here, we can enter an infinite loop
        if (!amIOverflowingMyContainer(previewEl)) {
          result = { ifTooMuchText: false, preHtml: previewWrapperEl.html() };
          break;
        }
      // text doesn't fit, and we can't keep on reducing font size
      } else {
        result = { ifTooMuchText: true, preHtml: previewWrapperEl.html() };
        break;
      }
    } else {
      // everything fits nicely, but let's try to increase the font size
      if (currentFontSize < maxFontSize) {
        currentFontSize += 1;
        setFontSize(previewEl, currentFontSize);
      // everything fits nicely, and font-size is the proper original 13pxs
      } else {
        result = { ifTooMuchText: false, preHtml: previewWrapperEl.html() };
        break;
      }
    }
  }

  previewWrapperEl.detach();

  return result;
};

export default getFittedText;
