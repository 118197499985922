import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel } from '@material-ui/core';
import classNames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import _ from 'lodash/core';

import Constants from '~/services/Constants';
import Utils from '~/services/Utils';
import Urls from '~/services/Urls';
import StudentApi from '~/api/StudentApi';
import BulkInviteStudentsTogglerAndModal from '~/_appPartials/BulkInviteStudentsTogglerAndModal';
import { Checkbox } from '~/_partials/standardForm';
import SelectDropdown from '~/_partials/SelectDropdown';
import StandardAlert from '~/_partials/StandardAlert';
import StandardTooltip from '~/_partials/StandardTooltip';
import ConfirmTogglerAndModal from '~/pages/Modals/ConfirmTogglerAndModal';
import LINK_ICON_PNG from '~/../static/frontend/img/link-icon.png';
import AddCustomTimeTogglerAndModal from '../../Modals/AddCustomTimeTogglerAndModal';
import AddStudentTogglerAndModal from '../../Modals/AddStudentTogglerAndModal';

import css from './index.scss';

const headers = {
  fullname: { id: 'fullname', order: 'asc' },
  email: { id: 'email', order: 'asc' },
  status: { id: 'status', order: 'asc' },
};

const STUDENT_TYPES = {
  current: 'current',
  invite: 'invite',
};

class StudentTabContent extends React.Component {
  static propTypes = {
    course_id: PropTypes.number.isRequired,
    course_active: PropTypes.bool.isRequired,
    open_invite_hash: PropTypes.string.isRequired,
    uiUpdateStudentsInvited: PropTypes.func.isRequired,
  };

  static defaultProps = {
    students_in_course: []
  }

  state = {
    all_students: [],
    students_in_course: [],
    groups: [],
    orderBy: 'fullname',
    ifActionsDropdownIsOpen: false,
    ifLinkCopied: false,
    actionResultMessage: '',
    formState: {
      if_all_selected: false,
    },
    ifLoadingFinished: false,
  }

  componentDidMount() {
    this.apiGetStudents()
      .then((payload) => {
        this.setState({
          all_students: payload.allStudents,
          ifLoadingFinished: true,
        });
        this.uiUpdateStudents(payload.studentsInProgram);
      });
    this.apiGetGroups()
      .then((payload) => {
        this.setState({ groups: payload.groups });
      });
  }

  apiGetStudents = () =>
    StudentApi.getProgramStudents(
      false, {
      program_id: this.props.course_id,
    })

  apiGetGroups = () =>
    StudentApi.getGroups(false)

  apiSetStudentCustomTime = (student_id, custom_time) =>
    StudentApi.setExtraTime(
      false, {
      program_id: this.props.course_id,
      student: student_id,
      custom_time,
    })

  apiReissueCourse = (student_id) =>
    StudentApi.reissueCourse(
      false, {
      program_id: this.props.course_id,
      student_id,
    })

  apiSendReminder = (student_id) =>
    StudentApi.sendReminder(
      false, {
      program_id: this.props.course_id,
      student_id,
    })

  apiRemoveStudent = (student) =>
    StudentApi.removeStudent(
      false, {
      program_id: this.props.course_id,
      type: student.type,
      student_id: student.id,
    })

  stableSort = (array) => {
    const { orderBy } = this.state;
    const headerOrder = headers[orderBy].order;
    const stabilizedThis = array.map((el, index) => {
      const sortableObject = {
        ...el,
      };
      sortableObject.fullname = this.getStudentFullname(el);
      sortableObject.email = el.email;
      sortableObject.status = this.getStudentStatus(el);
      const sortableArray = [sortableObject, index];
      return sortableArray;
    });

    stabilizedThis.sort((a, b) => {
      const order = Utils.getSorting(headerOrder, orderBy)(a[0], b[0]);

      if (order !== 0) {
        return order;
      }

      return a[1] - b[1];
    });

    return stabilizedThis.map(el => el[0]);
  }

  handleRequestOrder = (orderId) => {
    let order = 'asc';

    if (headers[orderId].order === 'asc' && orderId === this.state.orderBy) {
      order = 'desc';
    }

    headers[orderId].order = order;
    this.setState({ orderBy: orderId });
  }

  getStudentFullname = (student) =>
    `${student.first_name} ${student.last_name}`;

  getStudentStatus = (student) => {
    if (student.type === STUDENT_TYPES.current) {
      return student.is_active ? 'Active' : 'Inactive';
    } else {
      return 'Inactive';
    }
  }

  getSelectedStudents = () => {
    const { students_in_course, formState } = this.state;
    const allSelected = formState['if_all_selected'];
    let selectedStudents = [];

    if (allSelected) {
      selectedStudents = students_in_course;
    } else {
      _.keys(formState).forEach(formKey => {
        if (formState[formKey]) {
          const findStudent = students_in_course.find(student => `student_${student.id}` === formKey);
          selectedStudents.push(findStudent);
        }
      });
    }

    return selectedStudents;
  }

  updateFormState = (formState) => {
    const { students_in_course } = this.state;
    const selectedKeys = _.keys(formState).filter(key => key !== 'if_all_selected' && formState[key]);

    if (selectedKeys.length > 0 && selectedKeys.length === students_in_course.length) {
      formState['if_all_selected'] = true;
    } else {
      formState['if_all_selected'] = false;
    }

    this.setState({ formState });
  }

  uiUpdateStudentDropdownOpen = (updatedStudent, ifDropdownOpen) => {
    const { students_in_course } = this.state;
    const updatedIndex = students_in_course.findIndex(student => student.id === updatedStudent.id);
    this.setState({
      students_in_course: [
        ...students_in_course.slice(0, updatedIndex),
        {
          ...students_in_course[updatedIndex],
          ifDropdownOpen,
        },
        ...students_in_course.slice(updatedIndex + 1),
      ]
    });
  }

  addCustomTimeToStudent = (studentId, customTime) => {
    const convertedCustomTime = Utils.convertTime(customTime.hour, customTime.minute);
    this.apiSetStudentCustomTime(studentId, convertedCustomTime).then(() =>
      this.setState({ actionResultMessage: 'Custom program time added for students.' })
    );
  }

  reissueCourse = (students) => {
    const promises = students.map(student => this.apiReissueCourse(student.id));
    Promise.all(promises).then(() =>
      this.setState({
        actionResultMessage: 'Program reissued to students successfully.'
      }));
  }

  uiRemoveStudent = (selectedStudents) => {
    const { students_in_course, formState } = this.state;
    const promises = selectedStudents.map(student => this.apiRemoveStudent(student));
    Promise.all(promises).then(() => {
      const removedIds = selectedStudents.map(student => student.id);
      const newStudents_in_course = students_in_course.filter(student => !removedIds.includes(student.id));
      const newFormState = {
        ...formState,
      };
      removedIds.forEach(removedId => newFormState[`student_${removedId}`] = false);
      this.setState({
        students_in_course: newStudents_in_course,
        formState: newFormState,
      });
      this.props.uiUpdateStudentsInvited(newStudents_in_course.length > 0);
    });
  }

  sendReminder = (students) => {
    const promises = students.map(student => this.apiSendReminder(student.id));
    Promise.all(promises).then(() => this.setState({ actionResultMessage: 'Program reminder sent to students.' }));
  }

  uiSelectAllStudents = (formState) => {
    const allSelected = formState['if_all_selected'];
    _.keys(formState).forEach(key =>
      formState[key] = allSelected
    );
    this.setState({ formState });
  }

  uiUpdateDropdownToggle = () => {
    this.setState({ ifActionsDropdownIsOpen: !this.state.ifActionsDropdownIsOpen });
  }

  uiUpdateAllStudents = (all_students) => {
    this.setState({ all_students });
  }

  uiUpdateStudents = (students_in_course) => {
    const { formState } = this.state;
    const newFormState = {
      ...formState
    };
    students_in_course.forEach(student => {
      student.ifDropdownOpen = false;
      newFormState[`student_${student.id}`] = formState[`student_${student.id}`] ? formState[`student_${student.id}`] : false;
    });
    this.setState({
      students_in_course,
      formState: newFormState,
    });
    this.props.uiUpdateStudentsInvited(students_in_course.length > 0);
  };

  uiAddStudentsToCourse = (students) => {
    this.setState({ actionResultMessage: 'Students added to this course.' });
    this.uiUpdateStudents(students);
  }

  handleCopyClick = () => {
    this.input.focus();
    this.input.setSelectionRange(0, 9999);
    this.setState({ ifLinkCopied: true });
  }

  renderAddStudentToggler = () =>
    <button
      className="standard-button -underline btn-add-student"
      disabled={!this.props.course_active}
    >
      + Add Students
      {!this.props.course_active &&
        <StandardTooltip
          className="standard-info-tooltip"
          tooltipEl="This program is not currently Active. Please Activate the program to being adding students."
        />
      }
    </button>

  renderRemoveBulkToggler = () =>
    <a>Remove Student</a>

  renderReissueBulkToggler = () =>
    <a>Reissue Program</a>

  renderSendReminderBulkToggler = () =>
    <a>Send Reminder</a>

  renderCustomTimeToggler = (active) =>
    <li className={!active ? 'disabled' : ''}><a>Add Custom Time</a></li>

  renderReissueToggler = () =>
    <li><a>Reissue Program</a></li>

  renderDeleteToggler = () =>
    <li><a>Remove From Program</a></li>

  renderSendReminderToggler = (active) =>
    <li className={!active ? 'disabled' : ''}><a>Send Reminder</a></li>

  renderBulkUploadToggler = () =>
    <button className="standard-button -plum">Bulk Upload</button>

  render = () => {
    const { course_id, course_active, open_invite_hash } = this.props;
    const courseUrl = (typeof window !== 'undefined') ? location.protocol + '//' + location.host + Urls.getCourseInviteUrl(open_invite_hash) : '';
    const { all_students, students_in_course, groups, orderBy, ifActionsDropdownIsOpen, ifLinkCopied, formState, ifLoadingFinished, actionResultMessage } = this.state;
    const formProps = {
      formState,
      updateFormState: this.updateFormState
    };
    const selectedStudents = this.getSelectedStudents();
    const selectedActiveStudents = selectedStudents.filter(student => student.type === STUDENT_TYPES.current);
    return (
      <div className={css.studentContainer}>
        <div className="student-header">
          <h4>Open Enrollment Link</h4>
          {course_active &&
            <div className="enrollment">
              <a href={courseUrl} target="_blank" rel="noopener noreferrer">
                <img src={LINK_ICON_PNG}/>
              </a>
              <input
                value={courseUrl}
                onChange={() => {}}
                onClick={e => course_active ? e.target.setSelectionRange(0, 9999) : {}} ref={input => this.input = input}
                readOnly
              />

              <div className="enrollment-buttons">
                <CopyToClipboard text={courseUrl} onCopy={this.handleCopyClick}>
                  <button className="standard-button -plum -small">{!ifLinkCopied ? 'Copy' : 'Copied'}</button>
                </CopyToClipboard>
              </div>

            </div>
          }
          {!course_active &&
            <div className="enrollment">
              <span>This program is not currently Active. This link will only become available to students on an Active program</span>
            </div>
          }
          <div className="actions">
            <div className="left-actions">
              <div className="bulk-dropdown">
                <button
                  type="button"
                  className="standard-menu-button -bulk btn-bulk-action"
                  onClick={this.uiUpdateDropdownToggle}
                >
                  Bulk Actions
                  <i className="fa fa-angle-down"/>
                </button>
                <SelectDropdown open={ifActionsDropdownIsOpen} onCloseDropdown={this.uiUpdateDropdownToggle}>
                  <li
                    className={selectedStudents.length === 0 ? 'disabled' : ''}
                  >
                    <ConfirmTogglerAndModal
                      title="Are you sure you want to remove these students from the course?"
                      toggler={this.renderRemoveBulkToggler()}
                      onClose={() => this.uiRemoveStudent(selectedStudents)}
                    />
                  </li>
                  <li
                    className={selectedStudents.length === 0 ? 'disabled' : ''}
                  >
                    <ConfirmTogglerAndModal
                      title="Are you sure you want to reissue this program?"
                      toggler={this.renderReissueBulkToggler()}
                      reissuable={true}
                      onClose={() => this.reissueCourse(selectedStudents)}
                    />
                  </li>
                  <li
                    className={selectedActiveStudents.length === 0 ? 'disabled' : ''}
                  >
                    <ConfirmTogglerAndModal
                      title="Are you sure you want to send reminder to these students?"
                      toggler={this.renderSendReminderBulkToggler()}
                      onClose={() => this.sendReminder(selectedActiveStudents)}
                    />
                  </li>
                </SelectDropdown>
              </div>
              <AddStudentTogglerAndModal
                toggler={this.renderAddStudentToggler()}
                program_id={course_id}
                allStudents={all_students}
                studentsInCourse={students_in_course}
                groups={groups}
                uiUpdateAllStudents={this.uiUpdateAllStudents}
                uiUpdateStudents={this.uiAddStudentsToCourse}
              />
            </div>
            <BulkInviteStudentsTogglerAndModal toggler={this.renderBulkUploadToggler()}/>
          </div>
        </div>
        <div className="table-wrapper">
          <Table>
            <TableHead>
              <TableRow classes={{ root: 'standard-table-header-row' }}>
                <TableCell padding="checkbox" classes={{ root: 'standard-table-head-cell -cell-width-cs3' }}>
                  <Checkbox
                    name="if_all_selected"
                    outline={true}
                    checkmark={false}
                    formState={formProps.formState}
                    updateFormState={this.uiSelectAllStudents}
                  />
                </TableCell>
                <TableCell classes={{ root: 'standard-table-head-cell -cell-width-cs1' }}>
                  <TableSortLabel
                    classes={{
                      root: 'standard-table-header-sort-label',
                      active: 'label-active',
                      icon: 'sort-icon',
                    }}
                    active={orderBy === headers.fullname.id}
                    direction={headers.fullname.order}
                    onClick={() => this.handleRequestOrder(headers.fullname.id)}
                  >
                    Full Name
                  </TableSortLabel>
                </TableCell>
                <TableCell classes={{ root: 'standard-table-head-cell -cell-width-cs2' }}>
                  <TableSortLabel
                    classes={{
                      root: 'standard-table-header-sort-label',
                      active: 'label-active',
                      icon: 'sort-icon',
                    }}
                    active={orderBy === headers.email.id}
                    direction={headers.email.order}
                    onClick={() => this.handleRequestOrder(headers.email.id)}
                  >
                    Email
                  </TableSortLabel>
                </TableCell>
                <TableCell classes={{ root: 'standard-table-head-cell status-cell' }}>
                  <TableSortLabel
                    classes={{
                      root: 'standard-table-header-sort-label',
                      active: 'label-active',
                      icon: 'sort-icon',
                    }}
                    active={orderBy === headers.status.id}
                    direction={headers.status.order}
                    onClick={() => this.handleRequestOrder(headers.status.id)}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell classes={{ root: 'standard-table-head-cell' }}>
                  <TableSortLabel
                    classes={{
                      root: 'standard-table-header-sort-label -no-border',
                    }}
                  >
                    Action
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.stableSort(students_in_course).map((student, index) => (
                <TableRow key={index} classes={{ root: 'standard-table-body-row -checkbox' }}>
                  <TableCell padding="checkbox" classes={{ root: 'standard-table-body-cell' }}>
                    <div className="checkbox-wrapper">
                      <Checkbox
                        name={`student_${student.id}`}
                        outline={true}
                        checkmark={false}
                        {...formProps}
                      />
                    </div>
                  </TableCell>
                  <TableCell classes={{ root: 'standard-table-body-cell' }}>
                    <div className="standard-table-cell-div">{this.getStudentFullname(student)}</div>
                  </TableCell>
                  <TableCell classes={{ root: 'standard-table-body-cell' }}>
                    <div className="standard-table-cell-div">{student.email}</div>
                  </TableCell>
                  <TableCell classes={{ root: 'standard-table-body-cell' }}>
                    <div
                      className={classNames('standard-table-cell-div -status', {
                        '-inactive': (this.getStudentStatus(student) === 'Inactive'),
                      })
                    }
                    >
                      {this.getStudentStatus(student)}
                    </div>
                  </TableCell>
                  <TableCell classes={{ root: 'standard-table-body-cell' }}>
                    <div className="standard-table-cell-div -action action-cell-div">
                      <button
                        type="button"
                        className="standard-menu-button -bulk btn-student-action"
                        onClick={() => this.uiUpdateStudentDropdownOpen(student, !student.ifDropdownOpen)}
                      >
                        Actions
                        <i className="fa fa-angle-down"/>
                      </button>
                      <SelectDropdown open={student.ifDropdownOpen} onCloseDropdown={() => this.uiUpdateStudentDropdownOpen(student, false)}>
                        <AddCustomTimeTogglerAndModal
                          toggler={this.renderCustomTimeToggler(student.type === STUDENT_TYPES.current)}
                          onSave={customTime => this.addCustomTimeToStudent(student.id, customTime)}
                        />
                        <ConfirmTogglerAndModal
                          title="Are you sure you want to reissue this program?"
                          toggler={this.renderReissueToggler()}
                          reissuable={true}
                          onClose={() => this.reissueCourse([student])}
                        />
                        <ConfirmTogglerAndModal
                          title="Are you sure you want to remove this student from the course?"
                          toggler={this.renderDeleteToggler()}
                          onClose={() => this.uiRemoveStudent([student])}
                        />
                        <ConfirmTogglerAndModal
                          title="Are you sure you want to send reminder to this student?"
                          toggler={this.renderSendReminderToggler(student.type === STUDENT_TYPES.current)}
                          onClose={() => this.sendReminder([student])}
                        />
                      </SelectDropdown>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {ifLoadingFinished && students_in_course.length === 0 &&
            <div className="empty-students">This program does not have any students.</div>
          }
        </div>

        <StandardAlert
          open={actionResultMessage !== ''}
          type={Constants.ALERT_TYPE.success}
          message={actionResultMessage}
          onClose={() => this.setState({ actionResultMessage: '' })}
        />
      </div>
    );
  }
}

export default StudentTabContent;
