import React from 'react';
import PropTypes from 'prop-types';

import fakeFillOnCtrlP from './services/fakeFillOnCtrlP';
import LabelAndInput from './_partials/LabelAndInput';

class Select extends React.Component {
  static propTypes = {
    label: PropTypes.string, // not required when naked
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    formState: PropTypes.object.isRequired,
    updateFormState: PropTypes.func.isRequired,
    required: PropTypes.bool,
    naked: PropTypes.bool,
    readOnly: PropTypes.bool,
    autoComplete: PropTypes.string,
  }

  static defaultProps = {
    label: '',
    placeholder: '',
    required: false,
    naked: false,
    readOnly: false,
    autoComplete: 'off'
  }

  updateFormState = (event) =>

    this.props.updateFormState({
      ...this.props.formState,
      [this.props.name]: event.target.value
    })

  renderInput = () =>
    <select
      className={`standard-input -SelectInput ${this.props.readOnly ? '-read-only' : ''}`}

      onChange={this.updateFormState}
      readOnly={this.props.readOnly}
      autoComplete={this.props.autoComplete}
      placeholder={this.props.placeholder}
      defaultValue="No custom field specified."
    >
      {<option>No custom field specified.</option>}
      {this.props.formState.options.map(option =>
        <option value={option} key={option} selected={this.props.formState[this.props.name] === option}>{option}</option>
      )}
    </select>

  render = () => (
    this.props.naked ?
      this.renderInput() :
      <LabelAndInput
        className={'-' + this.props.name}
        label={this.props.label}
        required={this.props.required}
        inputEl={this.renderInput()}
      />
  )
}

fakeFillOnCtrlP(Select, function () {
  const randomString = (length) =>
    Math.random().toString(36).substring(2, length + 2);

  const value = randomString(10);

  this.updateFormState({ target: { value } });
});

export default Select;
