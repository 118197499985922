// ___Why not use already-used exceljs?
//    Because I couldn't make it worksheet.xlsx.read(with something other than Stream).
//    We can find how to read file as stream on input[type=file], but I decided to use xlsx for now.
import * as XLSX from 'xlsx';

// Reads FIRST worksheet of the file's spreadsheet as an array of hashes
//
// @param file - event.target.files[0]
// => Promise([{ Region: 'Caribbean', ... }, {}])
const readUploadedExcelFile = (file, { sheetToJsonOptions = {} } = {}) =>
  new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      const data = new Uint8Array(event.target.result);

      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      // ___Docs?
      //    https://docs.sheetjs.com/#json
      // ___What values can range be?
      //    (number)  Use worksheet range but set starting row to the value
      //    (string)  Use specified range (A1-style bounded range string)
      //    (default) Use worksheet range (ws['!ref'])
      resolve(XLSX.utils.sheet_to_json(worksheet, sheetToJsonOptions));
    };
    fileReader.readAsArrayBuffer(file);
  });

export default readUploadedExcelFile;
