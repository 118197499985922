import React from 'react';
import PropTypes from 'prop-types';

import createAndDownloadExcelFile from '~/services/createAndDownloadExcelFile';
import readUploadedExcelFile from '~/services/readUploadedExcelFile';
import SpeCreator from '~/services/SpeCreator';
import disableOnSpeRequest from '~/services/disableOnSpeRequest';
import InvitationApi from '~/api/InvitationApi';

import Loading from '~/_partials/Loading';
import TogglerAndModal from '~/_partials/TogglerAndModal';

import css from './index.scss';

class BulkInviteStudentsTogglerAndModal extends React.Component {
  static propTypes = {
    programId: PropTypes.number,
    toggler: PropTypes.element,
  }

  state = {
    speBulkInvite: SpeCreator.empty()
  }

  afterModalCloses = () => {
    this.setState({ speBulkInvite: SpeCreator.empty() });
  }

  uiGenerateAndDownloadExcelTemplate = () => {
    const titles = [{
      'Email': null,
      'First Name': null,
      'Last Name': null,
      'Navigator ID': null,
      'Residence/Apartment Number': null,
      'Country': null,
      'Gender': null,
      'Birth Date': null,
      'Total Residences': null,
      'Number of Apartments': null,
      'SqFt': null,
      'Owner Since': null
    }];
    							
    createAndDownloadExcelFile(titles, {
      fileName: 'BulkStudentUpload.xlsx',
      worksheetName: 'Students'
    });
  }

  apiInviteStudents = (arrayOfInvitations) =>
    InvitationApi.bulkInviteStudents(
      (spe) => this.setState({ speBulkInvite: spe }),
      arrayOfInvitations,
      { programId: this.props.programId || null }
    )

  uiReadExcelFileAndApiInviteStudents = (event) => {
    readUploadedExcelFile(event.target.files[0])
      .then((arrayOfHashes) => {
        const arrayOfInvitations = arrayOfHashes.map((hash) => ({
          email: hash['Email'] || '',
          first_name: hash['First Name'] || '',
          last_name: hash['Last Name'] || '',
          navigator_id: hash['Navigator ID'] || '',
          residence_apartment_number: hash['Residence/Apartment Number'] || '',
          country: hash['Country'] || '',
          gender: hash['Gender'] || '',
          birth_date: hash['Birth Date'] || '',
          total_residences: hash['Total Residences'] || '',
          number_of_apartments: hash['Number of Apartments'] || '',
          sqft: hash['SqFt'] || '',
          owner_since: hash['Owner Since'] || '',
        }));
        this.apiInviteStudents(arrayOfInvitations);
      });

    // Aand clear the input to enabled reuploads,
    // Otherwise input would do nothing onclick
    event.target.value = null;
  }

  renderToggler = () =>
    <button className={`standard-button -theme-bg ${css.toggler}`} type="button">
      <i className="fa fa-upload"/>
      Bulk Upload
    </button>

  renderStep1 = () =>
    <li className="step-1">
      <h1>STEP 1</h1>

      <button
        className="standard-button -theme-bg"
        type="button"
        onClick={this.uiGenerateAndDownloadExcelTemplate}
      >
        <i className="fa fa-download"/>
        Download
      </button>

      <div className="subscript">Download Excel Template</div>
    </li>

  renderStep2 = () =>
    <li className="step-2">
      <h1>STEP 2</h1>

      <label className="standard-button -theme-bg">
        <input
          style={{ display: 'none' }}
          type="file"
          onChange={this.uiReadExcelFileAndApiInviteStudents}
        />
        <i className="fa fa-upload"/>
        Upload
      </label>

      <div className="subscript">Upload Excel Template</div>
    </li>

  renderStep3 = () =>
    <li className={`step-3 ${this.state.speBulkInvite.status === 'success' ? '-students-already-invited' : '-students-not-yet-invited'}`}>
      <h1>STEP 3</h1>

      <i className="fa fa-check"/>

      <div
        onClick={() => this.state.speBulkInvite.status === 'success' && location.reload()}
        className="subscript"
      >View Results</div>
    </li>

  render = () =>
    <TogglerAndModal
      toggler={this.props.toggler ? this.props.toggler : this.renderToggler()}
      afterModalCloses={this.afterModalCloses}
    >{(closeModal, renderCloseButton) =>
      <div className={`standard-modal ${css.modal}`}>
        {renderCloseButton()}

        <div className="standard-modal-header">
          Bulk Upload
        </div>

        <div className="standard-modal-main" style={disableOnSpeRequest(this.state.speBulkInvite)}>
          <ul className="steps">
            {this.renderStep1()}
            {this.renderStep2()}
            {this.renderStep3()}
          </ul>
        </div>

        {
          this.state.speBulkInvite.status &&
          <div className="standard-modal-footer">
            <Loading spe={this.state.speBulkInvite}>{({ amount_of_students_invited, errors }) =>
              <div className="bulk-invite-results">
                <div className="amount-of-students-invited">
                  {
                    this.props.programId ?
                      `Number of students successfully invited to this program: ${amount_of_students_invited}` :
                      `Number of students successfully invited: ${amount_of_students_invited}`
                  }
                </div>
                <ul className="errors">
                  {errors.map((error, index) =>
                    <li key={index}>{error}</li>
                  )}
                </ul>
              </div>
            }</Loading>
          </div>
        }
      </div>
    }</TogglerAndModal>
}

export default BulkInviteStudentsTogglerAndModal;
