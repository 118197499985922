import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableHead, TableBody, TableRow, TableCell
} from '@material-ui/core';
import moment from 'moment';
import classNames from 'classnames';
import _ from 'lodash/core';

import Constants from '~/services/Constants';
import Loading from '~/_partials/Loading';
import SpeCreator from '~/services/SpeCreator';
import Utils from '~/services/Utils';
import Urls from '~/services/Urls';
import SelectDropdown from '~/_partials/SelectDropdown';
import StandardAlert from '~/_partials/StandardAlert';
import ProgramApi from '~/api/ProgramApi';
import StudentApi from '~/api/StudentApi';
import ConfirmTogglerAndModal from '~/pages/Modals/ConfirmTogglerAndModal';
import ChartView from '../../../../ChartView';
import AddCustomTimeTogglerAndModal from '../../../../Modals/AddCustomTimeTogglerAndModal';

import css from './index.scss';

const RESULT_DATA = {
  statistics: {
    started: { name: 'Administrations', value: 0 },
    all_enrolled: { name: 'Students', value: 0 },
    mean: { name: 'Mean', value: 0 },
    median: { name: 'Median', value: 0 },
    modes: { name: 'Mode', value: 0 },
  },
  scoring: {
    number_of_scores_passed: 0,
    number_of_scores_failed: 0,
  },
};

class ResultCourseContent extends React.Component {
  static propTypes = {
    course_id: PropTypes.number.isRequired,
    course_latest_id: PropTypes.number.isRequired,
    course_status: PropTypes.object.isRequired,
  };

  static defaultProps = {
    course_status: {
      scoring: {
        ...RESULT_DATA.scoring,
        mean: RESULT_DATA.statistics.mean.value,
        median: RESULT_DATA.statistics.median.value,
        modes: RESULT_DATA.statistics.modes.value,
      }
    },
  };

  state = {
    spePage: SpeCreator.empty(),
    datatable_rows: [],
    actionResultMessage: '',
  }

  componentDidMount() {
    this.uiUpdateDataTable();
  }

  apiGetPage = () =>
    ProgramApi.getResults(
      (spe) => this.setState({ spePage: spe }),
      this.props.course_id,
    )

  apiSetStudentCustomTime = (student_id, custom_time) =>
    StudentApi.setExtraTime(
      false, {
      program_id: this.props.course_id,
      student: student_id,
      custom_time,
    })

  apiReissueCourse = (student_id) =>
    StudentApi.reissueCourse(
      false, {
      program_id: this.props.course_id,
      student_id,
    })

  apiRemoveStudent = (student) =>
    StudentApi.removeStudent(
      false, {
      program_id: this.props.course_id,
      type: student.type,
      student_id: student.id,
    })

  getScoringPercentWidth = (scoringValue) => {
    const scoringSum = RESULT_DATA.scoring.number_of_scores_passed + RESULT_DATA.scoring.number_of_scores_failed;
    return scoringValue > 0 ? `${scoringValue / scoringSum * 100}%` : 0;
  }

  uiUpdateDataTable = (ifAfterReissued = false) => {
    this.apiGetPage()
      .then(response => {
        this.setState({
          datatable_rows: response.datatable_rows.map(row => {
            row.ifDropdownOpen = false;
            return row;
          }),
          actionResultMessage: ifAfterReissued ? 'Program reissued to students successfully.' : '',
        });
      });
  };

  uiRemoveStudent = (tableRow) => {
    const { datatable_rows } = this.state;
    this.apiRemoveStudent(tableRow)
      .then(() => {
        this.setState({
          datatable_rows: datatable_rows.filter(row => row.id !== tableRow.id)
        });
      });
  }

  uiUpdateTableRowDropdownToggle = (updatedRowIndex, ifDropdownOpen) => {
    const { datatable_rows } = this.state;
    this.setState({
      datatable_rows: [
        ...datatable_rows.slice(0, updatedRowIndex),
        {
          ...datatable_rows[updatedRowIndex],
          ifDropdownOpen,
        },
        ...datatable_rows.slice(updatedRowIndex + 1),
      ]
    });
  }

  uiUpdateProgramReissued = (studentId) => {
    this.apiReissueCourse(studentId)
      .then(() =>
        this.uiUpdateDataTable(true)
      );
  }

  uiAddCustomTimeToStudent = (studentId, customTime) => {
    const convertedCustomTime = Utils.convertTime(customTime.hour, customTime.minute);
    this.apiSetStudentCustomTime(studentId, convertedCustomTime)
      .then(() => this.setState({ actionResultMessage: 'Custom program time added for students.' }));
  }

  renderCustomTimeToggler = () =>
    <li><a>Add Custom Time</a></li>

  renderReissueToggler = () =>
    <li><a>Reissue Program</a></li>

  renderDeleteToggler = () =>
    <li><a>Remove From Program</a></li>

  renderTable = () => {
    const { datatable_rows } = this.state;
    return (
      <div className="table-wrapper">
        <Table>
          <TableHead>
            <TableRow classes={{ root: 'standard-table-header-row results-header-row' }}>
              <TableCell classes={{ root: 'standard-table-head-cell -no-padding cell-results-fullname' }}>
                <div className="standard-header-cell-div fullname-cell-div">
                  Full Name
                </div>
              </TableCell>
              <TableCell classes={{ root: 'standard-table-head-cell -no-padding' }}>
                <div className="standard-header-cell-div">
                  Sections Completed
                </div>
              </TableCell>
              <TableCell classes={{ root: 'standard-table-head-cell -no-padding' }}>
                <div className="standard-header-cell-div">
                  Video Progress
                </div>
              </TableCell>
              <TableCell classes={{ root: 'standard-table-head-cell -no-padding' }}>
                <div className="standard-header-cell-div">
                  Student Score
                </div>
              </TableCell>
              <TableCell classes={{ root: 'standard-table-head-cell -no-padding' }}>
                <div className="standard-header-cell-div">
                  Time Spent
                </div>
              </TableCell>
              <TableCell classes={{ root: 'standard-table-head-cell -no-padding' }}>
                <div className="standard-header-cell-div">
                  Date Completed
                </div>
              </TableCell>
              <TableCell classes={{ root: 'standard-table-head-cell -no-padding' }}>
                <div className="standard-header-cell-div">
                  Free Response
                </div>
              </TableCell>
              <TableCell classes={{ root: 'standard-table-head-cell -no-padding' }}>
                <div className="standard-header-cell-div action-header-cell-div">
                  Action
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {datatable_rows.map((tableRowInfo, index) => (
              <TableRow key={tableRowInfo.id} classes={{ root: 'results-table-body-row' }}>
                <TableCell classes={{ root: 'standard-table-body-cell' }}>
                  <div className="standard-table-cell-div">{`${tableRowInfo.first_name} ${tableRowInfo.last_name}`}</div>
                </TableCell>
                <TableCell classes={{ root: 'standard-table-body-cell' }}>
                  <div className="standard-table-cell-div">{tableRowInfo.amount_of_modules_started}</div>
                </TableCell>
                <TableCell classes={{ root: 'standard-table-body-cell' }}>
                  <div className="standard-table-cell-div">{tableRowInfo.video_progress}</div>
                </TableCell>
                <TableCell classes={{ root: 'standard-table-body-cell' }}>
                  <div className="standard-table-cell-div">{tableRowInfo.date_completed ? tableRowInfo.score : 'N/A'}</div>
                </TableCell>
                <TableCell classes={{ root: 'standard-table-body-cell' }}>
                  <div className="standard-table-cell-div">{tableRowInfo.time_spent}</div>
                </TableCell>
                <TableCell classes={{ root: 'standard-table-body-cell' }}>
                  <div className="standard-table-cell-div time-cell-div">{tableRowInfo.date_completed ? `${moment(tableRowInfo.date_completed).format('MMM DD, YYYY, h:mm a')}. EST` : 'N/A'}</div>
                </TableCell>
                <TableCell classes={{ root: 'standard-table-body-cell' }}>
                  <div className="standard-table-cell-div response-cell-div">
                    {tableRowInfo.date_completed && tableRowInfo.free_response_question_total > 0 ? (<a href={Urls.getFreeResponseUrl(this.props.course_latest_id, tableRowInfo.id)}>{tableRowInfo.free_response_question_scored}/{tableRowInfo.free_response_question_total} Scored</a>) : 'N/A'}
                  </div>
                </TableCell>
                <TableCell classes={{ root: 'standard-table-body-cell' }}>
                  <div className="standard-table-cell-div action-cell-div">
                    <button
                      type="button"
                      className="standard-menu-button -bulk btn-row-action"
                      onClick={() => this.uiUpdateTableRowDropdownToggle(index, !tableRowInfo.ifDropdownOpen)}
                    >
                      Actions
                      <i className="fa fa-angle-down"/>
                    </button>
                    <SelectDropdown open={tableRowInfo.ifDropdownOpen} onCloseDropdown={() => this.uiUpdateTableRowDropdownToggle(index, false)}>
                      <AddCustomTimeTogglerAndModal
                        toggler={this.renderCustomTimeToggler()}
                        onSave={customTime => this.uiAddCustomTimeToStudent(tableRowInfo.id, customTime)}
                        onClose
                      />
                      <ConfirmTogglerAndModal
                        title="Are you sure you want to reissue this program?"
                        toggler={this.renderReissueToggler()}
                        onClose={() => this.uiUpdateProgramReissued(tableRowInfo.id)}
                      />
                      <ConfirmTogglerAndModal
                        title="Are you sure you want to remove this student from the program?"
                        toggler={this.renderDeleteToggler()}
                        onClose={() => this.uiRemoveStudent(tableRowInfo)}
                      />
                    </SelectDropdown>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {datatable_rows.length === 0 &&
          <div className="standard-empty-content -table-content">
            <p>This program does not have any results</p>
          </div>
        }
      </div>
    );
  }

  render() {
    const { course_status } = this.props;
    const { actionResultMessage } = this.state;
    RESULT_DATA.statistics.started.value = course_status.amount_of_students.started;
    RESULT_DATA.statistics.all_enrolled.value = course_status.amount_of_students.all_enrolled;
    RESULT_DATA.statistics.mean.value = course_status.scoring.mean;
    RESULT_DATA.statistics.median.value = course_status.scoring.median;
    RESULT_DATA.statistics.modes.value = course_status.scoring.modes;
    RESULT_DATA.scoring.number_of_scores_passed = course_status.scoring.number_of_scores_passed;
    RESULT_DATA.scoring.number_of_scores_failed = course_status.scoring.number_of_scores_failed;

    return (
      <Loading spe={this.state.spePage} className="standard-page-loading results-loading">{() =>
        <div className={css.content}>
          <div className="content-body">
            <div className="results-items">
              {_.keys(RESULT_DATA.statistics).map(key => (
                <span
                  className={classNames('result-item', {
                    'has-number': RESULT_DATA.statistics[key] > 0,
                  })}
                  key={key}
                >
                  {RESULT_DATA.statistics[key].name}:
                  <span className={RESULT_DATA.statistics[key].value > 0 ? 'has-number' : ''}>
                    {RESULT_DATA.statistics[key].value > 0 ? RESULT_DATA.statistics[key].value : 'None'}
                  </span>
                </span>
              ))}
            </div>
            <div className="result-details">
              <ChartView
                className="chart-view"
                diameter={130}
                innerRadius={50}
                outerRadius={65}
                fillColor="white"
                course_status={course_status}
              />
              <div className="divider"/>
              <div className="scoring-panel">
                <div className={`scoring-values ${RESULT_DATA.scoring.number_of_scores_passed > 0 && RESULT_DATA.scoring.number_of_scores_failed > 0 ? '-no-border' : ''}`}>
                  {_.values(RESULT_DATA.scoring).map((scoringValue, index) =>
                    <div
                      key={index}
                      className={classNames('scoring-box', {
                        '-no-value': scoringValue === 0,
                        '-no-scoring': RESULT_DATA.scoring.number_of_scores_passed === 0 && RESULT_DATA.scoring.number_of_scores_failed === 0,
                      })}
                      style={{ width: this.getScoringPercentWidth(scoringValue) }}
                    >
                      <label>{scoringValue}</label>
                    </div>
                  )}
                </div>
                <div className="scoring-labels">
                  <label>Passed</label>
                  <label>Failed</label>
                </div>
              </div>
            </div>
            { this.renderTable() }
          </div>

          <StandardAlert
            open={actionResultMessage !== ''}
            type={Constants.ALERT_TYPE.success}
            message={actionResultMessage}
            onClose={() => this.setState({ actionResultMessage: '' })}
          />
        </div>
      }</Loading>
    );
  }
}

export default ResultCourseContent;
