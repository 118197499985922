// @param itemType - either 'module' or 'test'
const getCourseUrl = courseHash =>
  `/dashboard-program/${courseHash}`;

const getProgramTestUrl = (courseId, moduleId) =>
  `/${courseId}/survey/${moduleId}/1/`;

const getCourseInviteUrl = courseHash =>
  `/enroll/${courseHash}`;

const getCourseEditorUrl = ({ courseId, moduleId, itemType }) => (
  `/${courseId}/${itemType}-editor/${moduleId}/`
);

const getExamEditorUrl = ({ examId }) =>
  `/exam-editor/${examId}/`;

const getSalesPaymentSettingsUrl = (programId) =>
  `/manage-payment-settings/?program_id_we_are_setting_up_sales_for=${programId}`;

const getCompleteCompanyProfileUrl = (programId) =>
  `/company-profile/edit/?program_id_we_are_setting_up_sales_for=${programId}`;

// ids must be original!
const getCourseAdminUrl = ({ originalId }) =>
  `/dashboard-program/${originalId}/content`;
const getExamAdminUrl = ({ originalId }) =>
  `/dashboard-exam-scoring/${originalId}/`;

// ids must be versioned!
const getCoursePreviewUrl = (courseId) =>
  `/dashboard-preview/${courseId}/`;
const getExamPreviewUrl = (examId) =>
  `/dashboard-preview/${examId}/`;

const getStudentProfileUrl = (studentId) =>
  `/dashboard-students/${studentId}/`;

const getFreeResponseUrl = (coruseId, studentId) =>
  `/dashboard-program/${coruseId}/results/free-response/${studentId}`;

export default {
  getCourseUrl, getCourseInviteUrl, getProgramTestUrl,
  getCourseEditorUrl, getExamEditorUrl,
  getSalesPaymentSettingsUrl, getCompleteCompanyProfileUrl,
  getCourseAdminUrl, getExamAdminUrl,
  getCoursePreviewUrl, getExamPreviewUrl,
  getStudentProfileUrl, getFreeResponseUrl,
};
