import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BrowserRouter, Switch, Route, NavLink as Link } from 'react-router-dom';
import { Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel } from '@material-ui/core';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import HelpIcon from '@material-ui/icons/ErrorOutlineOutlined';
import Popover from 'react-awesome-popover';
import 'react-awesome-popover/build/index.css';
import _ from 'lodash/core';

import Constants from '~/services/Constants';
import Utils from '~/services/Utils';
import Urls from '~/services/Urls';
import StudentApi from '~/api/StudentApi';
import GroupApi from '~/api/GroupApi';
import { TextInput } from '~/_partials/standardForm';
import SelectDropdown from '~/_partials/SelectDropdown';
import StandardAlert from '~/_partials/StandardAlert';
import BulkInviteStudentsTogglerAndModal from '~/_appPartials/BulkInviteStudentsTogglerAndModal';
import ConfirmTogglerAndModal from '~/pages/Modals/ConfirmTogglerAndModal';
import NameEditTogglerAndModal from '~/pages/Modals/NameEditTogglerAndModal';
import AssignStudentsTogglerAndModal from './_partials/AssignStudentsTogglerAndModal';

import css from './index.scss';

const RELOAD_DELAY_TIME = 500;

const SIDEBAR_NAV = {
  students: {
    type: 'students',
    name: 'Students',
    path: '/',
  },
  groups: {
    type: 'groups',
    name: 'Groups',
    path: '/groups',
  }
};

const STUDENT_HEADER = {
  fullname: { id: 'fullname', order: 'asc', type: SIDEBAR_NAV.students.type },
  email: { id: 'email', order: 'asc', type: SIDEBAR_NAV.students.type },
  student_status: { id: 'student_status', order: 'asc', type: SIDEBAR_NAV.students.type },
};
const GROUP_HEADER = {
  group_name: { id: 'group_name', order: 'asc', type: SIDEBAR_NAV.groups.type },
  group_status: { id: 'group_status', order: 'asc', type: SIDEBAR_NAV.groups.type },
};
const STUDENTS_TABLE_HEADER = {
  ...STUDENT_HEADER,
  ...GROUP_HEADER,
};

class DashboardStudents extends React.Component {
  static propTypes = {
    students: PropTypes.array.isRequired,
    num_active_users: PropTypes.number.isRequired,
    groups: PropTypes.array.isRequired,
  };

  state = {
    searchState: {
      search_text: '',
    },
    formState: {
      first_name: '',
      last_name: '',
      email: '',
      group_name: '',
    },
    groups: this.props.groups.map(group => {
      group.ifDropdownOpen = false;
      return group;
    }),
    studentOrderBy: 'fullname',
    groupOrderBy: 'group_name',
    alertMessage: '',
    bodyClass: document.body.className,
    ifShowAddStudentForm: false,
    ifShowAddGroupForm: false,
    ifShowSuccessAlert: false,
  }

  componentDidMount = () => {
    document.body.addEventListener('bannerChange', this.uiUpdateBodyClass);
  }

  apiAddStudent = () =>
    StudentApi.addStudent(false, {
      first_name: this.state.formState.first_name,
      last_name: this.state.formState.last_name,
      email: this.state.formState.email,
      send_email: false,
    })

  apiAddGroup = () =>
    StudentApi.addGroup(false, {
      group_name: this.state.formState.group_name
    })

  apiUpdateGroup = (group_id, name) =>
    GroupApi.updateGroup(
      false, {
        group: group_id,
        name,
      }
    );

  apiDuplicateGroup = (group_id) =>
    GroupApi.duplicateGroup(
      false, {
        group_id,
      }
    );

  apiDeleteGroup = (group_id) =>
    GroupApi.deleteGroup(
      false, {
        group_id,
      }
    );

  getStudentFullname = (student) =>
    `${student.first_name} ${student.last_name}`

  getStudentStatus = (student) => {
    if (student.status === 'invited') {
      return 'Inactive';
    } else {
      return 'Active';
    }
  }

  stableSort = (array, type) => {
    const { studentOrderBy, groupOrderBy } = this.state;
    const orderBy = (type === SIDEBAR_NAV.students.type) ? studentOrderBy : groupOrderBy;
    const headerOrder = STUDENTS_TABLE_HEADER[orderBy].order;
    const stabilizedThis = array.map((el, index) => {
      const sortableObject = {
        ...el,
      };

      if (type === SIDEBAR_NAV.students.type) {
        sortableObject.fullname = this.getStudentFullname(el).toLowerCase();
        sortableObject.email = el.email;
        sortableObject.student_status = this.getStudentStatus(el);
      } else {
        sortableObject.group_name = el.name;
        sortableObject.group_status = el.status === 'active' ? 'Active' : 'Inactive';
      }
      const sortableArray = [sortableObject, index];
      return sortableArray;
    });

    stabilizedThis.sort((a, b) => {
      const order = Utils.getSorting(headerOrder, orderBy)(a[0], b[0]);

      if (order !== 0) {
        return order;
      }

      return a[1] - b[1];
    });

    return stabilizedThis.map(el => el[0]);
  }

  uiUpdateBodyClass = () => {
    this.setState({ bodyClass: document.body.className });
  }

  uiUpdateGroupDropdownToggle = (updatedGroup, ifDropdownOpen) => {
    const { groups } = this.state;
    const updatedGroupIndex = groups.findIndex(group => group.id === updatedGroup.id);
    this.setState({
      groups: [
        ...groups.slice(0, updatedGroupIndex),
        {
          ...groups[updatedGroupIndex],
          ifDropdownOpen,
        },
        ...groups.slice(updatedGroupIndex + 1),
      ]
    });
  }

  uiUpdateGroupName = (groupId, updatedName) => {
    const { groups } = this.state;
    this.apiUpdateGroup(groupId, updatedName)
      .then(() => {
        const updatedGroupIndex = groups.findIndex(group => group.id === groupId);
        this.setState({
          groups: [
            ...groups.slice(0, updatedGroupIndex),
            {
              ...groups[updatedGroupIndex],
              name: updatedName,
            },
            ...groups.slice(updatedGroupIndex + 1),
          ]
        });
      });
  }

  uiDuplicateGroup = (groupSource) => {
    const { groups } = this.state;
    const sourceIndex = groups.findIndex(group => group.id === groupSource.id);
    this.apiDuplicateGroup(groupSource.id)
      .then(result => {
        this.setState({
          groups: [
            ...groups.slice(0, sourceIndex),
            {
              ...groupSource,
              ifDropdownOpen: false,
            },
            ...groups.slice(sourceIndex + 1),
            {
              ...groupSource,
              id: result.id,
              name: `${groupSource.name} Copy`,
              ifDropdownOpen: false,
            }
          ]
        });
      });
  }

  uiDeleteGroup = (groupId) => {
    this.apiDeleteGroup(groupId)
      .then(() =>
        this.setState({
          groups: this.state.groups.filter(group => group.id !== groupId)
        })
      );
  }

  uiShowStudentsAlert = () => {
    this.setState({
      ifShowSuccessAlert: true,
      alertMessage: 'Students added to this group.',
    });
  }

  uiCloseAlert = () => {
    this.setState({
      ifShowSuccessAlert: false,
      alertMessage: '',
    });
  }

  updateFormState = (formState) => {
    this.setState({ formState });
  }

  handleAddStudentsClick = () => {
    this.setState({ ifShowAddStudentForm: true, ifShowAddGroupForm: false });
  }

  handleAddGroupsClick = () => {
    this.setState({ ifShowAddGroupForm: true, ifShowAddStudentForm: false });
  }

  handleStudentFormCancelClick = () => {
    this.setState({
      ifShowAddStudentForm: false,
      formState: {
        ...this.state.formState,
        first_name: '',
        last_name: '',
        email: '',
      }
    });
  }

  handleGroupFormCancelClick = () => {
    this.setState({
      ifShowAddGroupForm: false,
      formState: {
        ...this.state.formState,
        group_name: '',
      }
    });
  }

  handleAddStudentSubmit = () => {
    const { formState } = this.state;
    const invitedStudentWithEmail = this.props.students.find(student => student.email === formState.email && student.status === 'invited');

    if (invitedStudentWithEmail) {
      this.setState({ alertMessage: 'A user with this email is already invited.' });
    } else {
      this.apiAddStudent()
      .then(data => {
        if (data.message === 'duplicate_email') {
          this.setState({ alertMessage: 'A user with this email is already registered.' });
        } else if (data.message === 'not_student') {
          this.setState({ alertMessage: 'This user is not a student.' });
        } else if (data.message === 'invalid_email') {
          this.setState({ alertMessage: 'Please input valid email address.' });
        } else {
          this.setState({
              ifShowSuccessAlert: true,
              alertMessage: 'Student Added!'
            },
            () => setTimeout(() => location.reload(), RELOAD_DELAY_TIME)
          );
        }
      });
    }
  }

  handleAddGroupSubmit = () => {
    this.apiAddGroup()
      .then(() => {
        this.setState({
            ifShowSuccessAlert: true,
            alertMessage: 'Group Added!'
          },
          () => setTimeout(() => location.reload(), RELOAD_DELAY_TIME)
        );
      });
  }

  handleChangeSearchState = (searchState) => {
    this.setState({ searchState });
  }

  handleRequestOrder = (orderObject) => {
    const { studentOrderBy, groupOrderBy } = this.state;
    const orderId = orderObject.id;
    const orderBy = (orderObject.type === SIDEBAR_NAV.students.type) ? studentOrderBy : groupOrderBy;
    let order = 'asc';

    if (STUDENTS_TABLE_HEADER[orderId].order === 'asc' && orderId === orderBy) {
      order = 'desc';
    }

    STUDENTS_TABLE_HEADER[orderId].order = order;

    if (orderObject.type === SIDEBAR_NAV.students.type) {
      this.setState({ studentOrderBy: orderId });
    } else {
      this.setState({ groupOrderBy: orderId });
    }
  }

  renderBulkUploadToggler = () =>
    <button className="standard-button -plum btn-bulk">Bulk Upload</button>


  renderAddStudentsToggler = () =>
    <li><a>Add Students</a></li>

  renderRenameToggler = () =>
    <li><a>Rename Group</a></li>

  renderDeleteToggler = () =>
    <li><a>Delete Group</a></li>

  renderAddForm = () => {
    const { formState, ifShowAddStudentForm, ifShowAddGroupForm } = this.state;
    const formProps = {
      formState,
      updateFormState: this.updateFormState,
    };
    return (
      <form className="add-form">
        {ifShowAddStudentForm &&
        <div className="add-form-body">
          <div className="name-control-group">
            <TextInput name="first_name" maxLength={Constants.MAX_NAME_LENGTH} placeholder="First Name" {...formProps}/>
            <TextInput name="last_name" maxLength={Constants.MAX_NAME_LENGTH} placeholder="Last Name" {...formProps}/>
          </div>
          <TextInput name="email" placeholder="Email" {...formProps}/>
          <div className="form-buttons">
            <button type="button" className="btn-form-cancel" onClick={this.handleStudentFormCancelClick}>Cancel</button>
            <button
              type="button"
              className="standard-button -plum btn-form-submit"
              disabled={formProps.formState['first_name'].length === 0 || formProps.formState['last_name'].length === 0 || formProps.formState['email'].length === 0}
              onClick={this.handleAddStudentSubmit}
            >Add</button>
          </div>
        </div>
        }
        {ifShowAddGroupForm &&
        <div className="add-form-body group-body">
          <TextInput name="group_name" maxLength={Constants.MAX_NAME_LENGTH} placeholder="Group Name" {...formProps}/>
          <div className="form-buttons">
            <button type="button" className="btn-form-cancel" onClick={this.handleGroupFormCancelClick}>Cancel</button>
            <button
              type="button"
              className="standard-button -plum btn-form-submit"
              disabled={formProps.formState['group_name'].length === 0}
              onClick={this.handleAddGroupSubmit}
            >Add</button>
          </div>
        </div>
        }
      </form>
    );
  }

  renderInformationPanel = () => {
    const { students, num_active_users } = this.props;
    const { ifShowAddStudentForm, ifShowAddGroupForm, groups } = this.state;
    return (
      <div className="info-panel">
        <div className="top-bar">
          <div className="active-user">
            Current Active Users:
            <span>{num_active_users}</span>
            <Popover action="hover">
              <HelpIcon className="help-icon"/>
              <div className="popover-body">
                An active user is someone who has begun taking a training program within the 30-day billing period. Account logins are not counted toward users activity.
              </div>
            </Popover>
          </div>
          <div className="standard-search-box">
            <SearchOutlinedIcon className="search-icon"/>
            <TextInput
              name="search_text"
              formState={this.state.searchState}
              updateFormState={this.handleChangeSearchState}
              placeholder="Search by Name"
            />
          </div>
        </div>
        <div className="info-bar">
          <div className="info-panel">
            <div className="count-box">
              <span>{students.length}</span>
              <span>Students</span>
            </div>
            <BulkInviteStudentsTogglerAndModal toggler={this.renderBulkUploadToggler()}/>
            <button className="standard-button -underline btn-action-add" onClick={this.handleAddStudentsClick}>+ Add Students</button>
          </div>
          <div className="info-panel">
            <div className="count-box">
              <span>{groups.length}</span>
              <span>Groups</span>
            </div>
            <button className="standard-button -underline btn-action-add" onClick={this.handleAddGroupsClick}>+ Add Groups</button>
          </div>
        </div>
        {(ifShowAddStudentForm || ifShowAddGroupForm) && this.renderAddForm()}
      </div>
    );
  }

  renderStudents = () => {
    const { students } = this.props;
    const { studentOrderBy, searchState } = this.state;
    const filteredStudents = this.stableSort(students, SIDEBAR_NAV.students.type)
      .filter(student => student.fullname.toLowerCase().includes(searchState.search_text.toLowerCase()));
    return (
      <div className="students-body">
        { this.renderInformationPanel() }
        <div className="table-wrapper">
          <Table>
            <TableHead>
              <TableRow classes={{ root: 'standard-table-header-row' }}>
                <TableCell classes={{ root: 'standard-table-head-cell -cell-width-cs1' }}>
                  <TableSortLabel
                    classes={{
                      root: 'standard-table-header-sort-label',
                      active: 'label-active',
                      icon: 'sort-icon',
                    }}
                    active={studentOrderBy === STUDENTS_TABLE_HEADER.fullname.id}
                    direction={STUDENTS_TABLE_HEADER.fullname.order}
                    onClick={() => this.handleRequestOrder(STUDENTS_TABLE_HEADER.fullname)}
                  >
                    Full Name
                  </TableSortLabel>
                </TableCell>
                <TableCell classes={{ root: 'standard-table-head-cell -cell-width-cs2' }}>
                  <TableSortLabel
                    classes={{
                      root: 'standard-table-header-sort-label',
                      active: 'label-active',
                      icon: 'sort-icon',
                    }}
                    active={studentOrderBy === STUDENTS_TABLE_HEADER.email.id}
                    direction={STUDENTS_TABLE_HEADER.email.order}
                    onClick={() => this.handleRequestOrder(STUDENTS_TABLE_HEADER.email)}
                  >
                    Email
                  </TableSortLabel>
                </TableCell>
                <TableCell classes={{ root: 'standard-table-head-cell' }}>
                  <TableSortLabel
                    classes={{
                      root: 'standard-table-header-sort-label -no-border',
                      active: 'label-active',
                      icon: 'sort-icon',
                    }}
                    active={studentOrderBy === STUDENTS_TABLE_HEADER.student_status.id}
                    direction={STUDENTS_TABLE_HEADER.student_status.order}
                    onClick={() => this.handleRequestOrder(STUDENTS_TABLE_HEADER.student_status)}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredStudents.map((student, index) => (
                <TableRow key={index} classes={{ root: 'standard-table-body-row' }}>
                  <TableCell classes={{ root: 'standard-table-body-cell' }}>
                    <div className="standard-table-cell-div">
                      <a
                        className={classNames('student-name', { '-clickable': student.status !== 'invited' })}
                        href={student.status !== 'invited' ? Urls.getStudentProfileUrl(student.id) : ''}
                      >
                        {this.getStudentFullname(student)}
                      </a>
                    </div>
                  </TableCell>
                  <TableCell classes={{ root: 'standard-table-body-cell' }}>
                    <div className="standard-table-cell-div">{student.email}</div>
                  </TableCell>
                  <TableCell classes={{ root: 'standard-table-body-cell' }}>
                    <div
                      className={classNames('standard-table-cell-div -status', {
                        '-inactive': (student.status === 'invited'),
                      })
                    }
                    >
                      {this.getStudentStatus(student)}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {students.length > 0 && filteredStudents.length === 0 && searchState.search_text.length > 0 &&
            <div className="standard-empty-content -table-content">
              <p>{`No students found for "${searchState.search_text}"`}</p>
            </div>
          }
        </div>
      </div>
    );
  }

  renderGroups = () => {
    const { groups, groupOrderBy, searchState } = this.state;
    const filteredGroups = this.stableSort(groups, SIDEBAR_NAV.groups.type)
      .filter(group => group.name.toLowerCase().includes(searchState.search_text.toLowerCase()));
    return (
      <div className="students-body">
        { this.renderInformationPanel() }
        <div className="table-wrapper">
          <Table>
            <TableHead>
              <TableRow classes={{ root: 'standard-table-header-row' }}>
                <TableCell classes={{ root: 'standard-table-head-cell name-cell' }}>
                  <TableSortLabel
                    classes={{
                      root: 'standard-table-header-sort-label',
                      active: 'label-active',
                      icon: 'sort-icon',
                    }}
                    active={groupOrderBy === STUDENTS_TABLE_HEADER.group_name.id}
                    direction={STUDENTS_TABLE_HEADER.group_name.order}
                    onClick={() => this.handleRequestOrder(STUDENTS_TABLE_HEADER.group_name)}
                  >
                    Student Group
                  </TableSortLabel>
                </TableCell>
                <TableCell classes={{ root: 'standard-table-head-cell status-cell' }}>
                  <TableSortLabel
                    classes={{
                      root: 'standard-table-header-sort-label',
                      active: 'label-active',
                      icon: 'sort-icon',
                    }}
                    active={groupOrderBy === STUDENTS_TABLE_HEADER.group_status.id}
                    direction={STUDENTS_TABLE_HEADER.group_status.order}
                    onClick={() => this.handleRequestOrder(STUDENTS_TABLE_HEADER.group_status)}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell classes={{ root: 'standard-table-head-cell' }}>
                  <TableSortLabel
                    classes={{
                      root: 'standard-table-header-sort-label -no-border',
                    }}
                  >
                    Action
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredGroups.map((group, index) => (
                <TableRow key={index} classes={{ root: 'standard-table-body-row' }}>
                  <TableCell classes={{ root: 'standard-table-body-cell' }}>
                    <div className="standard-table-cell-div">{group.name}</div>
                  </TableCell>
                  <TableCell classes={{ root: 'standard-table-body-cell' }}>
                    <div
                      className={classNames('standard-table-cell-div -status', {
                        inactive: (group.status !== 'active'),
                      })}
                    >
                      {group.status === 'active' ? 'Active' : 'Inactive'}
                    </div>
                  </TableCell>
                  <TableCell classes={{ root: 'standard-table-body-cell' }}>
                    <div className="standard-table-cell-div -action">
                      <button
                        type="button"
                        className="standard-menu-button -bulk btn-group-action"
                        onClick={() => this.uiUpdateGroupDropdownToggle(group, !group.ifDropdownOpen)}
                      >
                        Actions
                        <i className="fa fa-angle-down"/>
                      </button>
                      <SelectDropdown open={group.ifDropdownOpen} onCloseDropdown={() => this.uiUpdateGroupDropdownToggle(group, false)}>
                        <AssignStudentsTogglerAndModal
                          toggler={this.renderAddStudentsToggler()}
                          groupId={group.id}
                          onUpdateStudents={() => this.uiShowStudentsAlert()}
                        />
                        <NameEditTogglerAndModal
                          toggler={this.renderRenameToggler()}
                          name={group.name}
                          title="Edit Group Name"
                          placeholder="Group Name"
                          onSave={name => this.uiUpdateGroupName(group.id, name)}
                        />
                        <li onClick={() => this.uiDuplicateGroup(group)}>
                          <a>Duplicate Group</a>
                        </li>
                        <ConfirmTogglerAndModal
                          title="Are you sure you want to delete this group?"
                          toggler={this.renderDeleteToggler()}
                          onClose={() => this.uiDeleteGroup(group.id)}
                        />
                      </SelectDropdown>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          { groups.length === 0 && <p className="no-list">This account doest not have any student groups yet.</p> }
          {groups.length > 0 && filteredGroups.length === 0 && searchState.search_text.length > 0 &&
            <div className="standard-empty-content -table-content">
              <p>{`No groups found for "${searchState.search_text}"`}</p>
            </div>
          }
        </div>
      </div>
    );
  }

  render() {
    const { alertMessage, ifShowSuccessAlert } = this.state;
    return (
      <main
        className={classNames(css.main, 'standard-dashboard-content', {
          '-with-banner': this.state.bodyClass.includes('-with-banner'),
          '-full-banner': this.state.bodyClass.includes('-full-banner'),
        })}
      >
        <BrowserRouter basename="/teacher-dashboard/students">
          <div className="students-wrapper">
            {/* Left Side Bar */}
            <div className="students-sidebar">
              <h3 className="student-title">Students</h3>
              <ul className="standard-navbar">
                {_.values(SIDEBAR_NAV).map(nav =>
                  <li key={nav.name} className="standard-nav-container">
                    <Link exact to={nav.path} className="standard-nav" activeClassName="-active">
                      {nav.name}
                    </Link>
                  </li>
                )}
              </ul>
            </div>
            {/* Content */}
            <div className="content-wrapper">
              <Switch>
                <Route exact path={SIDEBAR_NAV.students.path} render={() => this.renderStudents()}/>
                <Route exact path={SIDEBAR_NAV.groups.path} render={() => this.renderGroups()}/>
              </Switch>
            </div>

            <StandardAlert
              open={alertMessage !== ''}
              type={ifShowSuccessAlert ? Constants.ALERT_TYPE.success : Constants.ALERT_TYPE.warning}
              message={alertMessage}
              onClose={this.uiCloseAlert}
            />
          </div>
        </BrowserRouter>
      </main>
    );
  }
}

export default DashboardStudents;
