import React from 'react';
import PropTypes from 'prop-types';

import Constants from '~/services/Constants';
import TogglerAndModal from '~/_partials/TogglerAndModal';

import css from './index.scss';

class NameEditTogglerAndModal extends React.Component {
  constructor(props) {
    super(props);
    this.modalWrapperRef = React.createRef();
  }

  static propTypes = {
    toggler: PropTypes.element.isRequired,
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onSave: PropTypes.func.isRequired,
  };

  state = {
    nameText: this.props.name,
  }

  componentWillReceiveProps(props) {
    if (props.name !== this.state.nameText) {
      this.setState({ nameText: props.name });
    }
  }

  clearState = () => {
    this.setState({
      nameText: '',
    });
  }

  handleCancelClick = (closeModal) => {
    this.clearState();
    closeModal();
  }

  handleSaveClick = (closeModal) => {
    closeModal();
    this.props.onSave(this.state.nameText);
  }

  onClickOnModalWrapper = (event, closeModal) => {
    const elClickedOn = event.target;
    const modalWrapper = this.modalWrapperRef.current;

    if (elClickedOn === modalWrapper) {
      this.clearState();
      closeModal();
    }
  }

  renderFooter = (closeModal) => {
    const { nameText } = this.state;
    const isSaveDisabled = nameText.length === 0;

    return (<div className="standard-modal-footer -with-buttons">
      <button
        className="standard-button -sliding -red"
        type="reset"
        onClick={() => this.handleCancelClick(closeModal)}
      >Cancel</button>
      <button
        className="standard-button -sliding -blue"
        type="submit"
        disabled={isSaveDisabled}
        onClick={() => this.handleSaveClick(closeModal)}
      >Save</button>
    </div>);
  }

  render = () =>
    <TogglerAndModal
      toggler={this.props.toggler}
    >{(closeModal, renderCloseButton) =>
      <div
        className={`standard-modal-wrapper ${css.modal}`}
        onClick={e => this.onClickOnModalWrapper(e, closeModal)} ref={this.modalWrapperRef}
      >
        <div className="standard-modal -with-white-header">
          {renderCloseButton()}

          <div className="standard-modal-header">{this.props.title}</div>
          <div className="standard-modal-main">
            <input placeholder={this.props.placeholder} maxLength={Constants.MAX_TITLE_LENGTH} value={this.state.nameText} onChange={e => this.setState({ nameText: e.target.value })}/>
          </div>

          {this.renderFooter(closeModal)}
        </div>
      </div>
    }</TogglerAndModal>
}

export default NameEditTogglerAndModal;
