import React from 'react';
import PropTypes from 'prop-types';
import orFalse from '~/services/orFalse';

import FormState from './services/FormState';

import Slider from '@material-ui/core/Slider';
import ContentTogglerAndModalWrapper from '../ContentTogglerAndModalWrapper';
import ImageUploadDropzone from '~/_partials/ImageUploadDropzone';

import css from './index.scss';

class ImageTogglerAndModal extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    content: orFalse(PropTypes.shape({
      content_type: PropTypes.oneOf(['image', 'cert-image']).isRequired,
      // <img src="https://s3.amazonaws.com/module-uploads/uploads/images/f65a5c3c-b11d-4f3a-a140-29638222d6b5.18" class="module-img" style="width: 314px;">
      payload: PropTypes.string.isRequired
    })).isRequired,
    createWith: PropTypes.object,
  }

  setSrcForever = (formState, updateFormState, src) => {
    updateFormState({
      ...formState,
      src
    });
  }

  updateImageWidth = (formState, updateFormState, value) => {
    updateFormState({
      ...formState,
      width: value
    });
  }

  renderResizeSliderAndImage = (formState, updateFormState) =>
    <section className="resize-slider_and_image">
      <div className="image-width-number">Width: {formState.width}</div>
      <Slider
        max={FormState.initialState.width}
        min={0}
        step={1}
        value={formState.width}
        onChange={(event, value) => this.updateImageWidth(formState, updateFormState, value)}
        classes={{ root: 'slider', trackBefore: 'trackBefore', trackAfter: 'trackAfter', thumb: 'thumb' }}
      />
      <div dangerouslySetInnerHTML={{ __html: FormState.getImageHtml(formState) }}/>
    </section>

  renderImageUploadDropzone = (formState, updateFormState) =>
    <ImageUploadDropzone
      className="image-upload-dropzone"
      src={false}
      updateSrc={(src) => this.setSrcForever(formState, updateFormState, src)}
    />

  render = () => {
    const { content, createWith } = this.props;
    const x_coordinate = createWith ? createWith.x_coordinate : '0';
    const y_coordinate = createWith ? createWith.y_coordinate : '0';
    const initialState = {
      ...FormState.initialState,
      options: (content && content.options) ? content.options : `${x_coordinate}, ${y_coordinate}`,
    };
    return (
      <ContentTogglerAndModalWrapper
        title="Image"
        initialState={initialState}
        fromApiToState={FormState.fromApiToState}
        fromStateToApi={FormState.fromStateToApi}
        className={`${css.modal} -new`}
        {...this.props}
      >{(formState, updateFormState) =>
        <div className="standard-modal-main">
          {
            formState.src ?
              this.renderResizeSliderAndImage(formState, updateFormState) :
              this.renderImageUploadDropzone(formState, updateFormState)
          }
        </div>
      }</ContentTogglerAndModalWrapper>
    );
  }
}

export default ImageTogglerAndModal;
