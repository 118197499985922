import React from 'react';
import PropTypes from 'prop-types';
import orFalse from '~/services/orFalse';

import css from './index.scss';

// ___How to pass props to input?
//    All props get passed directly, except for className and label!
class SlidyTextInput extends React.Component {
  static propTypes = {
    label: orFalse(PropTypes.string),
    className: PropTypes.string,
  }

  static defaultProps = {
    label: false,
    className: '',
  }

  getInputProps = () => ({
    ...this.props,
    className: undefined,
    label: undefined,
  })

  focus = () => {
    if (this.inputRef) {
      this.inputRef.focus();
    }
  }

  // ___Why input has required props?
  //    In order for input:valid selector to work :-/
  render = () =>
    <div className={`${this.props.className} ${css.div}`}>
      <input required {...this.getInputProps()} ref={input => this.inputRef = input}/>
      <span className="sliding-border-bottom"/>
      {
        this.props.label &&
        <label>{this.props.label}</label>
      }
    </div>
}

export default SlidyTextInput;
