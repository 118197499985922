const handleErrors = (response) => {
  switch (response.status) {
    case 200: // all fine!
      return response.json();
    case 204: // all fine, but no content!
      return null;
    case 400: // validations!
      return response.json()
        .then((validation) =>
          // sometimes django sends us status 400 on server error :-/, resulting in no [array]
          Promise.reject({ _ifValidation: true, payload: validation ? validation : ['Something went wrong'] })
        );
    default: { // everything else should be an error!
      return response.json()
        .catch(() =>
          Promise.reject({ _ifError: true, payload: 'Something went wrong' })
        )
        .then((error) =>
          Promise.reject({ _ifError: true, payload: error.toString() })
        );
    }
  }
};

export default handleErrors;
