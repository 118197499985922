const checkReordableFromOneToNewIndex = (modules, fromIndex, toIndex) => {
  if (toIndex === fromIndex) return false;

  let ifCanReorder = true;
  const surveys = modules.filter(module => module.program_type === 'Survey');
  const certificateIndex = modules.findIndex(module => module.program_type === 'Certificate');
  const fromModule = modules[fromIndex];

  if (fromIndex < toIndex && fromIndex < certificateIndex && certificateIndex <= toIndex) {
    if (fromModule.program_type === 'Survey') {
      const nextSurvey = modules.find((module, index) =>
        module.id !== fromModule.id && module.program_type === 'Survey' && index < certificateIndex
      );

      if (surveys.length > 1 && !nextSurvey) {
        ifCanReorder = false;
      }
    } else {
      ifCanReorder = false;
    }
  } else if (fromIndex > toIndex && toIndex <= certificateIndex && certificateIndex < fromIndex) {
    if (fromModule.program_type === 'Survey') {
      const otherSurvey = modules.find((module, index) =>
        module.id !== fromModule.id && module.program_type === 'Survey' && index > certificateIndex
      );

      if (surveys.length > 1 && !otherSurvey) {
        ifCanReorder = false;
      }
    } else {
      ifCanReorder = false;
    }
  }

  return ifCanReorder;
};

export default checkReordableFromOneToNewIndex;
