import React from 'react';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import $ from 'jquery';

import ContentApi from '~/api/ContentApi';
import Constants from '~/services/Constants';
import Utils from '~/services/Utils';
import QuestionContentLi from './_partials/QuestionContentLi';
import HeadingContentLi from './_partials/HeadingContentLi';
import ParagraphContentLi from './_partials/ParagraphContentLi';
import DropdownContentLi from './_partials/DropdownContentLi';
import PageBreakContentLi from './_partials/PageBreakContentLi';
import ImageContentLi from './_partials/ImageContentLi';
import VideoContentLi from './_partials/VideoContentLi';
import FileContentLi from './_partials/FileContentLi';
import TextContentLi from './_partials/TextContentLi';
import RatingContentLi from './_partials/RatingContentLi';
import MlineTextContentLi from './_partials/MlineTextContentLi';
import LikertContentLi from './_partials/LikertContentLi';
import LineContent from './_partials/LineContent';

import css from './index.scss';

class Contents extends React.Component {
  static propTypes = {
    editorForCourseOrExam: PropTypes.oneOf(['course', 'exam']).isRequired,
    itemType: PropTypes.string.isRequired,
    courseName: PropTypes.string.isRequired,
    organizationName: PropTypes.string.isRequired,

    contents: PropTypes.array.isRequired,
    uiUpdateContent: PropTypes.func.isRequired,
    uiDeleteContent: PropTypes.func.isRequired,
    uiUpdateCoordinates: PropTypes.func.isRequired,

    scoringType: PropTypes.oneOf(['percentage', 'point']).isRequired,
    totalAmountOfPoints: PropTypes.number.isRequired
  }

  apiUpdateCertificateContent = (content_id, params) =>
    ContentApi.update(false, content_id, params)

  getEditorName = () => {
      switch (this.props.itemType) {
        case 'module': return 'Module Editor!';
        case 'test': return 'Test Editor!';
        case 'certificate': return 'Certificate Editor!';
        case 'survey': return 'Survey Builder!';
        case 'data': return 'Data Editor!';
        case 'alert': return 'Alert Editor!';
      }
  }

  getEditorCopy = () => {
      switch (this.props.itemType) {
        case 'module': return 'Welcome to the Module Editor!';
        case 'test': return 'Welcome to the Test Editor!';
        case 'certificate': return 'Welcome to the Certificate Editor!';
        case 'survey': return 'Welcome to the Survey Builder!';
        case 'data': return "Let's create a Form to collect some data!";
        case 'alert': return 'Welcome to the Alert Editor!';
      }
  }

  getDropProvider = (dropProvided, ref) => {
    this.containerRef = ref;
    return dropProvided.innerRef(ref);
  }

  uiUpdateCertificateElementLayout = (element, updatedContent) => {
    const contentsElement = element.parentElement;

    if (contentsElement) {
      const left = element.getBoundingClientRect().left - contentsElement.getBoundingClientRect().left;
      const top = element.getBoundingClientRect().top - contentsElement.getBoundingClientRect().top;
      let width = element.offsetWidth;
      let height = element.offsetHeight;
      const contentType = updatedContent.content_type;
      let payload = updatedContent.payload;

      if (contentType === 'horizontal-line' || contentType === 'vertical-line') {
        if (contentType === 'horizontal-line') {
          payload = width.toString();
        } else {
          payload = height.toString();
        }
      } else {
        const payloadHTML = $($.parseHTML(payload));

        if (contentType !== 'cert-image' && payloadHTML.css('width') === '0px' && payloadHTML.css('height') === '0px') {
          width += 1;
          height += 1;
        }

        payloadHTML.css('width', `${width}px`);

        if (contentType !== 'cert-image') {
          payloadHTML.css('height', `${height}px`);
        }

        payload = payloadHTML[0].outerHTML;
      }

      const params = {
        item_type: this.props.itemType,
        payload,
        x_coordinate: left.toString(),
        y_coordinate: top.toString(),
      };
      this.apiUpdateCertificateContent(updatedContent.id, params)
        .then(result => {
          this.props.uiUpdateContent(result);
        });
    }
  }

  uiUpdateCoordinates = (e) => {
    if (e.target === this.containerRef) {
      const containerBound = this.containerRef.getBoundingClientRect();
      const positionX = e.pageX - containerBound.left;
      const positionY = e.pageY - containerBound.top;
      this.props.uiUpdateCoordinates(positionX, positionY);
    }
  }

  renderContentLi = (content, index, dragProvided, dragSnapshot) => {
    const props = {
      itemType: this.props.itemType,
      content,
      courseName: this.props.courseName,
      organizationName: this.props.organizationName,
      liProps: {},
      uiUpdateContent: this.props.uiUpdateContent,
      uiDeleteContent: this.props.uiDeleteContent,
      // only useful for Ceritificate Content Element
      uiUpdateCertificateElementLayout: (el) => this.uiUpdateCertificateElementLayout(el, content),
      // only useful for QuestionContentLi
      scoringType: this.props.scoringType,
      totalAmountOfPoints: this.props.totalAmountOfPoints
    };

    if (this.props.itemType !== 'certificate') {
      props.liProps = {
        key: content.id,
        ref: dragProvided.innerRef,
        className: `content ${(this.props.itemType === 'survey' || this.props.itemType === 'data') ? '-new-layout' : ''} -${content.content_type} ${dragSnapshot.isDragging ? '-is-dragging' : ''} ${content.className ? content.className : ''}`,
        ...dragProvided.draggableProps,
        ...dragProvided.dragHandleProps
      };
    }

    switch (content.content_type) {
      case 'yes-no':
      case 'true-false':
      case 'multiple-choice':
      case 'multiple-choice-2':
      case 'nps':
        return <QuestionContentLi {...props}/>;
      case 'heading':         return <HeadingContentLi {...props}/>;
      case 'paragraph':
      case 'cert-text':
      case 'field':           return <ParagraphContentLi key={content.id} {...props}/>;
      case 'dropdown':        return <DropdownContentLi {...props}/>;
      case 'single-line-text':  return <TextContentLi {...props}/>;
      case 'rating-scale':    return <RatingContentLi {...props}/>;
      case 'multi-line-text': return <MlineTextContentLi {...props}/>;
      case 'likert':          return <LikertContentLi {...props}/>;
      case 'page-break':      return <PageBreakContentLi {...props}/>;
      case 'image':
      case 'cert-image':      return <ImageContentLi key={content.id} {...props}/>;
      case 'file':            return <FileContentLi {...props}/>;
      case 'video':           return <VideoContentLi {...props}/>;
      case 'horizontal-line':
      case 'vertical-line':   return <LineContent key={content.id} {...props}/>;
      default:
        throw Error(`No content.content_type '${content.content_type}' exists.`);
    }
  }

  renderCertificateContents = (dropProvided, dropSnapshot) => {
    const activeBackground = this.props.contents.find(content => content.content_type === 'background-image' && content.options === 'active');
    return (
      <div
        className={`certificate-contents-container ${dropSnapshot.isDraggingOver ? '-is-dragging-over' : ''}`}
        ref={ref => this.getDropProvider(dropProvided, ref)}
        onMouseUp={this.uiUpdateCoordinates}
        {...dropProvided.droppableProps}
      >
        <img className="certificate-background" src={activeBackground ? Utils.parseHtmlProps(activeBackground.payload, 'src') : Constants.CERTIFICATE_DEFAULT_BACKGROUNDS[Constants.DEFAULT_BACKGROUND_TITLE].image}/>
        {this.props.contents.filter(content => content.content_type !== 'background-image')
          .map((content, index) =>
            this.renderContentLi(content, index)
        )}
      </div>
    );
  }

  renderContentsUl = (dropProvided, dropSnapshot) =>
    this.props.itemType === 'certificate' ?
      this.renderCertificateContents(dropProvided, dropSnapshot) :
      <ul
        className={`contents ${dropSnapshot.isDraggingOver ? '-is-dragging-over' : ''}`}
        ref={dropProvided.innerRef}
        {...dropProvided.droppableProps}
      >
        {this.props.contents.map((content, index) =>
          <Draggable key={content.id} draggableId={content.id} index={index}>{(dragProvided, dragSnapshot) =>
            this.renderContentLi(content, index, dragProvided, dragSnapshot)
          }</Draggable>
        )}
        {dropProvided.placeholder}
      </ul>

  renderPlaceholderForContents = (dropProvided) => {
    const ifSurveyPlaceholder = this.props.editorForCourseOrExam === 'course' && this.props.itemType === 'survey';
    return (
      <div
        className="placeholder-instead-of-contents"
        ref={dropProvided.innerRef}
        {...dropProvided.droppableProps}
      >
        <div className="placeholder-contents">
          <h2 className="welcome">{this.getEditorCopy()}</h2>
          <h2 className="editor-guide-info">
            {!ifSurveyPlaceholder ?
              'Drag an element from the Design Toolbox to get started' :
              'Click here to add your first survery question.'
            }
          </h2>;
          {dropProvided.placeholder}
        </div>
      </div>
    );
  }

  render = () =>
    <section className={`contents-or-placeholder ${css.section} -${this.props.itemType}`}>
      <Droppable droppableId="contents">{(dropProvided, dropSnapshot) =>
        (this.props.contents.length > 0 || this.props.itemType === 'certificate') ?
          this.renderContentsUl(dropProvided, dropSnapshot) :
          this.renderPlaceholderForContents(dropProvided)
      }</Droppable>
    </section>
}

export default Contents;
