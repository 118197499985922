const dontCloseWindowIfStilUploadingFile = (event, spePage) => {
  const ifSomeTusIsStillUploading =
    spePage.status === 'success' &&
    spePage.payload.contents.some((content) =>
      content._videoIsUploadingViaTus && content._videoIsUploadingViaTus.status === 'request'
    );

  if (ifSomeTusIsStillUploading) {
    // Cancel the event as stated by the standard.
    event.preventDefault();
    // Chrome requires returnValue to be set.
    event.returnValue = '';
  }
};

export default dontCloseWindowIfStilUploadingFile;
