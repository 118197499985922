const _getQuestionDetails = (contentType) => {
  switch (contentType) {
    case 'yes-no': return [
      { payload: 'Yes',  isCorrectAnswer: true },
      { payload: 'No',  isCorrectAnswer: false }
    ];
    case 'multiple-choice':
    case 'multiple-choice-2':
      return [
        { payload: '',  isCorrectAnswer: true },
        { payload: '',  isCorrectAnswer: false },
        { payload: '',  isCorrectAnswer: false },
        { payload: '',  isCorrectAnswer: false }
      ];
    case 'nps':
      return [
        { payload: '0',  isCorrectAnswer: true },
        { payload: '1',  isCorrectAnswer: false },
        { payload: '2',  isCorrectAnswer: false },
        { payload: '3',  isCorrectAnswer: false },
        { payload: '4',  isCorrectAnswer: false },
        { payload: '5',  isCorrectAnswer: false },
        { payload: '6',  isCorrectAnswer: false },
        { payload: '7',  isCorrectAnswer: false },
        { payload: '8',  isCorrectAnswer: false },
        { payload: '9',  isCorrectAnswer: false },
        { payload: '10',  isCorrectAnswer: false }
      ];
    case 'true-false': return [
      { payload: 'True',  isCorrectAnswer: true },
      { payload: 'False',  isCorrectAnswer: false }
    ];
  }
};

const fromPointValueStringToInt = (formStatePointValue) => {
  const int = parseInt(formStatePointValue, 10);
  if (isNaN(int) || int < 0) {
    return 0;
  } else {
    return int;
  }
};

const getInitialState = (contentType) => ({
  payload: '',
  explanation: '',
  questionDetails: _getQuestionDetails(contentType),
  pointValue: '1'
});

const fromApiToState = (apiContent) => ({
  payload: apiContent.payload,
  explanation: apiContent.explanation,
  pointValue: apiContent.point_value.toString(),
  // easier to delete and recreate, so let's not care about ids
  questionDetails: apiContent.question_details.map((question_detail) => ({
    payload: question_detail.payload,
    isCorrectAnswer: question_detail.is_correct_answer
  }))
});

const fromStateToApi = (formState) => ({
  payload: formState.payload,
  explanation: formState.explanation,
  point_value: fromPointValueStringToInt(formState.pointValue),

  question_details: formState.questionDetails.map((questionDetail) => ({
    payload: questionDetail.payload,
    is_correct_answer: questionDetail.isCorrectAnswer
  }))
});

export default {
  getInitialState,
  fromApiToState,
  fromStateToApi,
  fromPointValueStringToInt
};
