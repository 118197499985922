// fetch() polyfill
import 'whatwg-fetch';
// For older browsers not to choke on es7
import 'airbnb-js-shims';
// `URLSearchParams` polyfill for parsing query params
import 'url-search-params-polyfill';
// Make css variables work in IE11!
import cssVars from 'css-vars-ponyfill';
cssVars();

import $ from 'jquery';
import ReactDOM from 'react-dom';
import React from 'react';

import { GatewayProvider, GatewayDest } from 'react-gateway';

// This must be prefixed:
// 1. with template's folder name,
// 2. with REACT__.

// Entire pages:
import REACT__DashboardCourses from '~/pages/DashboardCourses';
import REACT__DashboardCourseDetail from '~/pages/DashboardCourseDetail';
import REACT__ProgramEditor from '~/pages/ProgramEditor';
import REACT__DashboardStudents from '~/pages/DashboardStudents';
import REACT__dashboardCertificates_id from '~/pages/dashboardCertificates_id';
import REACT__ProgramTest from '~/pages/ProgramTest';

// Partial components (because we haven't reactified entire page yet):
import REACT__BulkInviteStudentsTogglerAndModal from '~/_appPartials/BulkInviteStudentsTogglerAndModal';
import REACT__ProgramDescriptionTogglerAndModal from '~/_appPartials/ProgramDescriptionTogglerAndModal';

// Results components
import REACT__SurveyResultDetail from '../results/frontend/pages/SurveyResultDetail';

// For the tooltip
import 'react-tippy/dist/tippy.css';

const APPS = {
  REACT__DashboardCourses,
  REACT__DashboardCourseDetail,
  REACT__ProgramEditor,
  REACT__DashboardStudents,
  REACT__dashboardCertificates_id,
  REACT__ProgramTest,

  REACT__BulkInviteStudentsTogglerAndModal,
  REACT__ProgramDescriptionTogglerAndModal,

  REACT__SurveyResultDetail
};

// All commmon classes that can be used anywhere
import './index.scss';

// Temporary, because my chrome refuses to clear console on refreshes
console.clear();

function renderAppInElement(el) {
  const App = APPS[el.id];

  // Get props from elements data attribute, like the post_id
  const propsJson = el.dataset.props;
  const propsObject = propsJson ? JSON.parse(propsJson) : {};

  ReactDOM.render(
    <GatewayProvider>
      <React.Fragment>
        <App {...propsObject}/>
        <GatewayDest name="main"/>
      </React.Fragment>
    </GatewayProvider>,
    el
  );
}

$('[id^="REACT__"]').toArray()
  .forEach(renderAppInElement);
