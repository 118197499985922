import React from 'react';
import PropTypes from 'prop-types';
import { Rnd } from 'react-rnd';

import Utils from '~/services/Utils';
import ImageTogglerAndModal from '../../_contentModals/ImageTogglerAndModal';

class ImageContentLi extends React.Component {
  static propTypes = {
    itemType: PropTypes.string.isRequired,
    content: PropTypes.shape({
      content_type: PropTypes.oneOf(['image', 'cert-image']).isRequired,
      // html string
      payload: PropTypes.string.isRequired,
      options: PropTypes.string,
    }).isRequired,
    liProps: PropTypes.object.isRequired,
    uiUpdateContent: PropTypes.func.isRequired,
    uiDeleteContent: PropTypes.func.isRequired,
    uiUpdateCertificateElementLayout: PropTypes.func,
  }

  state = {
    ifEnableToggler: true,
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.itemType === 'certificate' && this.props.content) {
      if (nextProps.content.payload !== this.props.content.payload && this.resizer) {
        const resizerElement = this.resizer.getSelfElement();
        resizerElement.style.width = `${Utils.parseHtmlTagCSS(nextProps.content.payload, 'width')}px`;
      }
    }
  }

  uiUpdateContentElementLayout = (ifUpdateFinished) => {
    if (ifUpdateFinished) {
      if (!this.state.ifEnableToggler) {
        const contentElement = this.resizer ? this.resizer.getSelfElement() : null;

        if (contentElement) {
          this.props.uiUpdateCertificateElementLayout(contentElement);
        }

        setTimeout(() => this.setState({ ifEnableToggler: true }));
      }
    } else if (this.state.ifEnableToggler) {
      this.setState({ ifEnableToggler: false });
    }
  }

  renderCertToggler = (content) =>
    <Rnd
      className="certificate-resizer -image"
      ref={rndRef => this.resizer = rndRef}
      default={{
        x: content.options ? parseInt(content.options.split(', ')[0], 10) : 0,
        y: content.options ? parseInt(content.options.split(', ')[1], 10) : 0,
        width: Utils.parseHtmlTagCSS(content.payload, 'width'),
      }}
      bounds="parent"
      enableResizing={{
        bottom: false,
        top: false,
        bottomLeft: true,
        bottomRight: true,
        left: true,
        right: true,
        topLeft: true,
        topRight: true,
      }}
      maxWidth={800}
      onResize={() => this.uiUpdateContentElementLayout(false)}
      onResizeStop={() => this.uiUpdateContentElementLayout(true)}
      onDrag={() => this.uiUpdateContentElementLayout(false)}
      onDragStop={() => this.uiUpdateContentElementLayout(true)}
    >
      <div className="cert-image-container" dangerouslySetInnerHTML={{ __html: content.payload }}/>
    </Rnd>

  renderLi = (content) =>
    <li {...this.props.liProps}>
      <div dangerouslySetInnerHTML={{ __html: content.payload }}/>
    </li>

  render = () =>
    <ImageTogglerAndModal
      toggler={this.props.content.content_type === 'image' ? this.renderLi(this.props.content) : this.renderCertToggler(this.props.content)}
      enableToggler={this.state.ifEnableToggler}
      itemType={this.props.itemType}
      content={this.props.content}
      afterContentIsUpdated={this.props.uiUpdateContent}
      afterContentIsDeleted={this.props.uiDeleteContent}
    />
}

export default ImageContentLi;
