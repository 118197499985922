import React from 'react';
import PropTypes from 'prop-types';
import orFalse from '~/services/orFalse';

import ContentTogglerAndModalWrapper from '../ContentTogglerAndModalWrapper';
import FileUploadDropzone from '~/_partials/FileUploadDropzone';

import css from './index.scss';

const initialState = {
  url:         false,
  title:       '',
  description: ''
};

const fromApiToState = (api) => ({
  url:         api.payload ? api.payload : false,
  title:       api.title,
  description: api.description
});

const fromStateToApi = (formState) => ({
  payload:     formState.url ? formState.url : null,
  title:       formState.title,
  description: formState.description
});

class FileTogglerAndModal extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    content: orFalse(PropTypes.shape({
      content_type: PropTypes.oneOf(['file']).isRequired,
      // https://s3.amazonaws.com/module-uploads/uploads/files/de2e331.doc
      payload: PropTypes.string.isRequired,
      // Article on Stigma
      title: PropTypes.string.isRequired,
      // Please read this article as a part of this course.
      description: PropTypes.string.isRequired
    })).isRequired
  }

  render = () =>
    <ContentTogglerAndModalWrapper
      className={`${css.modal} -new`}
      title="File"
      initialState={initialState}
      fromApiToState={fromApiToState}
      fromStateToApi={fromStateToApi}
      {...this.props}
    >{(formState, updateFormState) =>
      <div className="standard-modal-main">
        <FileUploadDropzone
          className="file-upload-dropzone"
          url={formState.url}
          updateUrl={(url) => updateFormState({ ...formState, url })}
          afterFileWasDropped={(file) => updateFormState({ ...formState, title: file.name })}
        />

        {
          formState.url &&
          <React.Fragment>
            <label>
              <div className="label-text">Title</div>
              <input
                className="standard-input -TextInput"
                type="text"
                value={formState.title}
                onChange={(e) => updateFormState({ ...formState, title: e.target.value })}
              />
            </label>

            <label>
              <div className="label-text">Description</div>
              <textarea
                className="standard-input -Textarea"
                type="text"
                value={formState.description}
                onChange={(e) => updateFormState({ ...formState, description: e.target.value })}
              />
            </label>
          </React.Fragment>
        }
      </div>
    }</ContentTogglerAndModalWrapper>
}

export default FileTogglerAndModal;
