import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash/core';

import TogglerAndModal from '~/_partials/TogglerAndModal';
import Constants from '~/services/Constants';

import css from './index.scss';

class AddModuleTogglerAndModal extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
    toggler: PropTypes.element,
    modules: PropTypes.array.isRequired,
    onClose: PropTypes.func,
  };

  componentDidMount() {
    if (this.props.open && this.addButton) {
      this.addButton.click();
    }
  }

  handleItemClick = (value, closeModal) => {
    closeModal();

    if (value === 'Test') {
      this.props.onClose('Exam');
    } else {
      this.props.onClose(value);
    }
  };

  renderToggler = () =>
    <button className="standard-button -plum" ref={btnRef => this.addButton = btnRef}>+ Add Section</button>

  render() {
    const certificateModule = this.props.modules.find(module => module.program_type === 'Certificate');
    return (
      <TogglerAndModal
        toggler={this.props.toggler ? this.props.toggler : this.renderToggler()}
      >{(closeModal, renderCloseButton) =>
        <div className={`standard-modal ${css.modal}`}>
          {renderCloseButton()}

          <div className="standard-modal-header module-modal-header">
            Add section
          </div>

          <div className="standard-modal-main module-modal-main">
            <div className="type-list">
              {_.values(Constants.MODULE_TYPE).filter(type => type.visible)
                .map(type =>
                  <div
                    key={type.id}
                    className={`type-item ${(type.id === 'certificate' && certificateModule !== undefined) ? '-certificate-disabled' : ''}`}
                    onClick={() => this.handleItemClick(type.type, closeModal)}
                  >
                    { type.icon({ fontSize: 45 }) }
                    <h5>{type.label}</h5>
                  </div>
                )}
              <div className="last-item"/>
            </div>
          </div>
        </div>
      }</TogglerAndModal>
    );
  }
}

export default AddModuleTogglerAndModal;
