// // it's better because:
// // 1. defaults to propTypes without us doing [...formLineDefaultProps]
// // 2. we can pass props separately to LabelAndInput and inputEl
// <LabelAndInput
//   inputEl={}
//   label=""
//   className={}
//   required
// />
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash/core';

class LabelAndInput extends React.Component {
  static propTypes = {
    // ___not yet working like that, but consider implementing:
    //    accepts either a function, or an element
    //    if function - called with (id)
    //    if element - injects id (==htmlFor) as props
    inputEl: PropTypes.element.isRequired,
    label: PropTypes.string.isRequired,
    rightLabel: PropTypes.string,
    className: PropTypes.string,
    required: PropTypes.bool,
    onClick: PropTypes.func,
  }

  static defaultProps = {
    rightLabel: '',
    className: '',
    required: false
  }

  componentWillMount = () => {
    this.unique_id = _.uniqueId('LabelAndInput-');
  }

  render = () =>
    <div className={`label-and-input ${this.props.className}`} onClick={e => this.props.onClick ? this.props.onClick(e) : {}}>
      <label
        htmlFor={this.unique_id}
        className={this.props.required ? 'required' : ''}
      >{this.props.label}</label>

      <div className="input-wrapper">
        {React.cloneElement(
          this.props.inputEl,
          { id: this.unique_id }
        )}
      </div>

      { this.props.rightLabel.length > 0 && <label className="right-label">{this.props.rightLabel}</label> }
    </div>
}

export default LabelAndInput;
