import React from 'react';
import PropTypes from 'prop-types';

import injectFromOldToNewIndex from '~/services/injectFromOldToNewIndex';
import GetQuestionDetails from '../services/GetQuestionDetails';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SlidyTextInput from '~/_partials/SlidyTextInput';

const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

class MultipleChoiceAnswers extends React.Component {
  static propTypes = {
    itemType: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    questionDetails: PropTypes.array.isRequired,
    updateQuestionDetails: PropTypes.func.isRequired,
    ifNps: PropTypes.bool.isRequired,
  }

  uiUpdateQuestionDetailPayload = (questionDetail, index, payload) =>
    this.props.updateQuestionDetails([
      ...this.props.questionDetails.slice(0, index),
      { ...questionDetail, payload },
      ...this.props.questionDetails.slice(index + 1)
    ])

  uiUpdateQuestionDetailIsCorrect = (questionDetail, index) =>
    this.props.updateQuestionDetails(
      GetQuestionDetails.withCorrectAnswerAtIndex(this.props.questionDetails, index)
    )

  uiCreateQuestionDetail = () =>
    this.props.updateQuestionDetails(
      GetQuestionDetails.withNewOne(this.props.questionDetails)
    )

  uiDeleteQuestionDetail = (indexToDelete) =>
    this.props.updateQuestionDetails(
      GetQuestionDetails.withDeletedAtIndex(this.props.questionDetails, indexToDelete)
    )

  onDragEnd = (result) => {
    const ifDroppedInsideList = result.destination;
    if (!ifDroppedInsideList) return;

    const fromIndex = result.source.index;
    const toIndex = result.destination.index;

    const ifChangedPosition = toIndex !== fromIndex;
    if (ifChangedPosition) {
      this.uiReorderQuestionDetails(fromIndex, toIndex);
    }
  }

  uiReorderQuestionDetails = (fromIndex, toIndex) => {
    const reorderedModules = injectFromOldToNewIndex(this.props.questionDetails, fromIndex, toIndex);
    this.props.updateQuestionDetails(reorderedModules);
  }

  // let's limit the amount of answers to the amount of letters in alphabet
  ifCanAddQuestionDetails = () =>
    this.props.questionDetails.length < letters.length && !this.props.ifNps

  ifCanAddDeleteQuestionDetails = () =>
    this.props.questionDetails.length > 1

  renderQuestionDetail = (questionDetail, index) =>
    <Draggable key={index} draggableId={`question-detail-${index}`} index={index}>{(dragProvided, dragSnapshot) =>
      <li
        className={`answer ${dragSnapshot.isDragging ? '-is-dragging' : ''}`}
        ref={dragProvided.innerRef}
        {...dragProvided.draggableProps}
      >
        <i className="fa fa-bars" {...dragProvided.dragHandleProps}/>
        {this.props.itemType === 'test' &&
          <div className="if-correct-answer-radio-input">
            <input
              type="radio"
              checked={questionDetail.isCorrectAnswer}
              onChange={() => this.uiUpdateQuestionDetailIsCorrect(questionDetail, index)}
            />
          </div>
        }

        <SlidyTextInput
          className="answer-payload-slidy-input"
          label={`Answer ${letters[index]}`}
          type="text"
          value={questionDetail.payload}
          onChange={(event) => this.uiUpdateQuestionDetailPayload(questionDetail, index, event.target.value)}
        />
        {
          this.ifCanAddDeleteQuestionDetails() ?
            <button
              className="delete-answer-button"
              type="button"
              onClick={() => this.uiDeleteQuestionDetail(index)}
            >
              <i className="fa fa-trash"/>
            </button> :
            <div className="delete-answer-button-placeholder"/>
        }
      </li>
    }</Draggable>

  renderAddAnswerButton = () =>
    <button
      className="add-answer-button"
      type="button"
      onClick={this.uiCreateQuestionDetail}
    >Add an Answer</button>

  render = () =>
    <div className={`answers ${this.props.className} -multiple-choice`}>
      {this.props.itemType === 'test' &&
        <div className="correct-thead">Correct</div>
      }

      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="question-details">{(dropProvided, dropSnapshot) =>
          <ul
            ref={dropProvided.innerRef}
            {...dropProvided.droppableProps}
          >
            {this.props.questionDetails.map(this.renderQuestionDetail)}
            {dropProvided.placeholder}
          </ul>
        }</Droppable>
      </DragDropContext>

      {
        this.ifCanAddQuestionDetails() &&
        this.renderAddAnswerButton()
      }
    </div>
}

export default MultipleChoiceAnswers;
