import ourFetch from './services/ourFetch';
// import hashToQueryString from './services/hashToQueryString';

const updateScoringType = (dispatch, moduleOrExamId, scoringType) =>
  ourFetch(
    dispatch,
    'PUT', `/api/module-or-exams/${moduleOrExamId}/update-scoring-type/`,
    { scoring_type: scoringType }
  );

export default {
  updateScoringType
};
