import React from 'react';
import PropTypes from 'prop-types';
import orFalse from '~/services/orFalse';

import disableOnSpeRequest from '~/services/disableOnSpeRequest';
import ContentApi from '~/api/ContentApi';
import TransloaditApi from '~/api/TransloaditApi';
import ServiceApi from '~/api/ServiceApi';

import TogglerAndModal from '~/_partials/TogglerAndModal';
import Loading from '~/_partials/Loading';

import FromDeviceOrFromUrlNav from './_partials/FromDeviceOrFromUrlNav';
import SectionUploadFromDevice from './_partials/SectionUploadFromDevice';
import SectionUploadFromUrl from './_partials/SectionUploadFromUrl';

import css from './index.scss';

const initialState = {
  // => false, 'fromUrl', 'fromDevice'
  typeOfUpload: 'fromDevice',
  speSave: {},

  formState: {
    file: false,
    videoUrl: '',
    mustWatch: false
  }
};

class VideoTogglerAndModal extends React.Component {
  static propTypes = {
    toggler: PropTypes.element.isRequired,

    content: orFalse(PropTypes.object).isRequired,
    createWith: orFalse(PropTypes.shape({
      moduleOrExamId: PropTypes.number.isRequired,
      itemType: PropTypes.oneOf(['module', 'test']).isRequired,
      contentType: PropTypes.oneOf(['video']).isRequired,
      position: PropTypes.number.isRequired
    })),

    afterContentIsCreated: PropTypes.func,
    afterContentIsUpdated: PropTypes.func,
    afterContentIsDeleted: PropTypes.func,
    apiProceedWithUploadingFileViaTus: PropTypes.func
  }

  static defaultProps = {
    afterContentIsCreated: () => {},
    afterContentIsUpdated: () => {},
    afterContentIsDeleted: () => {},
    apiProceedWithUploadingFileViaTus: () => {}
  }

  state = initialState

  afterModalOpens = () => {
    if (this.props.content) {
      this.setState({
        ...initialState,
        formState: {
          mustWatch: this.props.content.must_watch
        }
      });
    } else {
      this.setState(initialState);
    }
  }

  updateFormState = (hash) =>
    this.setState({
      formState: {
        ...this.state.formState,
        ...hash
      }
    })

  updateTypeOfUpload = (typeOfUpload) =>
    this.setState({
      ...initialState,
      typeOfUpload
    })

  apiCreateContent = (awsS3Url) =>
    ContentApi.create(
      (spe) => this.setState({ speSave: spe }),
      {
        payload: awsS3Url,
        must_watch: this.state.formState.mustWatch,

        item_type: this.props.createWith.itemType,
        module_or_exam_id: this.props.createWith.moduleOrExamId,
        content_type: 'video',
        position: this.props.createWith.position
      }
    )

  apiSave = (closeModal) => (
    this.props.content ?
      this.apiUpdate(closeModal) :
      this.apiCreate(closeModal)
  )

  apiUpdate = (closeModal) =>
    ContentApi.update(
      (spe) => this.setState({ speSave: spe }),
      this.props.content.id,
      {
        must_watch: this.state.formState.mustWatch
      }
    )
      .then((updatedContent) => {
        this.props.afterContentIsUpdated(updatedContent);
        closeModal();
      })

  apiCreate = (closeModal) => {
    if (this.state.typeOfUpload === 'fromDevice') {
      const file = this.state.formState.file;
      TransloaditApi.createAssemblyUploadVideoViaTus(
        (speSave) => this.setState({ speSave })
      )
        .then(({ _awsS3Url, _urlForTusUpload, _transloaditAssemblyUrl }) => {
          ContentApi.create(
            (spe) => this.setState({ speSave: spe }),
            {
              payload: _awsS3Url,
              transloadit_assembly_url: _transloaditAssemblyUrl,
              must_watch: this.state.formState.mustWatch,

              item_type: this.props.createWith.itemType,
              module_or_exam_id: this.props.createWith.moduleOrExamId,
              content_type: 'video',
              position: this.props.createWith.position
            }
          )
            .then((createdContent) => {
              this.props.afterContentIsCreated(createdContent);
              this.props.apiProceedWithUploadingFileViaTus({
                content: createdContent,
                file,
                tusUrl: _urlForTusUpload,
                assemblyUrl: _transloaditAssemblyUrl
              });
              closeModal();
            });
        });
    } else if (this.state.typeOfUpload === 'fromUrl') {
      const possiblyIndirectUrl = this.state.formState.videoUrl;
      ServiceApi.getDirectVideoUrl(
        (spe) => this.setState({ speSave: spe }),
        possiblyIndirectUrl
      )
        .then(({ direct_video_url }) => {
          TransloaditApi.createAssemblyUploadVideoViaUrl(
            (spe) => this.setState({ speSave: spe }),
            direct_video_url
          )
            .then(({ _awsS3Url, _transloaditAssemblyUrl }) => {
              ContentApi.create(
                (spe) => this.setState({ speSave: spe }),
                {
                  payload: _awsS3Url,
                  transloadit_assembly_url: _transloaditAssemblyUrl,
                  must_watch: this.state.formState.mustWatch,

                  item_type: this.props.createWith.itemType,
                  module_or_exam_id: this.props.createWith.moduleOrExamId,
                  content_type: 'video',
                  position: this.props.createWith.position
                }
              )
                .then((createdContent) => {
                  this.props.afterContentIsCreated(createdContent);
                  closeModal();
                });
            });
        });
    } else {
      throw Error(`Type of upload can't be ${this.state.typeOfUpload}`);
    }
  }

  apiDelete = (closeModal) =>
    ContentApi.delete(
      (spe) => this.setState({ speSave: spe }),
      this.props.content.id
    )
      .then(() => {
        closeModal();
        this.props.afterContentIsDeleted(this.props.content.id);
      })

  renderInstructions = () =>
    <section className="instructions">
      <div className="more-important">Upload video from your computer, or from the URl.</div>
      <div className="less-important">Supported formats: MP4, FLV, AVI, WMV , MOV, MPEGPS, WebM and MPEG4</div>
    </section>

  renderFooter = (closeModal) =>
    <div className="standard-modal-footer -with-buttons">
      {
        this.props.content &&
        <button
          className="standard-button -sliding -red"
          type="button"
          onClick={() => this.apiDelete(closeModal)}
          style={disableOnSpeRequest(this.state.speSave)}
        >Delete</button>
      }

      <button
        className="standard-button -sliding -red"
        type="reset"
        onClick={closeModal}
        style={disableOnSpeRequest(this.state.speSave)}
      >Cancel</button>

      <button
        className={`standard-button -sliding -blue ${!this.props.content && !(this.state.formState.file || this.state.formState.videoUrl) ? '-disabled' : ''}`}
        type="submit"
        onClick={() => this.apiSave(closeModal)}
        style={disableOnSpeRequest(this.state.speSave)}
      >Save</button>
    </div>

  renderUploadField = () => {
    switch (this.state.typeOfUpload) {
      case 'fromDevice':
        return <SectionUploadFromDevice
          file={this.state.formState.file}
          updateFile={(file) => this.updateFormState({ file })}
        />;
      case 'fromUrl':
        return <SectionUploadFromUrl
          videoUrl={this.state.formState.videoUrl}
          updateVideoUrl={(videoUrl) => this.updateFormState({ videoUrl })}
        />;
      default:
        throw Error(`Type of upload can't be ${this.state.typeOfUpload}`);
    }
  }

  renderMustWatchCheckbox = () =>
    <label className="if-must-watch-entire-video">
      <input
        type="checkbox"
        checked={this.state.formState.mustWatch}
        onChange={(event) => this.updateFormState({ mustWatch: event.target.checked })}
      />
      Student is required to watch entire video
    </label>

  renderNewContentForm = () =>
    <div className="standard-modal-main -new">
      {this.renderInstructions()}
      <FromDeviceOrFromUrlNav
        typeOfUpload={this.state.typeOfUpload}
        updateTypeOfUpload={this.updateTypeOfUpload}
      />
      {this.renderUploadField()}
      {this.renderMustWatchCheckbox()}
    </div>

  renderVideoTag = () =>
    <video controls>
      <source src={this.props.content.payload}/>
    </video>

  renderOldContentForm = () =>
    <div className="standard-modal-main -old">
      {this.renderVideoTag()}
      {this.renderMustWatchCheckbox()}
    </div>

  render = () =>
    <TogglerAndModal
      toggler={this.props.toggler}
      afterModalOpens={this.afterModalOpens}
    >{(closeModal, renderCloseButton) =>
      <div className={`standard-modal ${css.modal} -new`}>
        {renderCloseButton()}

        <div className="standard-modal-header">Video</div>

        {
          this.props.content ?
            this.renderOldContentForm() :
            this.renderNewContentForm()
        }

        <Loading className="standard-modal-spe" spe={this.state.speSave}/>

        {this.renderFooter(closeModal)}
      </div>
    }</TogglerAndModal>
}

export default VideoTogglerAndModal;
