import React from 'react';
import PropTypes from 'prop-types';

import Utils from '~/services/Utils';
import StudentApi from '~/api/StudentApi';
import TestService from '../../services/TestService';

class LikertContent extends React.Component {
  static propTypes = {
    content: PropTypes.object.isRequired,
    if_current_module_is_completed: PropTypes.bool.isRequired,
  }

  uiUpdateLikertAnswer = (answerId, option) => {
    const { content, if_current_module_is_completed } = this.props;

    if (!if_current_module_is_completed) {
      StudentApi.answerLikert(false, {
        content_id: content.id,
        answer_id: answerId,
        option,
      }).then(() => {
        TestService.countItems();
      });
    }
  }

  render = () => {
    const { content, if_current_module_is_completed } = this.props;
    const options = JSON.parse(content.options) || [];
    return (
      <div className="test-likert">
        <table>
          <thead>
            <tr>
              <th/>
              {options.map((option, index) =>
                <th key={index}>
                  <div className="likert-option-wrapper">
                    <div className="likert-info">{Utils.stripHtml(option.value)}</div>
                    <div className="likert-info -label">{Utils.stripHtml(option.label)}</div>
                  </div>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {content.answers.map((answer, answerIndex) =>
              <tr key={answerIndex}>
                <td>
                  <div className="likert-info likert-question">{Utils.stripHtml(answer.payload)}</div>
                </td>
                {options.map((option, index) =>
                  <td key={index}>
                    <div className="likert-info">
                      <input
                        className="likert-option"
                        type="radio"
                        name={`likert-option-${answerIndex + 1}`}
                        defaultChecked={answer.selected === option.value.toString()}
                        value={option.value}
                        disabled={if_current_module_is_completed}
                        onChange={() => this.uiUpdateLikertAnswer(answer.id, option.value)}
                      />
                    </div>
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default LikertContent;
