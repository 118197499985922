import $ from 'jquery';
import getFittedText from './getFittedText';

const initialState = {
  company_name: '',
  company_photo: false,
  send_cert: false,
  custom_certificate_text: '',
  custom_field_1: '',
  custom_field_2: '',
  options: []
};

const fromStateToApi = (formState) => ({
  company_name: formState.company_name,
  company_photo: formState.company_photo || null,
  send_cert: formState.send_cert,
  custom_certificate_text: formState.custom_certificate_text,
  custom_field_1: formState.custom_field_1,
  custom_field_2: formState.custom_field_2,
  options: formState.options
});

// @param api_custom_certificate_text - '<pre style="font-size: 29px;...">Congratulation, my dear friend</pre>'
const _getTextFromCertificateTextPre = (api_custom_certificate_text) => {
  try {
    // if it's by any chance not a <pre/> in backend, this will raise an error
    return api_custom_certificate_text;
  } catch (e) {
    return api_custom_certificate_text;
  }
};

const fromApiToState = (api) => ({
  company_name: api.company_name,
  company_photo: api.company_photo || false,
  send_cert: api.send_cert,
  custom_certificate_text: api.custom_certificate_text,
  custom_field_1: api.custom_field_1,
  custom_field_2: api.custom_field_2,
  options: api.options
});

export default {
  initialState,
  fromStateToApi,
  fromApiToState
};
