import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import StudentIcon from '@material-ui/icons/AssignmentIndOutlined';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import GroupApi from '~/api/GroupApi';
import StudentApi from '~/api/StudentApi';
import Constants from '~/services/Constants';
import SpeCreator from '~/services/SpeCreator';
import Loading from '~/_partials/Loading';
import { TextInput } from '~/_partials/standardForm';
import TogglerAndModal from '~/_partials/TogglerAndModal';
import StandardAlert from '~/_partials/StandardAlert';

import css from './index.scss';

const ALL_STUDENTS_DROPPABLE_ID     = 'allStudents';
const GROUP_STUDENTS_DROPPABLE_ID  = 'groupStudents';

const initialFormState = {
  first_name: '',
  last_name: '',
  email: '',
};

class AddStudentTogglerAndModal extends React.Component {
  static propTypes = {
    toggler: PropTypes.element.isRequired,
    groupId: PropTypes.number.isRequired,
    onUpdateStudents: PropTypes.func.isRequired,
  };

  state = {
    speSave: SpeCreator.empty(),
    allStudents: [],
    originalStudentsInGroup: [],
    studentsInGroup: [],
    speInvite: SpeCreator.empty(),
    ifShowInviteStudentsForm: false,
    ifInviteSent: false,
    alertMessage: '',
    inviteError: '',
    formState: {
      first_name: '',
      last_name: '',
      email: '',
    }
  }

  componentDidMount() {
    this.apiGetPage()
      .then(result =>
        this.setState({
          allStudents: result.allStudents,
          originalStudentsInGroup: result.studentsInGroup,
          studentsInGroup: result.studentsInGroup,
        })
      );
  }

  apiGetPage = () =>
    GroupApi.getStudents(
      false,
      {
        group: this.props.groupId,
      }
    );

  apiAddStudents = () =>
    GroupApi.addStudents(
      (spe) => this.setState({ speSave: spe }),
      {
        students: this.state.studentsInGroup,
        group: this.props.groupId,
      }
    );

  apiGetGroupStudents = () =>
    StudentApi.getGroupStudents(
      (spe) => this.setState({ speMoveGroup: spe }),
      {
        group: this.props.groupId,
      }
    );

  apiInviteStudent = (newStudent) =>
    StudentApi.inviteStudent(
      (spe) => this.setState({ speInvite: spe }),
      {
        ...newStudent,
      }
    );

  insertStudentToGroup = (student, toIndex) => {
    const { studentsInGroup } = this.state;
    const findIndex = studentsInGroup.findIndex(oldStudent => student.type === oldStudent.type && student.id === oldStudent.id);

    if (findIndex < 0) {
      this.setState({
        studentsInGroup: [
          ...studentsInGroup.slice(0, toIndex),
          student,
          ...studentsInGroup.slice(toIndex),
        ]
      });
    }
  };

  uiRemoveStudent = (removedStudent) => {
    const { studentsInGroup } = this.state;
    this.setState({
      studentsInGroup: studentsInGroup.filter(student => student.id !== removedStudent.id)
    });
  }

  updateFormState = (formState) => {
    this.setState({
      formState,
      ifInviteSent: false,
    });
  }

  onDragEnd = (result, allStudentsNotInGroup) => {
    const ifDroppedInsideList = result.destination;
    if (!ifDroppedInsideList) return;

    const source = result.source;
    const destination = result.destination;

    if (source.droppableId === GROUP_STUDENTS_DROPPABLE_ID || destination.droppableId === ALL_STUDENTS_DROPPABLE_ID) {
      return;
    }

    const fromIndex = source.index;
    const toIndex = destination.index;
    const sourceObject = allStudentsNotInGroup[fromIndex];
    this.insertStudentToGroup(sourceObject, toIndex);
  }

  handleInviteStudentsClick = () => {
    const { ifShowInviteStudentsForm } = this.state;

    if (!ifShowInviteStudentsForm) {
      this.setState({ ifShowInviteStudentsForm: true });
    } else {
      this.setState({
        speInvite: SpeCreator.empty(),
        ifShowInviteStudentsForm: false,
        ifInviteSent: false,
        inviteError: '',
        formState: initialFormState,
      });
    }
  }

  handleInviteStudentSubmit = () => {
    const { allStudents, studentsInGroup, formState } = this.state;
    const newStudent = {
      first_name: formState['first_name'],
      last_name: formState['last_name'],
      email: formState['email'],
    };
    this.setState({ ifInviteSent: false });

    if (newStudent.first_name.length === 0) {
      this.setState({ inviteError: 'Please input first name.', speInvite: SpeCreator.empty() });
    } else if (newStudent.last_name.length === 0) {
      this.setState({ inviteError: 'Please input last name.', speInvite: SpeCreator.empty() });
    } else if (newStudent.email.length === 0) {
      this.setState({ inviteError: 'Please input email.', speInvite: SpeCreator.empty() });
    } else {
      const findIndex = allStudents.findIndex(student => student.email === newStudent.email);

      if (findIndex < 0) {
        this.setState({ inviteError: '' });
        this.apiInviteStudent(newStudent)
          .then(result => {
            if (result.message === 'This user is already registered.') {
              this.setState({
                inviteError: 'Already Registered',
                alertMessage: 'This user is already registered.',
              });
            } else {
              newStudent.id = result.id;
              newStudent.type = 'invite';
              this.setState({
                ifInviteSent: true,
                inviteError: '',
                allStudents: [
                  ...allStudents,
                  newStudent
                ]
              });
              this.insertStudentToGroup(newStudent, studentsInGroup.length);
              setTimeout(() => {
                this.handleInviteStudentsClick();
              }, 500);
            }
          });
      } else {
        this.setState({
          inviteError: 'Duplicate Email',
          alertMessage: 'Duplicate Email!',
        });
      }
    }
  }

  hancleCancelClick = (closeModal) => {
    this.setState({
      studentsInGroup: this.state.originalStudentsInGroup,
      speInvite: SpeCreator.empty(),
      speMoveGroup: SpeCreator.empty(),
      ifShowInviteStudentsForm: false,
      inviteError: '',
      formState: initialFormState,
      ifInviteSent: false,
    });
    closeModal();
  }

  handleSaveClick = (closeModal) => {
    this.apiAddStudents()
      .then(() => {
        this.setState({
          originalStudentsInGroup: this.state.studentsInGroup,
          ifShowInviteStudentsForm: false,
          inviteError: '',
        });
        this.props.onUpdateStudents();
        closeModal();
      });
  }

  renderFooter = (closeModal) =>
    <div className="standard-modal-footer -with-buttons">
      <button
        className="standard-button -sliding -red"
        type="reset"
        onClick={() => this.hancleCancelClick(closeModal)}
      >Cancel</button>
      <button
        className="standard-button -sliding -blue"
        type="submit"
        onClick={() => this.handleSaveClick(closeModal)}
      >Save</button>
    </div>

  render() {
    const { speSave, speInvite, allStudents, studentsInGroup, formState, ifShowInviteStudentsForm, ifInviteSent, alertMessage, inviteError } = this.state;
    const groupStudentsIds = studentsInGroup.map(student => student.id);
    const allStudentsNotInGroup = allStudents.filter(student => !groupStudentsIds.includes(student.id));
    const formProps = {
      formState,
      updateFormState: this.updateFormState
    };
    return (
      <TogglerAndModal
        toggler={this.props.toggler}
      >{(closeModal, renderCloseButton) =>
        <div className={`standard-modal ${css.modal} -with-white-header -student-modal`}>
          {renderCloseButton()}

          <div className="standard-modal-header">Add Student to Group</div>
          <div className="standard-modal-main group-modal-main">
            <h4>Drag and drop Students and Groups to add them to this group.</h4>
            <div className="body-container">
              <DragDropContext onDragEnd={result => this.onDragEnd(result, allStudentsNotInGroup)}>
                <div className="left-panel">
                  <div className="title-bar">
                    <h2 className="standard-modal-title">{!ifShowInviteStudentsForm ? 'All Students' : 'Invite New Student'}</h2>
                    <button
                      className={classNames('standard-button -underline', { '-cancel': ifShowInviteStudentsForm })}
                      onClick={this.handleInviteStudentsClick}
                    >{!ifShowInviteStudentsForm ? '+ Invite Students' : 'Cancel'}</button>
                  </div>
                  {!ifShowInviteStudentsForm ?
                    <Droppable droppableId={ALL_STUDENTS_DROPPABLE_ID}>{(dropProvided) =>
                      <ul
                        className="students-list"
                        ref={dropProvided.innerRef}
                        {...dropProvided.droppableProps}
                      >
                        {allStudentsNotInGroup.map((student, index) =>
                          <Draggable draggableId={`all-${index}`} key={index} index={index}>{(dragProvided) =>
                            <li
                              ref={dragProvided.innerRef}
                              {...dragProvided.draggableProps}
                              {...dragProvided.dragHandleProps}
                            >
                              <div className="drag-icon">
                                <StudentIcon/>
                              </div>
                              {`${student.first_name} ${student.last_name}`}
                            </li>
                          }</Draggable>
                        )}
                      </ul>
                    }</Droppable> :
                    <form className="invitation-form">
                      <TextInput name="first_name" maxLength={Constants.MAX_NAME_LENGTH} placeholder="First Name" {...formProps}/>
                      <TextInput name="last_name" maxLength={Constants.MAX_NAME_LENGTH} placeholder="Last Name" {...formProps}/>
                      <TextInput name="email" placeholder="Email" {...formProps}/>
                      <button
                        type="button"
                        className="standard-button -outline btn-form-submit"
                        onClick={this.handleInviteStudentSubmit}
                      >Invite Student</button>
                      <Loading className="standard-modal-spe invitation-loading" spe={speInvite}/>
                      {inviteError.length > 0 &&
                        <div className="invite-status -error">{inviteError}</div>
                      }
                      {ifInviteSent &&
                        <div className="invite-status -sent">Invite Sent!</div>
                      }
                    </form>
                  }
                </div>

                <div className="right-panel">
                  <h2 className="standard-modal-title">Students In this Group</h2>
                  <Droppable droppableId={GROUP_STUDENTS_DROPPABLE_ID}>{(dropProvided) =>
                    <ul
                      className="students-list"
                      ref={dropProvided.innerRef}
                      {...dropProvided.droppableProps}
                    >
                      {studentsInGroup.map((student, index) =>
                        <Draggable draggableId={`course-${index}`} key={index} index={index}>{(dragProvided) =>
                          <li
                            ref={dragProvided.innerRef}
                            {...dragProvided.draggableProps}
                            {...dragProvided.dragHandleProps}
                          >
                            <div className="drag-icon">
                              <StudentIcon/>
                            </div>
                            <span>{`${student.first_name} ${student.last_name}`}</span>
                            <button className="btn-remove-student" onClick={() => this.uiRemoveStudent(student)}>
                              <i className="fa fa-times"/>
                            </button>
                          </li>
                        }</Draggable>
                      )}
                    </ul>
                  }</Droppable>
                </div>
              </DragDropContext>
            </div>
          </div>

          <Loading className="standard-modal-spe" spe={speSave}/>

          {this.renderFooter(closeModal)}

          <StandardAlert
            open={alertMessage !== ''}
            type={Constants.ALERT_TYPE.warning}
            message={alertMessage}
            onClose={() => this.setState({ alertMessage: '' })}
          />
        </div>
      }</TogglerAndModal>
    );
  }
}

export default AddStudentTogglerAndModal;
