import fetchWrapper from './fetchWrapper';
import handleErrors from './handleErrors';
import getCookie from './getCookie';

// Because lots of old api methods require formData for POST requests
const oldApiFetch = (dispatch, method, url, params) => {
  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    formData.append(key, params[key]);
  });
  return fetchWrapper(
    dispatch,
    fetch(url, {
      method,
      headers: {
        'X-CSRFToken': getCookie('csrftoken')
      },
      credentials: 'same-origin',
      body: formData
    })
      .then(handleErrors)
  );
};

export default oldApiFetch;
