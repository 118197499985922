import React from 'react';
import PropTypes from 'prop-types';
import StudentApi from '~/api/StudentApi';
import TestService from '../../services/TestService';

class RatingScaleContent extends React.Component {
  static propTypes = {
    content: PropTypes.object.isRequired,
    if_current_module_is_completed: PropTypes.bool.isRequired,
  }

  state = {
    selectedRating: 0
  }

  uiUpdateRatingScale = (rating) => {
    if (!this.props.if_current_module_is_completed) {
      const { selectedRating } = this.state;
      this.setState({ selectedRating: rating });
      StudentApi.answerRatingStar(false,  {
        content_id: this.props.content.id,
        stars: rating,
      }).then(() => {
        TestService.countItems();
      }).catch(() => {
        this.setState({ selectedRating });
      });
    }
  }

  render = () => {
    const { content, if_current_module_is_completed } = this.props;
    const { selectedRating } = this.state;
    const ratingButtons = [];
    const currentRatingScore = (if_current_module_is_completed && content.answers.length > 0) ? parseInt(content.answers[0].payload, 10) : selectedRating;

    for (let i = 0; i < 5; i += 1) {
      ratingButtons.push(
        <button
          key={i}
          className="button-rating"
          disabled={if_current_module_is_completed}
          onClick={() => this.uiUpdateRatingScale(i + 1)}
        >
          <i className={`glyphicon ${i >= currentRatingScore ? 'glyphicon-star-empty' : 'glyphicon-star'}`}/>
        </button>
      );
    }

    return (
      <div className="rating-stars test-answers">
        {ratingButtons}
      </div>
    );
  }
}

export default RatingScaleContent;
