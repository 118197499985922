import React from 'react';
import PropTypes from 'prop-types';

import TogglerAndModal from '~/_partials/TogglerAndModal';
import PLAYICON_SVG from '~/../static/frontend/img/video-play-icon.svg';

import css from './index.scss';

class WelcomeModal extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
  }

  state = {
    ifVideoPlayed: false,
  }

  componentDidMount() {
    if (this.props.open && this.togglerButton) {
      this.togglerButton.click();
    }
  }

  handleVideoPlayClick = () => {
    if (this.videoControl) {
      this.videoControl.play();
      this.setState({ ifVideoPlayed: true });
    }
  }

  handleSkipVideoClick = (closeModal) => {
    closeModal();
  }

  renderToggler = () =>
    <button ref={btnRef => this.togglerButton = btnRef}/>

  render = () =>
    <TogglerAndModal
      toggler={this.renderToggler()}
      afterModalOpens={this.afterModalOpens}
    >{(closeModal, renderCloseButton) =>
      <div className={`standard-modal ${css.modal} -with-white-header`}>
        {renderCloseButton()}

        <div className="welcome-header"/>

        <div className="welcome-mdoal-main">
          <div className="video-container">
            <video
              controls={this.state.ifVideoPlayed}
              ref={videoRef => this.videoControl = videoRef}
            >
              <source src="/static/frontend/sales/video/certcentral_tutorial_video-final_cut__without_trusted_partners_.mp4"/>
            </video>
            {!this.state.ifVideoPlayed &&
              <button className="btn-play" onClick={this.handleVideoPlayClick}>
                <img src={PLAYICON_SVG}/>
              </button>
            }
          </div>

          <div className="description-container">
            <p className="time-info">Learn certcentral in 2 minutes!</p>
            <p className="join-text">Join us for a short tour after which you will be able to play around with a sample course!</p>
          </div>
        </div>

        <div className="standard-modal-footer welcome-footer">
          <button
            className="standard-button -blue"
            type="button"
            onClick={() => this.handleSkipVideoClick(closeModal)}
          >Skip Video</button>
        </div>
      </div>
    }</TogglerAndModal>
}

export default WelcomeModal;
