import React from 'react';
import PropTypes from 'prop-types';
import orFalse from '~/services/orFalse';

import prettifyBytes from '~/services/prettifyBytes';

import css from './index.scss';

class VideoFile extends React.Component {
  static propTypes = {
    file: orFalse(PropTypes.object).isRequired,
    className: PropTypes.string.isRequired
  }

  static defaultProps = {
    className: ''
  }

  render = () =>
    <div className={`${this.props.className} ${css.div}`}>
      <div className="icon_and_size">
        <i className="fa fa-file-video-o"/>
        <div className="size">{prettifyBytes(this.props.file.size)}</div>
      </div>
      <div className="name">{this.props.file.name}</div>
    </div>
}

export default VideoFile;

