import React from 'react';
import PropTypes from 'prop-types';

import SubscriptionPlanApi from '~/api/SubscriptionPlanApi';

import Loading from '~/_partials/Loading';
import SwitchInput from '~/_partials/SwitchInput';
import SubscriptionPlanLi from './_partials/SubscriptionPlanLi';
// import BuySubscriptionModal from './_partials/BuySubscriptionModal';

import save20PercentPngSrc from './save20Percent.png';

import css from './index.scss';

class SelectSubscriptionPlan extends React.Component {
  static propTypes = {
    continueUrl: PropTypes.string.isRequired
  }

  state = {
    speSubscriptionPlans: {},
    monthlyOrYearly: 'Yearly',
    selectedSubscriptionPlan: false
  }

  componentDidMount = () => {
    this.getAllSubscriptionPlans();
  }

  getAllSubscriptionPlans = () =>
    SubscriptionPlanApi.index(
      (spe) => this.setState({ speSubscriptionPlans: spe })
    )

  renderSubscriptionPlan = (name, interval) => {
    const subscriptionPlan = this.state.speSubscriptionPlans.payload.find((sp) => (
      name === 'Enterprise' ?
        sp.name === name :
        sp.name === name && sp.interval === interval
    ));
    return <SubscriptionPlanLi
      key={subscriptionPlan.id}
      subscriptionPlan={subscriptionPlan}
      // updateSelectedSubscriptionPlan={(selectedSubscriptionPlan) => this.setState({ selectedSubscriptionPlan })}
      continueUrl={this.props.continueUrl}
    />;
  }

  render = () =>
    <section className={css.section}>
      <div className="switch-input_and_img">
        <SwitchInput
          className="switch-input"
          value={this.state.monthlyOrYearly}
          updateValue={(value) => this.setState({ monthlyOrYearly: value })}
          possibleValues={['Monthly', 'Yearly']}
          labelLeft="MONTHLY"
          labelRight="YEARLY"
        />
        <img src={save20PercentPngSrc}/>
      </div>

      {
        // this.state.selectedSubscriptionPlan &&
        // <BuySubscriptionModal
        //   subscriptionPlan={this.state.selectedSubscriptionPlan}
        //   closeModal={() => this.setState({ selectedSubscriptionPlan: false })}
        //   afterSubscriptionIsBought={this.props.afterSubscriptionIsBought}
        // />
      }

      <Loading spe={this.state.speSubscriptionPlans}>{() =>
        <ul className="subscription-plans">
          {this.renderSubscriptionPlan('Starter', this.state.monthlyOrYearly)}
          {this.renderSubscriptionPlan('Professional', this.state.monthlyOrYearly)}
          {this.renderSubscriptionPlan('Executive', this.state.monthlyOrYearly)}
          {this.renderSubscriptionPlan('Enterprise')}
        </ul>
      }</Loading>
    </section>
}

export default SelectSubscriptionPlan;
