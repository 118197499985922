import React from 'react';
import PropTypes from 'prop-types';

import Urls from '~/services/Urls';

import css from './index.scss';

class TopNav extends React.Component {
  static propTypes = {
    program: PropTypes.object.isRequired
  }

  // current program is the original!
  getPreviewHref = () => {
    const program = this.props.program;
    switch (program.program_type) {
      case 'CP': return Urls.getCoursePreviewUrl(program.latest_version_id);
      case 'Exam': return Urls.getExamPreviewUrl(program.latest_version_id);
    }
  }

  getEditHref = () => {
    const program = this.props.program;
    return Urls.getCourseEditorUrl({
      courseId: program.latest_version_id,
      moduleId: program.latest_version_first_module_id,
      itemType: 'module'
    });
  }

  render = () =>
    <nav className={`top-nav ${css.nav}`}>
      <div className="left-links">
        <a href="/dashboard-programs/">
          <i className="fa fa-angle-double-left"/> Back
        </a>
      </div>

      <div className="right-links">
        <a className="link" href={this.getEditHref()}>
          <div className="icon"><i className="fa fa-pencil"/></div>
          <div className="text">Edit Exam</div>
        </a>

        <a className="link" href={this.getPreviewHref()}>
          <div className="icon"><i className="fa fa-search"/></div>
          <div className="text">Preview</div>
        </a>

        <div className="link">
          <div className="icon">{this.props.program.active ? 'OPEN' : 'CLOSED'}</div>
          <div className="text">Status</div>
        </div>
      </div>
    </nav>
}

export default TopNav;
