import oldApiFetch from './services/oldApiFetch';
import ourFetch from './services/ourFetch';

const getStudent = (dispatch, stuent_id) =>
  ourFetch(dispatch,
    'GET',
    `/api/students/${stuent_id}/`,
  );

const getProgramStudents = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/program/students/',
    params,
  );

const getGroups = (dispatch) =>
  ourFetch(dispatch,
    'GET',
    '/api/groups/get-groups/',
  );

const addStudent = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/student/add/',
    params,
  );

const inviteStudent = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/student/invite',
    params,
  );

const setExtraTime = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/student/extra-time/',
    params,
  );

const reissueCourse = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/student/reissue-program/',
    params,
  );

const sendReminder = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/program/send-reminder/',
    params,
  );

const removeStudent = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/student/remove/',
    params,
  );


const addGroup = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/groups/create/',
    params,
  );

const updateTestTime = (dispatch, params) =>
  ourFetch(dispatch,
    'POST',
    '/api/test/time/',
    params,
  );

const verifyQuestionAnswered = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/verify/question/answered/',
    params,
  );

const continueTest = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/test/continue/',
    params,
  );

const completeTest = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/test/complete/',
    params,
  );

const answerTest = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/test/answer/',
    params,
  );

const answerFreeResponse = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/test/answer/freeresponse/',
    params,
  );

const answerRatingStar = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/test/answer/stars/',
    params,
  );

const answerLikert = (dispatch, params) =>
  oldApiFetch(dispatch,
    'POST',
    '/api/test/answer/likert/',
    params,
  );

export default {
  getStudent,
  getProgramStudents,
  getGroups,
  addStudent,
  inviteStudent,
  setExtraTime,
  reissueCourse,
  sendReminder,
  removeStudent,
  addGroup,
  updateTestTime,
  verifyQuestionAnswered,
  continueTest,
  completeTest,
  answerTest,
  answerFreeResponse,
  answerRatingStar,
  answerLikert,
};
