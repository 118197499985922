import React from 'react';
import PropTypes from 'prop-types';
import orFalse from '~/services/orFalse';

import Urls from '~/services/Urls';
import TutorialUserLookupApi from '~/api/TutorialUserLookupApi';

import SubscriptionPlansModal from './SubscriptionPlansModal';

class ContinueButton extends React.Component {
  static propTypes = {
    editorForCourseOrExam: PropTypes.oneOf(['course', 'exam']),
    itemType: PropTypes.string.isRequired,

    // - either
    exam: orFalse(PropTypes.object).isRequired,
    // - or
    course: orFalse(PropTypes.object).isRequired,
    moduleId: orFalse(PropTypes.number).isRequired
  }

  state = {
    ifSubscriptionPlansModalIsOpen: false,
    ifUserShouldSeeSubscriptionPlansModal: true
  }

  componentDidMount = () =>
    this.apiShouldUserSeeTutorial()

  apiShouldUserSeeTutorial = () =>
    TutorialUserLookupApi.getIfUserShouldSee(
      false,
      'for_creator__program_editor__subscriptions_modal'
    )
      .then(({ should_user_see }) =>
        this.setState({ ifUserShouldSeeSubscriptionPlansModal: should_user_see })
      )

  apiMarkTutorialAsSeen = () =>
    TutorialUserLookupApi.markAsSeen(
      false,
      'for_creator__program_editor__subscriptions_modal'
    )

  uiClickContinueButton = () => {
    if (this.ifShowSubscriptionPlansModal()) {
      this.setState({ ifSubscriptionPlansModalIsOpen: true });
      this.apiMarkTutorialAsSeen();
    } else {
      window.location = this.getUrlForContinueButton();
    }
  }

  ifShowSubscriptionPlansModal = () => {
    // - they clicked Continue either on the exam page, or on the course-test-items page
    if (
      this.props.editorForCourseOrExam === 'exam' ||
      (this.props.editorForCourseOrExam === 'course' && this.props.itemType === 'test')
    ) {
      //return this.state.ifUserShouldSeeSubscriptionPlansModal;
      return false;
    } else {
      return false;
    }
  }

  getUrlForContinueButton = () => {
    switch (this.props.editorForCourseOrExam) {
      case 'course':
        return Urls.getCourseAdminUrl({ originalId: this.props.course.original_program_id });
      case 'exam':
        return Urls.getExamAdminUrl({ originalId: this.props.exam.original_program_id });
    }
  }

  render = () =>
    <React.Fragment>
      {
        this.state.ifSubscriptionPlansModalIsOpen &&
        <SubscriptionPlansModal
          continueUrl={this.getUrlForContinueButton()}
        />
      }

      <button
        onClick={this.uiClickContinueButton}
        className="continue-button standard-button -theme-bg _joyride-course-module-5 _joyride-exam-4"
      >Return to Dashboard</button>
    </React.Fragment>
}

export default ContinueButton;
