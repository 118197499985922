import React from 'react';
import PropTypes from 'prop-types';

import { Gateway } from 'react-gateway';
import SelectSubscriptionPlan from '~/_appPartials/SelectSubscriptionPlan';

import css from './index.scss';

class SubscriptionPlansModal extends React.Component {
  static propTypes = {
    continueUrl: PropTypes.string.isRequired
  }

  render = () =>
    <Gateway into="main">
      <div className={`standard-modal-wrapper ${css.wrapper}`}>
        <div className="standard-modal">
          <div className="standard-modal-header"/>

          <div className="standard-modal-main">
            <h1 className="would-you-like-to-select-a-plan">Would you like to select a plan or continue with your free trial?</h1>

            <SelectSubscriptionPlan
              continueUrl={this.props.continueUrl}
            />

            <div className="continue-with-free-trial">
              <h2>Do you want to continue with your free trial?</h2>
              <a className="standard-button" href={this.props.continueUrl}>CONTINUE</a>
            </div>
          </div>
        </div>
      </div>
    </Gateway>
}

export default SubscriptionPlansModal;
