import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash/core';

import css from './index.scss';

class ExportTabContent extends React.Component {
  static propTypes = {
    program_id: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.exportList = [
      {
        title: 'Download All',
        description: 'All available data separated into different sheets.',
        link: `/api/generate-excel/all_program_reports/?program_id=${props.program_id}`,
      }, {
        title: 'Data Collection Questions',
        description: 'View frequencies and percentages for all collect data questions (e.g., participant demographics).',
        link: `/api/generate-excel/data_collection_summary/?program_id=${props.program_id}`,
      }, {
        title: 'Program Summary',
        description: 'View high-level data on your program including descriptive statistics (e.g., mean, median, mode), pass/fail percentages, and number of certificates issued.',
        link: `/api/generate-excel/program_summary/?program_id=${props.program_id}`,
      }, {
        title: 'Question-by-Question Analysis',
        description: 'View response frequencies and percentages for each question, including the percent of correct responses.',
        link: `/api/generate-excel/question_by_question_analysis/?program_id=${props.program_id}`,
      }, {
        title: 'Item-level Responses',
        description: 'View individual-level data for all items in your program: sign-up date and time, responses to sign-up questions, responses for all test items, and outcome statistics (e.g., final score, pass/fail).',
        link: `/api/generate-excel/item_level_responses/?program_id=${props.program_id}`,
      },
    ];
  }

  render = () =>
    <div className={css.exportContainer}>
      <h4 className="standard-title">Export your data to several different formats</h4>
      <ul className="export-list">
        {this.exportList.map((exportItem, index) =>
          <li key={index}>
            <a href={exportItem.link} className="export-link">
              <i className="fa fa-file-excel-o"/>
              <p>{exportItem.title}</p>
            </a>
            <p className="export-description">{exportItem.description}</p>
          </li>
        )}
      </ul>
    </div>
}

export default ExportTabContent;
