import React from 'react';
import PropTypes from 'prop-types';
import orFalse from '~/services/orFalse';

import ContentTogglerAndModalWrapper from '../ContentTogglerAndModalWrapper';

import css from './index.scss';

const initialState = {
  payload: '',
  point_value: 0,
};

const fromApiToState = (api) => ({
  payload: api.payload
});

const fromStateToApi = (formState) => ({
  payload: formState.payload
});

class TextTogglerAndModal extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    content: orFalse(PropTypes.shape({
      content_type: PropTypes.oneOf(['rating-scale', 'single-line-text', 'multi-line-text']).isRequired,
      payload: PropTypes.string.isRequired
    })).isRequired,
    createWith: PropTypes.object
  }

  getDialogTitle = () => {
    const { content, createWith } = this.props;
    const contentType = content ? content.content_type : createWith.contentType;
    let dialogTitle = '';

    switch (contentType) {
      case 'rating-scale':
        dialogTitle = 'Rating Scale';
        break;
      case 'single-line-text':
        dialogTitle = 'Single Line Text';
        break;
      case 'multi-line-text':
        dialogTitle = 'Multi Line Text';
        break;
    }

    return dialogTitle;
  }

  getTextPlaceholder = () => {
    const { content, createWith } = this.props;
    const contentType = content ? content.content_type : createWith.contentType;
    let placeholder = '';

    switch (contentType) {
      case 'rating-scale':
        placeholder = 'E.G. Overall, how would you rate this course?';
        break;
      case 'single-line-text':
        placeholder = 'E.G. What is your student ID #?';
        break;
      case 'multi-line-text':
        placeholder = 'E.G. Please describe how you felt about this course.';
        break;
    }

    return placeholder;
  }

  render = () =>
    <ContentTogglerAndModalWrapper
      className={`${css.modal} -with-green-header`}
      title={this.getDialogTitle()}
      initialState={initialState}
      fromApiToState={fromApiToState}
      fromStateToApi={fromStateToApi}
      {...this.props}
    >{(formState, updateFormState) =>
      <div className="standard-modal-main text-modal-main">
        <textarea
          value={formState.payload}
          placeholder={this.getTextPlaceholder()}
          onChange={(e) => updateFormState({ ...formState, payload: e.target.value })}
        />
      </div>
    }</ContentTogglerAndModalWrapper>
}

export default TextTogglerAndModal;
