import React from 'react';
import PropTypes from 'prop-types';
import orFalse from '~/services/orFalse';

import FormState from './services/FormState';

import ContentTogglerAndModalWrapper from '../ContentTogglerAndModalWrapper';
import QuestionAndExplanationDiv from './_partials/QuestionAndExplanationDiv';
import AnswersDiv from './_partials/AnswersDiv';
import PointValueInputDiv from './_partials/PointValueInputDiv';

import css from './index.scss';

class QuestionTogglerAndModal extends React.Component {
  static propTypes = {
    itemType: orFalse(PropTypes.string),
    content: orFalse(PropTypes.object).isRequired,
    createWith: orFalse(PropTypes.shape({
      contentType: PropTypes.oneOf(['yes-no', 'multiple-choice', 'multiple-choice-2', 'nps', 'true-false']).isRequired,
      itemType: PropTypes.string.isRequired,
    })),

    scoringType: PropTypes.oneOf(['percentage', 'point']).isRequired,
    totalAmountOfPoints: PropTypes.number.isRequired
  }

  getContentType = () => (
    this.props.content ?
      this.props.content.content_type :
      this.props.createWith.contentType
  )

  getQuestionTitleForHeader = () => {
    switch (this.getContentType()) {
      case 'yes-no':          return 'Yes/No Question';
      case 'multiple-choice': return 'Multiple Choice Question';
      case 'multiple-choice-2': return 'Multiple Choice 2 Question';
      case 'nps': return 'NPS Question';
      case 'true-false':      return 'True/False Question';
      default: throw new Error(`There is no such content.content_type '${this.getContentType()}'`);
    }
  }

  render = () =>
    <ContentTogglerAndModalWrapper
      title={this.getQuestionTitleForHeader()}
      initialState={FormState.getInitialState(this.getContentType())}
      fromApiToState={FormState.fromApiToState}
      fromStateToApi={FormState.fromStateToApi}
      className={css.modal}
      {...this.props}
    >{(formState, updateFormState) =>
      <div className="standard-modal-main">
        <div className="question_and_answers">
          <QuestionAndExplanationDiv
            itemType={this.props.createWith ? this.props.createWith.itemType : this.props.itemType}
            formState={formState}
            updateFormState={updateFormState}
          />

          <AnswersDiv
            itemType={this.props.createWith ? this.props.createWith.itemType : this.props.itemType}
            contentType={this.getContentType()}
            formState={formState}
            updateFormState={updateFormState}
          />
        </div>

        {
          this.props.scoringType === 'point' &&
          <PointValueInputDiv
            content={this.props.content}
            totalAmountOfPoints={this.props.totalAmountOfPoints}
            formState={formState}
            updateFormState={updateFormState}
          />
        }
      </div>
    }</ContentTogglerAndModalWrapper>
}

export default QuestionTogglerAndModal;
