import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import Popover from '@material-ui/core/Popover';
import NotifyIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import _ from 'lodash/core';

import Constants from '~/services/Constants';
import SpeCreator from '~/services/SpeCreator';
import Utils from '~/services/Utils';
import Loading from '~/_partials/Loading';
import StandardAlert from '~/_partials/StandardAlert';
import ModuleApi from '~/api/ModuleApi';
import ProgramApi from '~/api/ProgramApi';
import SwitchInput from '~/_partials/SwitchInput';
import TextInput from '~/_partials/standardForm/TextInput';
import TimeInput from '../../TimeInput';
import DatetimeInput from './_partials/DatetimeInput';

import css from './index.scss';

const HELP_CENTER_ARTICLIE = {
  passing_score: 0,
  minimum_time: 1,
};

const MENU_PAGES = {
  scoring: { id: 'scoring', name: 'Scoring' },
  randomization: { id: 'randomization', name: 'Randomization' },
  results: { id: 'results', name: 'Results' },
  restrictions: { id: 'restrictions', name: 'Time Restrictions' },
  notification: { id: 'notifications', name: 'Notifications' },
};

const MAX_SCORING = 100;
const MAX_RETAKES = 99;

class SettingsTabContent extends React.Component {
  static propTypes = {
    course_id: PropTypes.number.isRequired,
    scored: PropTypes.bool.isRequired,
    modules: PropTypes.array.isRequired,
    total_score_to_pass: PropTypes.any.isRequired,
    user_must_pass_all_modules: PropTypes.bool.isRequired,
    max_num_retakes: PropTypes.number.isRequired,
    randomize_answers: PropTypes.bool.isRequired,
    can_review: PropTypes.bool.isRequired,
    allow_viewing_corrected_test: PropTypes.bool.isRequired,
    minimum_time_required: PropTypes.bool.isRequired,
    start_date: PropTypes.string.isRequired,
    end_date: PropTypes.string.isRequired,
    score_release: PropTypes.string.isRequired,
    time_limit: PropTypes.number.isRequired,
    take_sequentially: PropTypes.bool.isRequired,
    randomize: PropTypes.bool.isRequired,
    if_student_can_view_module_content_on_retake: PropTypes.bool.isRequired,
    send_on_completion: PropTypes.bool.isRequired,
    send_on_pass: PropTypes.bool.isRequired,
    uiUpdateModules: PropTypes.func.isRequired,
    uiUpdateCourseDetail: PropTypes.func.isRequired,
  }

  getFormatUTCDate = (date) => {
    if (date === null || date.length === 0) {
      return '';
    } else {
      const formatDate = moment.utc(date).format('MM/DD/YYYY');
      return (formatDate === '01/01/0001' || formatDate === '12/31/9999') ? '' : moment.utc(date).format('MM/DD/YYYY h:mm A');
    }
  }

  calculateTotalWeight = (modules) => {
    let totalScoring = 0;

    modules.forEach((module) => {
      const moduleScoring = module.percentage_weight;
      if (moduleScoring && moduleScoring.length > 0) {
        totalScoring += moduleScoring.includes('.') ? parseFloat(moduleScoring) : parseInt(moduleScoring, 10);
      }
    });

    // Round total score
    if (totalScoring >= 99.9) { totalScoring = 100; }

    return totalScoring.toString();
  }

  getFormStateIncludingModule = () => {
    const formStateIncludingModule = {};
    const { modules } = this.props;
    modules.forEach((module) => {
      formStateIncludingModule[`module_scored${module.id}`] = module.scored;
      formStateIncludingModule[`module_scoring${module.id}`] = (module.scored || module.program_type === 'Exam') ? module.percentage_weight : '';
      formStateIncludingModule[`module_randomize${module.id}`] = module.randomize;
      formStateIncludingModule[`module_mintime_hours${module.id}`] = module.minimum_time === 0 ? '' : Math.floor(module.minimum_time / 60);
      formStateIncludingModule[`module_mintime_minutes${module.id}`] = module.minimum_time === 0 ? '' : module.minimum_time % 60;
    });
    return formStateIncludingModule;
  }

  state = {
    activeMenuIndex: 0,
    formState: {
      total_score_to_pass: this.props.total_score_to_pass,
      total_scoring: this.calculateTotalWeight(this.props.modules),
      pass_modules: this.props.user_must_pass_all_modules,
      program_scored: this.props.scored,
      max_retakes: parseInt(this.props.max_num_retakes, 10),
      randomize_answers: this.props.randomize_answers,
      can_review: this.props.can_review,
      allow_viewing_corrected: this.props.allow_viewing_corrected_test,
      minimum_time_required: this.props.minimum_time_required,
      test_open_start: this.getFormatUTCDate(this.props.start_date),
      test_open_end: this.getFormatUTCDate(this.props.end_date),
      score_release: this.getFormatUTCDate(this.props.score_release),
      time_limit_hours: this.props.time_limit === 0 ? '' : Math.floor(this.props.time_limit / 60),
      time_limit_minutes: this.props.time_limit === 0 ? '' : this.props.time_limit % 60,
      ...this.getFormStateIncludingModule(),
    },
    send_on_completion: this.props.send_on_completion,
    send_on_pass: this.props.send_on_pass,
    passingScoreHelpInfo: null,
    minimumTimeHelpInfo: null,
    ifShowTotalScoreHelpPopover: false,
    ifShowHelpPopover: false,
    speUpdate: SpeCreator.empty(),
    ifShowMoreScoring: false,
    ifSaveScoringError: false,
    ifSaveSettingsSuccess: false,
    ifHelpSubmitSuccess: false,
  }

  componentDidMount() {
    this.apiGetHelpArticles();
    this.formRef.addEventListener('scroll', this.handleFormScroll);
  }

  componentWillUnmount() {
    this.formRef.removeEventListener('scroll', this.handleFormScroll);
  }

  apiGetHelpArticles = () => {
    Promise.all([
      ProgramApi.getHelpArticle(false, 1),
      ProgramApi.getHelpArticle(false, 2),
    ]).then(responses => {
      const passingScoreHelpInfo = responses.find(response => response.article.id === 1).article;
      const minimumTimeHelpInfo = responses.find(response => response.article.id === 2).article;
      passingScoreHelpInfo.payload = JSON.parse(passingScoreHelpInfo.payload);
      minimumTimeHelpInfo.payload = JSON.parse(minimumTimeHelpInfo.payload);
      this.setState({
        passingScoreHelpInfo,
        minimumTimeHelpInfo,
      });
    });
  }

  apiUpdateModuleScoring = (params) =>
    ModuleApi.updateScoring(
      (spe) => this.setState({ speUpdate: spe }),
      params,
    )

  apiUpdateNotification = () =>
    ProgramApi.updateNotification(
      (spe) => this.setState({ speUpdate: spe }),
      this.props.course_id, {
        send_on_completion: this.state.send_on_completion,
        send_on_pass: this.state.send_on_pass,
      }
    )

  apiUpdateSettings = (courseSettings) =>
    ProgramApi.updateSettings(
      (spe) => this.setState({ speUpdate: spe }),
      {
        program_id: this.props.course_id,
        ...courseSettings
      });

  getSwitchLabelText = (switchName) => {
    if (switchName.includes('module_scored')) {
      return this.state.formState[switchName] ? 'Scored' : 'Enable Scoring';
    } else {
      return this.state.formState[switchName] ? 'Yes' : 'No';
    }
  }

  getValidatedScoring = (scoring) => {
    let validatedScoring = scoring.toString();
    let scoringSplits = validatedScoring.split('.');

    if (scoringSplits[0].length > 1 && scoringSplits[0].substring(0, 1) === '0') {
      validatedScoring = validatedScoring.substring(1);
    }

    scoringSplits = validatedScoring.split('.');
    if ((scoringSplits.length > 1 && scoringSplits[1].length > 2) || scoringSplits.length > 2) {
      validatedScoring = `${scoringSplits[0]}.${scoringSplits[1].substring(0, 2)}`;
    }

    return validatedScoring;
  }

  uiUpdateModuleSettings = () => {
    const { formState } = this.state;
    const { modules } = this.props;
    let prevModules = modules;
    this.setState({ ifSaveScoringError: false });
    modules.forEach((module) => {
      const moduleScored = formState[`module_scored${module.id}`];
      const moduleScoring = formState[`module_scoring${module.id}`]  ? formState[`module_scoring${module.id}`] : 0;
      const moduleRandomize = formState[`module_randomize${module.id}`];
      this.apiUpdateModuleScoring({
        module_id: module.id,
        scored: moduleScored,
        score_value: moduleScoring,
        random: moduleRandomize,
      }).then(() => {
        const updatedModuleIndex = prevModules.findIndex((li) => li.id === module.id);
        const newModules = [
          ...prevModules.slice(0, updatedModuleIndex),
          {
            ...module,
            scored: moduleScored,
            percentage_weight: moduleScoring,
            randomize: moduleRandomize,
          },
          ...prevModules.slice(updatedModuleIndex + 1)
        ];
        this.props.uiUpdateModules(newModules);
        prevModules = newModules;
      }).catch(() => this.setState({ ifSaveScoringError: true }));
    });
  }

  uiUpdateNotificationSettings = () => {
    this.apiUpdateNotification()
      .then(() =>
        this.props.uiUpdateCourseDetail({
          send_on_completion: this.state.send_on_completion,
          send_on_pass: this.state.send_on_pass,
        })
      ).catch(() => this.setState({ ifSaveScoringError: true }));
  }

  updateFormState = (formState) => {
    let totalScoring = 0;
    const maxRetakes = formState.max_retakes;
    const totalScoreToPass = formState['total_score_to_pass'];

    if (totalScoreToPass.toString().length === 0 || isNaN(parseFloat(totalScoreToPass))) {
      formState.total_score_to_pass = 0;
    } else {
      if (parseFloat(totalScoreToPass) >= MAX_SCORING) {
        formState.total_score_to_pass = MAX_SCORING;
      } else {
        formState.total_score_to_pass = this.getValidatedScoring(totalScoreToPass);
      }
    }

    this.props.modules.forEach((module) => {
      let moduleScoring = formState[`module_scoring${module.id}`];

      if (module.program_type === 'Exam' || formState[`module_scored${module.id}`]) {
        if (isNaN(parseInt(moduleScoring, 10)) || moduleScoring.length === 0) {
          formState[`module_scoring${module.id}`] = '0';
        } else {
          formState[`module_scoring${module.id}`] = this.getValidatedScoring(moduleScoring);
        }
      }

      if (moduleScoring && moduleScoring.length > 0) {
        if (parseInt(moduleScoring, 10) > 100) {
          moduleScoring = '100';
          formState[`module_scoring${module.id}`] = moduleScoring;
        }

        totalScoring += moduleScoring.includes('.') ? parseFloat(moduleScoring) : parseInt(moduleScoring, 10);
      }
    });
    formState.total_scoring = (totalScoring >= MAX_SCORING && totalScoring <= MAX_SCORING)  ? MAX_SCORING.toString() : totalScoring.toString();
    totalScoring = this.getValidatedScoring(totalScoring);
    formState.total_scoring = totalScoring;

    if (maxRetakes.length > 0 && parseInt(maxRetakes, 10) > MAX_RETAKES) {
      formState.max_retakes = MAX_RETAKES.toString();
    }

    this.setState({
      formState,
    });
  }

  uiUpdateModuleScored = (module, scored) => {
    this.updateFormState({
      ...this.state.formState,
      [`module_scored${module.id}`]: scored,
      [`module_scoring${module.id}`]: scored ? module.percentage_weight : '',
    });
  }

  getMinimumTimes = () => {
    const { formState } = this.state;
    const minimum_times = [];

    if (formState.minimum_time_required) {
      this.props.modules.forEach((module) => {
        const moduleId = module.id;
        const hrs = formState[`module_mintime_hours${moduleId}`];
        const mins = formState[`module_mintime_minutes${moduleId}`];
        minimum_times.push({
          module_id: moduleId,
          minimum_time: Utils.convertTime(hrs, mins)
        });
      });
    }

    return minimum_times;
  }

  uiUpdateNotification = (e, key) => {
    this.setState({ [key]: e.target.checked });
  }

  getFormatLocalDate = (date) => {
    if (date === null || date.length === 0) {
      return '';
    } else {
      return moment(date).format('MM/DD/YYYY h:mm A');
    }
  }

  showSuccessStatus = () => {
    this.setState({ ifSaveSettingsSuccess: true });
  }

  // Activate navigation menu link when scroll form
  handleFormScroll = e => {
    const settingsForm  = e.target;
    const contentItems = Array.prototype.slice.call(settingsForm.getElementsByClassName('setting-group'));
    const formTop = settingsForm.scrollTop;
    const formBottom = settingsForm.scrollTop + settingsForm.offsetHeight;
    const visibleElements = contentItems.filter(el => {
      const elementTop = el.offsetTop;
      const elementBottom = el.offsetTop + el.offsetHeight;
      return (elementTop > formTop && elementBottom < formBottom) || (formTop > elementTop && formBottom < elementBottom);
    });

    if (visibleElements.length > 0) {
      const currentElement = visibleElements[visibleElements.length - 1];
      const activeMenuIndex = _.values(MENU_PAGES).findIndex(page => page.id === currentElement.id);
      const sidebarListItems = Array.prototype.slice.call(document.getElementsByClassName('sidebar-listitem'));

      sidebarListItems.forEach(el => {
        el.parentElement.classList.remove('-active');
        if (el.getAttribute('href') === `#${currentElement.id}`) {
          el.parentElement.classList.add('-active');
        }
      });
      this.setState({ activeMenuIndex });
    }
  }

  // Scroll form when activate menu link
  handleMenuClick = (e, menuIndex, pageId) => {
    e.preventDefault();
    const settingsForm = this.formRef;
    settingsForm.removeEventListener('scroll', this.handleFormScroll);
    settingsForm.scrollTop = document.getElementById(`${pageId}`).offsetTop;

    this.setState({ activeMenuIndex: menuIndex });
    setTimeout(() => {
      settingsForm.addEventListener('scroll', this.handleFormScroll);
    }, 500);
  }

  handleHelpPopoverToggle = toggle => {
    if (toggle) {
      this.setState({ ifShowHelpPopover: toggle });
    } else {
      this.setState({
        ifShowTotalScoreHelpPopover: toggle,
        ifShowHelpPopover: toggle
      });
    }
  }

  handleHelpCenterSubmit = (articleType, ifOk) => {
    ProgramApi.respondHelpArticle(
      false, {
        article_id: articleType === HELP_CENTER_ARTICLIE.passing_score ? this.state.passingScoreHelpInfo.id : this.state.minimumTimeHelpInfo.id,
        response: ifOk ? 'Yes' : 'No',
      }
    ).then(() => {
      this.setState({ ifHelpSubmitSuccess: true });
      this.handleHelpPopoverToggle(false);
    });
  }

  handleSaveClick = () => {
    const { formState, ifSaveScoringError } = this.state;

    if (formState.program_scored && parseInt(formState.total_scoring, 10) !== MAX_SCORING) {
      alert('Total percentage weight needs to add up to 100%. Please change the values entered.');
    } else {
      const maxRetakes = parseInt(formState.max_retakes, 10);
      const courseSettings = {
        scored: formState.program_scored,
        total_score_to_pass: formState.total_score_to_pass,
        pass_modules: formState.pass_modules,
        max_retakes: !isNaN(maxRetakes) ? maxRetakes : 0,
        time_limit: Utils.convertTime(formState.time_limit_hours, formState.time_limit_minutes),
        allow_viewing_corrected: formState.allow_viewing_corrected,
        test_open_start: this.getFormatLocalDate(formState.test_open_start),
        test_open_end: this.getFormatLocalDate(formState.test_open_end),
        score_release: this.getFormatLocalDate(formState.score_release),
        randomize_answers: formState.randomize_answers,
        can_review: formState.can_review,
        minimum_time_required: formState.minimum_time_required,
        minimum_times: JSON.stringify(this.getMinimumTimes()),
        random: this.props.randomize,
        take_sequentially: this.props.take_sequentially,
        if_student_can_view_module_content_on_retake: this.props.if_student_can_view_module_content_on_retake,
      };
      this.setState({ ifSaveSettingsSuccess: false });
      this.uiUpdateModuleSettings();
      this.uiUpdateNotificationSettings();
      this.apiUpdateSettings(courseSettings).then((response) => {
        if (response.message === 'success') {
          if (!ifSaveScoringError) {
            this.showSuccessStatus();
          }

          const courseDetail = {
            ...courseSettings,
            user_must_pass_all_modules: courseSettings.pass_modules,
            max_num_retakes: courseSettings.max_retakes,
            allow_viewing_corrected_test: courseSettings.allow_viewing_corrected,
            start_date: courseSettings.test_open_start,
            end_date: courseSettings.test_open_end,
            randomize: courseSettings.random,
            score_setup: true,
          };
          this.props.uiUpdateCourseDetail(courseDetail);
        } else {
          alert(response.message);
        }
      });
    }
  }

  handleCloseAlert = () => {
    this.setState({
      ifSaveSettingsSuccess: false,
      ifHelpSubmitSuccess: false,
    });
  }

  renderScoring = formProps => {
    const { passingScoreHelpInfo, ifShowMoreScoring, ifShowTotalScoreHelpPopover } = this.state;
    const module_type = Constants.MODULE_TYPE;
    const examModules = this.props.modules.filter(module => module.program_type === 'Exam');
    const otherModules = this.props.modules.filter(module => module.program_type !== 'Exam' && module.program_type !== 'Certificate');
    const scoringModules = (!ifShowMoreScoring && examModules.length > 0) ?
      [...examModules] :
      [
        ...examModules,
        ...otherModules,
      ];
    const maxRetakes = formProps.formState.max_retakes;
    const ifHasMaxRetakesLimit = maxRetakes > 0 || (maxRetakes.length !== undefined && maxRetakes !== '0');
    return (
      <div className="setting-scoring setting-group" id={MENU_PAGES.scoring.id}>
        <div className="form-action">
          <h3 className="setting-group-title">Scoring</h3>
          <div className="action-box">
            <button type="button" className="standard-button -save" onClick={this.handleSaveClick}>Save</button>
            <Loading className="loading-spe" spe={this.state.speUpdate}/>
          </div>
        </div>
        <div className="content-wrapper">
          <SwitchInput
            value={formProps.formState.program_scored}
            labelLeft="Scored Program"
            labelRight={this.getSwitchLabelText('program_scored')}
            className="-green-small switch-input"
            updateValue={value => this.updateFormState({
              ...formProps.formState,
              program_scored: value,
            })}
          />
          {formProps.formState.program_scored &&
            <div className="scored-container">
              <div className="input-content-wrapper">
                <TextInput
                  label="Passing Grade"
                  type="number"
                  name="total_score_to_pass"
                  placeholder="Not set"
                  maxValue={100}
                  {...formProps}
                />
                <div className="help-wrapper -total-grade">
                  <button type="button" className="standard-button -icon" aria-label="Help" ref={node => this.anchorTotalGradeEl = node} onClick={() => passingScoreHelpInfo ? this.setState({ ifShowTotalScoreHelpPopover: true }) : {}}>
                    <NotifyIcon className="icon-help"/>
                  </button>
                  <Popover
                    open={ifShowTotalScoreHelpPopover}
                    anchorEl={this.anchorTotalGradeEl}
                    onClose={() => this.handleHelpPopoverToggle(false)}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}
                    className="help-popover"
                  >
                    {this.renderHelpPopoverContentPassingGrade()}
                  </Popover>
                </div>
              </div>
              <h4 className="sub-title">Section weighting</h4>
              <p className="weighting-description">
                Assign a scoring weight for each section toward the total score for your program. By default, scoring weights are evenly distributed. To change the weight for each section, change the percentage in each box below. The total percent for your program must add up to 100%.
              </p>
              <div
                className={classNames('weighting-wrapper', {
                  '-no-completed': parseFloat(formProps.formState.total_scoring) > MAX_SCORING,
                })}
              >
                { scoringModules.map((module) =>
                  <div
                    key={module.id}
                    className={classNames('icon-input-group', {
                      '-scored': module.program_type === 'Exam' || formProps.formState[`module_scored${module.id}`],
                    })}
                  >
                    { module_type[module.program_type.toLowerCase()].icon({ fontSize: 25, color: '#555' }) }
                    <TextInput
                      type="number"
                      label={`${module_type[module.program_type.toLowerCase()].name}: ${module.name}`}
                      name={`module_scoring${module.id}`}
                      maxValue={100}
                      placeholder="Not scored"
                      readOnly={module.program_type !== 'Exam' && !formProps.formState[`module_scored${module.id}`]}
                      {...formProps}
                    />
                    {module.program_type !== 'Exam' &&
                      <SwitchInput
                        value={formProps.formState[`module_scored${module.id}`]}
                        labelRight={this.getSwitchLabelText(`module_scored${module.id}`)}
                        className="-green-small switch-input"
                        updateValue={value => this.uiUpdateModuleScored(module, value)}
                      />
                    }
                  </div>
                )}
                { (examModules.length > 0 && otherModules.length > 0) &&
                  <div className="more-button-wrapper">
                    <button
                      type="button"
                      className="standard-more-button"
                      onClick={() => this.setState({ ifShowMoreScoring: !ifShowMoreScoring })}
                    >
                      { !ifShowMoreScoring ? 'Show More' : 'Show Less' }
                    </button>
                  </div>
                }
                <TextInput type="number" label="Total" name="total_scoring" readOnly {...formProps}/>
              </div>
              <SwitchInput
                value={formProps.formState.pass_modules}
                labelLeft="Student must pass all sections?"
                labelRight={this.getSwitchLabelText('pass_modules')}
                className="-green-small switch-input -modules"
                updateValue={value => this.updateFormState({
                  ...formProps.formState,
                  pass_modules: value,
                })}
              />
              <div className="retake-group">
                <SwitchInput
                  value={ifHasMaxRetakesLimit ? 1 : 0}
                  labelLeft="Allow retakes?"
                  labelRight={ifHasMaxRetakesLimit ? 'Yes' : 'No'}
                  className="-green-small switch-input -retake"
                  possibleValues={[0, 1]}
                  updateValue={value => this.updateFormState({
                    ...formProps.formState,
                    max_retakes: value > 0 ? '' : 0,
                  })}
                />
              </div>
              {ifHasMaxRetakesLimit &&
                <div className="retake-group -edit">
                  <TextInput
                    type="number"
                    name="max_retakes"
                    placeholder="Enter number of retakes permitted"
                    {...formProps}
                  />
                </div>
              }
            </div>
          }
        </div>
      </div>
    );
  }

  renderRandomization = formProps => {
    const module_type = Constants.MODULE_TYPE;
    return (
      <div className="setting-randomization setting-group" id={MENU_PAGES.randomization.id}>
        <h3 className="setting-group-title">Randomization</h3>
        <div className="content-wrapper">
          <h4 className="sub-title">Randomize test item order</h4>
          <div className="random-setting-wrapper">
            {this.props.modules.filter(module => module.program_type.toLowerCase() === module_type.exam.id)
              .map((module) =>
                <div key={module.id} className="switch-input-group">
                  { module_type[module.program_type.toLowerCase()].icon({ fontSize: 25, color: '#555' }) }
                  <SwitchInput
                    value={formProps.formState[`module_randomize${module.id}`]}
                    labelLeft={`${module_type[module.program_type.toLowerCase()].name}: ${module.name}`}
                    labelRight={this.getSwitchLabelText(`module_randomize${module.id}`)}
                    className="-green-small switch-input"
                    updateValue={value => formProps.updateFormState({
                      ...formProps.formState,
                      [`module_randomize${module.id}`]: value,
                    })}
                  />
                </div>
            )}
          </div>
          <SwitchInput
            value={formProps.formState.randomize_answers}
            labelLeft="Randomize answer choice ordering"
            labelRight={this.getSwitchLabelText('randomize_answers')}
            className="-green-small switch-input -answer-ordering"
            updateValue={value => formProps.updateFormState({
              ...formProps.formState,
              randomize_answers: value,
            })}
          />
        </div>
      </div>
    );
  }

  renderResults = formProps =>
    <div className="setting-results setting-group" id={MENU_PAGES.results.id}>
      <h3 className="setting-group-title">Results</h3>
      <div className="content-wrapper">
        <SwitchInput
          value={formProps.formState.can_review}
          labelLeft="Students can review program after completion"
          labelRight={this.getSwitchLabelText('can_review')}
          className="-green-small switch-input"
          updateValue={value => this.updateFormState({
            ...formProps.formState,
            can_review: value,
          })}
        />
        <SwitchInput
          value={formProps.formState.allow_viewing_corrected}
          labelLeft="Show correct answer when reviewing"
          labelRight={this.getSwitchLabelText('allow_viewing_corrected')}
          className="-green-small switch-input"
          updateValue={value => this.updateFormState({
            ...formProps.formState,
            allow_viewing_corrected: value,
          })}
        />
      </div>
    </div>

  renderHelpPopoverContent = () =>
    <div className="help-container">
      <div className="help-header">
        <NotifyIcon className="icon-help"/>
        <button type="button" className="standard-button -icon" onClick={() => this.handleHelpPopoverToggle(false)}>
          <CloseOutlinedIcon className="icon-close"/>
        </button>
        <h4 className="title">Help Center</h4>
        <h5 className="sub-title">{this.state.minimumTimeHelpInfo ? this.state.minimumTimeHelpInfo.title : ''}</h5>
      </div>
      <div className="help-body">
        <p className="content-detail">
          {this.state.minimumTimeHelpInfo ? this.state.minimumTimeHelpInfo.payload.description : ''}
        </p>
        <div className="example-container">
          <h4 className="example-title">Example</h4>
          <p className="example-content">
            {this.state.minimumTimeHelpInfo ? this.state.minimumTimeHelpInfo.payload.example : ''}
          </p>
        </div>
      </div>
      <div className="help-footer">
        <h1>Was this helpful?</h1>
        <button type="button" className="button-yes" onClick={() => this.handleHelpCenterSubmit(HELP_CENTER_ARTICLIE.minimum_time, true)}>Yes</button>
        <button type="button" className="button-no" onClick={() => this.handleHelpCenterSubmit(HELP_CENTER_ARTICLIE.minimum_time, false)}>No</button>
      </div>
    </div>

    renderHelpPopoverContentPassingGrade = () =>
      <div className="help-container">
        <div className="help-header">
          <NotifyIcon className="icon-help"/>
          <button type="button" className="standard-button -icon" onClick={() => this.handleHelpPopoverToggle(false)}>
            <CloseOutlinedIcon className="icon-close"/>
          </button>
          <h4 className="title">Help Center</h4>
          <h5 className="sub-title">{this.state.passingScoreHelpInfo ? this.state.passingScoreHelpInfo.title : ''}</h5>
        </div>
        <div className="help-body">
          <p className="content-detail">
            {this.state.passingScoreHelpInfo ? this.state.passingScoreHelpInfo.payload.description : ''}
          </p>
          <div className="example-container">
            <h4 className="example-title">Example</h4>
            <p className="example-content">
              {this.state.passingScoreHelpInfo ? this.state.passingScoreHelpInfo.payload.example : ''}
            </p>
          </div>
        </div>
        <div className="help-footer">
          <h1>Was this helpful?</h1>
          <button type="button" className="button-yes" onClick={() => this.handleHelpCenterSubmit(HELP_CENTER_ARTICLIE.passing_score, true)}>Yes</button>
          <button type="button" className="button-no" onClick={() => this.handleHelpCenterSubmit(HELP_CENTER_ARTICLIE.passing_score, false)}>No</button>
        </div>
      </div>

  renderTimeRestrictions = formProps => {
    const module_type = Constants.MODULE_TYPE;
    const { minimumTimeHelpInfo } = this.state;
    return (
      <div className="setting-restritions setting-group" id={MENU_PAGES.restrictions.id}>
        <h3 className="setting-group-title">Time Restrictions</h3>
        <div className="content-wrapper">
          <div className="switch-input-group">
            <SwitchInput
              value={formProps.formState.minimum_time_required}
              labelLeft="Enable minimum time requirement"
              labelRight={this.getSwitchLabelText('minimum_time_required')}
              className="-green-small switch-input"
              updateValue={value => this.updateFormState({
                ...formProps.formState,
                minimum_time_required: value,
              })}
            />
            <div className="help-wrapper">
              <button type="button" className="standard-button -icon" aria-label="Help" ref={node => this.anchorEl = node} onClick={() => minimumTimeHelpInfo ? this.handleHelpPopoverToggle(true) : {}}>
                <NotifyIcon className="icon-help"/>
              </button>
              <Popover
                open={this.state.ifShowHelpPopover}
                anchorEl={this.anchorEl}
                onClose={() => this.handleHelpPopoverToggle(false)}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}
                className="help-popover"
              >
                {this.renderHelpPopoverContent()}
              </Popover>
            </div>
          </div>
          {formProps.formState.minimum_time_required &&
            <div className="mintime-setting-wrapper">
              {this.props.modules.filter(module => module.program_type !== 'Certificate')
                .map((module) =>
                  <TimeInput
                    key={module.id}
                    className="mintime-group"
                    label={`${module_type[module.program_type.toLowerCase()].name}: ${module.name}`}
                    hourName={`module_mintime_hours${module.id}`}
                    minuteName={`module_mintime_minutes${module.id}`}
                    formState={formProps.formState}
                    updateFormState={formProps.updateFormState}
                  />
              )}
            </div>
          }
          <TimeInput
            className="timelimit-group"
            label="Time limit"
            hourName="time_limit_hours"
            minuteName="time_limit_minutes"
            {...formProps}
          />
          <DatetimeInput
            className="course-time-input"
            label="Program open from"
            value={formProps.formState.test_open_start}
            onChange={(value) => this.setState({ formState: {
              ...formProps.formState,
              test_open_start: value,
            } })}
          />
          <DatetimeInput
            className="course-time-input"
            label="Program open until"
            value={formProps.formState.test_open_end}
            onChange={(value) => this.setState({ formState: {
              ...formProps.formState,
              test_open_end: value,
            } })}
          />
          <DatetimeInput
            className="course-time-input"
            label="Program viewable"
            value={formProps.formState.score_release}
            onChange={(value) => this.setState({ formState: {
              ...formProps.formState,
              score_release: value,
            } })}
          />
        </div>
      </div>
    );
  }

  renderNotifications = () => {
    const { send_on_completion, send_on_pass } = this.state;
    return (
      <div className="setting-notification setting-group" id={MENU_PAGES.notification.id}>
        <h3 className="setting-group-title">Notifications</h3>
        <div className="content-wrapper">
          <div className="checkbox-group">
            <input type="checkbox" checked={send_on_completion} value={1} onChange={e => this.uiUpdateNotification(e, 'send_on_completion')}/>
            <label>Notify me when students complete program</label>
          </div>
          <div className="checkbox-group">
            <input type="checkbox" checked={send_on_pass} value={2} onChange={e => this.uiUpdateNotification(e, 'send_on_pass')}/>
            <label>Notify me when students pass program</label>
          </div>
        </div>
      </div>
    );
  }

  renderForm = () => {
    const formProps = {
      formState: this.state.formState,
      updateFormState: this.updateFormState
    };
    return (
      <form ref={formRef => this.formRef = formRef} className="settings-form" onSubmit={e => e.preventDefault()}>
        {this.renderScoring(formProps)}
        {this.renderRandomization(formProps)}
        {this.renderResults(formProps)}
        {this.renderTimeRestrictions(formProps)}
        {this.renderNotifications()}
        <div className="form-action">
          <button type="button" className="standard-button -save" onClick={this.handleSaveClick}>Save</button>
          <Loading className="loading-spe" spe={this.state.speUpdate}/>
        </div>
      </form>
    );
  }

  render = () => {
    const { activeMenuIndex, ifSaveSettingsSuccess, ifHelpSubmitSuccess } = this.state;
    return (
      <div className={css.settingsContainer}>
        <div className="title-and-sidebar">
          <h3 className="settings-title">Settings</h3>
          <ul className="standard-navbar">
            {_.values(MENU_PAGES).map((page, index) =>
              <li key={page.id} className={classNames('standard-nav', { '-active': activeMenuIndex === index })}>
                <a href={`#${page.id}`} className="sidebar-listitem" onClick={e => this.handleMenuClick(e, index, page.id)}>{page.name}</a>
              </li>
            )}
          </ul>
        </div>
        {this.renderForm()}

        <StandardAlert
          open={ifSaveSettingsSuccess || ifHelpSubmitSuccess}
          type={Constants.ALERT_TYPE.success}
          message={ifSaveSettingsSuccess ? 'Settings Saved!' : 'Thank you for your feedback!'}
          onClose={this.handleCloseAlert}
        />
      </div>
    );
  }
}

export default SettingsTabContent;
