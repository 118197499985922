import React from 'react';
import PropTypes from 'prop-types';

import SpeCreator from '~/services/SpeCreator';
import disableOnSpeRequest from '~/services/disableOnSpeRequest';
import UserTocApi from '~/api/UserTocApi';

import Loading from '~/_partials/Loading';
import StandardTooltip from '~/_partials/StandardTooltip';
import TogglerAndModal from '~/_partials/TogglerAndModal';
import StandardEditor from '~/_partials/StandardEditor';

import css from './index.scss';

class ProgramDescriptionTogglerAndModal extends React.Component {
  static propTypes = {
    // change it to accept .program when we only use it directly from /frontend
    programId: PropTypes.number.isRequired,
    initialProgramDescription: PropTypes.string.isRequired
  }

  state = {
    // We divide description in the editor and saved description in order to let user know whether descrription has been saved
    savedDescription: this.props.initialProgramDescription,
    description: this.props.initialProgramDescription,
    speUpdate: SpeCreator.empty()
  }

  afterModalCloses = () => {
    this.setState({ speUpdate: SpeCreator.empty() });
  }

  apiUpdate = (closeModal) =>
    UserTocApi.update(
      (spe) => this.setState({ speUpdate: spe }),
      this.props.programId,
      {
        description: this.state.description
      }
    )
      .then((response) => {
        this.setState({ savedDescription: response.description });
        closeModal();
      })

  renderTogglerButton = (text) =>
    <button
      className="standard-button -theme-bg"
      type="button"
    >
      {text}
      <StandardTooltip
        className="n-active-users-per-month"
        tooltipEl="Users will see your course title and this brief description during enrollment. Adding a description can help ensure new users complete the sign-up process and enter your course."
      />
    </button>

  renderToggler = () => (
    this.state.savedDescription.length === 0 ?
      <div className={`program-description-toggler ${css.toggler}`}>
        {this.renderTogglerButton('Add Description')}
      </div> :
      <div className={`program-description-toggler ${css.toggler}`}>
        <div className="standard-article" dangerouslySetInnerHTML={{ __html: this.state.savedDescription }}/>
        {this.renderTogglerButton('Edit Description')}
      </div>
  )

  render = () =>
    <TogglerAndModal
      toggler={this.renderToggler()}
      afterModalCloses={this.afterModalCloses}
    >{(closeModal, renderCloseButton) =>
      <div className="standard-modal">
        {renderCloseButton()}

        <div className="standard-modal-header">
          Update Description
        </div>

        <div className="standard-modal-main" style={disableOnSpeRequest(this.state.speUpdate)}>
          <StandardEditor
            value={this.state.description}
            updateValue={(html) => this.setState({ description: html })}
          />
        </div>

        <Loading className="standard-modal-spe" spe={this.state.speUpdate}/>

        <div className="standard-modal-footer -with-buttons">
          <button
            className="standard-button -sliding -red"
            type="reset"
            onClick={closeModal}
            style={disableOnSpeRequest(this.state.speUpdate)}
          >Cancel</button>
          <button
            className="standard-button -sliding -blue"
            type="submit"
            onClick={() => this.apiUpdate(closeModal)}
            style={disableOnSpeRequest(this.state.speUpdate)}
          >Save</button>
        </div>
      </div>
    }</TogglerAndModal>
}

export default ProgramDescriptionTogglerAndModal;
