import React from 'react';
import PropTypes from 'prop-types';

import orFalse from '~/services/orFalse';

import Tools from './_partials/Tools';
import ProgramScoringSection from './_partials/ProgramScoringSection';
import CertificateFieldSection from './_partials/CertificateFieldSection';
import ContinueButton from './_partials/ContinueButton';

import css from './index.scss';

class ElementToolbox extends React.Component {
  static propTypes = {
    editorForCourseOrExam: PropTypes.oneOf(['course', 'exam']),
    // - either
    exam: orFalse(PropTypes.object).isRequired,
    // - or
    course: orFalse(PropTypes.object).isRequired,
    moduleId: orFalse(PropTypes.number).isRequired,

    itemType: PropTypes.string.isRequired,

    scoringType: PropTypes.oneOf(['percentage', 'point']).isRequired,
    updateScoringType: PropTypes.func.isRequired,
    totalAmountOfPoints: PropTypes.number.isRequired,

    positionToCreateCurrentContentAt: PropTypes.number.isRequired,
    resetPositionToCreateCurrentContentAt: PropTypes.func.isRequired,

    uiCreateContent: PropTypes.func.isRequired,
    apiProceedWithUploadingFileViaTus: PropTypes.func.isRequired,
    uiOpenBackgrounds: PropTypes.func.isRequired,

    subscriptionPlanName: PropTypes.string.isRequired
  }

  getModuleOrExamId = () => {
    switch (this.props.editorForCourseOrExam) {
      case 'course': return this.props.moduleId;
      case 'exam': return this.props.exam.id;
    }
  }

  render = () =>
    <div className={css.div}>
      <Tools
        moduleOrExamId={this.getModuleOrExamId()}
        {...this.props}
      />

      {
        this.props.itemType === 'test' &&
        <ProgramScoringSection
          moduleOrExamId={this.getModuleOrExamId()}
          editorForCourseOrExam={this.props.editorForCourseOrExam}
          subscriptionPlanName={this.props.subscriptionPlanName}

          scoringType={this.props.scoringType}
          updateScoringType={this.props.updateScoringType}
          totalAmountOfPoints={this.props.totalAmountOfPoints}
        />
      }

      {this.props.itemType === 'certificate' &&
        <CertificateFieldSection
          moduleOrExamId={this.getModuleOrExamId()}
          courseId={this.props.course.id}
          disableOrganizationName={!this.props.organization_name}
          uiCreateContent={this.props.uiCreateContent}
        />
      }

      <ContinueButton
        editorForCourseOrExam={this.props.editorForCourseOrExam}
        itemType={this.props.itemType}
        exam={this.props.exam}
        course={this.props.course}
        moduleId={this.props.moduleId}
      />
    </div>
}

export default ElementToolbox;
