import React from 'react';
import PropTypes from 'prop-types';
import orFalse from '~/services/orFalse';
import { StarBorder as StarOutlinedIcon } from '@material-ui/icons';

import SlidyTextInput from '~/_partials/SlidyTextInput';
import ContentTogglerAndModalWrapper from '../ContentTogglerAndModalWrapper';

import css from './index.scss';

const RATING_MAX_POINT = 5;

const initialState = {
  payload: ''
};

const fromApiToState = (api) => ({
  payload: api.payload
});

const fromStateToApi = (formState) => ({
  payload: formState.payload
});

class RatingScaleTogglerAndModal extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    content: orFalse(PropTypes.shape({
      content_type: PropTypes.oneOf(['rating-scale', 'single-line-text', 'multi-line-text']).isRequired,
      payload: PropTypes.string.isRequired
    })).isRequired,
  }

  renderRatingStars = () => {
    const ratingStarContents = [];

    for (let i = 0; i < RATING_MAX_POINT; i += 1) {
      ratingStarContents.push(<StarOutlinedIcon key={i}/>);
    }

    return ratingStarContents;
  }

  render = () =>
    <ContentTogglerAndModalWrapper
      className={`${css.modal} -with-green-header`}
      title="Rating Scale"
      initialState={initialState}
      fromApiToState={fromApiToState}
      fromStateToApi={fromStateToApi}
      {...this.props}
    >{(formState, updateFormState) =>
      <div className="standard-modal-main rating-modal-main">
        <SlidyTextInput
          className="slidy-input-wrapper"
          type="text"
          placeholder="example: How would you rate this course?"
          value={formState.payload}
          onChange={e => updateFormState({ ...formState, payload: e.target.value })}
        />
        <div className="rating-stars-wrapper">
          {this.renderRatingStars()}
        </div>
      </div>
    }</ContentTogglerAndModalWrapper>
}

export default RatingScaleTogglerAndModal;
