import React from 'react';
import PropTypes from 'prop-types';
import orFalse from '~/services/orFalse';
import _ from 'lodash/core';

import Constants from '~/services/Constants';
import Utils from '~/services/Utils';
import ContentApi from '~/api/ContentApi';
import ImageTogglerAndModal from '../../_partials/_contentModals/ImageTogglerAndModal';
import ElementToolbox from './_partials/ElementToolbox';
import CLOUDICON from '~/../static/frontend/img/cloud-icon.svg';

import css from './index.scss';

class ToolboxAside extends React.Component {
  state = {
    ifBackgroundsOpen: false,
  }

  static propTypes = {
    editorForCourseOrExam: PropTypes.oneOf(['course', 'exam']),
    exam: orFalse(PropTypes.object).isRequired,
    course: orFalse(PropTypes.object).isRequired,
    moduleId: orFalse(PropTypes.number).isRequired,
    backgrounds: PropTypes.array.isRequired,

    itemType: PropTypes.string.isRequired,

    scoringType: PropTypes.oneOf(['percentage', 'point']).isRequired,
    updateScoringType: PropTypes.func.isRequired,
    totalAmountOfPoints: PropTypes.number.isRequired,

    positionToCreateCurrentContentAt: PropTypes.number.isRequired,
    resetPositionToCreateCurrentContentAt: PropTypes.func.isRequired,

    uiCreateContent: PropTypes.func.isRequired,
    uiDeleteContent: PropTypes.func.isRequired,
    uiCreateCertificateBackground: PropTypes.func.isRequired,
    uiUpdateCertificateBackground: PropTypes.func.isRequired,
    apiProceedWithUploadingFileViaTus: PropTypes.func.isRequired,

    subscriptionPlanName: PropTypes.string.isRequired
  }

  static defaultProps = {
    backgrounds: _.values(Constants.CERTIFICATE_DEFAULT_BACKGROUNDS),
  }

  apiDeleteContent = (contentId) =>
    ContentApi.delete(false, contentId)

  uiOpenBackgrounds = (ifBackgroundsOpen = true) => {
    this.setState({ ifBackgroundsOpen });
  }

  uiSelectBackground = (background) => {
    if (!background.id) {
      this.props.uiCreateCertificateBackground(background);
    } else {
      this.props.uiUpdateCertificateBackground(background);
    }
  }

  uiDeleteBackground = (backgroundContentId) => {
    this.apiDeleteContent(backgroundContentId)
      .then(() => this.props.uiDeleteContent(backgroundContentId));
  }

  renderUploadToggler = () =>
    <button className="btn-upload-template">
      <img src={Constants.CERTIFICATE_DEFAULT_BACKGROUNDS[Constants.DEFAULT_BACKGROUND_TITLE].image} className="template-image"/>
      <div className="upload-body">
        <img src={CLOUDICON}/>
        <span>Upload</span>
      </div>
    </button>

  renderContent = () => {
    const { ifBackgroundsOpen } = this.state;
    const { backgrounds } = this.props;

    if (!ifBackgroundsOpen) {
      return (
        <ElementToolbox
          editorForCourseOrExam={this.props.editorForCourseOrExam}
          itemType={this.props.itemType}
          exam={this.props.exam}
          course={this.props.course}
          moduleId={this.props.moduleId}

          scoringType={this.props.scoringType}
          updateScoringType={this.props.updateScoringType}
          totalAmountOfPoints={this.props.totalAmountOfPoints}

          positionToCreateCurrentContentAt={this.props.positionToCreateCurrentContentAt}
          resetPositionToCreateCurrentContentAt={this.props.resetPositionToCreateCurrentContentAt}

          uiCreateContent={this.props.uiCreateContent}
          apiProceedWithUploadingFileViaTus={this.props.apiProceedWithUploadingFileViaTus}
          uiOpenBackgrounds={this.uiOpenBackgrounds}

          subscriptionPlanName={this.props.subscriptionPlanName}
          {...this.props}
        />
      );
    } else {
      const selectedBackground = backgrounds.find(background => background.options === 'active');
      return (
        <div className="backgrounds-container">
          <button className="btn-template-back" onClick={() => this.uiOpenBackgrounds(false)}>
            <i className="fa fa-chevron-left"/>
          </button>
          <h2>Custom Background</h2>
          <ImageTogglerAndModal
            toggler={this.renderUploadToggler()}
            content={false}
            createWith={{
              contentType: 'background-image',
              moduleOrExamId: this.props.moduleId,
              itemType: this.props.itemType,
              position: this.props.positionToCreateCurrentContentAt,
            }}
            afterContentIsCreated={this.props.uiCreateContent}
          />
          <h2 className="templtes-title">Background Templates</h2>
          <ul className="template-list">
            {backgrounds.map((background, index) =>
              <li
                key={index}
                className={((selectedBackground && selectedBackground.id === background.id) ||
                  (!selectedBackground && background.title === Constants.DEFAULT_BACKGROUND_TITLE)) ? '-active' : ''
                }
                onClick={() => this.uiSelectBackground(background)}
              >
                <img src={background.id ? Utils.parseHtmlProps(background.payload, 'src') : background.image}/>
                {background.id && !background.title &&
                  <button className="btn-delete-background" onClick={() => this.uiDeleteBackground(background.id)}>
                    <i className="fa fa-times"/>
                  </button>
                }
              </li>
            )}
          </ul>
        </div>
      );
    }
  }

  render = () =>
    <aside className={`toolbox-aside ${css.section}`}>
      <h2 className="title">{!this.state.ifBackgroundsOpen ? 'Design Toolbox' : 'Backgrounds'}</h2>
      {this.renderContent()}
    </aside>
}

export default ToolboxAside;
