/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Switch, Route, NavLink as Link, Redirect } from 'react-router-dom';
import classNames from 'classnames';

import ResultCourseContent from './_partials/ResultCourseContent';
import SectionContent from './_partials/SectionContent';
import FreeResponseContent from './_partials/FreeResponseContent';

import css from './index.scss';

const SIDEBAR_COURSE_NAV = {
    title: 'PROGRAM',
    link: '/',
};

class ResultTabContent extends React.Component {
  static propTypes = {
    course_id: PropTypes.number.isRequired,
    course_status: PropTypes.object.isRequired,
    root_path: PropTypes.string.isRequired,
    modules: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.uiUpdateResultsMenu(props.modules);
  }

  componentWillReceiveProps(props) {
    if (props.modules !== this.props.modules) {
      this.uiUpdateResultsMenu(props.modules);
    }
  }

  getSelectedSectionType = () => {
    const selectedSection = this.props.modules.find(module => module.id === this.state.selectedSectionId);
    return selectedSection.program_type;
  }

  uiUpdateResultsMenu = (modules) => {
    const newMenuItems = modules.map(module => {
      const sectionItem = {
        title: module.program_type === 'Survey' ? 'SURVEY' : 'DATA COLLECTION',
        subtitle: module.name,
        link: `/${module.id}`,
      };
      return sectionItem;
    });

    if (!this.state) {
      this.state = {
        selectedSectionId: null,
        sidebarMenu: [
          SIDEBAR_COURSE_NAV,
          ...newMenuItems,
        ]
      };
    } else {
      this.setState({
        sidebarMenu: [
          SIDEBAR_COURSE_NAV,
          ...newMenuItems,
        ]
      });
    }
  }

  render = () =>
    <div
      className={classNames(css.resultContainer, {
        '-free-response': window.location.pathname.includes('/free-response'),
      })}
    >
      <BrowserRouter basename={this.props.root_path}>
        {!window.location.pathname.includes('/free-response') &&
          <div className={classNames('left-sidebar', { '-section-selected': this.state.selectedSectionId })}>
            {!this.state.selectedSectionId ?
              <div className="result-section-container">
                <h3 className="title">Results</h3>
                <ul className="standard-navbar result-links">
                  {this.state.sidebarMenu.map((nav, index) =>
                    <li key={index} className="standard-nav-container">
                      <Link exact to={nav.link} className="standard-nav" activeClassName="-active">
                        <div className="title-wrapper">
                          <p className="nav-title">{nav.title}</p>
                          {nav.subtitle &&
                            <p className="sub-title">{nav.subtitle}</p>
                          }
                        </div>
                      </Link>
                    </li>
                  )}
                </ul>
              </div> :
              <button className="btn-back-results" onClick={() => this.setState({ selectedSectionId: null })}>
                {`< Back to ${this.getSelectedSectionType()} Results`}
              </button>
            }
          </div>
        }
        <Switch>
          <Route path="/" render={() => <ResultCourseContent {...this.props}/>} exact/>
          <Route
            path="/:section_id"
            render={(props) =>
              <SectionContent
                contentSelected={this.state.selectedSectionId !== null}
                uiSelectSectionContent={sectionId => this.setState({ selectedSectionId: sectionId })}
                {...props}
              />
            }
            exact
          />
          <Route path="/free-response/:student_id" render={(props) => <FreeResponseContent course_id={this.props.course_id} {...props} exact/>}/>
          <Redirect to="/"/>
        </Switch>
      </BrowserRouter>
    </div>
}

export default ResultTabContent;
