import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import StudentIcon from '@material-ui/icons/AssignmentIndOutlined';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Constants from '~/services/Constants';
import ProgramApi from '~/api/ProgramApi';
import StudentApi from '~/api/StudentApi';
import GroupApi from '~/api/GroupApi';
import SpeCreator from '~/services/SpeCreator';
import Loading from '~/_partials/Loading';
import { TextInput } from '~/_partials/standardForm';
import TogglerAndModal from '~/_partials/TogglerAndModal';
import StandardAlert from '~/_partials/StandardAlert';

import css from './index.scss';

const ALL_STUDENTS_DROPPABLE_ID     = 'allStudents';
const COURSE_STUDENTS_DROPPABLE_ID  = 'courseStudents';
const GROUPS_DROPPABLE_ID           = 'groups';

const initialFormState = {
  first_name: '',
  last_name: '',
  email: '',
};

class AddStudentTogglerAndModal extends React.Component {
  static propTypes = {
    toggler: PropTypes.element.isRequired,
    program_id: PropTypes.number.isRequired,
    allStudents: PropTypes.array.isRequired,
    studentsInCourse: PropTypes.array.isRequired,
    groups: PropTypes.array.isRequired,
    uiUpdateAllStudents: PropTypes.func.isRequired,
    uiUpdateStudents: PropTypes.func.isRequired,
  };

  state = {
    studentsInCourse: this.props.studentsInCourse,
    allStudents: this.props.allStudents,
    speSave: SpeCreator.empty(),
    speMoveGroup: SpeCreator.empty(),
    speInvite: SpeCreator.empty(),
    ifShowInviteStudentsForm: false,
    ifStudentsAddedToCourse: false,
    ifShowAlert: false,
    inviteError: '',
    formState: {
      first_name: '',
      last_name: '',
      email: '',
    }
  }

  componentWillReceiveProps(props) {
    if (props.studentsInCourse !== this.state.studentsInCourse) {
      this.setState({ studentsInCourse: props.studentsInCourse });
    }
    if (props.allStudents !== this.state.allStudents) {
      this.setState({ allStudents: props.allStudents });
    }
  }

  apiAddStudents = (students) =>
    ProgramApi.addStudents(
      (spe) => this.setState({ speSave: spe }),
      {
        program: this.props.program_id,
        students,
      }
    );

  apiGetGroupStudents = (groupId) =>
    GroupApi.getStudents(
      (spe) => this.setState({ speMoveGroup: spe }),
      {
        group: groupId,
      }
    );

  apiCreateStudent = (newStudent) =>
    StudentApi.addStudent(
      (spe) => this.setState({ speInvite: spe }),
      {
        ...newStudent,
        program_id: this.props.program_id,
        send_email: false,
      }
    );

  getStudentsNotInCourse = () => {
    const studentInCourseIds = this.state.studentsInCourse.map(student => student.id);
    const studentsNotInCourse = this.props.allStudents.filter(student => !studentInCourseIds.includes(student.id));
    return studentsNotInCourse;
  }

  insertStudentsToCourse = (students, toIndex) => {
    const { studentsInCourse } = this.state;
    const studentIds = studentsInCourse.map(student => student.id);
    const newStudents = students.filter(student => !studentIds.includes(student.id));

    if (newStudents.length > 0) {
      this.setState({
        studentsInCourse: [
          ...studentsInCourse.slice(0, toIndex),
          ...newStudents,
          ...studentsInCourse.slice(toIndex),
        ],
        ifStudentsAddedToCourse: true,
      });
    }
  };

  onDragEnd = (result) => {
    const ifDroppedInsideList = result.destination;
    if (!ifDroppedInsideList) return;

    const source = result.source;
    const destination = result.destination;

    if (source.droppableId === destination.droppableId ||
      destination.droppableId !== COURSE_STUDENTS_DROPPABLE_ID) {
      return;
    }

    const { groups } = this.props;
    const studentsNotInCourse = this.getStudentsNotInCourse();
    const fromIndex = source.index;
    const toIndex = destination.index;
    const sourceObject = source.droppableId === ALL_STUDENTS_DROPPABLE_ID ? studentsNotInCourse[fromIndex] : groups[fromIndex];

    if (source.droppableId === GROUPS_DROPPABLE_ID) {
      this.apiGetGroupStudents(sourceObject.id)
        .then(response =>
          this.insertStudentsToCourse(response.studentsInGroup, toIndex)
        );
    } else {
      this.insertStudentsToCourse([sourceObject], toIndex);
    }
  }

  updateFormState = (formState) =>
    this.setState({ formState });

  handleInviteStudentsClick = (ifShowInviteStudentsForm) => {
    if (ifShowInviteStudentsForm) {
      this.setState({ ifShowInviteStudentsForm });
    } else {
      this.setState({
        speInvite: SpeCreator.empty(),
        ifShowInviteStudentsForm,
        inviteError: '',
        formState: initialFormState,
      });
    }
  }

  handleInviteStudentSubmit = () => {
    const { formState } = this.state;
    const newStudent = {
      first_name: formState['first_name'],
      last_name: formState['last_name'],
      email: formState['email'],
    };

    if (newStudent.first_name.length === 0) {
      this.setState({ inviteError: 'Please input first name.', speInvite: SpeCreator.empty() });
    } else if (newStudent.last_name.length === 0) {
      this.setState({ inviteError: 'Please input last name.', speInvite: SpeCreator.empty() });
    } else if (newStudent.email.length === 0) {
      this.setState({ inviteError: 'Please input email.', speInvite: SpeCreator.empty() });
    } else {
      const findIndex = this.props.allStudents.findIndex(student => student.email === newStudent.email);

      if (findIndex < 0) {
        this.setState({ inviteError: '' });
        this.apiCreateStudent(newStudent)
          .then((result) => {
            if (result.message === 'success') {
              const { studentsInCourse } = this.state;
              newStudent.id = result.id;
              newStudent.type = 'invite';
              this.setState({
                formState: initialFormState,
                ifShowInviteStudentsForm: false,
                ifStudentsAddedToCourse: true,
              });
              this.props.uiUpdateAllStudents([
                ...this.props.allStudents,
                newStudent,
              ]);
              this.setState({
                studentsInCourse: [
                  ...studentsInCourse,
                  newStudent,
                ]
              });
            } else {
              if (result.message === 'invalid_email') {
                this.setState({ inviteError: 'Invalid Email' });
              } else if (result.message === 'duplicate_email') {
                this.setState({
                  ifShowAlert: true,
                  inviteError: 'Duplicate Email',
                });
              }
            }
          });
      } else {
        this.setState({
          ifShowAlert: true,
          inviteError: 'Duplicate Email',
        });
      }
    }
  }

  handleCancelClick = (closeModal) => {
    this.setState({
      studentsInCourse: this.props.studentsInCourse,
      speInvite: SpeCreator.empty(),
      speMoveGroup: SpeCreator.empty(),
      ifShowInviteStudentsForm: false,
      ifStudentsAddedToCourse: false,
      inviteError: '',
      formState: initialFormState,
    });

    if (closeModal) {
      closeModal();
    }
  }

  handleSaveClick = (closeModal) => {
    this.apiAddStudents(this.state.studentsInCourse)
      .then(() => {
        this.setState({
          ifShowInviteStudentsForm: false,
          ifStudentsAddedToCourse: false,
          inviteError: '',
        });
        this.props.uiUpdateStudents(this.state.studentsInCourse);
        closeModal();
      });
  }

  renderFooter = (closeModal) =>
    <div className="standard-modal-footer -with-buttons">
      <button
        className="standard-button -sliding -red"
        type="reset"
        onClick={() => this.handleCancelClick(closeModal)}
      >Cancel</button>
      <button
        className="standard-button -sliding -blue"
        type="submit"
        onClick={() => this.handleSaveClick(closeModal)}
      >Save</button>
    </div>

  render() {
    const { groups, toggler } = this.props;
    const {
      studentsInCourse, speSave, speMoveGroup, speInvite,
      ifShowInviteStudentsForm, ifShowAlert, inviteError, formState,
    } = this.state;
    const formProps = {
      formState,
      updateFormState: this.updateFormState,
    };
    const studentsNotInCourse = this.getStudentsNotInCourse();
    return (
      <TogglerAndModal
        toggler={toggler}
        afterModalCloses={this.handleCancelClick}
      >{(closeModal, renderCloseButton) =>
        <div className={`standard-modal ${css.modal} -with-white-header -student-modal`}>
          {renderCloseButton()}

          <div className="standard-modal-header">Add Students to Program</div>
          <div className="standard-modal-main student-modal-main">
            <h4>Drag and drop students and groups to add them to this program.</h4>
            <div className="body-container">
              <DragDropContext onDragEnd={this.onDragEnd}>
                <div className="left-panel">
                  <div className="title-bar">
                    <h2 className="standard-modal-title">{!ifShowInviteStudentsForm ? 'All Students' : 'Invite New Student'}</h2>
                    <button
                      className={classNames('standard-button -underline', { '-cancel': ifShowInviteStudentsForm })}
                      onClick={() => this.handleInviteStudentsClick(!ifShowInviteStudentsForm)}
                    >{!ifShowInviteStudentsForm ? '+ Invite Students' : 'Cancel'}</button>
                  </div>
                  {!ifShowInviteStudentsForm ?
                    <Droppable droppableId={ALL_STUDENTS_DROPPABLE_ID}>{(dropProvided) =>
                      <ul
                        className="students-list"
                        ref={dropProvided.innerRef}
                        {...dropProvided.droppableProps}
                      >
                        {studentsNotInCourse.map((student, index) =>
                          <Draggable draggableId={`all-${index}`} key={index} index={index}>{(dragProvided) =>
                            <li
                              ref={dragProvided.innerRef}
                              {...dragProvided.draggableProps}
                              {...dragProvided.dragHandleProps}
                            >
                              <div className="drag-icon">
                                <StudentIcon/>
                              </div>
                              {`${student.first_name} ${student.last_name}`}
                            </li>
                          }</Draggable>
                        )}
                      </ul>
                    }</Droppable> :
                    <form className="invitation-form">
                      <TextInput name="first_name" maxLength={Constants.MAX_NAME_LENGTH} placeholder="First Name" {...formProps}/>
                      <TextInput name="last_name" maxLength={Constants.MAX_NAME_LENGTH} placeholder="Last Name" {...formProps}/>
                      <TextInput name="email" placeholder="Email" {...formProps}/>
                      <button
                        type="button"
                        className="standard-button -outline btn-form-submit"
                        onClick={this.handleInviteStudentSubmit}
                      >Invite Student</button>
                      <Loading className="standard-modal-spe invitation-loading" spe={speInvite}/>
                      {inviteError.length > 0 &&
                        <div className="invite-status -error">{inviteError}</div>
                      }
                    </form>
                  }
                  <h2 className="standard-modal-title">Groups</h2>
                  <Droppable droppableId={GROUPS_DROPPABLE_ID}>{(dropProvided) =>
                    <ul
                      className="group-list"
                      ref={dropProvided.innerRef}
                      {...dropProvided.droppableProps}
                    >
                      {groups.map((group, index) =>
                        <Draggable draggableId={`group-${index}`} key={index} index={index}>{(dragProvided) =>
                          <li
                            ref={dragProvided.innerRef}
                            {...dragProvided.draggableProps}
                            {...dragProvided.dragHandleProps}
                          >
                            <div className="drag-icon">
                              <StudentIcon/>
                            </div>
                            {group.name}
                          </li>
                        }</Draggable>
                      )}
                    </ul>
                  }</Droppable>
                </div>

                <div className="right-panel">
                  <h2 className="standard-modal-title">Students In Program</h2>
                  <Droppable droppableId={COURSE_STUDENTS_DROPPABLE_ID}>{(dropProvided) =>
                    <ul
                      className="students-list"
                      ref={dropProvided.innerRef}
                      {...dropProvided.droppableProps}
                    >
                      {studentsInCourse.map((student, index) =>
                        <Draggable draggableId={`course-${index}`} key={index} index={index}>{(dragProvided) =>
                          <li
                            ref={dragProvided.innerRef}
                            {...dragProvided.draggableProps}
                            {...dragProvided.dragHandleProps}
                          >
                            <div className="drag-icon">
                              <StudentIcon/>
                            </div>
                            {student.type !== 'group' ? `${student.first_name} ${student.last_name}` : student.name}
                          </li>
                        }</Draggable>
                      )}
                    </ul>
                  }</Droppable>

                  {studentsInCourse.length > 0 &&
                    <p className="invite-note">Click save to send email invitations</p>
                  }

                  <Loading className="standard-modal-spe group-students-loading" spe={speMoveGroup}/>

                </div>
              </DragDropContext>
            </div>
          </div>

          <Loading className="standard-modal-spe" spe={speSave}/>

          {this.renderFooter(closeModal)}

          <StandardAlert
            open={ifShowAlert}
            type={Constants.ALERT_TYPE.warning}
            message="Duplicate Email!"
            onClose={() => this.setState({ ifShowAlert: false })}
          />
        </div>
      }</TogglerAndModal>
    );
  }
}

export default AddStudentTogglerAndModal;
