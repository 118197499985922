import React from 'react';
import PropTypes from 'prop-types';

import TogglerAndModal from '~/_partials/TogglerAndModal';

import css from './index.scss';

class TestResultModal extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    toggler: PropTypes.element.isRequired,
    courseId: PropTypes.number.isRequired,
    nextModule: PropTypes.any.isRequired,
    passed: PropTypes.bool.isRequired,
    dataScore: PropTypes.string.isRequired,
    ifBlankModule: PropTypes.bool.isRequired,
    ifRetakeModule: PropTypes.bool.isRequired,
  };

  ifHasNextModule
  handleButtonClick = (closeModal) => {
    const { ifRetakeModule, courseId, nextModule } = this.props;
    closeModal();

    if (ifRetakeModule) {
      window.location = '/dashboard-programs/';
    } else {
      if (nextModule) {
        window.location = `/${courseId}/survey/${nextModule}/1`;
      } else {
        window.location = '/dashboard-programs/';
      }
    }
  };

  render() {
    return (
      <TogglerAndModal
        toggler={this.props.toggler}
      >{(closeModal) =>
        <div className={`standard-modal-wrapper ${css.modal}`}>
          <div className="standard-modal">

            <div className="standard-modal-header"/>
            <div className="standard-modal-main">
              <h2 className="modal-title">{this.props.title}</h2>
              {this.props.passed ?
                <h3 className="modal-message">
                  You&#39;ve Passed!
                </h3> :
                <h3 className="modal-message">
                  You didn&#39;t Pass.
                </h3>
              }
              <div className="modal-checkmark">
                <i className={`fa ${this.props.passed ? 'fa-check-circle' : 'fa-times-circle'}`}/>
              </div>
              <h3 className="modal-message">{this.props.dataScore}</h3>
            </div>

            {!this.props.ifBlankModule &&
              <div className="standard-modal-footer -with-buttons">
                <button
                  className="standard-button -sliding -blue"
                  type="button"
                  onClick={() => this.handleButtonClick(closeModal)}
                >
                  {(this.props.ifRetakeModule || !this.props.nextModule) ? 'Return to Dashboard' : 'Start Next Module'}
                </button>
              </div>
            }
          </div>
        </div>
      }</TogglerAndModal>
    );
  }
}

export default TestResultModal;
