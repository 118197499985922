import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './index.scss';

class SelectDropdown extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    children: PropTypes.any,
    onCloseDropdown: PropTypes.func,
  }

  static defaultProps = {
    open: false,
  }

  componentDidUpdate() {
    if (this.props.open) {
      setTimeout(() => {
        window.addEventListener('click', this.handleWindowClick);
      });
    } else {
      window.removeEventListener('click', this.handleWindowClick);
    }

    if (this.dropdownRef) {
      this.updateDropdownOpen();
    }
  }

  handleWindowClick = () => {
    this.props.onCloseDropdown();
  }

  updateDropdownOpen = () => {
    this.dropdownRef.style.opacity = 0;
    setTimeout(() => {
      if (this.dropdownRef) {
        if (this.props.open && this.dropdownRef.getBoundingClientRect().y + this.dropdownRef.offsetHeight > window.innerHeight) {
          this.dropdownRef.style.transform = 'translateY(-100%)';
        }
        this.dropdownRef.style.opacity = 1;
      }
    });
  }

  render = () =>
    <div
      className={classNames(`${css.div}`, {
        open: this.props.open,
      })}
    >
      <ul className="dropdown-menu" ref={dropdownRef => this.dropdownRef = dropdownRef}>
        {this.props.children}
      </ul>
    </div>
}

export default SelectDropdown;
