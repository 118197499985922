import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash/core';

import Constants from '~/services/Constants';
import SpeCreator from '~/services/SpeCreator';
import Loading from '~/_partials/Loading';
import ProgramApi from '~/api/ProgramApi';
import ContentApi from '~/api/ContentApi';
import SelectDropdown from '~/_partials/SelectDropdown';

const FIELD_NAME = {
  course: { name: 'Program Name'  },
  organization: { name: 'Organization Name' },
  completion: { name: 'Completion Date' },
  student: { name: 'Student Name' },
  certificate: { name: 'Certificate ID' },
};

class CertificateFieldSection extends React.Component {
  static propTypes = {
    moduleOrExamId: PropTypes.number.isRequired,
    courseId: PropTypes.number.isRequired,
    uiCreateContent: PropTypes.func.isRequired,
    disableOrganizationName: PropTypes.bool,
  }

  state = {
    speCreate: SpeCreator.empty(),
    selectedFieldValue: '',
    fields: _.values(FIELD_NAME),
    ifFieldDropdownIsOpen: false,
  }

  componentDidMount() {
    this.apiGetFields();
  }

  getSelectedFieldContentType = () => {
    const { selectedFieldValue } = this.state;
    switch (selectedFieldValue) {
      case FIELD_NAME.course.name:
        return Constants.FIELD_TYPE.program;
      case FIELD_NAME.organization.name:
        return Constants.FIELD_TYPE.organization;
      case FIELD_NAME.completion.name:
        return Constants.FIELD_TYPE.completion_date;
      case FIELD_NAME.student.name:
        return Constants.FIELD_TYPE.student_name;
      case FIELD_NAME.certificate.name:
          return Constants.FIELD_TYPE.certificate_id;
      default: {
        try {
          const signupFieldType = this.state.fields.find(field => field.type && field.name === selectedFieldValue).type;
          return signupFieldType;
        } catch (error) {
          console.log('No field type');
        }
      }
    }
  }

  apiGetFields = () =>
    ProgramApi.getSignupFields(false, this.props.courseId)
      .then(result =>
        this.setState({
          fields: [
            ..._.values(FIELD_NAME),
            ...result.signup_fields,
          ]
        })
      )

  apiCreateField = () =>
    ContentApi.create(
      (spe) => this.setState({ speCreate: spe }),
      {
        item_type: 'certificate',
        module_or_exam_id: this.props.moduleOrExamId,
        content_type: 'field',
        payload: `<p title=${this.getSelectedFieldContentType()}>${this.state.selectedFieldValue}</p>`,
        x_coordinate: '50',
        y_coordinate: '50',
      }
    )
      .then((createdContent) =>
        this.props.uiCreateContent(createdContent)
      );

  uiUpdateDropdownToggle = () => {
    this.setState({ ifFieldDropdownIsOpen: !this.state.ifFieldDropdownIsOpen });
  }

  handleFieldClick = (e, selectedFieldValue) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ selectedFieldValue, ifFieldDropdownIsOpen: false });
  }

  render = () =>
    <section className="certification-field-section">
      <label>Fields</label>
      <button className="btn-select-field" onClick={this.uiUpdateDropdownToggle}>
        <input placeholder="Select a Field To Add" value={this.state.selectedFieldValue} readOnly/>
        <span className="caret"/>
      </button>
      <SelectDropdown open={this.state.ifFieldDropdownIsOpen} onCloseDropdown={this.uiUpdateDropdownToggle}>
        {_.values(this.state.fields).map((fieldObj, index) =>
          <li
            key={index}
            onClick={e => this.handleFieldClick(e, fieldObj.name)}
            className={fieldObj.name === 'Organization Name' && this.props.disableOrganizationName ? 'disabled' : ''}
          >
            <a className={fieldObj.name === this.state.selectedFieldValue ? '-selected' : ''}>
              {fieldObj.name}
            </a>
          </li>
        )}
      </SelectDropdown>
      <button className="standard-button -plum btn-add" disabled={this.state.selectedFieldValue.length === 0} onClick={this.apiCreateField}>Add</button>

      <Loading className="standard-modal-spe" spe={this.state.speCreate}/>

    </section>
}

export default CertificateFieldSection;
