import TransloaditApi from '~/api/TransloaditApi';

const apiProceedWithUploadingFileViaTus = ({ content, file, tusUrl, assemblyUrl }, uiUpdateContent) => {
  uiUpdateContent({
    ...content,
    _videoIsUploadingViaTus: {
      status: 'request',
      payload: { percentage: 0 }
    }
  });

  TransloaditApi.startTusUpload({ file, tusUrl, assemblyUrl,
    callbacks: {
      onError: (error) => {
        uiUpdateContent({
          ...content,
          _videoIsUploadingViaTus: {
            status: 'failure',
            payload: { error: error.toString() }
          }
        });
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        const percentage = (bytesUploaded / bytesTotal) * 100;
        uiUpdateContent({
          ...content,
          _videoIsUploadingViaTus: {
            status: 'request',
            payload: { percentage }
          }
        });
      },
      onSuccess: () => {
        uiUpdateContent({
          ...content,
          _videoIsUploadingViaTus: undefined
        });
      }
    }
  });
};

export default apiProceedWithUploadingFileViaTus;
