import React from 'react';
import PropTypes from 'prop-types';

import css from './index.scss';

class ProgramReadinessMeter extends React.Component {
  static propTypes = {
    program: PropTypes.object.isRequired
  }

  renderLi = (text, ifActive) =>
    <li className={ifActive ? '-active' : '-not-active'}>
      <span className="line -left"/>
      <span className="dot"/>
      <span className="line -right"/>

      <label>{text}</label>
    </li>

  render = () =>
    <section className={`program-readiness-meter ${css.section}`}>
      <ul>
        {this.renderLi('Build',    true)}
        {this.renderLi('Score',    this.props.program.score_setup)}
        {this.renderLi('Certify',  this.props.program.send_cert)}
        {this.renderLi('Activate', this.props.program.active)}
      </ul>
    </section>
}

export default ProgramReadinessMeter;
