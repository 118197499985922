import React from 'react';
import PropTypes from 'prop-types';
import BackIcon from '@material-ui/icons/NavigateBeforeOutlined';

import orFalse from '~/services/orFalse';
import Urls from '~/services/Urls';

import css from './index.scss';

class SubHeader extends React.Component {
  static propTypes = {
    editorForCourseOrExam: PropTypes.oneOf(['course', 'exam']).isRequired,
    itemType: PropTypes.string.isRequired,

    // - either
    exam: orFalse(PropTypes.object).isRequired,
    // - or
    course: orFalse(PropTypes.object).isRequired,
    moduleId: orFalse(PropTypes.number).isRequired
  }

  getPreviewHref = () => {
    switch (this.props.editorForCourseOrExam) {
      case 'course': return Urls.getCoursePreviewUrl(this.props.course.id);
      case 'exam': return Urls.getExamPreviewUrl(this.props.exam.id);
    }
  }

  getAdminHref = () => {
    switch (this.props.editorForCourseOrExam) {
      case 'course': return Urls.getCourseAdminUrl({ originalId: this.props.course.original_program_id });
      case 'exam': return Urls.getExamAdminUrl({ originalId: this.props.exam.original_program_id });
    }
  }

  getCourseEditorHref = (itemType) =>
    Urls.getCourseEditorUrl({
      courseId: this.props.course.id,
      moduleId: this.props.moduleId,
      itemType
    })

  handleBackClick = () => {
    window.location = Urls.getCourseUrl(this.props.course.id);
  }

  renderCourseEditorLink = (itemType, text) =>
    <a
      className={`link -${itemType} ${this.props.itemType === itemType ? '-active' : '-not-active'}`}
      href={this.getCourseEditorHref(itemType)}
    >{text}</a>

  renderModuleVsTestSwitcherForCourse = () =>
    <div className="module-vs-test-switcher-for-course _joyride-course-module-1">
      {this.renderCourseEditorLink('module', 'Module Editor')}
      {this.renderCourseEditorLink('test', 'Add Test Items')}
    </div>

  render = () =>
    <section className={`subheader ${css.section}`}>
      <div className="switcher_and_program-name">
        <button className="btn-back" onClick={this.handleBackClick}>
          <BackIcon className="back-icon"/>
          Back
        </button>
        <h1 className="program-name">{
          this.props.editorForCourseOrExam === 'course' ?
            this.props.course.name :
            this.props.exam.name
        }</h1>
        <a className="preview-link" href={Urls.getCoursePreviewUrl(this.props.course.id)} target="_blank" rel="noopener noreferrer">
          <i className="fa fa-search"/>
          Preview
        </a>
      </div>
    </section>
}

export default SubHeader;
