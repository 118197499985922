import ourFetch from './services/ourFetch';
// import hashToQueryString from './services/hashToQueryString';

// { name, description }
const update = (dispatch, usertocId, params) =>
  ourFetch(
    dispatch,
    'PUT', `/api/usertocs/${usertocId}/update/`,
    params
  );

export default {
  update
};
