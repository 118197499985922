import React from 'react';
import PropTypes from 'prop-types';
import orFalse from '~/services/orFalse';

import Dropzone from 'react-dropzone';
import VideoFile from '~/_appPartials/VideoFile';

class SectionUploadFromDevice extends React.Component {
  static propTypes = {
    file: orFalse(PropTypes.object).isRequired,
    updateFile: PropTypes.func.isRequired
  }

  onDrop = (files) => {
    if (files.length > 0) {
      this.props.updateFile(files[0]);
    }
  }

  render = () =>
    <section className="upload-section -fromDevice">
      {
        this.props.file ?
          <VideoFile
            className="file"
            file={this.props.file}
          /> :
          <Dropzone
            // ___Why not limit mimetypes to videos?
            //    Because otherwise .flv files are not accepted when we click on the dropzone for some reason.
            // accept="video/*"
            onDrop={this.onDrop}
            multiple={false}
          >{({ getRootProps, getInputProps }) =>
            <section {...getRootProps()} className="standard-dropzone">
              <input {...getInputProps()}/>
              <label>Drop Video Here</label>
            </section>
          }</Dropzone>
      }
    </section>
}

export default SectionUploadFromDevice;
