import React from 'react';
import PropTypes from 'prop-types';
import orFalse from '~/services/orFalse';

import ContentTogglerAndModalWrapper from '../ContentTogglerAndModalWrapper';
import StandardEditor from '~/_partials/StandardEditor';

const fromApiToState = (api) => ({
  payload: api.payload,
  options: api.options,
});

const fromStateToApi = (formState) => ({
  payload: formState.payload,
  options: formState.options,
  x_coordinate: formState.options ? formState.options.split(', ')[0] : '50',
  y_coordinate: formState.options ? formState.options.split(', ')[1] : '50',
});

class ParagraphTogglerAndModal extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    content: orFalse(PropTypes.shape({
      content_type: PropTypes.oneOf(['paragraph', 'cert-text', 'field']).isRequired,
      // html
      payload: PropTypes.string.isRequired
    })).isRequired,
    createWith: PropTypes.object,
  }

  getModalTitle = () => {
    const contentType = this.props.content ? this.props.content.content_type : this.props.createWith.contentType;

    if (contentType === 'cert-text') {
      return 'Text';
    } else if (contentType === 'field') {
      return 'Field';
    } else {
      return 'Paragraph';
    }
  }

  render = () => {
    const { content, createWith } = this.props;
    const x_coordinate = createWith ? createWith.x_coordinate : '50';
    const y_coordinate = createWith ? createWith.y_coordinate : '50';
    const initialState = {
      payload: (content && content.payload) ? content.payload : '',
      options: (content && content.options) ? content.options : `${x_coordinate}, ${y_coordinate}`,
    };
    return (
      <ContentTogglerAndModalWrapper
        className=""
        title={this.getModalTitle()}
        initialState={initialState}
        fromApiToState={fromApiToState}
        fromStateToApi={fromStateToApi}
        {...this.props}
      >{(formState, updateFormState) =>
        <div className="standard-modal-main">
          <StandardEditor
            value={formState.payload}
            updateValue={(html) => updateFormState({ ...formState, payload: html })}
          />
        </div>
      }</ContentTogglerAndModalWrapper>
    );
  }
}

export default ParagraphTogglerAndModal;
