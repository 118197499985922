import React from 'react';
import PropTypes from 'prop-types';

// ___Docs?
//    https://reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
    errorEl: PropTypes.element.isRequired
  }

  state = { hasError: false };

  // Update state so the next render will show the fallback UI.
  static getDerivedStateFromError = () => ({
    hasError: true
  })

  // componentDidCatch = (error, info) => {
  //   // You can also log the error to an error reporting service
  //   console.log(error);
  //   console.log(info);
  // }

  render = () => (
    this.state.hasError ?
      this.props.errorEl :
      this.props.children
  )
}

export default ErrorBoundary;
