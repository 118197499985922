// spe objects creator
const request = () => ({
  status: 'request',
  payload: null,
  error: null
});

const success = (response) => ({
  status: 'success',
  payload: response,
  error: null
});

const failure = (error) => ({
  status: 'failure',
  payload: null,
  error // must be string
});

// returns nothign from <Loading/>.
// would be great if it was just `null`, but proptypes don't treat nulls well :-/
const empty = () => ({});

export default { request, success, failure, empty };
