import React from 'react';
import PropTypes from 'prop-types';
import SpeCreator from '~/services/SpeCreator';

import ProgramApi from '~/api/ProgramApi';

import Loading from '~/_partials/Loading';

import LeftNav from './_partials/LeftNav';
import TopNav from './_partials/TopNav';
import ProgramReadinessMeter from './_partials/ProgramReadinessMeter';
import ProgramTitle from './_partials/ProgramTitle';

import css from './index.scss';

class ProgramDashboardWrapper extends React.Component {
  static propTypes = {
    programId: PropTypes.number.isRequired,
    children: PropTypes.element.isRequired
  }

  state = {
    speGetProgram: SpeCreator.empty()
  }

  componentDidMount = () =>
    this.apiGetProgram()

  apiGetProgram = () =>
    ProgramApi.get(
      (spe) => this.setState({ speGetProgram: spe }),
      this.props.programId
    )

  render = () =>
    <Loading spe={this.state.speGetProgram} className="standard-page-loading">{(program) =>
      <main className={css.main}>
        <TopNav program={program}/>
        <div className="left-nav_and_content">
          <LeftNav program={program}/>
          <div className="content">
            <ProgramReadinessMeter program={program}/>
            <ProgramTitle program={program}/>

            {this.props.children}
          </div>
        </div>
      </main>
    }</Loading>
}

export default ProgramDashboardWrapper;
