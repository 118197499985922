import React from 'react';
import PropTypes from 'prop-types';

import { RadioInput } from '~/_partials/standardForm';
import QuestionTogglerAndModal from '../../_contentModals/QuestionTogglerAndModal';

class QuestionContentLi extends React.Component {
  static propTypes = {
    itemType: PropTypes.string.isRequired,
    content: PropTypes.object.isRequired,
    liProps: PropTypes.object.isRequired,
    uiUpdateContent: PropTypes.func.isRequired,
    uiDeleteContent: PropTypes.func.isRequired,

    scoringType: PropTypes.oneOf(['percentage', 'point']).isRequired,
    totalAmountOfPoints: PropTypes.number.isRequired
  }

  renderLi = (content) =>
    <li {...this.props.liProps}>
      <div className="question standard-article" dangerouslySetInnerHTML={{ __html: content.payload }}/>
      <ul className="answers">
        {content.question_details.map((question_detail) =>
          <li key={question_detail.id}>
            <RadioInput label={question_detail.payload} readOnly checked={question_detail.is_correct_answer}/>
          </li>
        )}
      </ul>
      {
        content.explanation &&
        <div className="explanation">
          <b>Explanation:</b> {content.explanation}
        </div>
      }

      {
        this.props.scoringType === 'point' &&
        <div className="point-value">
          Value: {content.point_value} points
        </div>
      }
    </li>

  render = () =>
    <QuestionTogglerAndModal
      itemType={this.props.itemType}
      toggler={this.renderLi(this.props.content)}
      content={this.props.content}
      afterContentIsUpdated={this.props.uiUpdateContent}
      afterContentIsDeleted={this.props.uiDeleteContent}

      scoringType={this.props.scoringType}
      totalAmountOfPoints={this.props.totalAmountOfPoints}
    />
}

export default QuestionContentLi;
