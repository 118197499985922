import TusClient from 'tus-js-client';

import fetchWrapper from './services/fetchWrapper';

import generateUniqueId from '~/services/generateUniqueId';

const TRANSLOADIT_AUTH_KEY = 'b38b889040c211e98f404d105c7e04b7';
const AWS_S3_URL_PREFIX = 'https://module-uploads.s3.amazonaws.com/';

// Let's be passing future AWS's path right away!
// Then we create the Content with url='http://aws.mp3'.
// We also store Content.transloadit_assembly_url (assembly_ssl_url).
// When we load the page, if transloadit_assembly_url is present, we start GETing it. (An Assembly is running if ok is either ASSEMBLY_UPLOADING or ASSEMBLY_EXECUTING. All other states mean the Assembly has completed. If it completed in error, the error property will be set. More details on ok and error codes can be found below. - https://transloadit.com/docs/api/#response-codes)
// If assembly is errory - we do nothing, fetched once and displayed an error.
// If assembly is successful - we fetch once and remove the transloadit_assembly_ssl_url.
// If assembly is still running - we continue fetching for status, until it's successful or errory.

// Basically presence of transloadit_assembly_url implies that .payload url is not yet active, it's either still uploading, or it errored out.
// ___Why do we need to store .transloadit_assembly_url, can't we just check if AWS url shows us the video?
//    We could, but then we wouldn't be able to see the difference between 'it's in progress' and 'it's an error' states.
//    We may just use pinback to only insert the AWS url once upload is done, but we all know how pinbacks work.
//    Basically right now we use the pinback that executes every time teacher is on the /edit page (or student is on the /learn page!).
//    This has an additional bonus of not needing the 'insert .payload url, remove .transloadit_assembly_url from Content' across multiple places (pinback on backend, and right-after-video-upload frontend).

const getAssembly = (dispatch, assemblyUrl) =>
  fetchWrapper(
    dispatch,
    fetch(assemblyUrl, { method: 'GET' })
      .then((response) => response.json())
      .catch((error) =>
        Promise.reject({ _ifError: true, payload: error.toString() })
      )
  );

const _stepEncode = (fromStep) => ({
  use: fromStep,
  robot: '/video/encode',
  ffmpeg_stack: 'v3.3.3',
  // 1024x768 (https://transloadit.com/docs/transcoding/#video-presets)
  preset: 'ipad-high'
});

const _stepUploadToS3 = (fromStep, awsS3Path) => ({
  use: fromStep,
  robot: '/s3/store',
  // ___How to create credentials for transloadit aws s3?
  //    Here (https://transloadit.com/template-credentials)
  // ___Why is name 'certcentral' instead of proper e.g. 'certcentral_s3_credentials'?
  //    Because transloadit errors out when I'm trying to edit it :-/
  credentials: 'certcentral',
  path: awsS3Path
});

const createAssemblyUploadVideoViaTus = (dispatch) => {
  const awsS3Path = generateUniqueId();

  const formData = new FormData();
  formData.append('params', JSON.stringify({
    auth: {
      key: TRANSLOADIT_AUTH_KEY
    },
    steps: {
      // 1. Upload the video
      ':original': { robot: '/upload/handle' },
      // 2. Convert the original video to mp4.
      'myEncodedVideo': _stepEncode(':original'),
      // 3. Export it to AWS S3
      'myExportedVideo': _stepUploadToS3('myEncodedVideo', awsS3Path)
    }
  }));
  formData.append('num_expected_upload_files', 1);
  return fetchWrapper(
    dispatch,
    fetch('https://api2.transloadit.com/assemblies', {
      method: 'POST',
      body: formData
    })
      .then((response) => response.json())
      .then((response) => ({
        ...response,
        _awsS3Url: AWS_S3_URL_PREFIX + awsS3Path,
        _urlForTusUpload: response.tus_url,
        _transloaditAssemblyUrl: response.assembly_ssl_url
      }))
      .catch((error) =>
        Promise.reject({ _ifError: true, payload: error.toString() })
      )
  );
};

const createAssemblyUploadVideoViaUrl = (dispatch, urlToImportFrom) => {
  const awsS3Path = generateUniqueId();

  const formData = new FormData();
  formData.append('params', JSON.stringify({
    auth: {
      key: TRANSLOADIT_AUTH_KEY
    },
    steps: {
      // 1. Upload the video
      ':original': {
        robot: '/http/import',
        url: urlToImportFrom
      },
      // 2. Convert the original video to mp4.
      'encodedVideo': _stepEncode(':original'),
      // 3. Export it to AWS S3
      'exported': _stepUploadToS3('encodedVideo', awsS3Path)
    }
  }));
  return fetchWrapper(
    dispatch,
    fetch('https://api2.transloadit.com/assemblies', {
      method: 'POST',
      body: formData
    })
      .then((response) => response.json())
      .then((response) => ({
        ...response,
        _awsS3Url: AWS_S3_URL_PREFIX + awsS3Path,
        _transloaditAssemblyUrl: response.assembly_ssl_url
      }))
      .catch((error) =>
        Promise.reject({ _ifError: true, payload: error.toString() })
      )
  );
};

const startTusUpload = ({ tusUrl, assemblyUrl, file, callbacks = {} }) => {
  const tusClientUpload = new TusClient.Upload(file, {
    endpoint: tusUrl,
    retryDelays: [0, 1000, 3000, 5000],
    metadata: {
      name: file.name,
      type: file.type,
      filename: file.name,
      filetype: file.type,
      // must just always be present and 'file', some transloadit-related bullshit
      fieldname: 'file',
      assembly_url: assemblyUrl
    },

    // ___Why disable resumes!
    //    For some reason repeated uploads don't work otherwise. This is maybe a question for transloadit devs.
    //    Basically if patch isn't sent (and only HEAD is sent like tus-js-client does it, asking is if it even makes sense to send bytes, - we just get `,"started_tus_uploads":0, "ok":"ASSEMBLY_UPLOADING"`) forever.
    resume: false,
    // chunkSize does work, but only for new videos?
    chunkSize: 10000000,

    onError: callbacks.onError,
    onProgress: callbacks.onProgress,
    onSuccess: callbacks.onSuccess
  });

  tusClientUpload.start();
};

export default {
  getAssembly,
  createAssemblyUploadVideoViaTus,
  createAssemblyUploadVideoViaUrl,
  startTusUpload
};
