import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Snackbar from '@material-ui/core/Snackbar';
import ErrorIcon from '@material-ui/icons/ErrorOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

import Constants from '~/services/Constants';

class StandardAlert extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  state = {
    ifShowAlert: false
  }

  componentWillReceiveProps(props) {
    if (props.open !== this.props.open) {
      this.setState({ ifShowAlert: props.open });
    }
  }

  render = () =>
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={{ root: 'standard-alert-container' }}
      open={this.state.ifShowAlert}
      autoHideDuration={Constants.ALERT_DISPLAY_TIME}
      onClose={() => this.props.onClose()}
    >
      <div
        className={classNames('standard-alert-body', {
          '-success': this.props.type === Constants.ALERT_TYPE.success,
          '-warning': this.props.type === Constants.ALERT_TYPE.warning,
        })}
      >
        {this.props.type === Constants.ALERT_TYPE.success && <CheckCircleIcon className="standard-alert-icon"/>}
        {this.props.type === Constants.ALERT_TYPE.warning && <ErrorIcon className="standard-alert-icon"/>}
        <div className="standard-course-alert">{this.props.message}</div>
      </div>
    </Snackbar>
}

export default StandardAlert;
