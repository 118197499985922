import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Urls from '~/services/Urls';
import ProgramApi from '~/api/ProgramApi';
import UserTocApi from '~/api/UserTocApi';
import FileApi from '~/api/FileApi';
import { Textarea } from '~/_partials/standardForm';
import Loading from '~/_partials/Loading';
import camera_svg from '~/../static/frontend/img/camera.svg';

import css from './index.scss';

class CourseEdit extends React.Component {
  constructor(props) {
    super(props);
    this.descriptionInputRef = React.createRef();
  }

  static propTypes = {
    course_id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    updateCourseImage: PropTypes.func.isRequired,
    updateDescription: PropTypes.func.isRequired,
  }

  state = {
    ifEditActive: false,
    descriptionEditText: '',
    formState: {
      course_description: '',
    },
    speUploadFile: {},
  }

  apiUpdateCourseImage = (imageUrl) =>
    ProgramApi.updateImage(
      (spe) => this.setState({ speUploadFile: spe }),
      {
        course_id: this.props.course_id,
        course_image: imageUrl,
      },
    )

  apiUpdateDescription = (newDescription) =>
    UserTocApi.update(
      false,
      this.props.course_id,
      {
        description: newDescription
      },
    )

  uiUpdateCourseImage = (imageUrl) => {
    this.apiUpdateCourseImage(imageUrl)
      .then((result) => {
        if (result.message === 'success') {
          this.props.updateCourseImage(imageUrl);
        }
      });
  }

  uiUpdateDescription = (newDescription) =>
    this.apiUpdateDescription(newDescription)
    .then(({ description }) =>
      this.props.updateDescription(description)
    );

  uploadFile = (e) => {
    const file = e.target.files[0];
    FileApi.upload(
      (spe) => this.setState({ speUploadFile: spe }),
      file
    )
      .then((response) =>
        this.uiUpdateCourseImage(response.url)
      );
  }

  updateFormstate = (formState) => {
    this.setState({ formState });
  }

  handleAddImageClick = () => {
    if (this.fileInputRef) {
      this.fileInputRef.click();
    }
  }

  handleEditClick = () => {
    this.setState({ ifEditActive: true, formState: { ...this.state.formState, course_description: this.props.description } }, () => {
      if (this.descriptionInputRef.current) {
        this.descriptionInputRef.current.focus();
      }
    });
  }

  handleSaveClick = () => {
    this.uiUpdateDescription(this.state.formState.course_description)
      .then(() => {
        this.setState({ ifEditActive: false });
      });
  }

  handleCancelClick = () => {
    this.setState({ ifEditActive: false, formState: { ...this.state.formState, course_description: this.props.description } });
  }

  renderDescriptionOrEdit = () => {
    const { description } = this.props;
    const { ifEditActive, formState } = this.state;

    if (!ifEditActive) {
      return (
        <div className="description-panel">
          <p className="name">{this.props.name}</p>
          <div className="description-box">
            <p className="description">{ description.length ? description : 'Add a description here...' }</p>
            <button className="standard-button -underline" onClick={this.handleEditClick}>Edit</button>
          </div>
          <a href={Urls.getCoursePreviewUrl(this.props.course_id)}>
            <button className="standard-button -outline btn-preview -plum">Preview</button>
          </a>
        </div>
      );
    } else {
      return (
        <div className="edit-panel">
          <Textarea
            name="course_description"
            placeholder="Type Description Here"
            textareaProps={{ maxLength: 1000 }}
            formState={formState}
            updateFormState={this.updateFormstate}
          />
          <div className="description-edit-actions">
            <button className="standard-button -transparent btn-cancel" onClick={this.handleCancelClick}>Cancel</button>
            <button className="standard-button -underline btn-save" onClick={this.handleSaveClick}>Save</button>
          </div>
        </div>
      );
    }
  }

  render = () => {
    const { imageUrl } = this.props;
    return (
      <div className={css.editContainer}>
        <div className={classNames('image-container', { '-has-image': imageUrl && imageUrl.length > 0 })}>
          {
            imageUrl.length > 0 &&
            <img src={imageUrl} className="course-image"/>
          }
          <div className="image-edit-container">
            <input type="file" accept="image/png, image/jpeg" ref={inputRef => this.fileInputRef = inputRef} onChange={this.uploadFile}/>
            <div className="camera-box">
              <img src={camera_svg}/>
              <p>Add Image</p>
            </div>
          </div>
          <Loading className="loading-contaienr" spe={this.state.speUploadFile}/>
          <button className="btn-upload-image" onClick={this.handleAddImageClick}>
            {imageUrl.length > 0 &&
              <p>Change Course Image</p>
            }
          </button>
        </div>
        <div className="course-description-container">
          {this.renderDescriptionOrEdit()}
        </div>
      </div>
    );
  }
}

export default CourseEdit;
