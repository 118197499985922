import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import ModuleApi from '~/api/ModuleApi';
import checkReordableFromOneToNewIndex from '~/services/checkReordableFromOneToNewIndex';
import injectFromOldToNewIndex from '~/services/injectFromOldToNewIndex';
import ModuleItem from './_partials/ModuleItem';
import AddModuleTogglerAndModal from '../../Modals/AddModuleTogglerAndModal';

import css from './index.scss';

class ModuleTabContent extends React.Component {
  static propTypes = {
    course_id: PropTypes.number.isRequired,
    course_latest_id: PropTypes.number.isRequired,
    if_new_course: PropTypes.bool.isRequired,
    modules: PropTypes.array.isRequired,
    uiUpdateModules: PropTypes.func.isRequired,
  }

  static defaultProps = {
    modules: []
  }

  state = {
    ifShowAddItem: false,
    newModuleType: '',
  }

  apiCreateModule = (newModule) =>
    ModuleApi.create(
      false,
      {
        program_id: this.props.course_latest_id,
        section_name: newModule.name,
        section_type: newModule.program_type,
        position: this.props.modules.length
      }
    )

  apiReorderModules = (reorderedModules) =>
    ModuleApi.reorderAll(
      false,
      reorderedModules.map((module) => module.id)
    )

  uiCreateModule = (newModule) =>
    this.apiCreateModule(newModule)
      .then(result => {
        this.setState({ ifShowAddItem: false, newModuleType: '' });
        const newMoudleRes = result.sections[result.sections.length - 1];

        const reorderedModules = [
          ...result.sections.slice(0, this.newModuleIdex),
          newMoudleRes,
          ...result.sections.slice(this.newModuleIdex, result.sections.length - 1)
        ];

        this.props.uiUpdateModules(reorderedModules);
        this.apiReorderModules(reorderedModules);
      });

  uiDuplicateModule = (sourceModule, newModule) => {
    const updatedModules = [];
    this.props.modules.forEach(module => {
      updatedModules.push(module);

      if (module.id === sourceModule.id) {
        updatedModules.push(newModule);
      }
    });
    this.props.uiUpdateModules(updatedModules);
  }

  uiUpdateModule = (updatedModule) => {
    const prevModules = this.props.modules;
    const updatedModuleIndex = prevModules.findIndex((li) => li.id === updatedModule.id);
    const newModules = [
      ...prevModules.slice(0, updatedModuleIndex),
      updatedModule,
      ...prevModules.slice(updatedModuleIndex + 1)
    ];
    this.props.uiUpdateModules(newModules);
  }

  uiReorderModules = ({ fromIndex, toIndex }) => {
    const reorderedModules = injectFromOldToNewIndex(this.props.modules, fromIndex, toIndex);
    this.props.uiUpdateModules(reorderedModules);
    this.apiReorderModules(reorderedModules);
  }

  handleAddModuleClose = (type) => {
    if (type && type.length) {
      this.setState({ ifShowAddItem: true, newModuleType: type });
    }
  }

  handleCancelAdd = () => {
    this.setState({ ifShowAddItem: false, newModuleType: '' });
  }

  handleModuleDelete = (moduleId) => {
    const { modules } = this.props;
    const findIndex = modules.findIndex(module => module.id === moduleId);

    if (findIndex >= 0) {
      modules.splice(findIndex, 1);
    }

    this.props.uiUpdateModules(modules);
  }

  onDragEnd = (result) => {
    const { modules } = this.props;
    const ifDroppedInsideList = result.destination;

    if (!ifDroppedInsideList) return;

    const fromIndex = result.source.index;
    const toIndex = result.destination.index;
    const ifCanReorder = checkReordableFromOneToNewIndex(modules, fromIndex, toIndex);

    if (ifCanReorder) {
      this.uiReorderModules({ fromIndex, toIndex });
    }
  }

  renderModuleList = () => {
    const { modules } = this.props;
    const { ifShowAddItem, newModuleType } = this.state;
    this.newModuleIdex = modules.findIndex(mdl => mdl.program_type === 'Certificate');
    
    if (this.newModuleIdex < 0) {
      this.newModuleIdex = modules.length;
    }

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="contents">{(dropProvided) =>
          <div
            id="moduleWrapper"
            className="module-list"
            ref={listRef => { dropProvided.innerRef(listRef); this.moduleListRef = listRef; }}
            {...dropProvided.droppableProps}
          >
            {
              this.newModuleIdex > 0 && modules.slice(0, this.newModuleIdex).map((module, index) => (
                <ModuleItem
                  key={index}
                  index={index}
                  course_id={this.props.course_latest_id}
                  className="module-item"
                  module={module}
                  onCopy={this.uiDuplicateModule} // use when duplicate
                  onUpdate={this.uiUpdateModule}
                  onDelete={this.handleModuleDelete}
                />
              ))
            }
            {ifShowAddItem &&
              <ModuleItem
                key={modules.length + 1}
                index={modules.length + 1}
                course_id={this.props.course_id}
                className="module-item"
                module={{ id: 0, program_type: newModuleType, name: '' }}
                isNew={true}
                onCancel={this.handleCancelAdd}
                onAdd={this.uiCreateModule}
              />
            }
            {
              modules.slice(this.newModuleIdex).map((module, index) => (
                <ModuleItem
                  key={index}
                  index={index}
                  course_id={this.props.course_latest_id}
                  className="module-item"
                  module={module}
                  onCopy={this.uiDuplicateModule} // use when duplicate
                  onUpdate={this.uiUpdateModule}
                  onDelete={this.handleModuleDelete}
                />
              ))
            }
          </div>
        }</Droppable>
      </DragDropContext>
    );
  }

  render = () =>
    <div className={css.moduleContainer}>
      <div className="add-module-bar">
        <h3 className="title">Sections</h3>
        <AddModuleTogglerAndModal open={this.props.if_new_course && this.props.modules.length === 0} modules={this.props.modules} onClose={this.handleAddModuleClose}/>
      </div>
      {this.renderModuleList()}
      {this.props.modules.length === 0 && !this.state.ifShowAddItem &&
        <div className="standard-empty-content empty-section">
          <p>Let's add your first section to this course.</p>
          <p>Click the green, Add Section button to add one.</p>
        </div>
      }
    </div>
}

export default ModuleTabContent;
