import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ContentEditable from 'react-contenteditable';
import _ from 'lodash/core';

import { RadioInput } from '~/_partials/standardForm';

class LikertTable extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    formState: PropTypes.object.isRequired,
    updateFormState: PropTypes.func,
  }

  componentDidUpdate() {
    const optionElements = document.querySelectorAll('.option-textarea');
    const questionElements = document.querySelectorAll('.question-textarea');
    Array.prototype.slice.call(optionElements).forEach(element => this.updateTextAreaHeight(element));
    Array.prototype.slice.call(questionElements).forEach(element => this.updateTextAreaHeight(element));
  }

  uiUpdateLikertQuestion = (e, index) => {
    const { formState, updateFormState } = this.props;
    const element = e.target;
    const questionHtml = element.value;

    if (updateFormState) {
      const question = {
        payload: questionHtml,
      };

      updateFormState({
        ...formState,
        question_details: [
          ...formState.question_details.slice(0, index),
          question,
          ...formState.question_details.slice(index + 1),
        ]
      });
    }
  }

  uiUpdateLikertOptionValue = (e, updatedOptionIndex) => {
    const { formState, updateFormState } = this.props;
    const element = e.target;
    const updatedOptionValueHtml = element.value;

    if (updateFormState) {
      const oldOptions = formState.options;
      const newOptions = [
        ...oldOptions,
      ];

      if (/^\d+$/.test(updatedOptionValueHtml)) {
        newOptions[updatedOptionIndex].value = parseInt(updatedOptionValueHtml, 10);
      }

      updateFormState({
        ...formState,
        options: newOptions,
      });
    }
  }

  uiUpdateLikertOptionLabel = (e, updatedOptionIndex) => {
    const { formState, updateFormState } = this.props;
    const element = e.target;
    const updatedOptionLabelHtml = element.value;

    if (updateFormState) {
      const oldOptions = formState.options;
      const newOptions = [
        ...oldOptions
      ];
      newOptions[updatedOptionIndex].label = updatedOptionLabelHtml;
      updateFormState({
        ...formState,
        options: newOptions,
      });
    }
  }

  uiDeleteLikertQuestion = (deleteQuestionIndex) => {
    const { formState, updateFormState } = this.props;

    if (updateFormState) {
      updateFormState({
        ...formState,
        question_details: [
          ...formState.question_details.slice(0, deleteQuestionIndex),
          ...formState.question_details.slice(deleteQuestionIndex + 1),
        ]
      });
    }
  }

  uiDeleteLikertOption = (deletedOptionIndex) => {
    const { formState, updateFormState } = this.props;

    if (updateFormState) {
      const oldOptions = formState.options;
      const newOptions = [
        ...oldOptions.slice(0, deletedOptionIndex),
        ...oldOptions.slice(deletedOptionIndex + 1),
      ];
      updateFormState({
        ...formState,
        options: newOptions,
      });
    }
  }

  render = () => {
    const { className, formState } = this.props;
    const options = formState.options;
    return (
      <table className={`likert-table ${className ? className : ''}`}>
        <thead>
          <tr>
            <th/>
            {options.length > 0 && options.map(((option, index) =>
              <th key={index}>
                <div className="option-content-group">
                  <div className="edit-box -option">
                    <ContentEditable
                      className="edit-area"
                      html={option.value.toString()}
                      disabled={false}
                      onChange={e => this.uiUpdateLikertOptionValue(e, index)}
                      tagName="div"
                    />
                  </div>
                  <div className={classNames('edit-box -option', { '-created': option.label.length === 0 })}>
                    <ContentEditable
                      className="edit-area"
                      html={option.label}
                      disabled={false}
                      onChange={e => this.uiUpdateLikertOptionLabel(e, index)}
                      tagName="div"
                    />
                    {!className && option.label.length === 0 &&
                      <span className="option-placeholder">
                        Edit Label
                      </span>
                    }
                  </div>
                </div>
                {options.length > 1 &&
                  <button className="btn-remove" onClick={() => this.uiDeleteLikertOption(index)}>
                    <i className="fa fa-times"/>
                  </button>
                }
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {_.values(formState.question_details).map((question, index) =>
            <tr key={index}>
              <td className={question.payload === '' ? '-created' : ''}>
                <div className="edit-box">
                  <ContentEditable
                    className="edit-area"
                    html={question.payload}
                    disabled={false}
                    onChange={e => this.uiUpdateLikertQuestion(e, index)}
                    tagName="div"
                  />
                  {question.payload === '' &&
                    <span className="option-placeholder">
                      Edit Text
                    </span>
                  }
                </div>
                {formState.question_details.length > 1 &&
                  <button className="btn-remove" onClick={() => this.uiDeleteLikertQuestion(index)}>
                    <i className="fa fa-times"/>
                  </button>
                }
              </td>
              {_.values(options).map((option, optionIndex) =>
                <td key={optionIndex}>
                  <RadioInput
                    name={`likert-option-${index}${optionIndex}`}
                    checked={question.is_correct_answer ? question.is_correct_answer : false}
                    readOnly
                  />
                </td>
              )}
            </tr>
          )}
        </tbody>
      </table>
    );
  }
}

export default LikertTable;
