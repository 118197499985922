import SpeCreator from '~/services/SpeCreator';

const fetchWrapper = (dispatch, fetchPromise) => {
  if (dispatch) dispatch(SpeCreator.request());
  return fetchPromise
    .then((response) => {
      if (dispatch) dispatch(SpeCreator.success(response));
      return response;
    })
    .catch((error) => {
      if (dispatch) dispatch(SpeCreator.failure(error));
      return Promise.reject(error);
    });
};

export default fetchWrapper;
