import React from 'react';
import PropTypes from 'prop-types';

import orFalse from '~/services/orFalse';
import TogglerAndModal from '~/_partials/TogglerAndModal';

import css from './index.scss';

class ConfirmTogglerAndModal extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    toggler: PropTypes.element.isRequired,
    reissuable: orFalse(PropTypes.bool),
    okOnly: orFalse(PropTypes.bool),
    onClose: PropTypes.func.isRequired,
  };

  handleSaveClick = (closeModal) => {
    closeModal();
    this.props.onClose();
  };

  render() {
    return (
      <TogglerAndModal
        toggler={this.props.toggler}
      >{(closeModal, renderCloseButton) =>
        <div className={`standard-modal-wrapper ${css.wrapper}`}>
          <div className="standard-modal">
            {renderCloseButton()}

            <div className="standard-modal-header"/>
            <div className="standard-modal-main">
              <h4>{this.props.title}</h4>
              {this.props.reissuable &&
                <p className="reissue-note">
                  *Please note*: All reissued programs must be started from the beginning.
                </p>
              }
            </div>

            <div className="standard-modal-footer -with-buttons">
              {!this.props.okOnly &&
                <button
                  className="standard-button -sliding -red"
                  type="button"
                  onClick={closeModal}
                >Cancel</button>
              }
              <button
                className="standard-button -sliding -blue"
                type="button"
                onClick={() => this.handleSaveClick(closeModal)}
              >
                {this.props.okOnly ? 'Okay' : 'Yes'}
              </button>
            </div>
          </div>
        </div>
      }</TogglerAndModal>
    );
  }
}

export default ConfirmTogglerAndModal;
