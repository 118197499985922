import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import wordCloud from 'highcharts/modules/wordcloud.js';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import * as surveyUtils from '../../services/utils';
import NpsChart from './NpsChart';

wordCloud(Highcharts);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
    marginTop: 20,
  },
  appBar: {
    boxShadow: 'none',
  }
});

const CustomTableRow = withStyles(theme => ({
  head: {
    height: 35,
  },
}))(TableRow);

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#eee',
    color: '#555',
    fontSize: 14,
    fontWeight: 700,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function TabContainer({ children }) {
  return (
    <Typography component="div" style={{ padding: 24, border: '1px solid #d9d9d9' }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class ContentPanel extends React.Component {
  state = {
    tabIndex: 0,
  }

  static propTypes = {
    content: PropTypes.object.isRequired,
  };

  handleChange = (event, value) => {
    this.setState({ tabIndex: value });
  };

  renderFreeResponseTable = (freeResponses) => {
    const { classes } = this.props;
    const responses = freeResponses.filter(response => response.free_response);

    return responses.length > 0 ? <Table className={classes.table}>
      <TableHead>
        <CustomTableRow>
          <CustomTableCell>Resident</CustomTableCell>
          <CustomTableCell>ANSWERS</CustomTableCell>
        </CustomTableRow>
      </TableHead>
      <TableBody>
        {responses.map((answer, index) =>
          <CustomTableRow key={`${answer.answer_id}-${index}`}>
            <CustomTableCell>
              {answer.student_name}
            </CustomTableCell>
            <CustomTableCell>
              {answer.free_response}
            </CustomTableCell>
          </CustomTableRow>
        )}
      </TableBody>
    </Table> : 'No Comments';
  }

  renderIndividualResponses = () => {
    const { classes, content } = this.props;

    return <Table className={classes.table}>
      <TableHead>
        <CustomTableRow>
          <CustomTableCell>Full Name</CustomTableCell>
          <CustomTableCell>Response</CustomTableCell>
          <CustomTableCell>Date Completed</CustomTableCell>
        </CustomTableRow>
      </TableHead>
      <TableBody>
        {content.student_answers.map((student_answer, index) => (
          <CustomTableRow key={index}>
            <CustomTableCell component="th" scope="row">
              {student_answer.student_name}
            </CustomTableCell>
            <CustomTableCell>
              {surveyUtils.getAnswerPayloadById(student_answer.answer_id, content.answers)}
            </CustomTableCell>
            <CustomTableCell>
              {student_answer.date_completed}
            </CustomTableCell>
          </CustomTableRow>
        ))}
      </TableBody>
    </Table>;
  }

  renderTabContainer = () => {
    const { content, classes } = this.props;
    const { tabIndex } = this.state;
    let averageScore = null;
    let medianScore = null;

    if (content.type === "multiple-choice-2") {
      averageScore = surveyUtils.getAverageScore(content);
      medianScore = surveyUtils.getMedianScore(content);
    }

    return <div className="tab-container">
        <AppBar position="static" color="default" className={classes.appBar}>
          <Tabs
            value={this.state.tabIndex}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Stats" />
            <Tab label="Comments" />
            <Tab label="Responses" />
          </Tabs>
        </AppBar>
          {tabIndex === 0 && <TabContainer>
            <div className="stats-item">
              <label>Total Responses: </label>
              <span>{content.student_answers.length}</span>
            </div>
            <div className="stats-item">
              <label>Skipped: </label>
              <span>0</span>
            </div>
            {averageScore && <div className="stats-item">
              <label>Average Score: </label>
              <span>{averageScore}</span>
            </div>}
            {medianScore && <div className="stats-item">
              <label>Median Score: </label>
              <span>{medianScore}</span>
            </div>}
          </TabContainer>}
          {tabIndex === 1 && <TabContainer>
            {this.renderFreeResponseTable(content.comments)}
          </TabContainer>}
          {tabIndex === 2 && <TabContainer>
            {content.type === 'multi-line-text' && this.renderFreeResponseTable(content.student_answers)}
            {content.type !== 'multi-line-text' && this.renderIndividualResponses()}
          </TabContainer>}
      </div>
  }

  render = () => {
    const { content } = this.props;
    const options = surveyUtils.getSurveyChartOption(content);
    const question = surveyUtils.stripHtml(content.payload);

    return (
      <div className="content-panel" id={`item_${content.id}`}>
        <div className="detail-pane">
          <h2>{question}</h2>
          <div className="sub-details">
            <span>Answered: {content.student_answers.length}</span>
            <span>Skipped: 0</span>
          </div>
          {(content.type === 'multiple-choice' || content.type === 'multiple-choice-2') && <div className="highcharts-div">
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
            />
          </div>}
          {content.type === 'nps' && <NpsChart value={surveyUtils.calcNPSScore(content)}/>}
          {content.type === 'multi-line-text' && <HighchartsReact
              highcharts={Highcharts}
              options={surveyUtils.getWordCloudChartOption(content)}
          />}
          {this.renderTabContainer()}
        </div>
      </div>
    );
  }
}

ContentPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContentPanel);
