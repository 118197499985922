import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import ContentPanel from './_partials/ContentPanel';
import SwitchInput from '~/_partials/SwitchInput';

import css from './index.scss';
import * as surveyUtils from '../services/utils';

const styles = theme => ({
  lengthOfOwnershipFormControl: {
    width: 150,
    margin: theme.spacing.unit,
  },
  formControl: {
    margin: theme.spacing.unit,
  },
});

const OWNERSHIP_FILTER = {
  ALL: 'all',
  LESS_THREE_YEARS: 'less_3_yrs',
  THREE_TO_FIVE_YEARS: '3_5_yrs',
  FIVE_TO_TEN_YEARS: '5_10_yrs',
  MORE_TEN_YEARS: 'more_10_yrs',
};

const SQFT_FILTER = {
  ALL: 'all',
  UNTIL_400: 'until_400',
  UNTIL_1000: 'until_1000',
  UNTIL_2000: 'until_2000',
  OVER_2000: 'over_2000',
};

const SURVEY_EXPORT = {
  CLASSIC: {
    id: 'CLASSIC',
    type: 'classic_survey_results_report',
    duplicated: 0,
  },
  CLASSIC_DUP: {
    id: 'CLASSIC_DUP',
    type: 'classic_survey_results_report',
    duplicated: 1,
  },
  LINEAR: {
    id: 'LINEAR',
    type: 'linear_survey_results_report',
    duplicated: 0,
  },
  LINEAR_DUP: {
    id: 'LINEAR_DUP',
    type: 'linear_survey_results_report',
    duplicated: 1,
  },
  QUESTION_BY_QUESTION: {
    id: 'QUESTION_BY_QUESTION',
    type: 'question_by_question_analysis',
    duplicated: 0,
  },
  QUESTION_BY_QUESTION_DUP: {
    id: 'QUESTION_BY_QUESTION_DUP',
    type: 'question_by_question_analysis',
    duplicated: 1,
  }
};

class SurveyResultDetail extends React.Component {
  static propTypes = {
    survey_program_details: PropTypes.object.isRequired,
    survey_contents: PropTypes.array,
  };

  state = {
    ownershipFilter: OWNERSHIP_FILTER.ALL,
    sqftFilter: SQFT_FILTER.ALL,
    surveyExportId: SURVEY_EXPORT.CLASSIC.id,
  };

  componentDidMount() {
    this.contentListRef.style.marginTop = this.h1ElementRef.offsetHeight + 'px';
  }

  handleOwnershipFilterChange = (e) => {
    this.setState({ ownershipFilter: e.target.value });
    if (e.target.value !== OWNERSHIP_FILTER.ALL) {
      this.setState({ sqftFilter: SQFT_FILTER.ALL });
    }
  }

  handleSqftFilterChange = (e) => {
    this.setState({ sqftFilter: e.target.value });
    if (e.target.value !== SQFT_FILTER.ALL) {
      this.setState({ ownershipFilter: OWNERSHIP_FILTER.ALL });
    }
  }

  handleSurveyExportChange = (e) => {
    this.setState({ surveyExportId: e.target.value });
  }

  handleExportResultsClick = () => {
    const { survey_program_details } = this.props;
    const { surveyExportId } = this.state;
    const downloadUrl = `/api/generate-excel/${SURVEY_EXPORT[surveyExportId].type}/?program_id=${survey_program_details.id}&duplicated=${SURVEY_EXPORT[surveyExportId].duplicated}`;
    $('.suvey-results-loading-div').show();
    fetch(downloadUrl)
    .then(resp => resp.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = 'Survey_Results.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      $('.suvey-results-loading-div').hide();
    })
    .catch(() => alert('oh no!'));
  }

  handleHideAnswersSwitch = (v) => {
    $('.suvey-results-loading-div').show();
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    if (v) {
      searchParams.set('isHideNA', 1);
    } else {
      searchParams.delete('isHideNA');
    }

    url.search = searchParams.toString();
    window.location.href = url.toString();
  }

  render = () => {
    const { survey_program_details, survey_contents, classes } = this.props;
    const { sqftFilter, ownershipFilter, surveyExportId } = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    const isHideNA = urlParams.get('isHideNA') || false;
    const contents = JSON.parse(JSON.stringify(survey_contents)).filter(survey_content => surveyUtils.isAvailableTypeInResult(survey_content.type));
    contents.forEach(content => {
      content.student_answers = content.student_answers.filter(student_answer => {
        if (ownershipFilter !== OWNERSHIP_FILTER.ALL && sqftFilter === SQFT_FILTER.ALL) {
          if (ownershipFilter === OWNERSHIP_FILTER.LESS_THREE_YEARS) {
            return student_answer.navigator_length_of_ownership < 1095; // 1095 = 3 years
          } else if (ownershipFilter === OWNERSHIP_FILTER.THREE_TO_FIVE_YEARS) {
            return student_answer.navigator_length_of_ownership >= 1095 && student_answer.navigator_length_of_ownership < 1825; // 1095 = 3 years, 1825 = 5 years
          } else if (ownershipFilter === OWNERSHIP_FILTER.FIVE_TO_TEN_YEARS) {
            return student_answer.navigator_length_of_ownership >= 1825 && student_answer.navigator_length_of_ownership < 3650; // 1825 = 5 years, 3650 = 10 years
          } else if (ownershipFilter === OWNERSHIP_FILTER.MORE_TEN_YEARS) {
            return student_answer.navigator_length_of_ownership >= 3650; // 3650 = 10 years
          }
        } else {
          const studentSqft = !isNaN(parseInt(student_answer.sqft, 10)) ? parseInt(student_answer.sqft, 10) : 0;
          if (sqftFilter === SQFT_FILTER.UNTIL_400) {
            return studentSqft <= 400; // 0 - 400
          } else if (sqftFilter === SQFT_FILTER.UNTIL_1000) {
            return studentSqft > 400 && studentSqft <= 1000; // 401 - 1000
          } else if (sqftFilter === SQFT_FILTER.UNTIL_2000) {
            return studentSqft > 1001 && studentSqft <= 2000; // 1001 - 2000
          } else if (sqftFilter === SQFT_FILTER.OVER_2000) {
            return studentSqft > 2000; // 2001+
          }
        }

        return true;
      });
    });

    return (
      <div className={css.container}>
        <h1 ref={ref => this.h1ElementRef = ref}>
          Enterprise > {survey_program_details.name}
          <SwitchInput
            value={isHideNA}
            labelLeft='Hide N/A answers'
            className="hide-na-switch -green-small switch-input"
            updateValue={this.handleHideAnswersSwitch}
          />
          <div className="export_survey">
            {false && <FormControl className={classes.lengthOfOwnershipFormControl}>
              <InputLabel htmlFor="length-of-ownership">Length of Ownership</InputLabel>
              <Select
                native
                value={this.state.ownershipFilter}
                onChange={this.handleOwnershipFilterChange}
                inputProps={{
                  name: 'resident',
                  id: 'length-of-ownership',
                }}
              >
                <option value={OWNERSHIP_FILTER.ALL}>All Residents</option>
                <option value={OWNERSHIP_FILTER.LESS_THREE_YEARS}>Less than 3yrs</option>
                <option value={OWNERSHIP_FILTER.THREE_TO_FIVE_YEARS}>3 - 5yrs</option>
                <option value={OWNERSHIP_FILTER.FIVE_TO_TEN_YEARS}>5 - 10yrs</option>
                <option value={OWNERSHIP_FILTER.MORE_TEN_YEARS}>10+yrs</option>
              </Select>
            </FormControl>}
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="square-feet">Square Feet</InputLabel>
              <Select
                native
                value={this.state.sqftFilter}
                onChange={this.handleSqftFilterChange}
                inputProps={{
                  name: 'squareFeet',
                  id: 'square-feet',
                }}
              >
                <option value={SQFT_FILTER.ALL}>All Residents</option>
                <option value={SQFT_FILTER.UNTIL_400}>0 - 400</option>
                <option value={SQFT_FILTER.UNTIL_1000}>401 - 1000</option>
                <option value={SQFT_FILTER.UNTIL_2000}>1001 - 2000</option>
                <option value={SQFT_FILTER.OVER_2000}>2001+</option>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
            <InputLabel htmlFor="survey_export-native-simple">Export Type</InputLabel>
              <Select
                native
                value={surveyExportId}
                onChange={this.handleSurveyExportChange}
                inputProps={{
                  name: 'survey_export',
                  id: 'survey_export-native-simple',
                }}
              >
                <option value={SURVEY_EXPORT.CLASSIC.id}>Responses by Question</option>
                <option value={SURVEY_EXPORT.CLASSIC_DUP.id}>Responses by Question (Duplicated)</option>
                <option value={SURVEY_EXPORT.LINEAR.id}>Responses by Person</option>
                <option value={SURVEY_EXPORT.LINEAR_DUP.id}>Responses by Person (Duplicated)</option>
                <option value={SURVEY_EXPORT.QUESTION_BY_QUESTION.id}>Topline Analysis</option>
                <option value={SURVEY_EXPORT.QUESTION_BY_QUESTION_DUP.id}>Topline Analysis (Duplicated)</option>
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              onClick={this.handleExportResultsClick}
            >
              Export Results
            </Button>
          </div>
        </h1>
        <div className="content-list" ref={ref => this.contentListRef = ref }>
          {contents.map(content => <ContentPanel key={content.id} content={content}/>)}  
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SurveyResultDetail);
