import React from 'react';
import PropTypes from 'prop-types';

import Utils from '~/services/Utils';

class VideoContent extends React.Component {
  static propTypes = {
    content: PropTypes.object.isRequired,
  };

  render = () => {
    const { content } = this.props;
    return (
      <video
        id={`video-${content.id}`}
        className="video-js vim-css"
        controls={true}
        preload="auto"
        width="640"
        height="264"
        poster=""
      >
        <source src={Utils.stripHtml(content.payload)} type="video/mp4"/>
        <p className="vjs-no-js">
          To view this video please enable JavaScript, and consider upgrading to a web browser that
          <a href="https://videojs.com/html5-video-support/" target="_blank" rel="noopener noreferrer">supports HTML5 video</a>
        </p>
      </video>
    );
  }
}

export default VideoContent;
