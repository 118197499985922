import ourFetch from './services/ourFetch';
// import hashToQueryString from './services/hashToQueryString';

// => {}
const getProgramWithContents = (dispatch, { module_or_exam_id, item_type }) =>
  ourFetch(dispatch,
    'GET', `/api/program-with-contents/${module_or_exam_id}/?item_type=${item_type}`
  );

// {
//   ...this.state.formState,
//   module_or_exam_id: this.props.createWith.moduleOrExamId,
//   content_type: this.props.createWith.contentType,
//   position: this.props.createWith.position
// }

const get = (dispatch, contentId) =>
  ourFetch(dispatch,
    'GET', `/api/contents/${contentId}/`
  );

const create = (dispatch, params) =>
  ourFetch(dispatch,
    'POST',
    params.item_type === 'certificate' ? '/api/certificate/element/add/' : '/api/contents/create/',
    params
  );

const update = (dispatch, contentId, params) =>
  ourFetch(dispatch,
    'PUT',
    params.item_type === 'certificate' ? `/api/certificate/element/${contentId}/update/` : `/api/contents/${contentId}/update/`,
    params
  );

const reorderAll = (dispatch, arrayOfContentIds) =>
  ourFetch(dispatch,
    'PUT', '/api/contents/reorder/',
    { content_ids: arrayOfContentIds }
  );

const _delete = (dispatch, contentId) =>
  ourFetch(dispatch,
    'DELETE', `/api/contents/${contentId}/delete/`
  );

const removeTransloaditAssemblyUrlFromVideo = (dispatch, contentId) =>
  ourFetch(dispatch,
    'PUT', `/api/contents/${contentId}/removeTransloaditAssemblyUrlFromVideo/`
  );

export default {
  getProgramWithContents,
  get,
  create,
  update, reorderAll,
  delete: _delete,
  removeTransloaditAssemblyUrlFromVideo
};
