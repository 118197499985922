import $ from 'jquery';
import _ from 'lodash/core';
import StudentApi from '~/api/StudentApi';

let Timer = null;

function initTimer() {
  const hms = window.time_spent ? window.time_spent : '0:0:0';   // your input string
  const a = hms.split(':'); // split it at the colons
  const hms_module = window.time_spent_module ? window.time_spent_module : '0:0:0';
  const a_module = hms_module.split(':');

  // minutes are worth 60 seconds. Hours are worth 60 minutes.
  const seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
  const seconds_module = (+a_module[0]) * 60 * 60 + (+a_module[1]) * 60 + (+a_module[2]);
  window.time = seconds;
  window.time_module = seconds_module;
  window.videosBlocked = false;

  function _timer(callback) {
    let mode = 1;           //  Mode: count up or count down
    let status = 0;         //  Status: timer is running or stoped
    let timer_id;           // This is used by setInterval function

    // This method will render the time variable to hour:minute:second format
    function generateTime() {
      let time;
      if (mode === 2) {
        time = window.time_limit - window.time;
      } else {
        time = window.time;
      }

      let second = time % 60;
      let minute = Math.floor(time / 60) % 60;
      let hour = Math.floor(time / 3600) % 60;

      second = (second < 10) ? '0' + second : second;
      minute = (minute < 10) ? '0' + minute : minute;
      hour = (hour < 10) ? '0' + hour : hour;

      $('.timer span.second').html(second);
      $('.timer span.minute').html(minute);
      $('.timer span.hour').html(hour);
    }

    // This methode will render the time variable to hour:minute:second format
    this.setTime = () => {
      const time = window.time;
      let second = time % 60;
      let minute = Math.floor(time / 60) % 60;
      let hour = Math.floor(time / 3600) % 60;

      second = (second < 10) ? '0' + second : second;
      minute = (minute < 10) ? '0' + minute : minute;
      hour = (hour < 10) ? '0' + hour : hour;

      if (window.time_limit === 0 || window.time_limit > window.time) {
        $('.timer span.second').html(second);
        $('.timer span.minute').html(minute);
        $('.timer span.hour').html(hour);
      } else {
        $('.timer span.second').html('Time Limit Reached');
      }
    };

    // this will start the timer ex. start the timer with 1 second interval timer.start(1000)
    this.start = (interval) => {
      interval = (typeof (interval) !== 'undefined') ? interval : 1000;

      if (status === 0) {
          status = 1;
          setInterval(() => {
            if (window.time_limit === 0 || window.time_limit > window.time) {
              window.time += 1;
              window.time_module += 1;
              generateTime();
              if (typeof (callback) === 'function') callback(window.time);
            } else {
              this.stop();
              window.if_current_module_is_completed = true;

              if (window.user_role === 'student') {
                window.location = '/program/time-limit/' + window.currentCourseId + '/' + window.time_limit + '/';
              }
            }
          }, interval);
      }
    };

    //  Same as the name, this will stop or pause the timer ex. Timer.stop()
    this.stop = () => {
      if (status === 1) {
        status = 0;
        clearInterval(timer_id);
      }
    };

    // Reset the timer to zero or reset it to your own custom time ex. reset to zero second timer.reset(0)
    this.reset = (sec) => {
      sec = (typeof (sec) !== 'undefined') ? sec : 0;
      window.time = sec;
      generateTime(window.time);
    };

    // Change the mode of the timer, count-up (1) or countdown (0)
    this.mode = (tmode) =>
      mode = tmode;

    // This methode return the current value of the timer
    this.getTime = () => window.time;

    // This methode return the current mode of the timer count-up (1) or countdown (0)
    this.getMode = () => mode;

    // This methode return the status of the timer running (1) or stoped (1)
    this.getStatus = () => status;
  }

  Timer = new _timer(
    (time) => {
      if (time === 0) {
        Timer.stop();
        alert('time out');
      }
    }
  );
}

function stopTimer() {
  if (Timer) {
    Timer.stop();
  }
}

function resetFlag(navigator_id) {

  var request = $.ajax({
    url: "https://myworldnavigator.com/wp-content/mu-plugins/navigator/api/survey_taken.php",
    type: "POST",
    data: {user_id : navigator_id},
    dataType: "json"
  });

  request.done(function(msg) {
    console.log(msg);
  });

  request.fail(function(jqXHR, textStatus) {
    console.log(textStatus);
    //alert( "Request failed: " + textStatus );
  });
  
}


function canContinue() {
  const playerMeta = window.playerMeta || [];

  if (window.current_course.minimum_time_required && window.time_module < window.current_module.minimum_time * 60) {
    return 1;    // User need to spend more than minimum_time
  }

  if (window.numVimeoElements > playerMeta.length) {
    window.videosBlocked = true;
    console.log('vimeo not loaded simple :(');
  }

  for (let i = 0; i < playerMeta.length; i += 1) {
    console.log('vimeo loop');
    console.log(playerMeta[i].tocID.toString().replace('video-', ''));

    if (window.required_videos.indexOf(playerMeta[i].tocID.toString().replace('video-', '')) > -1) {
      console.log('vimeo');
      console.log(playerMeta[i].duration);
      console.log(playerMeta[i].seconds + 1);
      console.log(window.videosBlocked);

      if ((playerMeta[i].duration > playerMeta[i].seconds + 1) && !window.videosBlocked) {
        return 2;
      }
    }
  }

  return 0;    // User can go next module.
}

/* Validate Questions Answered */
function validateQuestions() {
  
  $('.question-answer').each(function () {
    if ($(this).prop('checked')) {
      window.questions_answered_on_page = true;
    }
  });

  $('.not-applicable-radio-box input').each(function () {
    if ($(this).prop('checked')) {
      window.questions_answered_on_page = true;
    }
  });

  //Exception for pages with no other question choices
  if ( $(".multiple-choice-2").length == 0 && $(".multiple-choice").length == 0 && $(".nps").length == 0 ) {
    window.questions_answered_on_page = true;
  }
  
}

/* Count Test items */
function countItems() {
  let test_item_count = window.questions_answered;

  $('.question-answer').each(function () {
    if ($(this).prop('checked')) {
      test_item_count += 1;
    }
  });

  $('.test-likert').each(function () {
    const questions = $(this).find('.likert-question');
    const checkedOptions = $(this).find('.likert-option:checked');

    if (questions.length > 0 && questions.length === checkedOptions.length) {
      test_item_count += 1;
    }
  });

  $('.rating-stars').each(function () {
    const ratingStars = $(this).find('.glyphicon-star');

    if (ratingStars.length > 0) {
      test_item_count += 1;
    }
  });

  $('.text-input').each(function () {
    if (!_.isEmpty($(this).val())) {
      test_item_count += 1;
    }
  });


  $('.dropdown-input').each(function () {
    if (!_.isEmpty($(this).val())) {
      test_item_count += 1;
    }
  });
  $('.test-items-completed').text(test_item_count);
  let amountOfContents = parseInt($('#survey-amount-of-contents').text(), 10);
  $('#survey-progress-div').css('width', test_item_count/amountOfContents * 100 + '%');
  
  //Let's start with some very simple logic
  $('input[type=radio]').change(function() {
    showCommentsBoxIfQuestionAnswered();
  });

  
} //Close count items

function showCommentsBoxIfQuestionAnswered() {
  if ($(".answer-container").find('input').is(':checked')) {
    $('.survey-multi-line-text').attr('style', 'display: block !important');
  }

  //Exception for pages with no other question choices
  if ( $(".multiple-choice-2").length == 0 && $(".multiple-choice").length == 0 && $(".nps").length == 0 ) {
    $('.survey-multi-line-text').attr('style', 'display: block !important');
  }

}

function updateTime() {
  const currentTime = window.time;
  const ajax_data = {
    program_id: window.currentCourseId,
    module_id: window.currentModuleId,
    time: currentTime,
    time_module: window.time_module,
    videos: window.playerMeta || [],
    test_started: true,
  };

  StudentApi.updateTestTime(false, ajax_data)
    .then((data) => {
      if (data.time > currentTime) {
        window.time = data.time;
      }

      if (window.time_module < data.time_module) {
          window.time_module = data.time_module;
      }
  });
} //Close Update Time

function startTimer() {
  if (!window.if_current_module_is_completed) {
    if (window.time_limit > 0) {
      Timer.start(1000);
      Timer.mode(2);
    } else {
      Timer.start(1000);
      Timer.mode(1);
    }
  } else {
    Timer.setTime();
  }

  // // if we're not reviewing/previewing the course
  // // ATTENTION: don't check for user_profile.role == 'student', because if we review student's course as teacher our user_profile will be student's :-/
  if (!window.view_as) {
    updateTime();
    setInterval(() => updateTime(), 10000);
  }
}

$(document).ready(() => {
  if (typeof preventTimeUpdateInVimeoVideos !== 'undefined') {
    preventTimeUpdateInVimeoVideos(window.video_infos);
  }

  /* Completed Modules */
  if (window.if_current_module_is_completed || window.view_as > 0) {
    $('#droppable-area').on('click', 'input', (event) => {
      event.preventDefault();
    });
  }


  //Run on pageload
  showCommentsBoxIfQuestionAnswered();
  validateQuestions();

  countItems();
  if (Timer) {
    startTimer();
  }
});

export default {
  initTimer,
  stopTimer,
  canContinue,
  countItems,
  updateTime,
  validateQuestions,
  resetFlag
};
