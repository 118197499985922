import $ from 'jquery';

const getImageHtml = (formState) =>
  $('<img/>', {
    // .module-img className is needed for legacy reason
    class: 'module-img',
    src: formState.src,
    css: {
      width: formState.width
    }
  })[0].outerHTML;

const initialState = {
  src: false,
  width: 800,
  options: '50, 50',
};

const fromStateToApi = (formState) => ({
  payload: formState.src ? getImageHtml(formState) : null,
  options: formState.options,
  x_coordinate: formState.options ? formState.options.split(', ')[0] : '50',
  y_coordinate: formState.options ? formState.options.split(', ')[1] : '50',
});

const fromApiToState = (api) => {
  const htmlString = api.payload;
  const htmlEl = $($.parseHTML(htmlString));

  return {
    src: htmlEl.prop('src'),
    width: parseInt(htmlEl.css('width'), 10),
    options: api.options,
  };
};

export default {
  initialState,
  fromStateToApi,
  fromApiToState,
  getImageHtml
};
